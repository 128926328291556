// NPM Modules
import cookie from 'cookie';
// UI Actions
import { types } from '../actions/ui';
// User Actions
import user from '../actions/user';
/**
 * defaultState
 * @property {string} status null, get, failed, success, cancel
 * @property {object} error null or info on why request failed
 * @property {object} info null or response from request
 */
const defaultState = {
  scheme:
    typeof document === 'undefined'
      ? 'auto'
      : cookie.parse(document.cookie).scheme || 'auto'
};
/**
 * Template Get Reducer
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_SCHEME: {
      if (typeof document !== 'undefined') {
        const newCookie = cookie.serialize('scheme', action.payload.scheme, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365 // 1 year
        });
        document.cookie = newCookie;
      }
      return { ...state, scheme: action.payload.scheme };
    }
    case user.types.logout.SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
};
