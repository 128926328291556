// Components
import { Items } from '../../../../components/Cake';
import { FiveColor, MonoChrome } from './ColorSystems';

export default class HeatmapItem extends Items.Item {
  constructor(width, height, data, options) {
    super(0, 0, options);
    this.width = width;
    this.height = height;
    this.data = data;
    this.scale = options.hasOwnProperty('scale') ? options.scale : 10;
    this.type = options.hasOwnProperty('type') ? options.type : 'c';
    this.logScale = options.hasOwnProperty('logScale')
      ? options.logScale
      : false;
    this.colorSystem = options.hasOwnProperty('colorSystem')
      ? options.colorSystem
      : 'five';
  }
  draw(ctx) {
    // Setup items
    const colorSystem = this.colorSystem === 'five' ? FiveColor : MonoChrome;
    const min = Math.min(...this.data.map(node => node[this.type]));
    const max = Math.max(...this.data.map(node => node[this.type]));

    // Rendering
    ctx.save();

    // Set origin bottom left
    ctx.translate(0, ctx.canvas.height);
    ctx.scale(1, -1);

    // Render nodes
    this.data.forEach(node => {
      const { x, y } = node;
      // Draw node
      ctx.fillStyle = colorSystem(node[this.type], min, max, this.logScale);
      ctx.fillRect(x * this.scale, y * this.scale, this.scale, this.scale);
    });

    ctx.restore();
  }
}
