// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import array from '../../../../redux/actions/array';
// Material-UI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// Material-UI List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
// Material-UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// Material-UI Icons
import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
/**
 * Arrays Component
 */
class Arrays extends Component {
  /**
   * component state
   */
  state = {
    search: ''
  };
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getArrayList({ org_key: this.props.org_key, status: 'active' });
  }
  /**
   *
   * @param {*} array
   */
  handleDataPointAdd(array) {
    this.props.onAdd({
      metadata: { ...array },
      dataPoint: {
        array_key: array.array_key,
        label: array.array_name,
        color: array.color,
        start: this.props.startDateTime,
        end: this.props.endDateTime,
        timezone: this.props.timezone
      }
    });
  }
  /**
   *
   * @param {*} dataPoint
   */
  handleDataPointRemove(dataPoint) {
    this.props.onRemove(dataPoint.id);
  }
  /**
   * Debounce func for dealing with input delay
   * @param {*} fn
   * @param {*} time
   * @returns
   */
  debounce = (fn, time) => {
    let timeout;
    return function () {
      const functionCall = () => fn.apply(this, arguments);
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  };
  /**
   *
   */
  updateSearch = this.debounce(search => {
    this.setState({ search });
    this.props.getArrayList({
      org_key: this.props.org_key,
      search,
      status: 'active'
    });
  }, 500);
  /**
   *
   * @param {*} param0
   */
  handleSearchUpdate = ({ target }) => {
    this.updateSearch(target.value);
  };
  /**
   * Returns a list of arrays that
   * @returns {*}
   */
  generateNonDataPointArrayList() {
    const filteredArrays = this.props.list.filter(array =>
      this.props.dataPoints.find(
        dataPoint => dataPoint.array_key === array.array_key
      )
        ? false
        : true
    );

    const arrayList = {};

    filteredArrays.forEach(item => {
      arrayList[item.deployment_key] = {
        arrays: arrayList[item.deployment_key]
          ? [...arrayList[item.deployment_key].arrays, item]
          : [item],
        info: {
          deployment_name: item.deployment_name,
          deployment_key: item.deployment_key
        }
      };
    });

    return arrayList;
  }
  /**
   * render
   */
  render() {
    const { arrays, open, dataPoints, onClose } = this.props;
    const arrayList = this.generateNonDataPointArrayList();

    const dataPointDeployments = {};
    dataPoints.forEach(dp => {
      dataPointDeployments[arrays[dp.array_key].deployment_key] =
        arrays[dp.array_key].deployment_name;
    });

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          Select data points to display
          <TextField
            label="Search Data Points"
            fullWidth
            autoFocus
            defaultValue={this.state.search}
            onChange={this.handleSearchUpdate}
          />
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.entries(dataPointDeployments).map(
              ([deployment_key, deployment_name]) => (
                <List
                  key={deployment_key}
                  subheader={
                    <ListSubheader component="div" disableSticky>
                      {deployment_name}
                    </ListSubheader>
                  }
                >
                  {dataPoints
                    .filter(
                      dataPoint =>
                        arrays[dataPoint.array_key].deployment_key ===
                        deployment_key
                    )
                    .map(dataPoint => (
                      <ListItem
                        key={dataPoint.id}
                        dense
                        button
                        onClick={() => this.handleDataPointRemove(dataPoint)}
                      >
                        <ListItemIcon>
                          <ExtensionRoundedIcon
                            style={{
                              color: `rgb(${dataPoint.color.toString()})`
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${
                            arrays[dataPoint.array_key].array_name
                          } (${arrays[dataPoint.array_key].array_key})`}
                        />
                        <Checkbox color="primary" checked />
                      </ListItem>
                    ))}
                </List>
              )
            )}
            {dataPoints.length === 0 ? (
              <Typography variant="subtitle1">No points selected.</Typography>
            ) : null}
          </List>
          <Divider />
          {Object.values(arrayList).map(deployment => (
            <List
              key={deployment.info.deployment_key}
              subheader={
                <ListSubheader component="div" disableSticky>
                  {deployment.info.deployment_name}
                </ListSubheader>
              }
            >
              {Object.values(deployment.arrays).map(array => (
                <ListItem
                  key={array.array_key}
                  dense
                  button
                  onClick={() => this.handleDataPointAdd(array)}
                  style={{ paddingLeft: '16px' }}
                >
                  <ListItemIcon>
                    <ExtensionRoundedIcon
                      style={{ color: `rgb(${array.color.toString()})` }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${array.array_name} (${array.array_key})`}
                  />
                  <Checkbox color="primary" disableRipple />
                </ListItem>
              ))}
            </List>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
  /**
   * propTypes
   */
  static propTypes = {
    open: PropTypes.bool,
    dataPoints: PropTypes.array,
    onAdd: PropTypes.func,
    onClose: PropTypes.func,
    onRemove: PropTypes.func
  };
  /**
   * propTypes.defaultProps
   */
  static defaultProps = {
    open: false,
    dataPoints: [],
    onAdd: () => {},
    onClose: () => {},
    onRemove: () => {}
  };
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state, props) {
    return {
      // Array List
      list: state.array.list.list,
      page: state.array.list.page,
      count: state.array.list.count,
      error: state.array.list.error,
      status: state.array.list.status,
      fetched: state.array.list.fetched,
      // Organization Info
      org_key: state.organization.info.info.org_key,
      // Metadata Arrays
      arrays: state.metadata.array,
      // Date Time Global System
      timezone: state.dateTime.timezone,
      endDateTime: state.dateTime.endDateTime,
      startDateTime: state.dateTime.startDateTime
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Array
        getArrayList: array.list.get
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  Arrays.mapStateToProps,
  Arrays.mapDispatchToProps
)(Arrays);
