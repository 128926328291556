// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../actions/authentication';
// Reducer Template
import post from './templates/post';
// Reducer
export default combineReducers({
  passwordReset: (s, a) => post(s, a, types.passwordReset),
  passwordResetRequest: (s, a) => post(s, a, types.passwordResetRequest)
});
