export default {
  palette: {
    type: 'dark',
    background: {
      default: 'rgba(5, 32, 35, 1)',
      paper: 'rgba(9, 52, 55, 0.9)'
    }
  },
  navigation: {
    topBar: {
      text: '#fff',
      background: 'rgba(7, 42, 45, 0.9)'
    }
  }
};
