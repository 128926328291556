// NPM Modules
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// Materail-UI
import IconButton from '@material-ui/core/IconButton';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import AppBar from '@material-ui/core/AppBar';

import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
// Styled Components
import { Section, Toolbar } from './style';
// Components
import SimpleDatePicker from './components/SimpleDatePicker';
// Actions
import dateTime from '../../redux/actions/dateTime';
/**
 * Date Range Picker Component
 */
class DateRangePicker extends Component {
  state = {
    autoUpdate: false,
    selected: 4,
    openSimpleDatePicker: false,
    showDateTimeInfo: !this.props.isMobile,
    moreMenu: false
  };
  /**
   * timer
   * Handles auto updates to the system.
   */
  timer = this.setTimer();
  /**
   * setTimer
   */
  setTimer() {
    return setInterval(() => {
      if (this.state.autoUpdate) {
        this.props.setEndDateTime(new Date());
      }
    }, 30000);
  }
  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  resetTimer() {
    this.clearTimer();
    this.timer = this.setTimer();
  }
  /**
   * oneHour
   */
  oneHour = () => {
    if (!this.state.autoUpdate) this.setState({ autoUpdate: false });
    if (this.state.selected !== 0) this.setState({ selected: 0 });
    this.resetTimer();
    this.props.setAll({
      startDateTime: moment
        .tz(this.props.timezone)
        .subtract(1, 'hours')
        .format('Y-MM-DDTHH:mm:ss'),
      endDateTime: moment.tz(this.props.timezone).format('Y-MM-DDTHH:mm:ss'),
      interval: '5m',
      threshold: 4000
    });
  };
  /**
   * oneDay
   */
  oneDay = () => {
    if (!this.state.autoUpdate) this.setState({ autoUpdate: false });
    if (this.state.selected !== 1) this.setState({ selected: 1 });
    this.resetTimer();
    this.props.setAll({
      startDateTime: moment
        .tz(this.props.timezone)
        .set({ hour: 0, minute: 0, second: 0 })
        .format('Y-MM-DDTHH:mm:ss'),
      endDateTime: moment
        .tz(this.props.timezone)
        .set({ hour: 23, minute: 59, second: 59 })
        .format('Y-MM-DDTHH:mm:ss'),
      interval: '1h',
      threshold: 4000
    });
  };
  /**
   * oneWeek
   */
  oneWeek = () => {
    if (this.state.autoUpdate) this.setState({ autoUpdate: false });
    if (this.state.selected !== 2) this.setState({ selected: 2 });
    this.resetTimer();
    this.props.setAll({
      startDateTime: moment
        .tz(this.props.timezone)
        .subtract(7, 'days')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('Y-MM-DDTHH:mm:ss'),
      endDateTime: moment
        .tz(this.props.timezone)
        .set({ hour: 23, minute: 59, second: 59 })
        .format('Y-MM-DDTHH:mm:ss'),
      interval: '1d',
      threshold: 4000
    });
  };
  /**
   * oneMonth
   */
  oneMonth = () => {
    if (this.state.autoUpdate) this.setState({ autoUpdate: false });
    if (this.state.selected !== 3) this.setState({ selected: 3 });
    this.resetTimer();
    this.props.setAll({
      startDateTime: moment
        .tz(this.props.timezone)
        .subtract(1, 'months')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('Y-MM-DDTHH:mm:ss'),
      endDateTime: moment
        .tz(this.props.timezone)
        .set({ hour: 23, minute: 59, second: 59 })
        .format('Y-MM-DDTHH:mm:ss'),
      interval: '1d',
      threshold: 4000
    });
  };
  /**
   * handleDateChange
   */
  handleDateChange = (
    startDateTime,
    endDateTime,
    interval,
    timezone,
    threshold
  ) => {
    if (this.state.autoUpdate) this.setState({ autoUpdate: false });
    if (this.state.selected !== 4) this.setState({ selected: 4 });
    this.resetTimer();
    this.props.setAll({
      startDateTime,
      endDateTime,
      interval,
      timezone,
      threshold
    });
  };
  toggleMoreMenu = event => {
    this.setState({
      moreMenu: !this.state.moreMenu
    });
  };
  /**
   * render
   * @return {React Component} React Component
   */
  render() {
    const { startDateTime, endDateTime, interval, timezone, isMobile } =
      this.props;

    const [intervalNumber, intervalType] = interval.split(/(d|h|m|M)/g, 2);

    let intervalTypeFullName;
    switch (intervalType) {
      case 'd':
        intervalTypeFullName = 'day';
        break;
      case 'h':
        intervalTypeFullName = 'hour';
        break;
      case 'm':
        intervalTypeFullName = 'minute';
        break;
      case 'M':
        intervalTypeFullName = 'month';
        break;
      default:
        break;
    }
    if (parseInt(intervalNumber, 10) > 1) intervalTypeFullName += 's';
    const displayInterval = `${intervalNumber} ${intervalTypeFullName}`;

    const start = moment.tz(timezone).set({ hour: 0, minute: 0, second: 0 });
    const end = moment
      .tz(timezone)
      .set({ hour: 23, minute: 59, second: 59 })
      .format('Y-MM-DDTHH:mm:ss');

    const DeviceButton = isMobile ? IconButton : Button;
    return (
      <div>
        <SimpleDatePicker
          onClose={() => this.setState({ openSimpleDatePicker: false })}
          onUpdate={this.handleDateChange}
          startDateTime={this.props.startDateTime}
          endDateTime={this.props.endDateTime}
          threshold={this.props.threshold}
          interval={this.props.interval}
          timezone={this.props.timezone}
          open={this.state.openSimpleDatePicker}
          hasInterval={this.props.hasInterval}
          hasThreshold={this.props.hasThreshold}
        />
        <Section isMobile={isMobile} seperate>
          <div>
            <Tooltip
              title={`${moment
                .tz(timezone)
                .subtract(1, 'hours')
                .format('h:mm:ss A')}${moment
                .tz(timezone)
                .format('h:mm:ss A')}`}
            >
              <DeviceButton
                color={this.state.selected === 0 ? 'primary' : 'default'}
                onClick={this.oneHour}
                style={isMobile ? { width: '48px', height: '48px' } : null}
              >
                {isMobile ? (
                  <Typography variant="button">1h</Typography>
                ) : (
                  '1 hour'
                )}
              </DeviceButton>
            </Tooltip>
            <Tooltip title={`${start.format('Y-MM-DDTHH:mm:ss')} | ${end}`}>
              <DeviceButton
                color={this.state.selected === 1 ? 'primary' : 'default'}
                onClick={this.oneDay}
                style={isMobile ? { width: '48px', height: '48px' } : null}
              >
                {isMobile ? (
                  <Typography variant="button">1d</Typography>
                ) : (
                  '1 day'
                )}
              </DeviceButton>
            </Tooltip>
            <Tooltip
              title={`${start
                .subtract(7, 'days')
                .format('Y-MM-DDTHH:mm:ss')} | ${end}`}
            >
              <DeviceButton
                color={this.state.selected === 2 ? 'primary' : 'default'}
                onClick={this.oneWeek}
                style={isMobile ? { width: '48px', height: '48px' } : null}
              >
                {isMobile ? (
                  <Typography variant="button">1w</Typography>
                ) : (
                  '1 week'
                )}
              </DeviceButton>
            </Tooltip>
            <Tooltip
              title={`${start
                .subtract(1, 'months')
                .format('Y-MM-DDTHH:mm:ss')} | ${end}`}
            >
              <DeviceButton
                color={this.state.selected === 3 ? 'primary' : 'default'}
                onClick={this.oneMonth}
                style={isMobile ? { width: '48px', height: '48px' } : null}
              >
                {isMobile ? (
                  <Typography variant="button">1m</Typography>
                ) : (
                  '1 month'
                )}
              </DeviceButton>
            </Tooltip>
            {isMobile ? (
              <IconButton
                color={this.state.selected === 4 ? 'primary' : 'default'}
                onClick={() =>
                  this.setState({ openSimpleDatePicker: true, selected: 4 })
                }
              >
                <DateRangeRoundedIcon />
              </IconButton>
            ) : (
              <Tooltip title={`Set custom date range`}>
                <Button
                  color={this.state.selected === 4 ? 'primary' : 'default'}
                  onClick={() =>
                    this.setState({ openSimpleDatePicker: true, selected: 4 })
                  }
                >
                  Date Range
                </Button>
              </Tooltip>
            )}
            {!isMobile && (
              <React.Fragment>
                <Tooltip title={`Auto refresh data every 30 seconds when on`}>
                  <Button
                    onClick={() =>
                      this.setState({ autoUpdate: !this.state.autoUpdate })
                    }
                  >
                    Auto Update: {this.state.autoUpdate ? 'on' : 'off'}
                  </Button>
                </Tooltip>
                <Tooltip title={`Toggle date range info`}>
                  <IconButton
                    style={{ width: '36px', height: '36px' }}
                    onClick={() =>
                      this.setState({
                        showDateTimeInfo: !this.state.showDateTimeInfo
                      })
                    }
                  >
                    {this.state.showDateTimeInfo ? (
                      <VisibilityRoundedIcon />
                    ) : (
                      <VisibilityOffRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
          <div>
            {isMobile ? (
              <React.Fragment>
                <IconButton onClick={this.toggleMoreMenu}>
                  <MoreVertRoundedIcon />
                </IconButton>
                <Dialog
                  onClose={this.toggleMoreMenu}
                  open={this.state.moreMenu}
                  fullScreen
                >
                  <AppBar position="relative" color="transparent" elevation="0">
                    <Toolbar>
                      <IconButton edge="start" onClick={this.toggleMoreMenu}>
                        <ArrowBackRoundedIcon />
                      </IconButton>
                      <Typography variant="h6" style={{ marginLeft: '16px' }}>
                        Extra
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <React.Fragment>
                    <List>
                      <ListItem>
                        <ListItemText>Auto Update Data</ListItemText>
                        <ListItemSecondaryAction>
                          <Switch
                            edge="end"
                            color="primary"
                            tabIndex={-1}
                            checked={this.state.autoUpdate}
                            onChange={() => {
                              this.setState({
                                autoUpdate: !this.state.autoUpdate
                              });
                            }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Date Time Info Labels</ListItemText>
                        <ListItemSecondaryAction>
                          <Switch
                            edge="end"
                            color="primary"
                            tabIndex={-1}
                            checked={this.state.showDateTimeInfo}
                            onChange={() => {
                              this.setState({
                                showDateTimeInfo: !this.state.showDateTimeInfo
                              });
                            }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                    {this.props.hasOwnProperty('exporter') &&
                      this.props.exporter}
                  </React.Fragment>
                </Dialog>
              </React.Fragment>
            ) : (
              this.props.hasOwnProperty('exporter') && this.props.exporter
            )}
          </div>
        </Section>
        {this.state.showDateTimeInfo && (
          <React.Fragment>
            <Divider />
            <Section isMobile={isMobile}>
              <Button disabled>
                <Typography variant="button" color="textPrimary">
                  Start Date Time:{' '}
                  <Typography variant="button" color="textSecondary">
                    {moment
                      .tz(startDateTime, timezone)
                      .format('MMMM Do hh:mm a')}
                  </Typography>
                </Typography>
              </Button>
              <Button disabled>
                <Typography variant="button" color="textPrimary">
                  End Date Time:{' '}
                  <Typography variant="button" color="textSecondary">
                    {moment.tz(endDateTime, timezone).format('MMMM Do hh:mm a')}
                  </Typography>
                </Typography>
              </Button>
              {this.props.hasInterval && (
                <Button disabled>
                  <Typography variant="button" color="textPrimary">
                    Interval:{' '}
                    <Typography variant="button" color="textSecondary">
                      {displayInterval}
                    </Typography>
                  </Typography>
                </Button>
              )}
              {this.props.hasThreshold && (
                <Button disabled>
                  <Typography variant="button" color="textPrimary">
                    Threshold:{' '}
                    <Typography variant="button" color="textSecondary">
                      {this.props.threshold / 1000} Seconds
                    </Typography>
                  </Typography>
                </Button>
              )}
              <Button disabled>
                <Typography variant="button" color="textPrimary">
                  Timezone:{' '}
                  <Typography variant="button" color="textSecondary">
                    {timezone}
                  </Typography>
                </Typography>
              </Button>
            </Section>
          </React.Fragment>
        )}
      </div>
    );
  }
  /**
   * Component PropTypes
   */
  static propTypes = {
    hasInterval: PropTypes.bool,
    hasThreshold: PropTypes.bool
  };
  /**
   * Component Default PropTypes
   */
  static defaultProps = {
    hasInterval: true,
    hasThreshold: false
  };
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      isMobile: state.device.isMobile,
      startDateTime: state.dateTime.startDateTime,
      endDateTime: state.dateTime.endDateTime,
      interval: state.dateTime.interval,
      timezone: state.dateTime.timezone,
      threshold: state.dateTime.threshold
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        setAll: dateTime.all.set,
        setStartDateTime: dateTime.startDateTime.set,
        setEndDateTime: dateTime.endDateTime.set,
        setInterval: dateTime.interval.set
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  DateRangePicker.mapStateToProps,
  DateRangePicker.mapDispatchToProps
)(DateRangePicker);
