// NPM Modules
import { v4 as uuidv4 } from 'uuid';
// DataPoint Actions
import { types } from '../actions/dataPointSystem';
// Organization Actions
import organization from '../actions/organization';
// User Actions
import user from '../actions/user';
/**
 * Reducers
 */
export default (state = {}, { type, payload }) => {
  switch (type) {
    /**
     * Reset data when logging out and switching organizations
     */
    case user.types.LOGOUT_SUCCESS:
    case organization.types.info.GET: {
      return {};
    }

    // dataPoints
    case types.ADD: {
      const dataPoint = {
        id: uuidv4(),
        ...payload
      };
      return { ...state, [dataPoint.id]: dataPoint };
    }
    case types.UPDATE: {
      const dataPoint = {
        ...state[payload.id],
        ...payload
      };
      return { ...state, [dataPoint.id]: dataPoint };
    }
    case types.REMOVE: {
      const stateCopy = { ...state };
      delete stateCopy[payload.id];
      return stateCopy;
    }
    default: {
      return state;
    }
  }
};
