// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Components
import ZoneField from './ZoneField';
import {
  convertArrayToRegion,
  convertRegionToArray,
  validateRegion
} from '../util';
// Styled Components
import { ZoneIcon } from './style';
// Material-UI
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Dialog Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// Material-UI Icons
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { convertPolygonToRegion } from '../../../../../components/ArrayConfigurationTool/util';
/**
 * Zone Item
 */
class ZoneItem extends Component {
  state = {
    // Zone Info
    zone_name: this.props.zone.zone_name,
    zone_type: this.props.zone.zone_type,
    algorithm_name: this.props.zone.algorithm_name,
    algorithm_settings: this.props.zone.algorithm_settings,
    start_date: this.props.zone.start_date,
    end_date: this.props.zone.end_date,
    region: this.props.zone.region,
    // Extra Zone Fields
    regionTextFieldValue: this.props.zone.region, // this is used for validating region inputs
    invalidRegion: false, // Toggle show if region input is valid
    // ZoneItem hide state
    collapse: true, // all zone items should be collapsed on init render
    // ZoneItem dailog states
    showSaveDialog: false,
    showDeleteDialog: false
  };
  /**
   *
   */
  componentDidMount() {}
  /**
   *
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.editing && // Validate this zone is being edited
      prevProps.region.toString() !== this.props.region.toString() // Validate that the prop region is update so update the state
    ) {
      const tempRegion = convertArrayToRegion(this.props.region, true);
      if (this.state.region !== tempRegion) {
        this.setState({ region: tempRegion, regionTextFieldValue: tempRegion });
      }
    }
  }
  hanldeRegionChange = ({ target }) => {
    const valid = validateRegion(target.value);

    if (valid) {
      this.props.onRegionChanged(convertRegionToArray(target.value, true));
    }

    this.setState({
      regionTextFieldValue: target.value,
      invalidRegion: !valid
    });
  };
  /**
   *
   * @param {*} event
   */
  handleZoneFieldChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  /**
   *
   */
  handleEditZoneSave = () => {
    if (this.state.invalidRegion) return; // Only save if region is valid

    this.props.onEditZoneSave(this.props.zone, {
      algorithm_name: this.state.algorithm_name,
      algorithm_settings: this.state.algorithm_settings,
      array_key: this.props.zone.array_key,
      region: this.state.region,
      zone_name: this.state.zone_name,
      zone_type: this.state.zone_type,
      start_date: this.state.start_date,
      ...(this.state.end_date ? { end_date: this.state.end_date } : {})
    });
  };
  /**
   * toggle the edit state. If zone is in editing mode the fields can be changed
   */
  toggleZoneEdit = () => {
    if (this.props.editing) {
      this.setState({
        ...this.props.zone,
        regionTextFieldValue: this.props.zone.region,
        invalidRegion: false
      });
      this.props.onSetEditZone(null);
    } else {
      this.props.onSetEditZone(this.props.zone);
    }
    this.setState({ editing: !this.props.editing, collapse: false });
  };
  /**
   * toggle collapse state of info panel
   */
  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  /**
   * toggle save dialog
   */
  toggleSaveDialog = () => {
    this.setState({ showSaveDialog: !this.state.showSaveDialog });
  };
  /**
   * toggle delete dialog
   */
  toggleDeleteDialog = () => {
    this.setState({ showDeleteDialog: !this.state.showDeleteDialog });
  };
  /**
   * Dialog telling user that modifying a zone creates a new copy of the old with the the new fields changed
   * @returns {Component}
   */
  renderSaveDialog = () => (
    <Dialog open={this.state.showSaveDialog} onClose={this.toggleSaveDialog}>
      <DialogTitle>Save zone changes?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Modifying a zone creates a new copy of that zone with the changed
          fields. Doing this action will delete the old zone and create the new
          one. Zone key will change. Analysis will need to be rerun for old
          data. Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.toggleSaveDialog}>Cancel</Button>
        <Button onClick={this.handleEditZoneSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
  /**
   * Dialog telling user that deleting a zone is permanent
   * @returns {Component}
   */
  renderDeleteDialog = () => (
    <Dialog
      open={this.state.showDeleteDialog}
      onClose={this.toggleDeleteDialog}
    >
      <DialogTitle>Delete zone forever?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Once a zone is deleted it can never be undeleted. This is a permanent
          action. Are you sure you want to delete this zone?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.toggleDeleteDialog}>Cancel</Button>
        <Button onClick={this.props.onDelete}>Delete forever</Button>
      </DialogActions>
    </Dialog>
  );
  /**
   * List header for the zone
   * @returns {Component}
   */
  renderZoneHeader = () => (
    <ListItem dense>
      <ListItemIcon>
        <ZoneIcon iconcolor={this.props.zone.color} />
      </ListItemIcon>
      <ListItemText
        primary={this.props.zone.zone_name}
        secondary={this.props.zone.zone_key}
      />
      <ListItemSecondaryAction>
        {this.props.canCreateDelete ? (
          <IconButton onClick={this.toggleZoneEdit}>
            {this.props.editing ? <CancelRoundedIcon /> : <EditRoundedIcon />}
          </IconButton>
        ) : null}
        <IconButton edge="end" onClick={this.toggleCollapse}>
          {this.state.collapse ? (
            <ExpandMoreRoundedIcon />
          ) : (
            <ExpandLessRoundedIcon />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
  /**
   *
   * @returns {Component}
   */
  renderZoneInfo = () => (
    <Collapse in={!this.state.collapse} timeout="auto" unmountOnExit>
      <List>
        {this.props.editing ? (
          <ZoneField
            disabled={!this.props.editing}
            label="Zone Name"
            name="zone_name"
            value={this.state.zone_name}
            onChange={this.handleZoneFieldChange}
          />
        ) : null}
        <ZoneField
          disabled={!this.props.editing}
          label="Zone Type"
          name="zone_type"
          value={this.state.zone_type}
          onChange={this.handleZoneFieldChange}
        />
        <ZoneField
          disabled={!this.props.editing}
          label="Algorithm Name"
          name="algorithm_name"
          value={this.state.algorithm_name}
          onChange={this.handleZoneFieldChange}
        />
        <ZoneField
          disabled={!this.props.editing}
          label="Algorithm Settings"
          name="algorithm_settings"
          value={JSON.stringify(this.state.algorithm_settings)}
          onChange={this.handleZoneFieldChange}
        />
        <ZoneField
          disabled={!this.props.editing}
          label="Start Date"
          name="start_date"
          value={this.state.start_date}
          onChange={this.handleZoneFieldChange}
        />
        <ZoneField
          disabled={!this.props.editing}
          label="End Date"
          name="end_date"
          value={this.state.end_date ? this.state.end_date : 'On-going'}
          onChange={this.handleZoneFieldChange}
        />
        <ZoneField
          disabled={!this.props.editing}
          label="Region"
          name="region"
          value={this.state.regionTextFieldValue}
          error={this.state.invalidRegion}
          onChange={this.hanldeRegionChange}
        />
        {this.props.editing ? (
          <ListItem dense>
            <ListItemText
              inset
              primary={
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={this.toggleZoneEdit}
                    variant="outlined"
                    fullWidth
                    style={{ marginRight: '8px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.toggleSaveDialog}
                    variant="outlined"
                    fullWidth
                    style={{ marginLeft: '8px' }}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </ListItem>
        ) : (
          <ListItem dense>
            <ListItemText
              inset
              primary={
                <Button onClick={this.toggleDeleteDialog} variant="outlined">
                  Delete forever
                </Button>
              }
            />
          </ListItem>
        )}
      </List>
    </Collapse>
  );
  /**
   *
   * @returns {Component}
   */
  render() {
    return (
      <React.Fragment>
        {this.renderSaveDialog()}
        {this.renderDeleteDialog()}
        {this.renderZoneHeader()}
        {this.renderZoneInfo()}
      </React.Fragment>
    );
  }
}

export default ZoneItem;
