// NPM Modules
import { combineReducers } from 'redux';

// Reducers
import ui from './ui';
import user from './user';
import zone from './zone';
import array from './array';
import roles from './roles';
import users from './users';
import device from './device';
import metadata from './metadata';
import dateTime from './dateTime';
import location from './location';
import aggregate from './aggregate';
import dataPoints from './dataPoints';
import deployment from './deployment';
import organization from './organization';
import authentication from './authentication';
import confirmAccount from './confirmAccount';
import dataPointSystem from './dataPointSystem';

// Export reducers together as one object
export default combineReducers({
  aggregate,
  array,
  authentication,
  confirmAccount,
  dataPoints,
  dataPointSystem,
  dateTime,
  deployment,
  device,
  location,
  metadata,
  organization,
  roles,
  ui,
  user,
  users,
  zone
});
