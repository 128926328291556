// Action Templates
import get from './templates/get';
import post from './templates/post';
// Action Calls
export const types = {
  info: {
    GET: 'array/INFO:GET',
    RESET: 'array/INFO:RESET',
    CANCEL: 'array/INFO:CANCEL',
    FAILED: 'array/INFO:FAILED',
    SUCCESS: 'array/INFO:SUCCESS'
  },
  list: {
    GET: 'array/LIST:GET',
    RESET: 'array/LIST:RESET',
    CANCEL: 'array/LIST:CANCEL',
    FAILED: 'array/LIST:FAILED',
    SUCCESS: 'array/LIST:SUCCESS'
  },
  create: {
    POST: 'array/CREATE:POST',
    RESET: 'array/CREATE:RESET',
    CANCEL: 'array/CREATE:CANCEL',
    FAILED: 'array/CREATE:FAILED',
    SUCCESS: 'array/CREATE:SUCCESS'
  },
  edit: {
    POST: 'array/EDIT:POST',
    RESET: 'array/EDIT:RESET',
    CANCEL: 'array/EDIT:CANCEL',
    FAILED: 'array/EDIT:FAILED',
    SUCCESS: 'array/EDIT:SUCCESS'
  },
  remove: {
    POST: 'array/DELETE:POST',
    RESET: 'array/DELETE:RESET',
    CANCEL: 'array/DELETE:CANCEL',
    FAILED: 'array/DELETE:FAILED',
    SUCCESS: 'array/DELETE:SUCCESS'
  },
  matList: {
    GET: 'array/mat/LIST:GET',
    RESET: 'array/mat/LIST:RESET',
    CANCEL: 'array/mat/LIST:CANCEL',
    FAILED: 'array/mat/LIST:FAILED',
    SUCCESS: 'array/mat/LIST:SUCCESS'
  }
};
// Deployment Functions
export default {
  types,
  info: get(types.info),
  list: get(types.list),
  matList: get(types.matList),
  create: post(types.create),
  edit: post(types.edit),
  remove: post(types.remove)
};
