// NPM Modules
import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
// Material-UI
import Button from '@material-ui/core/Button';
// Themes
import themes from '../../../../../theme/themes';
// Styled Components
export const SchemeButton = styled(Button)`
  ${props => {
    const prefersColorScheme =
      typeof window === 'undefined'
        ? 'nordLight'
        : window.matchMedia('(prefers-color-scheme: light)').matches
        ? 'nordLight'
        : 'nord';
    const theme = createMuiTheme(
      themes[props.scheme === 'auto' ? prefersColorScheme : props.scheme]
    );
    return `
      color: ${theme.palette.text.primary};
      background: ${theme.palette.background.default};
      padding: ${theme.spacing(2)}px;
      border: 2px solid ${
        props.selected
          ? props.theme.palette.primary.main
          : props.theme.palette.divider
      };

      &:hover {
        background: ${theme.palette.background.default};
        border: 2px solid ${props.theme.palette.primary.main};
      }

      
      & > span {
        display:flex;
      }

      & > span > span {
        flex-grow: 1;
      }

      & > span > span:first-child {
        flex-grow: 0;
        flex-shrink: 1;
      }
    `;
  }}
`;
export const SchemeGroup = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
  column-gap: ${({ theme }) => theme.spacing(2)}px;
  grid-template-columns: repeat(auto-fill, minmax(177px, 1fr));
`;
