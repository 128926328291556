// NPM Modules
import equal from 'deep-equal';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Styled Components
// import {} from './style';
// Material-UI
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker } from '@material-ui/pickers';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
/**
 * HeatmapInfoPanel => DataPointInfo Component
 */
class DataPointInfo extends Component {
  /**
   * Component PropTypes
   */
  static propTypes = {
    dataPoint: PropTypes.object
  };
  /**
   * Component Default PropTypes
   */
  static defaultProps = {
    dataPoint: {}
  };
  /**
   * Component State
   */
  state = {
    collapse: false,
    start: moment.tz(this.props.dataPoint.start, this.props.dataPoint.timezone),
    end: moment.tz(this.props.dataPoint.end, this.props.dataPoint.timezone),
    timezone: this.props.dataPoint.timezone,
    timezoneInput: this.props.dataPoint.timezone,
    timezoneList: moment.tz.names()
  };
  /**
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!equal(prevProps.dataPoint, this.props.dataPoint)) {
      this.setState({
        start: moment.tz(
          this.props.dataPoint.start,
          this.props.dataPoint.timezone
        ),
        end: moment.tz(this.props.dataPoint.end, this.props.dataPoint.timezone),
        timezone: this.props.dataPoint.timezone,
        timezoneInput: this.props.dataPoint.timezone
      });
    }
  }
  /**
   * Component Render
   * @returns {Component}
   */
  render() {
    const { dataPoint } = this.props;
    return (
      <React.Fragment>
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary="Data Point Settings"
              secondary={`Heatmap: ${dataPoint.label}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() =>
                  this.setState({
                    collapse: !this.state.collapse
                  })
                }
              >
                {this.state.collapse ? (
                  <ExpandMoreRoundedIcon />
                ) : (
                  <ExpandLessRoundedIcon />
                )}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Collapse in={!this.state.collapse} timeout="auto" unmountOnExit>
          <List dense>
            <ListItem>
              <ListItemText primary="Logarithmic Scale (Base 10)" />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => this.handleToggleUpdate('toggleLogScale')}
                >
                  {dataPoint.toggleLogScale ? (
                    <CheckBoxRoundedIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Duration Map" />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => this.handleToggleUpdate('toggleDuration')}
                >
                  {dataPoint.toggleDuration ? (
                    <CheckBoxRoundedIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Show Zones" />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => this.handleToggleUpdate('toggleZones')}
                >
                  {dataPoint.toggleZones ? (
                    <CheckBoxRoundedIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Divider variant="middle" />
          <List dense>
            <ListItem>
              <ListItemText primary="Synced To Global Date Time System" />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => this.handleToggleUpdate('toggleGlobalSync')}
                >
                  {dataPoint.toggleGlobalSync ? (
                    <CheckBoxRoundedIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <FormControl fullWidth>
                    <DateTimePicker
                      label="Start Date"
                      size="small"
                      inputVariant="outlined"
                      value={this.state.start}
                      onChange={this.handleStartChange}
                      disabled={dataPoint.toggleGlobalSync}
                      disableFuture
                    />
                  </FormControl>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <FormControl fullWidth>
                    <DateTimePicker
                      label="End Date"
                      size="small"
                      inputVariant="outlined"
                      value={this.state.end}
                      onChange={this.handleEndChange}
                      disabled={dataPoint.toggleGlobalSync}
                      disableFuture
                    />
                  </FormControl>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Autocomplete
                    fullWidth
                    margin="normal"
                    size="small"
                    value={this.state.timezone}
                    disabled={dataPoint.toggleGlobalSync}
                    inputValue={this.state.timezoneInput}
                    options={this.state.timezoneList}
                    getOptionLabel={option => option}
                    onChange={(event, newInputValue) => {
                      this.setState({ timezone: newInputValue });
                    }}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ timezoneInput: newInputValue });
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Timezone"
                        name="timezone"
                        variant="outlined"
                      />
                    )}
                    groupBy={option => option.split('/')[0]}
                  />
                  // <TextField
                  //   size="small"
                  //   fullWidth
                  //   variant="outlined"
                  //   label="Timezone"
                  //   name="timezone"
                  //   value={dataPoint.timezone}
                  //   disabled={dataPoint.toggleGlobalSync}
                  // />
                }
              />
            </ListItem>
            <ListItem dense={false}>
              <ListItemText primary="test" style={{ opacity: 0 }} />
              <ListItemSecondaryAction>
                <Button
                  onClick={this.handleUpdateDataPoint}
                  disabled={dataPoint.toggleGlobalSync}
                >
                  Update
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
  /**
   * Calls prop.onUpdateDataPoint with new start, end, and timezone data
   */
  handleUpdateDataPoint = () => {
    this.props.onUpdateDataPoint({
      ...this.props.dataPoint,
      start: this.state.start,
      end: this.state.end,
      timezone: this.state.timezone
    });
  };
  /**
   * Calls prop.onUpdateDataPoint with new toggle state
   * @param {string} toggle
   */
  handleToggleUpdate = toggle => {
    this.props.onUpdateDataPoint({
      ...this.props.dataPoint,
      [toggle]: !this.props.dataPoint[toggle]
    });
  };
  /**
   * Updates the component state.start with new input
   * @param {moment} date
   */
  handleStartChange = date => {
    this.setState({ start: moment.tz(date, this.state.timezone) });
  };
  /**
   * Updates the component state.end with new input
   * @param {moment} date
   */
  handleEndChange = date => {
    this.setState({ end: moment.tz(date, this.state.timezone) });
  };
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default DataPointInfo;
