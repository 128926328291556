// Action Calls
export const types = {
  ADD: 'DATAPOINTS:ADD',
  REMOVE: 'DATAPOINTS:REMOVE'
};
// DataPoints Functions
export default {
  types,
  add: point => ({
    type: types.ADD,
    payload: point
  }),
  remove: point => ({
    type: types.REMOVE,
    payload: point
  })
};
