// Action Templates
import post from './templates/post';
// Action Calls
export const types = {
  acceptInvitation: {
    POST: 'users/ACCEPTINVITATION:POST',
    RESET: 'users/ACCEPTINVITATION:RESET',
    FAILED: 'users/ACCEPTINVITATION:FAILED',
    SUCCESS: 'users/ACCEPTINVITATION:SUCCESS'
  }
};
// Users Actions
export default {
  // Action Calls
  types,
  // Action Functions
  acceptInvitation: post(types.acceptInvitation)
};
