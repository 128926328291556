// NPM Modules
import React from 'react';
import moment from 'moment-timezone';
import randomColor from 'randomcolor';
import { Link as RouterLink } from 'react-router-dom';
// Material-UI
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ControlCameraRoundedIcon from '@material-ui/icons/ControlCameraRounded';
/**
 * Info Text Component
 * Renders a dense Material-UI ListItem.
 * Takes in a label and vlaue.
 * @param {{label:string, value:string}} props
 */
function InfoItem({ label, value }) {
  return (
    <ListItem dense>
      <ListItemText
        inset
        primary={
          <React.Fragment>
            <Typography variant="body2">{label}</Typography>
            <Typography variant="body2" component="div">
              {value}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}
/**
 * lastConnected
 */
function lastConnected(time) {
  if (!time) {
    return 'No time data available.';
  }
  let now = moment.tz('UTC').valueOf();
  let amount = (now - time) / 1000;
  // Stop from going negative
  amount = amount < 0 ? 0 : amount;
  if (amount < 60) {
    return (
      <Typography variant="body2" color={'primary'}>
        {`Last connected: ${amount.toFixed(0)} seconds ago.`}
      </Typography>
    );
  }
  amount = amount / 60;
  if (amount < 60) {
    return (
      <Typography variant="body2" color={amount < 16 ? 'primary' : 'error'}>
        {`Last connected: ${amount.toFixed(0)} minutes ago.`}
      </Typography>
    );
  }
  amount = amount / 60;
  if (amount < 24) {
    return (
      <Typography variant="body2" color="error">
        {`Last connected: ${amount.toFixed(0)} hours ago.`}
      </Typography>
    );
  }
  amount = amount / 24;
  if (amount < 365) {
    return (
      <Typography variant="body2" color="error">
        {`Last connected: ${amount.toFixed(0)} days ago.`}
      </Typography>
    );
  }
  amount = amount / 365;
  return (
    <Typography variant="body2" color="error">
      {`Last connected: ${amount.toFixed(2)} years ago.`}
    </Typography>
  );
}

function formatConnectionInterfaces(interfaces) {
  const LOCAL_IPS = ['192.168.42.1', '169.254.100.100'];
  const REMOVE_IPS = ['172.17.0.1', ...LOCAL_IPS];
  const NETWORK_IPS = interfaces
    .trim()
    .split(' ')
    .filter(ci => !REMOVE_IPS.includes(ci));

  return (
    <>
      <div>
        Local IPs:{' '}
        {LOCAL_IPS.map(ip => (
          <Link href={`http://${ip}`} target="_blank" key={ip} color="primary">
            {ip}
          </Link>
        )).reduce((p, c) => [p, ', ', c])}
      </div>
      <div>
        Network IPs:{' '}
        {NETWORK_IPS.length > 0
          ? NETWORK_IPS.map(ip => (
              <Link
                href={`http://${ip}`}
                target="_blank"
                key={ip}
                color="secondary"
              >
                {ip}
              </Link>
            )).reduce((p, c) => [p, ', ', c])
          : 'None'}
      </div>
    </>
  );
}

/**
 * Basestation List Component
 * @param {*} param0
 */
function BasestationItem({ basestation }) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem dense>
        <ListItemIcon>
          <ControlCameraRoundedIcon
            style={{ color: randomColor({ seed: basestation.serial_number }) }}
          />
        </ListItemIcon>
        <ListItemText
          primary={basestation.hostname}
          secondary={lastConnected(basestation.basestation_time)}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="toggle"
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <InfoItem
            label={'Serial Number:'}
            value={basestation.serial_number}
          />
          <InfoItem
            label={'Connection Interfaces:'}
            // 169.254.100.100 | 192.168.42.1
            value={formatConnectionInterfaces(
              basestation.connection_interfaces
            )}
          />
        </List>
      </Collapse>
    </React.Fragment>
  );
}

export default ({ list, isLoading }) => {
  if (isLoading) return <CircularProgress />;

  return (
    <List>
      {list.map(basestation => (
        <BasestationItem
          basestation={basestation}
          key={`bs_list[bs${basestation.serial_number}]`}
        />
      ))}
    </List>
  );
};
