// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Components
import Action from './Action';
/**
 * Actions
 */
class Actions extends Component {
  /**
   * render
   */
  render() {
    return this.props.actions.map((action, index) => (
      <Action action={action} key={`action[${index}]`} />
    ));
  }
  /**
   * Component Default Prop State
   */
  static defaultProps = {
    actions: []
  };
  /**
   * Component Prop Types
   */
  static propTypes = {
    actions: PropTypes.array.isRequired
  };
}
/**
 * Export React Component
 */
export default Actions;
