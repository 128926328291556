// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Helpers
import permissionCheck from '../../helpers/permissionCheck';
// Actions
import deployment from '../../redux/actions/deployment';
// Components
import Page from '../../components/Page';
import ArraySystem from './components/ArraySystem';
import BasestationSystem from './components/BasestationSystem';
import DeploymentInfoPanel from './components/DeploymentInfoPanel';
// Material-UI
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
/**
 * View Page: Deployment
 */
class DeploymentPage extends Component {
  state = {
    redirect: false
  };
  componentDidMount() {
    const { deploymentInfo, match } = this.props;
    const { deployment_key } = match.params;
    if (!deploymentInfo || deploymentInfo.deployment_key !== deployment_key) {
      this.props.getDeployment({ deployment_key });
    }
  }
  componentDidUpdate(prevProps) {
    const props = this.props;
    if (
      prevProps.deploymentEditStatus !== 'success' &&
      props.deploymentEditStatus === 'success'
    ) {
      props.getDeployment({
        deployment_key: props.deploymentInfo.deployment_key
      });
      props.enqueueSnackbar('Deployment updated successfully.', {
        variant: 'success'
      });
    }
    if (
      prevProps.deploymentEditStatus !== 'failed' &&
      props.deploymentEditStatus === 'failed'
    ) {
      props.enqueueSnackbar(
        props.deploymentEditError
          ? props.deploymentEditError.data.detail ||
              props.deploymentEditError.data.message
          : 'Failed to edit deployment. Check that inputs are valid.',
        {
          variant: 'error'
        }
      );
    }
    if (props.deploymentDeleteStatus === 'success') {
      this.setState({ redirect: true });
      props.resetDeployment();
      props.deleteDeploymentReset();
    }
  }
  handleDeploymentEdit = info => {
    this.props.editDeployment({ ...info });
  };
  /**
   * Sends request action to delete deployment
   */
  handleDeploymentDelete = () => {
    this.props.deleteDeployment({
      deployment_key: this.props.deploymentInfo.deployment_key
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="./list" />;
    }

    const { deploymentInfo, organizationInfo, isMobile } = this.props;
    let body = <CircularProgress />;
    if (deploymentInfo) {
      const canEdit = permissionCheck('deployment_write', [
        organizationInfo.permissions,
        deploymentInfo.permissions
      ]);
      const canDelete = permissionCheck('deployment_create_delete', [
        organizationInfo.permissions,
        deploymentInfo.permissions
      ]);
      body = (
        <React.Fragment>
          <DeploymentInfoPanel
            info={deploymentInfo}
            organization={organizationInfo}
            onEdit={this.handleDeploymentEdit}
            canEdit={canEdit}
            onDelete={this.handleDeploymentDelete}
            canDelete={canDelete}
            isMobile={isMobile}
          />
          <Divider />
          <ArraySystem deployment={deploymentInfo} canCreateDelete={canEdit} />
          <Divider />
          <BasestationSystem deploymentInfo={deploymentInfo} />
        </React.Fragment>
      );
    }
    return <Page>{body}</Page>;
  }
  static mapStateToProps(state) {
    return {
      // Device Info
      isMobile: state.device.isMobile,
      // Organization Info
      organizationInfo: state.organization.info.info,
        // Deployment Info
      deploymentInfo: state.deployment.info.info,
      deploymentInfoStatus: state.deployment.info.status,
        // Deployment Edit
      deploymentEditError: state.deployment.edit.error,
      deploymentEditStatus: state.deployment.edit.status,
        // Deployment Delete
      deploymentDeleteInfo: state.deployment.remove.info,
      deploymentDeleteStatus: state.deployment.remove.status
    };
  }
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Deployment Info
        getDeployment: deployment.info.get,
        resetDeployment: deployment.info.reset,
        // Deployment Edit
        editDeployment: deployment.edit.post,
        // Deployment Delete
        deleteDeployment: deployment.remove.post,
        deleteDeploymentReset: deployment.remove.reset
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  DeploymentPage.mapStateToProps,
  DeploymentPage.mapDispatchToProps
)(withSnackbar(DeploymentPage));
