// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../actions/deployment';
// Reducer Template
import get from './templates/get';
import post from './templates/post';
import list from './templates/list';

// Reducer
export default combineReducers({
  info:       (s, a) =>  get(s, a, types.info),
  list:       (s, a) => list(s, a, types.list),
  create:     (s, a) => post(s, a, types.create),
  edit:       (s, a) => post(s, a, types.edit),
  remove:     (s, a) => post(s, a, types.remove),
  occupancy: combineReducers({
    list:     (s, a) => list(s, a, types.occupancy.list),
    tracker:  (s, a) =>  get(s, a, types.occupancy.tracker)
  }),
  basestation: combineReducers({
    list:     (s, a) => list(s, a, types.basestation.list)
  })
});
