import Item from '../Items/Item';
import offscreenCanvas from '../util/offscreenCanvas';

/**
 *
 */
class Layer {
  /**
   *
   * @param {number} width - size of layer canvas (recommend making same as cake width)
   * @param {number} height - size of layer canvas (recommend making same as cake height)
   * @param {Object} [options]
   * @param {string} [options.name="untitiled layer"] - user friendly name for the layer
   */
  constructor(width, height, options) {
    if (isNaN(width) || isNaN(height)) {
      throw TypeError('width and height must be a number.');
    }
    this._width = width;
    this._height = height;
    this._items = new Set();
    this._canvas = new offscreenCanvas(width, height);
    this._scale = 1;
    this._visible = options.hasOwnProperty('visible') ? options.visible : true;
    this.name = options.hasOwnProperty('name') ? options.name : 'untiled-layer';
    this.xOffset = options.hasOwnProperty('xOffset') ? options.xOffset : 0;
    this.yOffset = options.hasOwnProperty('yOffset') ? options.yOffset : 0;
  }
  /**
   * Clears the layer canvas
   * @private
   */
  _clearCanvas() {
    const ctx = this._canvas.getContext('2d');
    ctx.globalCompositeOperation = 'source-over';
    ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
  }
  /**
   * Renders all the layers items to the layer canvas
   */
  update() {
    this._clearCanvas();
    const ctx = this._canvas.getContext('2d');
    ctx.globalCompositeOperation = 'source-over';
    this._items.forEach(item => {
      item.draw(ctx);
    });
  }
  /**
   * Returns if the layer is visible or not
   * @returns {boolean}
   */
  isVisible() {
    return this._visible;
  }
  /**
   * Set the layers visibility. If true layer will not be renderd on the cake, otherwise it will render.
   * @param {*} visible
   */
  setVisible(visible) {
    this._visible = visible;
  }
  /**
   * Add an item to the layer's item set
   * @param {Item} item
   */
  addItem(item) {
    this._items.add(item);
    this.update();
  }
  /**
   * Add multiple items to the layer's item set
   * @param  {...Item} items
   */
  addItems(items) {
    items.forEach(item => {
      this._items.add(item);
    });
    this.update();
  }
  /**
   * Remove an item at specified index
   * @param {Object} item
   */
  removeItem(item) {
    this._items.delete(item);
  }
  /**
   * Removes all items from layer
   */
  removeAllItems() {
    this._items = new Set();
    this._clearCanvas();
  }
  /**
   * Returns a list of items in the layer
   * @returns {Set} the set of items in the layer
   */
  getItems() {
    return this._items;
  }
  /**
   * Returns layer's virtual canvas
   * @return {OffscreenCanvas}
   */
  getCanvas() {
    return this._canvas;
  }
}

export default Layer;
