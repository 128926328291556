/** @typedef {Array.<[number, number]>} PolygonArray */

/**
 *
 * @param {string} region
 * @param {boolean} dropLast
 * @returns {PolygonArray}
 */
export function convertRegionToArray(region, dropLast = false) {
  let array = region
    .slice(region.indexOf('((') + 2, region.indexOf('))'))
    .split(',')
    .map(point => point.trim().split(' '))
    .map(point => point.map(n => parseInt(n, 10)));

  if (dropLast) {
    array.pop();
  }

  return [...array];
}

/**
 *
 * @param {PolygonArray} array
 * @param {boolean} addStartPointToEnd
 * @returns {string}
 */
export function convertArrayToRegion(array, addStartPointToEnd = false) {
  if (addStartPointToEnd) {
    return `POLYGON ((${array
      .map(p => p.join(' '))
      .join(', ')}, ${array[0].join(' ')}))`;
  }
  return `POLYGON ((${array.map(p => p.join(' ')).join(', ')}))`;
}

/**
 *
 * @param {string} region
 * @returns {boolean}
 */
export function validateRegion(region) {
  try {
    const polygon = convertRegionToArray(region);
    return polygon.every(
      point => point.length === 2 && point.every(num => !isNaN(num))
    );
  } catch (error) {
    return false;
  }
}
