// Modules
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// Components
import SideMenu from '../Desktop/SideMenu';
// Material-UI
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// Icons
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
// Styled Components
import { Body, Content, LoggedOutConent } from './style';
// Styled Components
import { MenuButton, TopBar, Left, Logo } from '../Desktop/style';
/**
 * Mobile Theme
 */
class MobileTheme extends Component {
  /**
   * Component State
   */
  state = {
    value: 'deployments',
    menuOpen: false
  };
  /**
   * Updates nav value
   * @param {*} event
   * @param {*} value
   */
  handleChange = (event, value) => {
    if (this.state.value !== value) {
      this.setState({ value });
    }
  };
  /**
   * Toggle SideMenu
   */
  toggleSideMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };
  /**
   * render method
   * @return {Object} JSX
   */
  render() {
    const loggedIn = !!this.props.user;
    return loggedIn ? this.renderLoggedInView() : this.renderLoggedOutView();
  }
  /**
   * Logged in view
   * @returns {Component}
   */
  renderLoggedInView() {
    const org_key = this.props.organization.org_key;
    return (
      <Body>
        <Content>{this.props.children}</Content>
        <AppBar
          position="fixed"
          style={{ top: 'auto', bottom: 0, background: '#072a2c' }}
        >
          <BottomNavigation
            value={this.state.value}
            onChange={this.handleChange}
            showLabels
          >
            <BottomNavigationAction
              component={Link}
              to={`/organization/${org_key}/data-exploration`}
              label="Insights"
              value="data-exploration"
              icon={<BarChartRoundedIcon />}
            />
            <BottomNavigationAction
              component={Link}
              to={`/organization/${org_key}/deployment/list`}
              label="Deployments"
              value="deployments"
              icon={<DashboardRoundedIcon />}
            />
            <BottomNavigationAction
              component={Link}
              to={`/organization/${org_key}/array/list`}
              label="Arrays"
              value="arrays"
              icon={<ExtensionRoundedIcon />}
            />
            <BottomNavigationAction
              component={Link}
              to={`/organization/${org_key}/more`}
              label="More"
              value="more"
              icon={<MoreHorizRoundedIcon />}
            />
          </BottomNavigation>
        </AppBar>
      </Body>
    );
  }
  /**
   *  Logged out view
   * @returns {Component}
   */
  renderLoggedOutView() {
    // For the logged out view we borrow the desktop theme style
    return (
      <Body>
        <TopBar>
          <Left>
            <MenuButton
              edge="start"
              color="inherit"
              onClick={this.toggleSideMenu}
            >
              <MenuRoundedIcon />
            </MenuButton>
            <Logo />
          </Left>
        </TopBar>
        <SideMenu
          loggedIn={false}
          open={this.state.menuOpen}
          organization={this.props.organization}
          onClose={this.toggleSideMenu}
          title={this.props.title}
        />
        <LoggedOutConent open={this.state.menuOpen}>
          {this.props.children}
        </LoggedOutConent>
      </Body>
    );
  }
  /**
   * Map redux state to component
   * @param {*} state
   * @returns
   */
  static mapStateToProps(state) {
    return {
      user: state.user.info.info,
      title: state.location.title,
      deployment: state.deployment.info.info,
      organization: state.organization.info.info
    };
  }
}

export default connect(MobileTheme.mapStateToProps)(MobileTheme);
