/**
 * Base Class for all tools
 * @abstract
 */
class Tool {
  constructor(cake, active = false, options = {}) {
    this._cake = cake;
    this._active = active;
    this.eventListeners = options.hasOwnProperty('eventListeners')
      ? options.eventListeners
      : {};
  }

  isActive() {
    return this._active;
  }
  setActive(active) {
    this._active = active;
  }

  on(eventName, callback, bindToCanvas = false) {
    if (bindToCanvas) {
      this._cake.canvas.addEventListener(eventName, callback);
    } else {
      window.addEventListener(eventName, callback);
    }
  }

  triggerEvent(name, payload) {
    if (this.eventListeners.hasOwnProperty(name)) {
      this.eventListeners[name](payload);
    }
  }
}

export default Tool;
