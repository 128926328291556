// Actions
import get from '../templates/get';
import post from '../templates/post';
import theme, { types } from '../../actions/organization/theme';
// API URLS
const API = {
  DASH: '/api/dashboard/organization',
  QUERY: '/api/organization'
};
// Epics
export default {
  info: action$ => get(action$, theme.info, types.info, `${API.DASH}/:org_key/theme`),
  edit: action$ => post(action$, theme.edit, types.edit, `${API.DASH}/theme/edit`),
};
