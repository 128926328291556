// NPM packages
import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
// Material-UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// Helper Functions
import formatMilliseconds from '../../../helpers/formatMilliseconds';

/**
 *
 * @param {[{data:[{x:*,y:number}]}]} datasets - using chartjs dataset structure
 * @param {('sum'|'max'|'min'|'average')} aggregation
 * @returns {number}
 */
function calculateValue(datasets, aggregation) {
  if (datasets.length === 0) return 0;

  switch (aggregation) {
    case 'average':
      return datasets
        .map(set =>
          set.data.length > 0
            ? set.data.reduce((a, b) => a + b.y, 0) / set.data.length
            : 0
        ) // calculate averages of each set
        .flat() // combine arrays
        .reduce((a, b) => a + b); // total them up
    case 'max':
      return Math.max(...datasets.map(set => set.data.map(n => n.y)).flat());
    case 'min':
      return Math.min(...datasets.map(set => set.data.map(n => n.y)).flat());
    default:
      // sum
      return datasets
        .map(set => set.data.map(n => n.y))
        .flat()
        .reduce((a, b) => a + b, 0);
  }
}

/**
 * Big Data Visualizer
 */
const BigDataVisualizer = ({
  datasets,
  type,
  title,
  aggregation,
  theme,
  sparkline,
  unitLabel,
  numberFormat,
  value
}) => {
  if (value === null) {
    value = calculateValue(datasets, aggregation);
  }

  if (type === 'time') {
    const format = formatMilliseconds(value);
    value = numeral(format.number).format(numberFormat);
    unitLabel = ` ${format.label} ${unitLabel}`;
  } else {
    value = numeral(value).format(numberFormat);
  }

  // NOTE: We set the third lines height to 1.375 rem which is 1rem + lineheight
  //       adjusted to leave a blank spot for when there is no unit label
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography display="block" noWrap color="textSecondary">
          {title}
        </Typography>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="h4"
          display="block"
          noWrap
        >
          {value}
        </Typography>
        <Typography
          style={{ height: '1.375rem' }}
          display="block"
          noWrap
          color="textSecondary"
        >
          {unitLabel.replace(/\b\w/g, c => c.toUpperCase())}
        </Typography>
      </CardContent>
    </Card>
  );
};
/**
 * Big Data Visualizer Prop Types
 */
BigDataVisualizer.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  theme: PropTypes.exact({
    color: PropTypes.string,
    background: PropTypes.string
  }),
  sparkline: (props, propName, componentName) => {
    if (props[propName]) {
      if (Array.isArray(props['data'])) {
        return;
      } else {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}'. Validation failed.`
        );
      }
    }
  },
  aggregation: PropTypes.oneOf(['sum', 'max', 'min', 'average']),
  unitLabel: PropTypes.string,
  numberFormat: PropTypes.string, // http://numeraljs.com/#format
  type: PropTypes.oneOf(['number', 'time']),
  value: PropTypes.number
};
/**
 * Big Data Visualizer Default Prop Types
 */
BigDataVisualizer.defaultProps = {
  title: '',
  sparkline: false,
  aggregation: 'sum',
  unitLabel: '',
  numberFormat: '0,0',
  type: 'number',
  value: null
};
/**
 * Export Component
 */
export default BigDataVisualizer;
