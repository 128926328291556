// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../actions/users';
// Reducer Template
import post from './templates/post';
// Reducer
export default combineReducers({
  acceptInvitation: (s, a) => post(s, a, types.acceptInvitation)
});
