// Action Templates
import get from '../templates/get';
import post from '../templates/post';
// Action Calls
export const types = {
  // Organization Actions
  info: {
    GET: 'organization/theme/INFO:GET',
    // RESET: 'organization/theme/INFO:REST',
    // CANCEL: 'organization/theme/INFO:CANCEL',
    SET_TEMP_COLORS: 'organization/theme/INFO:SET_TEMP_COLORS',
    FAILED: 'organization/theme/INFO:FAILED',
    SUCCESS: 'organization/theme/INFO:SUCCESS'
  },
  edit: {
    POST: 'organization/theme/EDIT:POST',
    RESET: 'organization/theme/EDIT:RESET',
    CANCEL: 'organization/theme/EDIT:CANCEL',
    FAILED: 'organization/theme/EDIT:FAILED',
    SUCCESS: 'organization/theme/EDIT:SUCCESS'
  }
};
// Organization Functions
export default {
  types,
  // GET Requests
  info: {
    ...get(types.info),
    setTempColors: payload => ({ type: types.info.SET_TEMP_COLORS, payload })
  },
  // POST Requests
  edit: post(types.edit)
};
