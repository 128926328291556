// NPM Modules
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
// Material-UI
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
// Styled Components
import { ColorButton } from './style';

function rgbaObjToString(color) {
  return `rgba(${Object.values(color).toString()})`;
}

/**
 *
 * @param {*} props
 */
function ColorPicker(props) {
  const { label, onChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState({
    r: props.color[0],
    g: props.color[1],
    b: props.color[2],
    a: props.color[3] || 1
  });
  const open = Boolean(anchorEl);
  // Handler Functions
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleColorChange = color => {
    setColor(color.rgb);
  };
  // Component
  return (
    <div>
      <Typography variant="body1">
        {label.charAt(0).toUpperCase()}
        {label.slice(1)}:
      </Typography>
      <ColorButton
        onClick={handleClick}
        color={rgbaObjToString(color)}
      ></ColorButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <ChromePicker
          color={color}
          onChange={handleColorChange}
          onChangeComplete={color => onChange(Object.values(color.rgb))}
        />
      </Popover>
    </div>
  );
}

export default ColorPicker;
