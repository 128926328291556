/**
 * @typedef {object} Item~Options
 * @property {string} [fill="black"] - fill color of item
 * @property {number} [fillOpacity=1] - (0.0 to 1.0) opacity of fill color of item.
 * @property {string} [stroke="black"] - stroke color of item
 * @property {number} [strokeOpacity=1] - (0.0 to 1.0) opacity of stroke color of item.
 * @property {number} [strokeWidth=1] - size of the stroke
 */

/**
 * @namespace Items
 */

/**
 * Base Class for all items
 * @abstract
 */
class Item {
  /**
   * 
   * @abstract
   * @param {number} x - x coordinate
   * @param {number} y - y coordinate
   * @param {Item~Options} [options]
   */
  constructor(x = 0, y = 0, options = {}) {
    if (new.target === Item) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }
    this.x = x;
    this.y = y;
    this.style = {
      fill: options.hasOwnProperty('fill') ? options.fill : 'black',
      fillOpacity: options.hasOwnProperty('fillOpacity')
        ? options.fillOpacity
        : 1,
      stroke: options.hasOwnProperty('stroke') ? options.stroke : 'black',
      strokeOpacity: options.hasOwnProperty('strokeOpacity')
        ? options.strokeOpacity
        : 1,
      strokeWidth: options.hasOwnProperty('strokeWidth')
        ? options.strokeWidth
        : 1
    };
  }
  /**
   *
   * @abstract
   * @param {CanvasRenderingContext2D} ctx
   */
  draw(ctx) {
    throw new Error('must be implemented by subclass!');
  }
}

export default Item;
