// NPM Modules
import equal from 'deep-equal';
import moment from 'moment';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { pointInPolygon, pointOnPolygon } from 'geometric';
// Components
// import ZoneItem from './ZoneItem';
import ZonePolygon from '../../../../components/ArrayConfigurationTool/ZonePolygon';
import { convertRegionToArray } from '../../../../components/ArrayConfigurationTool/util';
import HeatmapItem from './HeatmapItem';
import HoverInfoPanel from './HoverInfoPanel';
import Cake, { Layer } from '../../../../components/Cake';
// Actions
import zone from '../../../../redux/actions/zone';
import aggregate from '../../../../redux/actions/aggregate';
import dataPointSystem from '../../../../redux/actions/dataPointSystem';
// Styled Components
import {
  Container,
  Header,
  HeaderContent,
  HeaderTooltip,
  Body,
  Canvas
} from './style';
// Material-UI
import { withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-UI Icons
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
/**
 * Heatmap Component
 */
class Heatmap extends Component {
  // Component Refs
  canvas = React.createRef();
  menuButton = React.createRef();
  // System for cake and hover div
  // We use this because it's less stressing on constant updates
  cake = null;
  SCALE = 50;
  node = { x: 0, y: 0, durMs: 0, c: 0, zones: [] };
  hoverNodeEl = null;
  /**
   * Component State
   */
  state = {
    menuOpen: false
  };
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getZoneList({
      array_key: this.props.array.array_key,
      metadata: true
    });
    this.getHeatmap();
    this.setupStyle();
    this.setupHeatmap();
  }
  /**
   * componentDidUpdate
   */
  componentDidUpdate(prevProps) {
    // Handle date time change as well as updating this heatmap datapoint
    // Fetch new heatmap data if datapoint updated

    // If the data point is synced to global date-time
    // Update the data point when global date time changes
    if (this.props.dataPoint.toggleGlobalSync) {
      if (
        prevProps.timezone !== this.props.timezone ||
        prevProps.endDateTime !== this.props.endDateTime ||
        prevProps.startDateTime !== this.props.startDateTime ||
        !prevProps.dataPoint.toggleGlobalSync
      ) {
        this.props.dataPointsUpdate({
          ...this.props.dataPoint,
          timezone: this.props.timezone,
          start: this.props.startDateTime,
          end: this.props.endDateTime
        });
      }
    }
    // If prev data point vs current data point are not equal then we should update the heatmap
    if (!equal(prevProps.dataPoint, this.props.dataPoint)) {
      if (
        prevProps.dataPoint.timezone !== this.props.dataPoint.timezone ||
        prevProps.dataPoint.end !== this.props.dataPoint.end ||
        prevProps.dataPoint.start !== this.props.dataPoint.start
      ) {
        this.getHeatmap();
      } else {
        this.renderHeatmap();
      }
    }
    // If any changes to zones happen then render new zones
    if (!equal(prevProps.zones, this.props.zones)) {
      this.renderZones();
    }
    // If there was no previous heatmap then we should render it
    if (prevProps.heatmap) {
      if (
        !this.props.heatmap.fetching &&
        prevProps.heatmap.fetching &&
        !this.props.heatmap.error
      ) {
        this.renderHeatmap();
      }
    }
  }
  /**
   * Remove all event listeners
   */
  componentWillUnmount() {
    const canvas = this.canvas.current;
    if (canvas) {
      canvas.removeEventListener('mouseenter', this.handleMouseEnter);
      canvas.removeEventListener('mousemove', this.handleMouseMove);
      canvas.removeEventListener('mouseleave', this.handleMouseLeave);
    }
    if (this.hoverNodeEl) {
      this.hoverNodeEl.removeEventListener('mouseleave', this.handleMouseLeave);
    }
  }
  /**
   * Fetches dataPoint heatmap for the page
   */
  getHeatmap() {
    const { dataPoint } = this.props;
    this.props.getHeatmap({
      array_key: dataPoint.array_key,
      start: moment(dataPoint.start).toISOString(true).slice(0, -6),
      end: moment(dataPoint.end).toISOString(true).slice(0, -6),
      timezone: dataPoint.timezone
    });
  }
  /**
   * Setups up an object with the current pixel info
   * @param {MouseEvent} event
   * @returns {object}
   */
  getPixelLocation(event) {
    const rect = this.canvas.current.getBoundingClientRect();
    const canvas = this.canvas.current;
    const x = Math.floor(event.offsetX / this.SCALE);
    const y = Math.floor(event.offsetY / this.SCALE);

    return {
      pixel: {
        x,
        y: this.props.heatmap.array_size_y - 1 - y,
        offsetX: canvas.offsetLeft + x * this.SCALE,
        offsetY: canvas.offsetTop + y * this.SCALE
      },
      rect
    };
  }
  /**
   * Handle mouse enter on canvas
   * Setup base hover el if not created
   * @param {MouseEvent} event
   * @returns
   */
  handleMouseEnter = event => {
    this.createHoverNode(event);
  };
  /**
   * Handles mouse leave from canvas and hover div
   * @param {MouseEvent} event
   * @returns
   */
  handleMouseLeave = event => {
    const { id } = this.props.dataPoint;
    if (
      event.relatedTarget &&
      event.relatedTarget.id !== `heatmap-canvas[${id}]` &&
      event.relatedTarget.id !== `heatmap-canvas[${id}]-hover-display` &&
      this.hoverNodeEl &&
      !this.hoverNodeEl.contains(event.relatedTarget)
    ) {
      this.hoverNodeEl.removeEventListener('mouseleave', this.handleMouseLeave);
      document.body.removeChild(this.hoverNodeEl);
      this.hoverNodeEl = null;
    }
  };
  /**
   * Handles updating the hover div info and moving it
   * @param {MouseEvent} event
   * @returns
   */
  handleMouseMove = event => {
    const { pixel } = this.getPixelLocation(event);
    const node = this.props.heatmap.results.find(
      node => node.x === pixel.x && node.y === pixel.y
    );
    if (node && (node.x != this.node.x || node.y != this.node.y)) {
      node.zones = this.props.zones.filter(zone => {
        const polygon = convertRegionToArray(zone.region);
        return (
          pointInPolygon([pixel.x, pixel.y], polygon) ||
          pointOnPolygon([pixel.x, pixel.y], polygon)
        );
      });
      this.node = node;
      if (this.hoverNodeEl) {
        this.updateHoverNode(event);
      }
    }
  };
  /**
   * Called when component mounts div styling for hover element
   */
  setupStyle() {
    if (document.querySelector('[data-meta=hover-div]')) return; // if the styling already exists do not create

    const style = document.head.appendChild(document.createElement('style'));
    style.dataset.meta = 'hover-div';
    style.innerHTML = `
.heatmap-canvas-hover-display {
  position: absolute;
  left: 0px;
  right: 0px;
  transition: left 0s, top 0s, opacity 0.36s;
  width: ${this.SCALE}px;
  height: ${this.SCALE}px;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.5);
  opacity: 0;
  z-index: 9999;
}
`;
  }
  /**
   * Creates the base element for the hover div
   * @param {MouseEvent} event
   * @returns
   */
  createHoverNode(event) {
    if (!this.hoverNodeEl) {
      const { id } = this.props.dataPoint;

      this.hoverNodeEl = document.createElement('div');
      this.hoverNodeEl.addEventListener('mouseleave', this.handleMouseLeave);
      this.hoverNodeEl.id = `heatmap-canvas[${id}]-hover-display`;
      this.hoverNodeEl.className = 'heatmap-canvas-hover-display';

      document.body.appendChild(this.hoverNodeEl);
      this.updateHoverNode(event);
    }
  }
  /**
   * Updates the hover div element
   * @param {object} pixel
   * @param {MouseEvent} event
   * @returns
   */
  updateHoverNode(event) {
    const { pixel, rect } = this.getPixelLocation(event);
    if (!this.hoverNodeEl) this.createHoverNode(event);
    const width = 300;

    let left = width / 2 - this.SCALE / 2; // Center Bound
    if (pixel.offsetX - left < rect.left) {
      left = pixel.offsetX - rect.left;
    } else if (pixel.offsetX + (left + this.SCALE) > rect.right) {
      left = width - (rect.right - (pixel.offsetX + this.SCALE)) - this.SCALE;
    }

    this.hoverNodeEl.style.left = `${pixel.offsetX}px`;
    this.hoverNodeEl.style.top = `${pixel.offsetY}px`;
    // We do this because we don't want a weird shift delay with the position of the hover div being moved
    if (this.hoverNodeEl.style.opacity === '1') {
      this.hoverNodeEl.style.transition = 'left 0.1s, top 0.1s, opacity 0.36s';
    }
    this.hoverNodeEl.style.opacity = 1;

    ReactDOM.render(
      <HoverInfoPanel
        node={this.node}
        left={left}
        top={pixel.y > this.props.array.bounding_box_size_y / 2}
        scale={this.SCALE}
        theme={this.props.theme}
      />,
      this.hoverNodeEl
    );
  }
  /**
   * Setup eveht listeners for hover element for the heatmap
   */
  setupEventListeners() {
    const canvas = this.canvas.current;
    canvas.addEventListener('mouseenter', this.handleMouseEnter);
    canvas.addEventListener('mousemove', this.handleMouseMove);
    canvas.addEventListener('mouseleave', this.handleMouseLeave);
  }
  /**
   * Setup base heatmap cake system
   */
  setupHeatmap() {
    const { array, dataPoint } = this.props;
    const width = array.bounding_box_size_x * this.SCALE;
    const height = array.bounding_box_size_y * this.SCALE;

    this.setupEventListeners();

    this.cake = new Cake(this.canvas.current, {
      width: width,
      height: height,
      viewportXOffset: 0,
      viewportYOffset: 0
    });

    const heatmapLayer = new Layer(width, height, { name: 'heatmap' });
    this.cake.addLayer(heatmapLayer);

    const zoneLayer = new Layer(width, height, {
      name: 'zone',
      visible: dataPoint.toggleZones
    });
    this.cake.addLayer(zoneLayer);
  }
  /**
   * Creates and adds the heatmap item to the cake heatmap
   */
  renderHeatmap() {
    const { dataPoint, heatmap } = this.props;
    const heatmapItem = new HeatmapItem(
      this.cake.width,
      this.cake.height,
      heatmap.results,
      {
        logScale: dataPoint.toggleLogScale,
        type: dataPoint.toggleDuration ? 'durMs' : 'c',
        scale: this.SCALE
      }
    );

    const heatmapLayer = this.cake.getLayer('heatmap');
    heatmapLayer.removeAllItems();
    heatmapLayer.addItem(heatmapItem);

    const zoneLayer = this.cake.getLayer('zone');
    zoneLayer.setVisible(dataPoint.toggleZones);

    this.cake.update();
  }
  /**
   * Renders the heatmap zones to the canvas
   */
  renderZones() {
    const { zones } = this.props;

    const zoneLayer = this.cake.getLayer('zone');
    zoneLayer.removeAllItems();
    zoneLayer.addItems(
      zones.map(
        zone =>
          new ZonePolygon(
            convertRegionToArray(zone.region).map(p => [
              (p[0] + 0.5) * this.SCALE,
              this.cake.height - (p[1] + 0.5) * this.SCALE // flip y axis
            ]),
            {
              renderStyle: 'heatmap',
              fill: `rgb(${zone.color.toString()})`,
              fillOpacity: 0.1,
              stroke: `rgb(${zone.color.toString()})`,
              strokeWidth: this.SCALE, // use grid gap for heatmap style rendering
              outline: true,
              // strokeWidth: 2,
              closePath: true
            }
          )
      )
    );

    this.cake.update();
  }
  /**
   * Creates a png image of the current heatmap
   */
  handleSaveImage = () => {
    const url = this.canvas.current.toDataURL();
    const { array_key } = this.props.array;
    let link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `heatmap-${array_key}`);
    link.click();
  };
  /**
   * Toggles the state.menuOpen
   */
  handleMenuToggle = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };
  /**
   * Updates dataPoint with new toggle state
   */
  handleDataPointTogle = key => {
    this.props.dataPointsUpdate({
      ...this.props.dataPoint,
      [key]: !this.props.dataPoint[key]
    });
  };
  /**
   * Component Render
   * @return {Component}
   */
  render() {
    const loading = this.props.heatmap ? this.props.heatmap.fetching : true; // Default loading to true
    const { selected, array, dataPoint, isMobile } = this.props;
    const width = array.bounding_box_size_x * this.SCALE;
    const height = array.bounding_box_size_y * this.SCALE;
    const color = `rgb(${array.color.toString()})`;
    const error = this.props.heatmap && this.props.heatmap.error;

    return (
      <React.Fragment>
        <Container width={width}>
          <Header
            selected={selected}
            color={color}
            onClick={() => this.props.onSelect(dataPoint.id)}
          >
            <HeaderContent color={color}>
              <HeaderTooltip title={array.array_name} interactive arrow>
                <Typography component="span" noWrap>
                  {array.array_name}
                </Typography>
              </HeaderTooltip>
              <IconButton
                onClick={
                  isMobile
                    ? () => this.props.onSelect(dataPoint.id)
                    : this.handleMenuToggle
                }
                color="inherit"
                ref={this.menuButton}
                size="small"
              >
                <MoreVertRoundedIcon />
              </IconButton>
            </HeaderContent>
          </Header>
          <Body selected={selected} color={color} width={width} height={height}>
            {loading ? <CircularProgress /> : null}
            {error ? <h3>Error Getting Data</h3> : null}
            <Canvas
              style={{ display: loading || error ? 'none' : 'block' }}
              id={`heatmap-canvas[${dataPoint.id}]`}
              ref={this.canvas}
            />
          </Body>
        </Container>
        <Menu
          anchorEl={this.menuButton.current}
          open={this.state.menuOpen}
          onClose={this.handleMenuToggle}
        >
          <MenuItem onClick={() => this.handleDataPointTogle('toggleLogScale')}>
            <ListItemIcon>
              {dataPoint.toggleLogScale ? (
                <CheckBoxRoundedIcon color="primary" />
              ) : (
                <CheckBoxOutlineBlankRoundedIcon />
              )}
            </ListItemIcon>
            <Typography variant="inherit">Log Scale</Typography>
          </MenuItem>
          <MenuItem onClick={() => this.handleDataPointTogle('toggleDuration')}>
            <ListItemIcon>
              {dataPoint.toggleDuration ? (
                <CheckBoxRoundedIcon color="primary" />
              ) : (
                <CheckBoxOutlineBlankRoundedIcon />
              )}
            </ListItemIcon>
            <Typography variant="inherit">Duration</Typography>
          </MenuItem>
          <MenuItem onClick={() => this.handleDataPointTogle('toggleZones')}>
            <ListItemIcon>
              {dataPoint.toggleZones ? (
                <CheckBoxRoundedIcon color="primary" />
              ) : (
                <CheckBoxOutlineBlankRoundedIcon />
              )}
            </ListItemIcon>
            <Typography variant="inherit">Zones</Typography>
          </MenuItem>
          <MenuItem onClick={this.handleSaveImage}>
            <ListItemIcon>
              <ImageRoundedIcon />
            </ListItemIcon>
            <Typography variant="inherit">Save As Image</Typography>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
  /**
   * Component PropTypes
   */
  static propTypes = {
    dataPointId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired
  };
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state, props) {
    // Calculating this outside of the return so we can use it in the heatmap fetch
    const dataPoint = state.dataPointSystem[props.dataPointId];
    return {
      // Device => isMobile
      isMobile: state.device.isMobile,
      // Metadata => Array Info
      array: state.metadata.array[dataPoint.array_key],
      // Metadata => Zone List
      zones: Object.values(state.metadata.zone).filter(
        zone => zone.array_key === dataPoint.array_key
      ),
      // Data Point System => Data Point
      dataPoint: dataPoint,
      // Aggregate => Heatmap
      heatmap: state.aggregate.heatmaps[dataPoint.array_key],
      // Date Time Global System
      timezone: state.dateTime.timezone,
      endDateTime: state.dateTime.endDateTime,
      startDateTime: state.dateTime.startDateTime
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Aggregate => Heatmap
        getHeatmap: aggregate.array.heatmap.get,
        cancelHeatmap: aggregate.array.heatmap.cancel,
        // Data Point System => Data Points
        dataPointsUpdate: dataPointSystem.update,
        // Zone
        getZoneList: zone.list.get
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  Heatmap.mapStateToProps,
  Heatmap.mapDispatchToProps
)(withTheme(Heatmap));
