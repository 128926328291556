// NPM Modules
import equal from 'deep-equal';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import aggregate from '../../../redux/actions/aggregate';
import dataPointSystem from '../../../redux/actions/dataPointSystem';
// App Components
import Page from '../../../components/Page';
import BigData from '../../../components/visualizers/BigData';
// NOTE: Feature disabled till it meets all requirements for production
// import HourlyChart from '../../../components/visualizers/charts/RushHour/HourlyChart';
import LineBarChart from '../../../components/visualizers/charts/LineBarChart';
import DateRangePicker from '../../../components/DateRangePicker';
// Data Exporation Components
import Exporter from '../components/Exporter';
import DataPicker from '../components/DataPicker';
// Helper Functions
import intervalSplit from '../../../helpers/intervalSplit';
// Material-UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
/**
 * Data Exploration Entrances Page
 */
class Entrances extends Component {
  /**
   * Fetch data on mount
   * @param {*} prevProps
   */
  componentDidMount() {
    this.props.dataPoints.forEach(dataPoints => {
      this.getAggregateData(dataPoints);
    });
  }
  /**
   * Fetch data and inform client on anything going wrong
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    // Inform client if any errors occure
    this.props.dataPoints.forEach(dataPoint => {
      const aggregate = this.props.entrances[dataPoint.zone_key];
      if (
        aggregate &&
        aggregate.error &&
        aggregate.error.data.error === 'Over 1000 time bins' &&
        prevProps.entrances[dataPoint.zone_key].fetching === true &&
        aggregate.fetching === false
      ) {
        this.props.enqueueSnackbar(
          `${aggregate.error.data.error}. Please reduce time window or interval.`,
          {
            variant: 'error',
            preventDuplicate: true
          }
        );
      }
    });
    // If date time system updates fetch fresh data
    if (
      prevProps.timezone !== this.props.timezone ||
      prevProps.interval !== this.props.interval ||
      prevProps.startDateTime !== this.props.startDateTime ||
      prevProps.endDateTime !== this.props.endDateTime
    ) {
      this.props.dataPoints.forEach(dataPoint => {
        // this.getAggregateData(dataPoint);
        this.props.dataPointsUpdate({
          ...dataPoint,
          interval: this.props.interval,
          timezone: this.props.timezone,
          start: this.props.startDateTime,
          end: this.props.endDateTime
        });
      });
    }
    // If dp has changed or just been added fetch data
    this.props.dataPoints.forEach(dataPoint => {
      const prev = prevProps.dataPoints.find(dp => dp.id === dataPoint.id);
      if (prev === undefined || !equal(prev, dataPoint)) {
        this.getAggregateData(dataPoint);
      }
    });
  }
  /**
   * Fetch aggregate data for this component
   * @param {*} dataPoint
   */
  getAggregateData(dataPoint) {
    this.props.getZoneEntrances({
      zone_key: dataPoint.zone_key,
      start: moment(dataPoint.start).toISOString(true).slice(0, -6),
      end: moment(dataPoint.end).toISOString(true).slice(0, -6),
      interval: dataPoint.interval,
      timezone: dataPoint.timezone
    });
  }
  /**
   * Render component
   * @returns
   */
  render() {
    const { dataPoints, entrances, interval, timezone, history } = this.props;

    const [number, type] = intervalSplit(interval);
    const unitLabel = ` per ${number === 1 ? '' : number} ${type}`;

    const datasets = dataPoints
      .map(dataPoint => {
        const data = entrances[dataPoint.zone_key] || { results: [] };
        const exitColor = [
          255 - dataPoint.color[0],
          255 - dataPoint.color[1],
          255 - dataPoint.color[2]
        ];
        return [
          {
            id: dataPoint.id + '-entrances',
            zoneType: 'entrances',
            label: dataPoint.label + ' - entrances',
            backgroundColor: `rgba(${dataPoint.color.join(',')}, 0.5)`,
            borderColor: `rgba(${dataPoint.color.join(',')}, 1)`,
            radius: 0,
            borderWidth: 2,
            data: data.results.map(node => ({
              x: moment.tz(node.date, timezone).format('Y-MM-DDTHH:mm:ss'),
              y: node.entrances
            }))
          },
          {
            id: dataPoint.id + '-exits',
            zoneType: 'exits',
            label: dataPoint.label + ' - exits',
            backgroundColor: `rgba(${exitColor.join(',')}, 0.5)`,
            borderColor: `rgba(${exitColor.join(',')}, 1)`,
            radius: 0,
            borderWidth: 2,
            data: data.results.map(node => ({
              x: moment.tz(node.date, timezone).format('Y-MM-DDTHH:mm:ss'),
              y: node.exits
            }))
          }
        ];
      })
      .flat();

    const datasetEntrances = datasets.filter(
      set => set.zoneType === 'entrances'
    );
    const datasetExits = datasets.filter(set => set.zoneType === 'exits');

    /* NOTE: Feature disabled till it meets all requirements for production
    let rushHourOptions;
    let rushHourLabels;
    const datasetRushHour = dataPoints
      .map((dataPoint, index, array) => {
        const boxNum = index * 2;
        const totalBoxes = array.length * 2;
        //console.log({boxNum, totalBoxes});
        const data = entrances[dataPoint.zone_key] || { results: [] };
        const exitColor = [
          255 - dataPoint.color[0],
          255 - dataPoint.color[1],
          255 - dataPoint.color[2]
        ];

        //make most of the option objects:
        let chartOptionsEntrances = {
          id: dataPoint.id + '-entrances',
          zoneType: 'entrances',
          label: dataPoint.label + ' - entrances',
          backgroundColor: `rgb(${dataPoint.color.join(',')})`,
          //borderColor: 'rgb(0,0,0)',
          radius: 0,
          borderWidth: 2
        };

        let chartOptionsExits = {
          id: dataPoint.id + '-exits',
          zoneType: 'exits',
          label: dataPoint.label + ' - exits',
          backgroundColor: `rgb(${exitColor.join(',')})`,
          //borderColor: 'rgb(0,0,0)',
          radius: 0,
          borderWidth: 2
        };

        if (type === 'hour') {
          //hourly format
          chartOptionsEntrances.data = data.results.map(node => ({
            x: new Date(node.date),
            y: new Date(data.results[0].date).setHours(
              new Date(node.date).getHours()
            ),
            r: node.entrances,
            pos: { boxNum: boxNum, totalBoxes: totalBoxes }
          }));
          chartOptionsExits.data = data.results.map(node => ({
            x: new Date(node.date),
            y: new Date(data.results[0].date).setHours(
              new Date(node.date).getHours()
            ),
            r: node.exits,
            pos: { boxNum: boxNum + 1, totalBoxes: totalBoxes }
          }));
          rushHourOptions = {
            aspectRatio: 1.5,
            scales: {
              y: {
                type: 'time',
                time: {
                  unit: 'hour',
                  round: 'hour',
                  ticks: {
                    source: 'data'
                  }
                }
              },
              x: {
                type: 'timeseries',
                time: {
                  unit: 'day',
                  round: 'day',
                  ticks: {
                    source: 'data'
                  }
                }
              }
            }
          };
        } else {
          //daily format
          chartOptionsEntrances.data = data.results.map(node => ({
            x: new Date(node.date),
            y: new Date(data.results[0].date).setDate(
              new Date(node.date).getDate()
            ),
            r: node.entrances,
            pos: { boxNum: boxNum, totalBoxes: totalBoxes }
          }));
          chartOptionsExits.data = data.results.map(node => ({
            x: new Date(node.date),
            y: new Date(data.results[0].date).setDate(
              new Date(node.date).getDate()
            ),
            r: node.exits,
            pos: { boxNum: boxNum + 1, totalBoxes: totalBoxes }
          }));
          rushHourOptions = {
            aspectRatio: 1.5,
            scales: {
              y: {
                reverse: true,
                type: 'time',
                time: {
                  unit: 'day',
                  round: 'day',
                  displayFormats: {
                    day: 'do'
                  },
                  ticks: {
                    source: 'data'
                  }
                }
              },
              x: {
                type: 'timeseries',
                time: {
                  unit: 'month',
                  round: 'month',
                  ticks: {
                    source: 'data'
                  }
                }
              }
            }
          };
        }
        rushHourOptions.plugins = {
          tooltip: {
            //Below is where the tooltip is customized
            callbacks: {
              title: function (context) {
                let x = context[0].element.$context.raw.x;
                return x;
              }
            }
          }
        };
        return [chartOptionsEntrances, chartOptionsExits];
      })
      .flat();
    */

    return (
      <Page padding={false}>
        <DateRangePicker
          exporter={
            <Exporter
              isMobile={this.props.isMobile}
              data={entrances}
              dataPoints={dataPoints}
            />
          }
        />
        <DataPicker
          history={history}
          DataPointType={dataPointSystem.DataPointType.ZoneEntrances}
          category="entrances"
        />
        <Page>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} lg={3}>
              <BigData
                datasets={datasetEntrances}
                aggregation="sum"
                title="Total Entrances"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <BigData
                datasets={datasetExits}
                aggregation="sum"
                title="Total Exits"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <BigData
                datasets={datasetEntrances}
                aggregation="average"
                unitLabel={unitLabel}
                title="Average Entrances"
                numberFormat="0,0.[00]"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <BigData
                datasets={datasetExits}
                aggregation="average"
                unitLabel={unitLabel}
                title="Average Exits"
                numberFormat="0,0.[00]"
              />
            </Grid>
            {/* When the HourlyChart is ready for prod make lg={6} */}
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Entrances and Exits
                  </Typography>
                  <LineBarChart chartType="line" datasets={datasets} />
                </CardContent>
              </Card>
            </Grid>
            {/* NOTE: Feature disabled till it meets all requirements for production */}
            {/* <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">Entrances and Exits</Typography>
                  <HourlyChart
                    datasets={datasetRushHour}
                    options={rushHourOptions}
                  />
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Page>
      </Page>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      dataPoints: Object.values(state.dataPointSystem).filter(
        dataPoint => dataPoint.parent === 'entrances'
      ),
      entrances: state.aggregate.entrances,
      timezone: state.dateTime.timezone,
      interval: state.dateTime.interval,
      endDateTime: state.dateTime.endDateTime,
      startDateTime: state.dateTime.startDateTime,
      // Mobile state
      isMobile: state.device.isMobile
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Aggregate => Zones
        getZoneEntrances: aggregate.zone.entrances.get,
        // Data Point System => Data Points
        dataPointsUpdate: dataPointSystem.update
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  Entrances.mapStateToProps,
  Entrances.mapDispatchToProps
)(withSnackbar(Entrances));
