// NPM Modules
import React from 'react';
import styled from 'styled-components';
// Material-UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiDialog from '@material-ui/core/Dialog';
import MuiToolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// Material-UI Icons
import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
import PhotoSizeSelectSmallRoundedIcon from '@material-ui/icons/PhotoSizeSelectSmallRounded';
// Styled Components
export const Container = styled.div`
  display: ${({ display }) => display};
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(4)}px;
  height: calc(100vh - 56px);
  overflow-y: auto;
  right: 0;
  width: 400px;
`;
export const AppBar = styled(MuiAppBar)`
  background: ${({ theme, mode }) =>
    mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.background.default};
`;

const useStyles = makeStyles(theme => ({
  root: {
    transition: '0.3s',
    boxShadow: theme.shadows[4]
  },
  paper: {
    transition: '0.3s',
    background: props =>
      props.mode === 'light'
        ? theme.palette.background.paper
        : theme.palette.background.default
  }
}));
export const Dialog = props => {
  const classes = useStyles(props);
  return <MuiDialog {...props} classes={classes} />;
};

export const Toolbar = withStyles(theme => ({
  root: {
    paddingLeft: `${theme.spacing(6)}px`,
    paddingRight: `${theme.spacing(6)}px`
  }
}))(MuiToolbar);
// Custom colored icons
export const ArrayIcon = styled(ExtensionRoundedIcon)`
  color: rgb(${({ iconcolor }) => iconcolor.toString()});
`;
export const ZoneIcon = styled(PhotoSizeSelectSmallRoundedIcon)`
  color: rgb(${({ iconcolor }) => iconcolor.toString()});
`;
export const ExpandButton = styled(IconButton)`
  flex-grow: 1;
  margin-right: 48px;
`;
