// NPM Modules
import React from 'react';
import PropTypes from 'prop-types';
// App Components
import fileDataExporter from '../../../../components/FileDataExporter';
// Material-UI
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// Material-UI Icons
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

class Exporter extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    dataPoints: PropTypes.array,
    fileName: PropTypes.string
  };
  static defaultProps = {
    data: {},
    dataPoints: [],
    fileName: 'exported-data'
  };
  /**
   * Component State
   * @namespace
   * @property {object} state
   * @property {Element} state.anchorEl
   */
  state = {
    anchorEl: null,
    open: false,
    width: '100%'
  };
  /**
   * @returns {React.Component}
   */
  render() {
    if (this.props.isMobile) return this.mobileView();
    return this.desktopView();
  }
  mobileView() {
    return (
      <React.Fragment>
        <Divider />
        <List>
          <ListItem button onClick={() => this.setState({ open: true })}>
            <ListItemText>Export Data</ListItemText>
          </ListItem>
        </List>
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          keepMounted
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Data Export</DialogTitle>
          <DialogContent dividers>
            <List>
              <ListItem size button onClick={this.handleClose('csv')}>
                <ListItemText primary="CSV" />
              </ListItem>
              <ListItem button onClick={this.handleClose('xlsx')}>
                <ListItemText primary="Excel" />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ open: false })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  desktopView() {
    const open = Boolean(this.state.anchorEl);
    return (
      <React.Fragment>
        <Popper
          open={open}
          anchorEl={this.state.anchorEl}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 500 }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper style={{ width: this.state.width }}>
                <ClickAwayListener onClickAway={this.handleClose()}>
                  <MenuList autoFocusItem={open}>
                    <MenuItem onClick={this.handleClose('csv')}>CSV</MenuItem>
                    <MenuItem onClick={this.handleClose('xlsx')}>
                      Excel
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button endIcon={<GetAppRoundedIcon />} onClick={this.handleOpen}>
          Export
        </Button>
      </React.Fragment>
    );
  }
  /**
   * Sets state anchorEl to button pressed
   * @param {event}
   */
  handleOpen = ({ currentTarget }) => {
    this.setState({
      anchorEl: currentTarget,
      width: `${currentTarget.getBoundingClientRect().width}px`
    });
  };
  /**
   * Closes popper and if fileType is specified it will download that file
   * @param {'xlsx'|'csv'} fileType
   */
  handleClose =
    (fileType = null) =>
    () => {
      if (fileType && this.props.dataPoints.length > 0) {
        fileDataExporter(
          this.props.data,
          this.props.dataPoints,
          fileType,
          this.props.fileName
        );
      }
      this.setState({ anchorEl: null });
    };
}

export default Exporter;
