// Actions
import get from './templates/get';
import post from './templates/post';

import { types, actions } from '../actions/authentication';

const URL = '/api/accounts';

export default {
  //                    action$    type(action$, actionHandler,                actionType,                 URL)
  passwordReset:        action$ => post(action$, actions.passwordReset,        types.passwordReset,        `${URL}/password_reset/reset`),
  passwordResetRequest: action$ => post(action$, actions.passwordResetRequest, types.passwordResetRequest, `${URL}/password_reset/request`)
};
