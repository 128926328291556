// Actions
import dataPoints from '../actions/dataPoints';
// Organization Actions
import organization from '../actions/organization';
// User Actions
import user from '../actions/user';
// Default State of Reducer
const defaultState = {
  entrance: [],
  visit: [],
  array: []
};
/**
 * DataPoints Reducer
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case user.types.LOGOUT_SUCCESS:
    case organization.types.info.GET: {
      return { ...defaultState };
    }

    case dataPoints.types.ADD: {
      let newState = { ...state };
      let points = [...newState[action.payload.type]];
      let point = null;
      if (action.payload.type === 'array') {
        point = points.findIndex(p => p.array_key === action.payload.array_key);
      } else {
        point = points.findIndex(p => p.zone_key === action.payload.zone_key);
      }
      if (point !== -1) {
        points[point] = action.payload;
      } else {
        points.push(action.payload);
      }
      state[action.payload.type] = points;
      return state;
    }
    case dataPoints.types.REMOVE: {
      let newState = { ...state };
      let points = [...newState[action.payload.type]];
      let point = null;
      if (action.payload.type === 'array') {
        point = points.findIndex(p => p.array_key === action.payload.array_key);
      } else {
        point = points.findIndex(p => p.zone_key === action.payload.zone_key);
      }
      if (point !== -1) {
        points.splice(point, 1);
      }
      newState[action.payload.type] = points;
      return newState;
    }
    default: {
      return state;
    }
  }
};
