// NPM Modules
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// Components
// import Logo from '../Logo';
import SideMenu from './SideMenu';
import UserPopMenu from './UserPopMenu';
// Styled Components
import { Body, MenuButton, TopBar, Left, Right, Content, Logo } from './style';
// Material-UI
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
/**
 * DesktopTheme
 */
function DesktopTheme({ user, organization, children, title }) {
  const theme = useTheme();
  const largeWindow = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(true);
  const loggedIn = !!user;

  const toggleSideMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (largeWindow) {
      setOpen(true);
    } else if (!largeWindow && open) {
      setOpen(false);
    }
  }, [largeWindow]);

  return (
    <Body>
      <TopBar>
        <Left>
          <MenuButton edge="start" color="inherit" onClick={toggleSideMenu}>
            <MenuRoundedIcon />
          </MenuButton>
          <Logo />
        </Left>
        <Right>{loggedIn ? <UserPopMenu user={user} /> : null}</Right>
      </TopBar>
      <SideMenu
        loggedIn={loggedIn}
        open={open}
        organization={organization}
        onClose={toggleSideMenu}
        title={title}
      />
      <Content open={open}>{children}</Content>
    </Body>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.info.info,
    title: state.location.title,
    organization: state.organization.info.info,
  };
}

export default connect(mapStateToProps)(DesktopTheme);
