// NPM Modules
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Styled Components
import { ZoneIcon } from './style';
// Material-UI
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

function ZoneInfoItem({ zone }) {
  const [collapse, setCollapse] = React.useState(true);
  return (
    <React.Fragment>
      <ListItem>
        <ListItemIcon>
          <ZoneIcon iconcolor={zone.color} />
        </ListItemIcon>
        <ListItemText primary={zone.zone_name} secondary={zone.zone_key} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => setCollapse(!collapse)}>
            {collapse ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={!collapse} timeout="auto" unmountOnExit>
        <ListItem dense>
          <ListItemText
            inset
            primary="Array"
            secondary={
              <Link component={RouterLink} to={`../array/${zone.array_key}`}>
                {zone.array_key}
              </Link>
            }
          />
        </ListItem>
        <ListItem dense>
          <ListItemText inset primary="Zone Type" secondary={zone.zone_type} />
        </ListItem>
        <ListItem dense>
          <ListItemText
            inset
            primary="Algorithm Name"
            secondary={zone.algorithm_name}
          />
        </ListItem>
        <ListItem dense>
          <ListItemText
            inset
            primary="Algorithm Settings"
            secondary={JSON.stringify(zone.algorithm_settings)}
          />
        </ListItem>
        <ListItem dense>
          <ListItemText
            inset
            primary="Start Date"
            secondary={zone.start_date}
          />
        </ListItem>
        <ListItem dense>
          <ListItemText
            inset
            primary="End Date"
            secondary={zone.end_date ? zone.end_date : 'On-going'}
          />
        </ListItem>
        <ListItem dense>
          <ListItemText inset primary="Region" secondary={zone.region} />
        </ListItem>
      </Collapse>
    </React.Fragment>
  );
}
ZoneInfoItem.propTypes = { zone: PropTypes.object };
ZoneInfoItem.defaultProps = { zone: {} };

function ZoneList({ zones }) {
  return (
    <List subheader={<ListSubheader>Zone List</ListSubheader>}>
      {zones.map(zone => (
        <ZoneInfoItem key={zone.zone_key} zone={zone} />
      ))}
    </List>
  );
}
ZoneList.propTypes = { zones: PropTypes.array };
ZoneList.defaultProps = { zones: [] };
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default ZoneList;
