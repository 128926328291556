// NPM Modules
import React from 'react';
// Styled Components
import { Hexagons } from './style';
/**
 * Hexagons Component
 */
export default () => (
  <Hexagons viewBox="0 0 400 220">
    <polygon
      className="color"
      points="72.02,87.74 24.02,87.74 0.02,129.31 24.02,170.88 72.02,170.88 96.02,129.31"
    />
    <polygon
      className="color"
      points="147.02,44.44 99.02,44.44 75.02,86.01 99.02,127.58 147.02,127.58 171.02,86.01"
    />
    <polygon
      className="color"
      points="222.02,1.14 174.02,1.14 150.02,42.71 174.02,84.28 222.02,84.28 246.02,42.71"
    />
    <polygon
      className="color"
      points="297.02,44.44 249.02,44.44 225.02,86.01 249.02,127.58 297.02,127.58 321.02,86.01"
    />
    <polygon
      className="color"
      points="372.02,1.14 324.02,1.14 300.02,42.71 324.02,84.28 372.02,84.28 396.02,42.71"
    />
    <polygon
      className="color"
      points="297.02,131.04 249.02,131.04 225.02,172.61 249.02,214.18 297.02,214.18 321.02,172.61"
    />
    <path
      className="color"
      d="M222.02,87.74h-48l-24,41.57l24,41.57h48l24-41.57L222.02,87.74z M221.02,150.09h-12l-6-10.39l6-10.39h12l6,10.39L221.02,150.09z"
    />
  </Hexagons>
);
