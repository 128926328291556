// NPM Modules
import qs from 'qs';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Material-UI
import TextField from '@material-ui/core/TextField';
/**
 * SearchBar
 */
class SearchBar extends Component {
  /**
   * state
   */
  state = {
    fakeTerm:
      qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true
      }).search || '',
    searchTerm:
      qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true
      }).search || ''
  };
  // Var for history listener
  unlisten = null;
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.unlisten = this.props.history.listen(({ search }) => {
      const query = qs.parse(search, { ignoreQueryPrefix: true });
      const historyTerm = query.search || '';
      if (this.state.searchTerm !== historyTerm) {
        this.setState({ searchTerm: historyTerm });
        this.updateSearchTerm(historyTerm, false);
      }
    });
  }
  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.unlisten();
  }
  /**
   * debounce
   */
  debounce = (fn, time) => {
    let timeout;
    return function () {
      const functionCall = () => fn.apply(this, arguments);
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  };
  /**
   * updateSearchTerm
   */
  updateSearchTerm = this.debounce((fakeTerm, pushToHistory = true) => {
    this.setState({ fakeTerm });
    if (this.props.onSearch) {
      // Get the locatoin search query and update it with new search term
      const query = qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true
      });
      if (pushToHistory) {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
          search: qs.stringify(
            { ...query, search: fakeTerm },
            { encode: false }
          )
        });
      }
      this.props.onSearch(fakeTerm);
    }
  }, this.props.debounceTime);
  /**
   * handleSearchTerm
   */
  handleSearchTerm = ({ target }) => {
    this.setState({ searchTerm: target.value });
    this.updateSearchTerm(target.value);
  };
  /**
   * render
   */
  render() {
    return (
      <TextField
        onChange={this.handleSearchTerm}
        style={{ flexGrow: 1 }}
        label={this.props.text}
        variant={this.props.variant}
        value={this.state.searchTerm}
      />
    );
  }
  /**
   * Component Default Prop State
   */
  static defaultProps = {
    text: '',
    variant: 'standard'
  };
  /**
   * Component Prop Types
   */
  static propTypes = {
    text: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled'])
  };
}
/**
 * Export React Component
 */
export default SearchBar;
