import dark from './dark';
import light from './light';
import nord from './nord';
import nordLight from './nord-light';
import scan from './scan';

export default {
  dark,
  light,
  nord,
  nordLight,
  scan
};
