// NPM Modules
import styled from 'styled-components';
// Material-UI
import Chip from '@material-ui/core/Chip';
// Styled Components
export const CustomChip = styled(Chip)`
  && {
    color: ${({ theme, chipcolor }) =>
      theme.palette.getContrastText(chipcolor)};
    background-color: ${({ chipcolor }) => chipcolor};
    margin: 6px 8px;

    svg {
      color: ${({ theme, chipcolor }) =>
        theme.palette.getContrastText(chipcolor)};
    }
  }
`;
// NOTE: Dumb way to do this but till styled-components fixed dom issue doing it this way.
export const AddDataPointChip = styled(Chip)`
  && {
    margin: 6px 8px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    span {
      ${({ label }) => (label === '' ? `padding: 0` : null)}
    }
    svg {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      ${({ label }) => (label === '' ? `margin: 0px 4px;` : null)}
    }
  }
`;
export const Section = styled.div`
  padding: ${({ theme, isMobile }) =>
    `${theme.spacing(3)}px ${theme.spacing(isMobile ? 4 : 6)}px`};
`;
