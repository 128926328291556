// Module
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
// Material-UI
import MuiPaper from '@material-ui/core/Paper';
import MuiDivider from '@material-ui/core/Divider';
import MuiToolbar from '@material-ui/core/Toolbar';
import MuiTypography from '@material-ui/core/Typography';
// Styled Components
export const MarkdownSection = styled(ReactMarkdown)`
  display: flex;
  flex-direction: column;
`;
export const SectionHeader = styled(MuiTypography)`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;
export const SectionDivider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(4)}px 0;
`;
export const Block = styled.div`
  margin: ${({ theme }) => theme.spacing(3)}px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const BlockTitle = styled(MuiTypography)`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  flex: 1 0 100%;
`;

export const BlockType = styled(MuiTypography)`
  flex: 0 0 0;
  align-self: flex-start;

  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  ${({ children, theme }) => {
    const type = children[0];
    let background = '';
    switch (type) {
      case 'Breaking': {
        background = theme.palette.error.main;
        break;
      }
      case 'Change': {
        background = theme.palette.warning.main;
        break;
      }
      default: {
        background = theme.palette.success.main;
        break;
      }
    }
    return `background: ${background}; color: ${theme.palette.getContrastText(
      background
    )};`;
  }}
`;

export const BlockDescription = styled(MuiTypography)`
  flex: 1 1 50%;
  margin-left: ${({ theme }) => theme.spacing(3)}px;
`;
export const Toolbar = styled(MuiToolbar)`
  padding: 0px ${({ isMobile, theme }) => theme.spacing(isMobile ? 4 : 6)}px;
`;
export const FullScreenDark = styled(MuiPaper)`
  background: ${({ fullscreen, theme }) =>
    fullscreen
      ? theme.palette.background.default
      : theme.palette.background.paper};
`;
