// NPM Modules
import styled from 'styled-components';
// Styled Componets
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;
export const Body = styled.div``;
export const SubHeader = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: flex-end;
`;
export const Loading = styled.div`
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
