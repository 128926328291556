// NPM Modules
import React from 'react';
import moment from 'moment-timezone';
// Components
import EditDialog from './EditDialog';
import UserInviteDialog from '../../../../components/Dialogs/UserInviteDialog';
// Material-UI
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Dialog Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// Material-UI Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
/**
 * PanelItem
 * @param {*} props
 */
function PanelItem(props) {
  return (
    <ListItem dense>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography variant="body1">{props.label}</Typography>
            <Typography variant="body2">{props.value}</Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}
/**
 * DeploymentInfoPanel
 * @param {*} param0
 */
function DeploymentInfoPanel(props) {
  const [open, setOpen] = React.useState(true);
  const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  return (
    <React.Fragment>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete deployment forever?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once a deployment is deleted it can never be undeleted. This is a
            permanent action. Are you sure you want to delete this deployment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={props.onDelete}>Delete forever</Button>
        </DialogActions>
      </Dialog>
      <EditDialog
        info={props.info}
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        onEdit={props.onEdit}
        isMobile={props.isMobile}
      />
      <UserInviteDialog
        open={openAddUserDialog}
        onClose={() => setOpenAddUserDialog(false)}
        deployment_key={props.info.deployment_key}
        org_key={props.organization.org_key}
        roles={
          props.canEdit
            ? [
                ['deployment_admin', 'Admin'],
                ['deployment_viewer', 'Viewer']
              ]
            : [['deployment_viewer', 'Viewer']]
        }
      />
      <List>
        <ListItem>
          <ListItemText
            primary={props.info.deployment_name}
            secondary={props.info.deployment_key}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Add user to deployment">
              <IconButton
                aria-label="add"
                onClick={() => setOpenAddUserDialog(true)}
              >
                <PersonAddRoundedIcon />
              </IconButton>
            </Tooltip>
            {props.canEdit ? (
              <Tooltip title="Edit deployment info">
                <IconButton
                  aria-label="edit"
                  onClick={() => setOpenEditDialog(true)}
                >
                  <EditRoundedIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {props.canDelete ? (
              <Tooltip title="Delete deployment">
                <IconButton
                  aria-label="delete"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <DeleteForeverRoundedIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Toggle deployment info">
              <IconButton
                edge="end"
                aria-label="toggle"
                onClick={() => setOpen(!open)}
              >
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <PanelItem label="Type:" value={props.info.deployment_type} />
          <PanelItem
            label="Start Date:"
            value={moment
              .tz(props.info.start_date, props.info.timezone)
              .format('MMMM Do YYYY')}
          />
          <PanelItem
            label="End Date:"
            value={
              props.info.end_date
                ? moment
                    .tz(props.info.end_date, props.info.timezone)
                    .format('MMMM Do YYYY')
                : 'Ongoing'
            }
          />
          <PanelItem label="Timezone:" value={props.info.timezone} />
        </Collapse>
      </List>
    </React.Fragment>
  );
}

export default DeploymentInfoPanel;
