// Action Templates
import get from './templates/get';
import post from './templates/post';
// Action Calls
export const types = {
  info: {
    GET: 'deployment/INFO:GET',
    RESET: 'deployment/INFO:RESET',
    CANCEL: 'deployment/INFO:CANCEL',
    FAILED: 'deployment/INFO:FAILED',
    SUCCESS: 'deployment/INFO:SUCCESS'
  },
  list: {
    GET: 'deployment/LIST:GET',
    RESET: 'deployment/LIST:RESET',
    CANCEL: 'deployment/LIST:CANCEL',
    FAILED: 'deployment/LIST:FAILED',
    SUCCESS: 'deployment/LIST:SUCCESS'
  },
  create: {
    POST: 'deployment/CREATE:POST',
    RESET: 'deployment/CREATE:RESET',
    CANCEL: 'deployment/CREATE:CANCEL',
    FAILED: 'deployment/CREATE:FAILED',
    SUCCESS: 'deployment/CREATE:SUCCESS'
  },
  edit: {
    POST: 'deployment/EDIT:POST',
    RESET: 'deployment/EDIT:RESET',
    CANCEL: 'deployment/EDIT:CANCEL',
    FAILED: 'deployment/EDIT:FAILED',
    SUCCESS: 'deployment/EDIT:SUCCESS'
  },
  remove: {
    POST: 'deployment/DELETE:POST',
    RESET: 'deployment/DELETE:RESET',
    CANCEL: 'deployment/DELETE:CANCEL',
    FAILED: 'deployment/DELETE:FAILED',
    SUCCESS: 'deployment/DELETE:SUCCESS'
  },
  occupancy: {
    list: {
      GET: 'deployment/occupancy/LIST:GET',
      RESET: 'deployment/occupancy/LIST:RESET',
      CANCEL: 'deployment/occupancy/LIST:CANCEL',
      FAILED: 'deployment/occupancy/LIST:FAILED',
      SUCCESS: 'deployment/occupancy/LIST:SUCCESS'
    },
    tracker: {
      GET: 'deployment/occupancy/TRACKER:GET',
      RESET: 'deployment/occupancy/TRACKER:RESET',
      CANCEL: 'deployment/occupancy/TRACKER:CANCEL',
      FAILED: 'deployment/occupancy/TRACKER:FAILED',
      SUCCESS: 'deployment/occupancy/TRACKER:SUCCESS'
    }
  },
  basestation: {
    list: {
      GET: 'deployment/basestation/LIST:GET',
      RESET: 'deployment/basestation/LIST:RESET',
      CANCEL: 'deployment/basestation/LIST:CANCEL',
      FAILED: 'deployment/basestation/LIST:FAILED',
      SUCCESS: 'deployment/basestation/LIST:SUCCESS'
    }
  }
};
// Deployment Functions
export default {
  types,
  info: get(types.info),
  list: get(types.list),
  create: post(types.create),
  edit: post(types.edit),
  remove: post(types.remove),
  occupancy: {
    list: get(types.occupancy.list),
    tracker: get(types.occupancy.tracker)
  },
  basestation: {
    list: get(types.basestation.list)
  }
};
