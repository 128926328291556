// Action Templates
import get from './templates/get';
// Action Calls
export const types = {
  title: {
    SET: 'location/TITLE:SET'
  }
};
// Deployment Functions
export default {
  title: {
    set: payload => ({ type: types.title.SET, payload })
  }
};
