// NPM Modules
import { combineReducers } from 'redux';
// Zone Action Types
import { types } from '../actions/zone';
// Reducer Template
import get from './templates/get';
import post from './templates/post';
import list from './templates/list';
// Zone Reducer
export default combineReducers({
  info:   (s, a) =>  get(s, a, types.info),
  list:   (s, a) => list(s, a, types.list),
  create: (s, a) => post(s, a, types.create),
  edit:   (s, a) => post(s, a, types.edit),
  remove: (s, a) => post(s, a, types.remove)
});
