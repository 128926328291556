// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Helpers
import permissionCheck from '../../helpers/permissionCheck';
// Actions
import array from '../../redux/actions/array';
// Components
import ArrayInfoPanel from './components/ArrayInfoPanel';
import ZoneSystem from './components/ZoneSystem';
import Page from '../../components/Page';
// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';
/**
 * Array Component
 */
class ArrayPage extends Component {
  state = {
    redirect: false
  };
  /**
   * componentDidUpdate
   */
  componentDidMount() {
    this.getArray();
  }
  /**
   * handleStatusError
   * Shows a snackbar message for status errors
   * @param {string} currentStatus - this.props.status
   * @param {string} oldStatus - prevProps.status
   * @param {string} message - message to show user
   */
  handleStatusError(currentStatus, oldStatus, message) {
    if (currentStatus === 'failed' && oldStatus !== 'failed') {
      this.props.enqueueSnackbar(message, { variant: 'error' });
    }
  }
  /**
   * componentDidUpdate
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.arrayEditStatus !== 'success' &&
      this.props.arrayEditStatus === 'success'
    ) {
      this.props.getArray({ array_key: this.props.array.array_key });
    }
    this.handleStatusError(
      this.props.arrayGetStatus,
      prevProps.arrayGetStatus,
      'Failed to find array.'
    );
    this.handleStatusError(
      this.props.arrayEditStatus,
      prevProps.arrayEditStatus,
      this.props.arrayEditError
        ? this.props.arrayEditError.data.detail ||
            this.props.arrayEditError.data.message
        : 'Failed to edit array. Check that inputs are valid.'
    );
    if (this.props.arrayDeleteStatus === 'success') {
      this.setState({ redirect: true });
      this.props.resetArray();
      this.props.deleteArrayReset();
    }
  }
  /**
   * getArray
   * Fetches array info on component load unless loaded by server.
   * If loaded by server component will wait 5 seconds before being able to get info fetched.
   */
  getArray() {
    if (
      !this.props.arrayGetStatus ||
      this.props.arrayGetStatus === 'success' ||
      !this.props.arrayGetStatus ||
      this.props.arrayGetStatus === 'success' ||
      this.props.arrayGetStatus === 'cancel' ||
      this.props.match.params.array_key !== this.props.array.array_key
    ) {
      this.props.getArray({
        array_key: this.props.match.params.array_key
      });
    } else if (
      this.props.arrayGetStatus === 'server' &&
      new Date(this.props.array.fetched).getTime() < new Date().getTime() - 5000
    ) {
      this.props.getArray({
        array_key: this.props.array.array_key
      });
    }
  }
  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    if (this.props.arrayGetStatus === 'get') {
      this.props.cancelArrayGet();
    }
    if (this.props.arrayEditStatus === 'post') {
      this.props.cancelArrayEdit();
    }
  }
  /**
   * handleArrayEdit
   */
  handleArrayEdit = array => {
    this.props.editArray({
      array_key: array.array_key,
      array_name: array.array_name,
      bounding_box_size_x: array.bounding_box_size_x,
      bounding_box_size_y: array.bounding_box_size_y,
      description: array.description,
      region: array.region
    });
  };
  /**
   * Sends request action to delete array
   */
  handleArrayDelete = () => {
    this.props.deleteArray({ array_key: this.props.array.array_key });
  };
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    if (this.state.redirect) {
      return <Redirect to="./list" />;
    }
    if (this.props.array === null) {
      return (
        <Page>
          <CircularProgress />
        </Page>
      );
    }

    const canEdit = permissionCheck('array_write', [
      this.props.organization.permissions,
      this.props.array.permissions
    ]);
    const canDelete = permissionCheck('array_create_delete', [
      this.props.organization.permissions,
      this.props.array.permissions
    ]);

    const array = this.props.array;
    if (array.context === null) array.context = {};

    return (
      <React.Fragment>
        <Page>
          <ArrayInfoPanel
            array={array}
            onEdit={this.handleArrayEdit}
            canEdit={canEdit}
            onDelete={this.handleArrayDelete}
            canDelete={canDelete}
            isMobile={this.props.isMobile}
          />
        </Page>
        <ZoneSystem array={array} canCreateDelete={canEdit} />
      </React.Fragment>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      // Device Info
      isMobile: state.device.isMobile,
      organization: state.organization.info.info,
      array: state.array.info.info,
      arrayGetError: state.array.info.error,
      arrayGetStatus: state.array.info.status,
      arrayEditError: state.array.edit.error,
      arrayEditStatus: state.array.edit.status,
      arrayDeleteInfo: state.array.remove.info,
      arrayDeleteStatus: state.array.remove.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Array Info
        getArray: array.info.get,
        resetArray: array.info.reset,
        cancelArrayGet: array.info.cancel,
        // Array Edit
        editArray: array.edit.post,
        cancelArrayEdit: array.edit.cancel,
        // Array Delete
        deleteArray: array.remove.post,
        deleteArrayReset: array.remove.reset
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  ArrayPage.mapStateToProps,
  ArrayPage.mapDispatchToProps
)(withSnackbar(ArrayPage));
