// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Components
import Actions from '../Actions';
// Material-UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
// Styled Components
import { Container } from './style';
/**
 * List
 */
class List extends Component {
  /**
   * render
   */
  render() {
    return <Container>{this.renderTiles()}</Container>;
  }
  /**
   * renderTiles
   */
  renderTiles() {
    const { list } = this.props;
    return list.map((item, key) => (
      <Card key={`list-item-${key}`}>
        <CardActionArea component={Link} to={`${item.link}`}>
          <CardContent>
            <div>{item.title}</div>
            <div>{item.subTitle}</div>
          </CardContent>
          <CardActions>
            <Actions actions={item.actions} />
          </CardActions>
        </CardActionArea>
      </Card>
    ));
  }
  /**
   * Component Default Prop State
   */
  static defaultProps = {
    list: []
  };
  /**
   * Component Prop Types
   */
  static propTypes = {
    list: PropTypes.array
  };
}
/**
 * Export React Component
 */
export default List;
