// NPM Modules
import styled from 'styled-components';
// Material-UI
import Chip from '@material-ui/core/Chip';
// Styled Components
export const CustomChip = styled(Chip)`
  && {
    background-color: ${({ chipcolor }) => chipcolor};
    margin-right: 8px;

    svg {
      color: #072a2c;
    }
  }
`;
// NOTE: Dumb way to do this but till styled-components fixed dom issue doing it this way.
export const AddDataPointChip = styled(Chip)`
  && {
    margin: 6px 8px;
    span {
      ${({ label }) => (label === '' ? `padding: 0` : null)}
    }
    svg {
      color: ${({ theme }) =>
        `rgba(${theme.palette.primary}, 1)`};
      ${({ label }) => (label === '' ? `margin: 0px 4px;` : null)}
    }
  }
`;
export const Section = styled.div`
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(6)}px`};
`;
