// NPM Modules
import styled from 'styled-components';
// Styled Components
export const LayoutManger = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    flex-wrap: nowrap;
  }
`;
export const ZoneListContainer = styled.div`
  flex: 0 1 auto;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    width: 480px;
  }
`;

export const BorderContainer = styled.div`
  ${({ theme, top = false }) =>
    `border-top: ${top ? 1 : 0}px solid ${theme.palette.divider};`}

  ${({ theme, bottom = false }) =>
    `border-bottom: ${bottom ? 1 : 0}px solid ${theme.palette.divider};`}

  ${({ theme, right = false }) =>
    `border-right: ${right ? 1 : 0}px solid ${theme.palette.divider};`}

  ${({ theme, left = false }) =>
    `border-left: ${left ? 1 : 0}px solid ${theme.palette.divider};`}
`;

export const ACTContainer = styled(BorderContainer)`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-right: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    ${({ theme }) => `border-right: 1px solid ${theme.palette.divider};`}
  }
`;
