// NPM Modules
import { types } from '../actions/metadata';
// Organization Actions
import organization from '../actions/organization';
// User Actions
import user from '../actions/user';

const defaultState = {
  array: {},
  deployment: {},
  zone: {}
};

function getKeyName(payload) {
  if (payload.hasOwnProperty('zone_key')) return 'zone';
  if (payload.hasOwnProperty('array_key')) return 'array';
  if (payload.hasOwnProperty('deployment_key')) return 'deployment';
}

export default (state = { ...defaultState }, { payload, type }) => {
  switch (type) {
    /**
     * Reset data when logging out and switching organizations
     */
    case user.types.LOGOUT_SUCCESS:
    case organization.types.info.GET: {
      return { ...defaultState };
    }

    case types.ADD: {
      const keyName = getKeyName(payload);
      const list = { ...state[keyName] };
      list[payload[`${keyName}_key`]] = { ...payload };
      return { ...state, [keyName]: { ...list } };
    }

    case types.ADD_LIST: {
      const results = payload.list;
      if (results.length > 0) {
        const keyName = getKeyName(results[0]);
        const list = { ...state[keyName] };
        results.forEach(data => {
          list[data[`${keyName}_key`]] = { ...data };
        });
        return { ...state, [keyName]: { ...list } };
      }
      return { ...state };
    }

    default: {
      return { ...state };
    }
  }
};
