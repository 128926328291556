// NPM Modules
import React, { Component } from 'react';
import moment from 'moment-timezone';
// Material-UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DatePicker } from '@material-ui/pickers';
/**
 * Simple Dialog Form
 */
class SimpleForm extends Component {
  /**
   * Component State
   */
  state = {
    ...this.props.info,
    end_date: this.props.info.end_date
      ? moment.tz(this.props.info.end_date, this.props.info.timezone)
      : null,
    timezoneInput: this.props.info.timezone,
    timezoneList: moment.tz.names()
  };
  /**
   * handleTextFieldChange
   * handles text field change and updates the component state
   * @param {*} event
   */
  handleTextFieldChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };
  /**
   * handleEndChange
   * Just here to deal with pickers component change
   * @param {moment} date
   */
  handleEndChange = date => {
    this.setState({ end_date: moment.tz(date, this.props.info.timezone) });
  };
  /**
   * handleEdit
   * Calls props.onEdit with changed state from this component
   */
  handleEdit = () => {
    const state = this.state;
    const end_date = state.end_date ? state.end_date.toISOString(true) : null;
    let dep = {
      deployment_key: state.deployment_key,
      deployment_name: state.deployment_name,
      deployment_type: state.deployment_type,
      timezone: state.timezone
    };
    if (end_date) dep.end_date = end_date;
    this.props.onEdit(dep);
  };
  /**
   * render
   */
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullScreen={this.props.isMobile}
      >
        <DialogTitle>Edit Deployment</DialogTitle>
        <DialogContent>
          <TextField
            id="deployment_name"
            label="Name"
            type="text"
            fullWidth
            value={this.state.deployment_name}
            onChange={this.handleTextFieldChange}
          />
          <TextField
            id="deployment_type"
            label="Type"
            type="text"
            fullWidth
            value={this.state.deployment_type}
            onChange={this.handleTextFieldChange}
          />
          <FormControl margin="normal" fullWidth>
            <DatePicker
              label="End Date"
              format="MMMM Do YYYY  "
              onChange={this.handleEndChange}
              value={this.state.end_date}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              fullWidth
              margin="normal"
              value={this.state.timezone}
              inputValue={this.state.timezoneInput}
              options={this.state.timezoneList}
              getOptionLabel={option => option}
              onChange={(event, timezone) => {
                this.setState({ timezone });
              }}
              onInputChange={(event, timezoneInput) => {
                this.setState({ timezoneInput });
              }}
              renderInput={params => (
                <TextField {...params} label="Timezone" name="timezone" />
              )}
              groupBy={option => option.split('/')[0]}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleEdit}>Update</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
// Export it
export default SimpleForm;
