// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';
// Actions
import theme from '../../redux/actions/organization/theme';
// Material-UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// Material-UI Icons
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// Component
import Page from '../../components/Page';
import ColorPicker from './components/ColorPicker';
// Helper Functions
/**
 * Formats theme.colors into rgba objects for easy use.
 * @param {string} strArray
 */
function formatColor(strArray) {
  const rgbArray = strArray.split(',');
  return [
    parseInt(rgbArray[0]),
    parseInt(rgbArray[1]),
    parseInt(rgbArray[2]),
    rgbArray[3] ? parseFloat(rgbArray[3]) : 1
  ];
}
/**
 * Sets up the colors state.
 * Takes in theme object from redux.
 * @param {Object} theme
 */
function setupColors(theme) {
  let colors = theme.tempColors ? theme.tempColors : theme.colors;
  const [primary, secondary] = colors.split(';').map(c => formatColor(c));
  return {
    primary,
    secondary
  };
}

/**
 * Organization Component
 */
class Organization extends Component {
  state = {
    colors: setupColors(this.props.theme),
    tempSnackbar: null
  };

  componentDidUpdate = prevProps => {
    if (!prevProps.theme.tempColors && this.props.theme.tempColors) {
      let tempSnackbar = this.props.enqueueSnackbar(
        'Color changes are temporary until you save them.',
        {
          persist: true,
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          action: key => (
            <React.Fragment>
              <Button color="primary" onClick={this.handleSave}>
                Save
              </Button>
              <IconButton
                onClick={() => {
                  this.setState({ tempSnackbar: null });
                  this.props.closeSnackbar(key);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </React.Fragment>
          )
        }
      );
      this.setState({ tempSnackbar });
    }
    if (
      prevProps.themeEditStatus === 'post' &&
      this.props.themeEditStatus === 'success'
    ) {
      if (this.state.tempSnackbar) {
        this.props.closeSnackbar(this.state.tempSnackbar);
        this.setState({ tempSnackbar: null });
      }
      this.props.enqueueSnackbar('Successfully updated theme.', {
        variant: 'success'
      });
    }
  };

  handleSave = () => {
    if (this.props.theme.tempColors) {
      this.props.editTheme({
        org_key: this.props.organization.org_key,
        colors: this.props.theme.tempColors
      });
    }
  };

  render() {
    return (
      <Page>
        <div>
          <Typography variant="h6">
            {this.props.organization.org_name}
          </Typography>
          <Typography variant="subtitle2">
            {this.props.organization.org_key}
          </Typography>
        </div>
        <ColorPicker
          label="Organization Color"
          color={this.state.colors.primary}
          onChange={newColor => {
            let colors = { ...this.state.colors };
            colors.primary = newColor;
            this.setState({ colors });
            this.props.setTempColors({
              tempColors: Object.values(colors).join(';')
            });
          }}
        />
        <Button onClick={this.handleSave}>Save</Button>
      </Page>
    );
  }
  /**
   * Map the state of redux store to the props of this component
   * @param {Object} state redux state
   * @return {Object} object of states from store
   */
  static mapStateToProps(state) {
    return {
      theme: state.organization.theme.info.info,
      themeEditStatus: state.organization.theme.edit.status,
      organization: state.organization.info.info
    };
  }
  /**
   * Map the dispatch functions to the props of this component
   * @param {Object} dispatch
   * @return {bindActionCreators} action creators
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        setTempColors: theme.info.setTempColors,
        editTheme: theme.edit.post
      },
      dispatch
    );
  }
}

export default connect(
  Organization.mapStateToProps,
  Organization.mapDispatchToProps
)(withSnackbar(Organization));
