export default {
  palette: {
    type: 'light',
    background: {
      default: 'rgba(229, 233, 240, 1)',
      paper: 'rgba(236, 239, 244, 1)'
    },
    text: {
      disabled: 'rgba(76, 86, 106, 0.38)',
      hint: 'rgba(76, 86, 106, 0.38)',
      icon: 'rgba(76, 86, 106, 0.38)',
      primary: 'rgba(76, 86, 106, 1)',
      secondary: 'rgba(76, 86, 106, 0.54)'
    }
  },
  navigation: {
    topBar: {
      text: 'rgba(236, 239, 244, 1)',
      background: 'rgba(59, 66, 82, 0.9)'
    },
    sideMenu: {
      background: '#D8DEE9'
    }
  }
};
