export default {
  palette: {
    type: 'dark',
    background: {
      default: 'rgb(36, 41, 51)',
      paper: 'rgb(46, 52, 64)'
    },
    text: {
      disabled: 'rgba(236, 239, 244, 0.7)',
      hint: 'rgba(236, 239, 244, 0.7)',
      icon: 'rgba(236, 239, 244, 0.7)',
      primary: 'rgba(236, 239, 244, 1)',
      secondary: 'rgba(236, 239, 244, 0.7)'
    }
  },
  navigation: {
    topBar: {
      text: 'rgba(236, 239, 244, 1)',
      background: 'rgba(59, 66, 82, 0.9)'
    }
  }
};

// Nav: nord-1
// RGBA: rgba(59, 66, 82, 0.9)

// Polar Night
// nord-0 #2E3440
// nord-1 #3B4252
// nord-2 #434C5E
// nord-3 #4C566A

// Snow Storm
// nord-4 #D8DEE9
// nord-5 #E5E9F0
// nord-6 #ECEFF4
