// NPM Modules
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Components
import * as Logos from '../../../Logos';
import ReleaseNotesDialog from '../../../../components/Dialogs/ReleaseNotesDialog';
// Styled Components
import {
  Drawer,
  Footer,
  MenuLogo,
  MenuButton,
  Logo,
  ListItem,
  ListItemIcon,
  ListSubheader
} from './style';
// Material-UI
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Material-ui Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Material-UI Icons
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded';
import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import FeedbackRoundedIcon from '@material-ui/icons/FeedbackRounded';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import DataUsageRoundedIcon from '@material-ui/icons/DataUsageRounded';
import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
import RoomServiceRoundedIcon from '@material-ui/icons/RoomServiceRounded';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
/**
 * Side Menu Component
 */
function SideMenu({ open, organization, onClose, loggedIn, title }) {
  const theme = useTheme();
  const persistent = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Drawer
      open={open}
      anchor="left"
      variant={persistent ? 'persistent' : 'temporary'}
      onClose={onClose}
    >
      {persistent ? (
        <SideMenuPush
          organization={organization}
          loggedIn={loggedIn}
          title={title}
        />
      ) : (
        <SideMenuPopOut
          onClose={onClose}
          organization={organization}
          loggedIn={loggedIn}
          title={title}
        />
      )}
      <Divider />
      <SideMenuFooter
        loggedIn={loggedIn}
        onClose={persistent ? null : onClose}
      />
    </Drawer>
  );
}
/**
 * SideMenuFooter
 */
function SideMenuFooter(props) {
  const [open, setOpen] = useState(false);
  const toggleReleaseNotes = () => {
    setOpen(!open);
  };
  return (
    <>
      <ReleaseNotesDialog open={open} onClose={toggleReleaseNotes} />
      <Footer>
        <Link
          variant="body2"
          href="https://www.scanalyticsinc.com/"
          target="_blank"
        >
          <Logos.PowerdByScanalytics />
        </Link>
        <br />
        <div>
          <Link
            variant="body2"
            href="https://query.scanalyticsinc.com/"
            target="_blank"
          >
            API
          </Link>
          {props.loggedIn ? (
            <>
              <span> &middot; </span>
              <Link
                variant="body2"
                component="button"
                style={{ verticalAlign: 'baseline' }}
                onClick={toggleReleaseNotes}
              >
                Release Notes
              </Link>
            </>
          ) : null}
        </div>
        <div>
          <Link
            variant="body2"
            onClick={props.onClose}
            component={RouterLink}
            to="/privacy-policy"
          >
            Privacy
          </Link>
          <span> &middot; </span>
          <Link
            variant="body2"
            onClick={props.onClose}
            component={RouterLink}
            to="/terms-of-service"
          >
            Terms
          </Link>
        </div>
        <br />
        <div>&copy; {new Date().getFullYear()} Scanalytics Inc.</div>
      </Footer>
    </>
  );
}
/**
 * Menu
 * @param {*} param0
 */
function Menu({ organization, loggedIn, onClose, title }) {
  const [feedbackDialog, openFeedbackDialog] = useState(false);
  const [contactDialog, openContactDialog] = useState(false);
  if (loggedIn) {
    const orgKey = organization.org_key;
    return (
      <React.Fragment>
        <List>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={``}
            active={(title === 'Home').toString()}
          >
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List
          subheader={
            <ListSubheader disableSticky>
              <Typography variant="button" color="inherit">
                Data Exploration
              </Typography>
            </ListSubheader>
          }
        >
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/organization/${orgKey}/data-exploration/heatmaps`}
            active={(title === 'Heatmaps').toString()}
          >
            <ListItemIcon>
              <GridOnRoundedIcon />
            </ListItemIcon>
            <ListItemText>Heatmaps</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/organization/${orgKey}/data-exploration/entrances`}
            active={(title === 'Entrances').toString()}
          >
            <ListItemIcon>
              <DataUsageRoundedIcon />
            </ListItemIcon>
            <ListItemText>Entrances</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/organization/${orgKey}/data-exploration/visits`}
            active={(title === 'Visits').toString()}
          >
            <ListItemIcon>
              <DataUsageRoundedIcon />
            </ListItemIcon>
            <ListItemText>Visits</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/organization/${orgKey}/data-exploration/occupancy`}
            active={(title === 'Occupancy').toString()}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText>Occupancy</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/organization/${orgKey}/deployment/list`}
            active={(title === 'Deployments').toString()}
          >
            <ListItemIcon>
              <DashboardRoundedIcon />
            </ListItemIcon>
            <ListItemText>Deployments</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/organization/${orgKey}/array/list`}
            active={(title === 'Arrays').toString()}
          >
            <ListItemIcon>
              <ExtensionRoundedIcon />
            </ListItemIcon>
            <ListItemText>Arrays</ListItemText>
          </ListItem>
        </List>
        {/* Feedback Form / Contact Us */}
        <Divider />
        <List>
          <ListItem button onClick={() => openContactDialog(true)}>
            <ListItemIcon>
              <ContactSupportRoundedIcon />
            </ListItemIcon>
            <ListItemText>Contact Us</ListItemText>
          </ListItem>
          <ListItem button onClick={() => openFeedbackDialog(true)}>
            <ListItemIcon>
              <FeedbackRoundedIcon />
            </ListItemIcon>
            <ListItemText>Feedback</ListItemText>
          </ListItem>
        </List>
        <Dialog open={contactDialog} onClose={() => openContactDialog(false)}>
          <DialogTitle id="simple-dialog-title">Contact Us</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              If you have any feedback for the dashboard please use the Feedback
              button on the menu. For issues, questions, or related topics
              please send an email to:{' '}
              <Link href="mailto:info@scanalyticsinc.com">
                info@scanalyticsinc.com
              </Link>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => openContactDialog(false)} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={feedbackDialog} onClose={() => openFeedbackDialog(false)}>
          <iframe
            width="600"
            height="615"
            src="https://app.smartsheet.com/b/form/fd453ab15855480ba8c022a6327efbe6"
          ></iframe>
        </Dialog>
        {/* End of Feedback Form */}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <List>
          <ListItem
            button
            component={RouterLink}
            to={`/login`}
            active={(title === 'Login').toString()}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>Login</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/privacy-policy`}
            active={(title === 'Privacy Policy').toString()}
          >
            <ListItemIcon>
              <PolicyRoundedIcon />
            </ListItemIcon>
            <ListItemText>Privacy Policy</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={onClose}
            component={RouterLink}
            to={`/terms-of-service`}
            active={(title === 'Terms of Service').toString()}
          >
            <ListItemIcon>
              <RoomServiceRoundedIcon />
            </ListItemIcon>
            <ListItemText>Terms of Service</ListItemText>
          </ListItem>
        </List>
      </React.Fragment>
    );
  }
}
/**
 * SideMenuPush
 * @param {*} param0
 */
function SideMenuPush({ organization, loggedIn, title }) {
  return <Menu organization={organization} loggedIn={loggedIn} title={title} />;
}
/**
 * SideMenuPopOut
 * @param {*} param0
 */
function SideMenuPopOut({ organization, onClose, loggedIn, title }) {
  return (
    <React.Fragment>
      <MenuLogo>
        <MenuButton onClick={onClose}>
          <MenuRoundedIcon />
        </MenuButton>
        <ListItemText>
          <Logo />
        </ListItemText>
      </MenuLogo>
      <Divider />
      <Menu
        organization={organization}
        loggedIn={loggedIn}
        title={title}
        onClose={onClose}
      />
    </React.Fragment>
  );
}

export default SideMenu;
