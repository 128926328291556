/**
 * Creates a rectangle region
 * @param {number} x position of the rectangle
 * @param {number} y position of the rectangle
 * @param {number} width of the rectangle
 * @param {number} height of the rectangle
 * @return {string} region
 */
function regionRect(x, y, width, height) {
  const bl = `${x} ${y}`;
  const br = `${x + width} ${y}`;
  const tr = `${x + width} ${y + height}`;
  const tl = `${x} ${y + height}`;
  return `POLYGON ((${bl}, ${br}, ${tr}, ${tl}, ${bl}))`;
}
/**
 * Export Region Functions
 */
export { regionRect };
