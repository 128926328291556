// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Styled Components
import {
  Block,
  BlockType,
  BlockTitle,
  BlockDescription,
  SectionHeader,
  SectionDivider,
  MarkdownSection,
  Toolbar,
  FullScreenDark
} from './style';
// Material-UI
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// Markdown Document
import release_notes from '../../../../documents/release_notes.md';
/**
 * Used to change the app bar elevation
 * @param {*} props
 * @returns
 */
function ScrollTrigger(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.target ? props.target : undefined
  });

  return React.cloneElement(props.children, {
    elevation: trigger ? 4 : 0
  });
}
/**
 * ReleaseNotesDialog Component
 */
class ReleaseNotesDialog extends Component {
  dialogRef = React.createRef();

  state = {
    markdown: ''
  };

  componentDidMount() {
    fetch(release_notes)
      .then(response => response.text())
      .then(text => {
        this.setState({ markdown: text });
      });
  }

  render() {
    return (
      <Dialog
        keepMounted
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ fullscreen: this.props.isMobile ? 1 : 0 }}
        PaperComponent={FullScreenDark}
        fullScreen={this.props.isMobile}
      >
        <ScrollTrigger target={this.dialogRef.current}>
          <AppBar position="relative" color="transparent" elevation="0">
            <Toolbar>
              {this.props.isMobile ? null : (
                <Typography variant="h6" style={{ flex: 1 }}>
                  Release Notes
                </Typography>
              )}
              <IconButton
                edge={this.props.isMobile ? 'start' : 'end'}
                onClick={this.props.onClose}
              >
                <CloseRoundedIcon />
              </IconButton>
              {this.props.isMobile ? (
                <Typography variant="h6" style={{ marginLeft: '16px' }}>
                  Release Notes
                </Typography>
              ) : null}
            </Toolbar>
          </AppBar>
        </ScrollTrigger>
        <DialogContent ref={this.dialogRef}>
          <MarkdownSection
            remarkPlugins={[]}
            children={this.state.markdown}
            components={{
              a: Link,
              hr: ({ ...props }) => <SectionDivider {...props} />,
              h1: ({ ...props }) => <SectionHeader {...props} variant="h4" />,
              blockquote: Block,
              h2: ({ ...props }) => <BlockTitle {...props} variant="h6" />,
              h3: ({ ...props }) => <BlockType {...props} variant="button" />,
              p: BlockDescription
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }

  static mapStateToProps = state => ({
    isMobile: state.device.isMobile
  });
}

export default connect(ReleaseNotesDialog.mapStateToProps)(ReleaseNotesDialog);
