// Template Actions
import get from './templates/get';
import post from './templates/post';
// Redux Actions
import zone, { types } from '../actions/zone';
// URL Shortner
const URL = '/api/zone';
// Action Commands
export default {
  //        action$    type(action$, actionHandler, actionType,   URL)
  info:     action$ =>  get(action$, zone.info,      types.info,   `${URL}/:zone_key`),
  list:     action$ =>  get(action$, zone.list,      types.list,   `${URL}/list?org_key&page&page_size&array_key&search&status&zone_type`),
  create:   action$ => post(action$, zone.create,    types.create, `${URL}/create`),
  edit:     action$ => post(action$, zone.edit,      types.edit,   `${URL}/edit`),
  remove:   action$ => post(action$, zone.remove,    types.remove, `${URL}/delete`),
};
