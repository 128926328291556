// NPM Modules
import moment from 'moment-timezone';
// Actions
import { types } from '../actions/dateTime';
import user from '../actions/user';
// Default State of Reducer
const defaultState = {
  startDateTime: moment
    .tz(moment.tz.guess(true))
    .subtract(7, 'days')
    .format('Y-MM-DDT00:00:00'),
  endDateTime: moment.tz(moment.tz.guess(true)).format('Y-MM-DDT23:59:59'),
  interval: '1d',
  timezone: moment.tz.guess(true),
  threshold: 4000
};
/**
 * Date Time Reducer
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case user.types.login.SUCCESS: {
      return {
        ...state,
        timezone: action.payload.timezone
      };
    }
    case types.startDateTime.SET: {
      return {
        ...state,
        startDateTime: moment
          .tz(action.payload, state.timezone)
          .format('Y-MM-DDTHH:mm:ss')
      };
    }
    case types.endDateTime.SET: {
      return {
        ...state,
        endDateTime: moment
          .tz(action.payload, state.timezone)
          .format('Y-MM-DDTHH:mm:ss')
      };
    }
    case types.interval.SET: {
      return { ...state, interval: action.payload };
    }
    case types.all.SET: {
      const { startDateTime, endDateTime, interval, timezone, threshold } =
        action.payload;
      return {
        startDateTime: moment
          .tz(startDateTime, state.timezone)
          .format('Y-MM-DDTHH:mm:ss'),
        endDateTime: moment
          .tz(endDateTime, state.timezone)
          .format('Y-MM-DDTHH:mm:ss'),
        interval,
        timezone: timezone ? timezone : state.timezone,
        threshold
      };
    }
    default: {
      return state;
    }
  }
};
