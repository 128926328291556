import Tool from './Tool';
/**
 * Pan Tool
 */
class Pan extends Tool {
  /**
   * TODO: Fill in comments
   * @param {*} cake
   * @param {*} active
   */
  constructor(cake, active) {
    super(cake, active);
    // Mouse Events
    this.mouseDown = false;
    this.on('mouseup', this.handleMouseUp);
    this.on('mousemove', this.handleMouseMove);
    this.on('mousedown', this.handleMouseDown, true);
  }
  /**
   * TODO: Fill in comments
   * @param {*} event
   */
  handleMouseUp = event => {
    if (this.isActive()) {
      this.mouseDown = false;
      this._cake.canvas.style.cursor = 'grab';
    }
  };
  /**
   * TODO: Fill in comments
   * @param {*} event
   */
  handleMouseMove = event => {
    if (this.isActive()) {
      if (this.mouseDown) {
        this._cake._viewport.e += event.clientX - this._cake._mouse.x;
        this._cake._viewport.f += event.clientY - this._cake._mouse.y;
        this._cake.canvas.style.cursor = 'grabbing';
        this._cake.update();
      }

      this._cake._mouse.x = event.clientX;
      this._cake._mouse.y = event.clientY;
    }
  };
  /**
   * TODO: Fill in comments
   * @param {*} event
   */
  handleMouseDown = event => {
    if (this.isActive() && event.button === 0) {
      this.mouseDown = true;
      this._cake._mouse.x = event.clientX;
      this._cake._mouse.y = event.clientY;
      this._cake.canvas.style.cursor = 'grab';
      event.preventDefault();
    }
  };
}

export default Pan;
