// Modules
import styled from 'styled-components';
// Material-UI
import ButtonBase from '@material-ui/core/ButtonBase';

// Styled Components
export const ColorButton = styled(ButtonBase)`
  padding: 8px 16px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border: 5px solid
    ${({ theme, color }) => {
      let c = theme.palette.getContrastText(color);
      if (c.indexOf('rgb') !== -1) {
        let a = c
          .substring(c.indexOf('(') + 1, c.length - 1)
          .split(',')
          .map(n => parseFloat(n));
        a[3] = 0.2;
        return `rgba(${a.toString()})`;
      }
      return 'rgba(255,255,255,0.3)';
    }};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: ${({ color }) => color};
`;
