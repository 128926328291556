// Actions
import get from './templates/get';
import post from './templates/post';
import array, { types } from '../actions/array';

const URL = '/api/array';

export default {
  //        action$    type(action$, actionHandler, actionType,   URL)
  info:     action$ =>  get(action$, array.info,      types.info,    `${URL}/:array_key`),
  list:     action$ =>  get(action$, array.list,      types.list,    `${URL}/list?org_key&page&page_size&deployment_key&search&status`),
  matList:  action$ =>  get(action$, array.matList,   types.matList, `${URL}/:array_key/mat/list?page&page_size`),
  create:   action$ => post(action$, array.create,    types.create,  `${URL}/create`),
  edit:     action$ => post(action$, array.edit,      types.edit,    `${URL}/edit`),
  remove:   action$ => post(action$, array.remove,    types.remove,  `${URL}/delete`),
};
