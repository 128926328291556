// NPM Modules
import styled from 'styled-components';
// Material-UI
import BlurOnRoundedIcon from '@material-ui/icons/BlurOnRounded';
import PhotoSizeSelectSmallRoundedIcon from '@material-ui/icons/PhotoSizeSelectSmallRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import MeetingRoomRoundedIcon from '@material-ui/icons/MeetingRoomRounded';

// Styled Components
export const ZoneIcon = styled(PhotoSizeSelectSmallRoundedIcon)`
  color: rgb(${({ iconcolor }) => iconcolor.toString()});
`;
export const DoorIcon = styled(MeetingRoomRoundedIcon)`
  color: ${({ iconcolor }) => iconcolor.toString()};
`;
export const LineIcon = styled(RemoveRoundedIcon)`
  color: ${({ iconcolor }) => iconcolor.toString()};
`;