// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link as RouterLink } from 'react-router-dom';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import array from '../../redux/actions/array';
import deployment from '../../redux/actions/deployment';
// Helper Functions
import { regionRect } from '../../helpers/region';
import debounce from '../../helpers/debounce';
// Material-UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Default State
const DEFAULT_STATE = {
  array_name: '',
  bounding_box_size_x: 2,
  bounding_box_size_y: 2,
  deployment_key: '',
  deploymentSearchTerm: '',
  description: '',
  region: 'POLYGON ((0 0, 1 0, 1 1, 0 1, 0 0))',
  loading: false
};
/**
 * Create Array Dialog
 */
class CreateArrayDilaog extends Component {
  state = { ...DEFAULT_STATE };
  /**
   * fires when a component has updated
   * send message to use if array was created successfully otherwise send error message
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const {
      arrayCreateInfo,
      arrayCreateError,
      arrayCreateStatus,
      organization
    } = this.props;
    if (
      arrayCreateStatus === 'success' &&
      prevProps.arrayCreateStatus !== 'success'
    ) {
      this.props.onArrayCreated();
      this.props.enqueueSnackbar(
        <span>
          Array successfully created:{' '}
          <Link
            component={RouterLink}
            to={`/organization/${organization.org_key}/array/${arrayCreateInfo.array_key}`}
          >
            {arrayCreateInfo.message.slice(0, -14)}
          </Link>
        </span>
      );
      this.setState({ ...DEFAULT_STATE });
    }
    if (
      arrayCreateStatus === 'failed' &&
      prevProps.arrayCreateStatus !== 'failed' &&
      arrayCreateError
    ) {
      this.props.enqueueSnackbar(arrayCreateError.data.message, {
        variant: 'error'
      });
    }
  }
  /**
   * Fires array create action
   */
  handleCreateArray = () => {
    this.props.createArray({
      array_name: this.state.array_name,
      bounding_box_size_x: this.state.bounding_box_size_x,
      bounding_box_size_y: this.state.bounding_box_size_y,
      deployment_key: this.props.deployment_key
        ? this.props.deployment_key
        : this.state.deployment_key,
      description: this.state.description,
      region: this.state.region
    });
  };
  /**
   * Handles field input changes and updates component state to reflect
   * @param {Object} event
   */
  handleFieldChange = ({ target }) => {
    let value = target.value;
    let region = this.state.region;
    if (target.type === 'number') {
      const { bounding_box_size_x, bounding_box_size_y } = this.state;
      // Make sure parse the string value to number
      value = parseInt(value, 10);
      value = value < 1 ? 0 : value;
      if (target.name === 'bounding_box_size_x') {
        region = regionRect(0, 0, value - 1, bounding_box_size_y - 1);
      } else {
        region = regionRect(0, 0, bounding_box_size_x - 1, value - 1);
      }
    }
    this.setState({ [target.name]: value, region });
  };
  /**
   * update deploymentSearchTerm state and set debounce call to get new data
   */
  handleDeploymentListSearch = ({ target }) => {
    this.setState({ deploymentSearchTerm: target.value, loading: true });
    this.debounceDeploymentListSearch();
  };
  /**
   * fetches new list of deployments with search key term
   * @param {Object} event
   */
  debounceDeploymentListSearch = debounce(() => {
    this.setState({ loading: false });
    this.props.getDeploymentList({
      org_key: this.props.organization.org_key,
      search: this.state.deploymentSearchTerm,
      status: 'active'
    });
  }, 500);
  /**
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    const loading =
      this.state.loading || this.props.deploymentListStatus === 'get';
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullScreen={this.props.isMobile}
      >
        <DialogTitle>Create Array</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The region field is locked and is adjust by changing the bounding
            box size.
          </DialogContentText>
          {this.props.deployment_key ? null : (
            <Autocomplete
              options={loading ? [] : this.props.deploymentList}
              loading={loading}
              getOptionLabel={option => option.deployment_name}
              onChange={(_, value) => {
                if (value && value.hasOwnProperty('deployment_key')) {
                  this.setState({ deployment_key: value.deployment_key });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Deployment"
                  type="text"
                  fullWidth
                  onChange={this.handleDeploymentListSearch}
                />
              )}
            />
          )}
          <TextField
            name="array_name"
            label="Name"
            type="text"
            fullWidth
            value={this.state.array_name}
            onChange={this.handleFieldChange}
          />
          <TextField
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={this.state.description}
            onChange={this.handleFieldChange}
          />
          <TextField
            name="bounding_box_size_x"
            label="Bounding box size x"
            type="number"
            fullWidth
            value={this.state.bounding_box_size_x}
            onChange={this.handleFieldChange}
          />
          <TextField
            name="bounding_box_size_y"
            label="Bounding box size Y"
            type="number"
            fullWidth
            value={this.state.bounding_box_size_y}
            onChange={this.handleFieldChange}
          />
          <TextField
            name="region"
            label="Region"
            disabled
            type="text"
            fullWidth
            value={this.state.region}
            onChange={this.handleFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleCreateArray}>Create</Button>
        </DialogActions>
      </Dialog>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      // Device Info
      isMobile: state.device.isMobile,
      // Organization Info
      organization: state.organization.info.info,
      // Array Create Info
      arrayCreateInfo: state.array.create.info,
      arrayCreateError: state.array.create.error,
      arrayCreateStatus: state.array.create.status,
      // Deployment List Info
      deploymentList: state.deployment.list.list,
      deploymentListPage: state.deployment.list.page,
      deploymentListCount: state.deployment.list.count,
      deploymentListError: state.deployment.list.error,
      deploymentListStatus: state.deployment.list.status,
      deploymentListPageSize: state.deployment.list.page_size
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getDeploymentList: deployment.list.get,
        createArray: array.create.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  CreateArrayDilaog.mapStateToProps,
  CreateArrayDilaog.mapDispatchToProps
)(withSnackbar(CreateArrayDilaog));
