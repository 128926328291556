// NPM Modules
import styled from 'styled-components';
// Material-UI
import MuiBox from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
// NOTE: Colors are hard coded for login page
const COLORS = {
  green: '#46c965',
  greenLight: '#66D081',
  white: '#ECEFF4',
  grey: '#D8DEE9',
  black: '#2E3440',
  background: '#2E3440',
  hexagons: '#434853'
};
// Styled Components
export const Container = styled.div`
  background-image: url(https://storage.googleapis.com/scanalytics-public-files/images/blueprint.jpg);
  background-size: cover;
  background-position: center;
`;
export const LoginColumn = styled(MuiBox)`
  position: relative;
  color: ${COLORS.white};
  min-height: 100vh;
  padding: 24px;
  background-color: ${COLORS.background};

  overflow: hidden;

  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 100%;
  }
`;
export const Link = styled(MuiLink)`
  z-index: 5;
  color: ${COLORS.white};
`;

export const Logo = styled.div`
  z-index: 5;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;

  svg {
    position: relative;
  }
  svg .logo-hexagon-color {
    fill: ${COLORS.green};
  }
  svg .logo-text-color {
    fill: ${COLORS.white};
  }
`;
export const Hexagons = styled.svg`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 220%;
  transform: translate(-25%, 40%) rotate(330deg);

  z-index: 0;

  .color {
    fill: ${COLORS.hexagons};
  }
`;
