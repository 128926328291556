// Action Templates
import get from './templates/get';
import post from './templates/post';
// Action Calls
export const types = {
  // Organization Actions
  info: {
    GET: 'organization/INFO:GET',
    RESET: 'organization/INFO:REST',
    CANCEL: 'organization/INFO:CANCEL',
    FAILED: 'organization/INFO:FAILED',
    SUCCESS: 'organization/INFO:SUCCESS'
  },
  list: {
    GET: 'organization/LIST:GET',
    RESET: 'organization/LIST:RESET',
    CANCEL: 'organization/LIST:CANCEL',
    FAILED: 'organization/LIST:FAILED',
    SUCCESS: 'organization/LIST:SUCCESS'
  },
  edit: {
    POST: 'organization/EDIT:POST',
    RESET: 'organization/EDIT:RESET',
    CANCEL: 'organization/EDIT:CANCEL',
    FAILED: 'organization/EDIT:FAILED',
    SUCCESS: 'organization/EDIT:SUCCESS'
  },
  create: {
    POST: 'organization/CREATE:POST',
    RESET: 'organization/CREATE:RESET',
    CANCEL: 'organization/CREATE:CANCEL',
    FAILED: 'organization/CREATE:FAILED',
    SUCCESS: 'organization/CREATE:SUCCESS'
  },
  delete: {
    POST: 'organization/DELETE:POST',
    RESET: 'organization/DELETE:RESET',
    CANCEL: 'organization/DELETE:CANCEL',
    FAILED: 'organization/DELETE:FAILED',
    SUCCESS: 'organization/DELETE:SUCCESS'
  },
  overview: {
    GET: 'organization/OVERVIEW:GET',
    RESET: 'organization/OVERVIEW:RESET',
    CANCEL: 'organization/OVERVIEW:CANCEL',
    FAILED: 'organization/OVERVIEW:FAILED',
    SUCCESS: 'organization/OVERVIEW:SUCCESS'
  },
  // Organization Member Actions
  member: {
    info: {
      GET: 'organization/member/INFO:GET',
      RESET: 'organization/member/INFO:RESET',
      CANCEL: 'organization/member/INFO:CANCEL',
      FAILED: 'organization/member/INFO:FAILED',
      SUCCESS: 'organization/member/INFO:SUCCESS'
    },
    list: {
      GET: 'organization/member/LIST:GET',
      RESET: 'organization/member/LIST:RESET',
      CANCEL: 'organization/member/LIST:CANCEL',
      FAILED: 'organization/member/LIST:FAILED',
      SUCCESS: 'organization/member/LIST:SUCCESS'
    },
    add: {
      POST: 'organization/member/ADD:POST',
      RESET: 'organization/member/ADD:RESET',
      CANCEL: 'organization/member/ADD:CANCEL',
      FAILED: 'organization/member/ADD:FAILED',
      SUCCESS: 'organization/member/ADD:SUCCESS'
    },
    edit: {
      POST: 'organization/member/EDIT:POST',
      RESET: 'organization/member/EDIT:RESET',
      CANCEL: 'organization/member/EDIT:CANCEL',
      FAILED: 'organization/member/EDIT:FAILED',
      SUCCESS: 'organization/member/EDIT:SUCCESS'
    },
    remove: {
      POST: 'organization/member/REMOVE:POST',
      RESET: 'organization/member/REMOVE:RESET',
      CANCEL: 'organization/member/REMOVE:CANCEL',
      FAILED: 'organization/member/REMOVE:FAILED',
      SUCCESS: 'organization/member/REMOVE:SUCCESS'
    }
  }
};
// Organization Functions
export default {
  types,
  // GET Requests
  info:      get(types.info),
  list:      get(types.list),
  overview:  get(types.overview),
  // POST Requests
  create:   post(types.create),
  edit:     post(types.edit),
  delete:   post(types.delete),
  // Organization Member Endpoints
  member: {
    // GET Requests
    info:    get(types.member.info),
    list:    get(types.member.list),
    // POST Requests
    add:    post(types.member.add),
    edit:   post(types.member.edit),
    remove: post(types.member.remove)
  }
};
