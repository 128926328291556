// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../../actions/organization/theme';
// Reducer Template
import post from '../templates/post';

const DEFAULT_STATE = {
  error: null,
  status: null,
  info: {
    logourl:
      'https://storage.googleapis.com/scanalytics-public-files/images/dashboard.svg',
    colors:
      '70,201,101,1;70,201,101,1;9,52,55,0.9;5,32,35,1;81,224,115,1;7,42,45,0.9;255,255,255,1;70,201,101,1;6,41,43,1;255,255,255,1;81,224,115,1;255,255,255,0.08;255,255,255,0.12',
    tempColors: null
  }
};

const copy = state => ({ ...state, info: { ...state.info } });

const info = (state = DEFAULT_STATE, { type, payload }) => {
  const stateCopy = copy(state);
  const defaultStateCopy = copy(DEFAULT_STATE);
  switch (type) {
    case types.info.SET_TEMP_COLORS: {
      const { tempColors } = payload;
      return { ...stateCopy, info: { ...state.info, tempColors } };
    }
    case types.edit.SUCCESS: {
      const { colors } = payload;
      return {
        ...stateCopy,
        info: { ...state.info, tempColors: null, colors }
      };
    }
    case types.info.GET: {
      return {
        ...stateCopy,
        error: null,
        status: 'get'
      };
    }
    case types.info.SUCCESS: {
      const { logourl, colors } = payload;
      return {
        ...defaultStateCopy,
        info: {
          ...defaultStateCopy.info,
          logourl,
          colors
        },
        status: 'success'
      };
    }
    case types.info.FAILED: {
      return {
        ...stateCopy,
        error: null,
        status: 'get'
      };
    }
    default: {
      return stateCopy;
    }
  }
};

export default combineReducers({
  info,
  edit: (s, a) => post(s, a, types.edit)
});
