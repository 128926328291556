// Modules
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware, compose } from 'redux';

// Reducers
import reducers from './reducers';

// Epics
import epics from './epics';

export default preloadedState => {
  const epicMiddlware = createEpicMiddleware();

  const composeEnhancers =
    process.env.NODE_ENV === 'production'
      ? compose
      : (typeof window !== 'undefined' &&
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

  const store = createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(epicMiddlware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  // v4 redux + update to redux-observable and rxjs v6
  epicMiddlware.run(epics);

  return store;
};
