// Organization Actions
import organization from '../../actions/organization';
// User Actions
import user from '../../actions/user';
/**
 * defaultState
 * @property {string} status null, get, failed, success, cancel
 * @property {object} error null or info on why request failed
 * @property {object} info null or response from request
 */
const defaultState = { status: null, error: null, info: null };
/**
 * Template Get Reducer
 */
export default (state = defaultState, action, types) => {
  switch (action.type) {
    case types.GET:
      return { status: 'get', error: null, info: null };
    case types.CANCEL:
      return { status: 'cancel', error: null, info: null };
    case types.FAILED:
      return { status: 'failed', error: action.payload, info: null };
    case types.SUCCESS:
      return { status: 'success', error: null, info: action.payload };
    case user.types.LOGOUT_SUCCESS:
    case organization.types.info.GET:
    case types.RESET:
      return { ...defaultState };
    default:
      return { ...state };
  }
};
