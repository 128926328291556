// NPM Modules
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import location from '../redux/actions/location';
/**
 * LocatoinTracker Route stores the value of the current location.
 */
class LocatoinTracker extends Component {
  componentDidMount() {
    if (this.props.title !== this.props.routeTitle) {
      if (document) {
        document.title = `Scanalytics Inc. - ${this.props.routeTitle}`;
      }
      this.props.setTitle(this.props.routeTitle);
    }
  }
  componentDidUpdate() {
    if (this.props.title !== this.props.routeTitle) {
      if (document) {
        document.title = `Scanalytics Inc. - ${this.props.routeTitle}`;
      }
      this.props.setTitle(this.props.routeTitle);
    }
  }
  render() {
    const { user, path, component: Component, ...props } = this.props;

    // Send user to login page if they are logged out and path is not public
    if (user === null && !this.props.public && path !== '/login') {
      return <Redirect to="/login" />;
    }

    return <Route {...props} component={Component} />;
  }
  static mapStateToProps(state) {
    return {
      title: state.location.title,
      user: state.user.info.info
    };
  }
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        setTitle: location.title.set
      },
      dispatch
    );
  }
  static propTypes = {
    public: PropTypes.bool,
    routeTitle: PropTypes.string
  };
  static defaultProps = {
    public: false,
    routeTitle: ''
  };
}

export default connect(
  LocatoinTracker.mapStateToProps,
  LocatoinTracker.mapDispatchToProps
)(LocatoinTracker);
