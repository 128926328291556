export function convertRegionToArray(region) {
  return region
    .substring(region.indexOf('((') + 2, region.indexOf('))'))
    .split(',')
    .map(point =>
      point
        .trim()
        .split(' ')
        .map(num => parseInt(num, 10))
    );
}

export function convertPolygonToRegion(polygon) {
  return `POLYGON ((${polygon
    .map(point => point.join(' '))
    .toString()}, ${polygon[0].join(' ')}))`;
}

export function convertLineStringToLine(linestring) {
  let line = linestring
    .slice(linestring.indexOf('(') + 1, linestring.indexOf(')'))
    .split(',')
    .map(point => point.trim().split(' '))
    .map(point => point.map(n => parseInt(n, 10)));

  return [...line];
}
