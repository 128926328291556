import Tool from './Tool';
/**
 * Mouse Tool
 */
class Mouse extends Tool {
  /**
   * TODO: Fill in comments
   * @param {*} cake
   * @param {*} active
   */
  constructor(cake, active) {
    super(cake, active);
  }
}

export default Mouse;
