// NPM Modules
import React, { Component } from 'react';
// Components
import Page from '../../components/Page';
// Styled Components
import { Markdown } from './style';
// Material-UI
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
/**
 * MarkdownDocument Component
 */
class MarkdownDocument extends Component {
  state = {
    markdown: ''
  };

  componentDidMount() {
    fetch(this.props.source)
      .then(response => response.text())
      .then(text => {
        this.setState({ markdown: text });
      });
  }

  render() {
    return (
      <Page>
        <Container disableGutters maxWidth="md">
          <Markdown
            children={this.state.markdown}
            components={{
              a: Link
            }}
          />
        </Container>
      </Page>
    );
  }
}

export default MarkdownDocument;
