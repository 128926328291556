/**
 * takes in function and delays response by time
 * @param {function} fn
 * @param {number} time (miliseconds)
 */
export default function debounce(fn, time) {
  let timeout;
  return () => {
    const functionCall = () => fn.apply(this, arguments);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
}
