// NPM Modules
import styled from 'styled-components';
// Styled Components
export const Padding = styled.div`
  padding: 
    ${(props) => (props.top    ? props.theme.spacing(props.topSpacing    || props.spacing) : 0)}px
    ${(props) => (props.right  ? props.theme.spacing(props.rightSpacing  || props.spacing) : 0)}px
    ${(props) => (props.bottom ? props.theme.spacing(props.bottomSpacing || props.spacing) : 0)}px
    ${(props) => (props.left   ? props.theme.spacing(props.leftSpacing   || props.spacing) : 0)}px;
`;
