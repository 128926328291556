// NPM Packages
import numeral from 'numeral';
/**
 * formatMilliseconds
 * @param {number} milliseconds
 * @param {object} [options={}]
 * @return {{number:number, label:string}} object of number and label
 */
export default (milliseconds, opts = {}) => {
  const options = { rawout: false, round: false, shorthand: false, ...opts };
  const time = parseInt(milliseconds, 10);

  const seconds = time / 1000;
  const minutes = time / 60000;
  const hours = time / 3600000;
  const days = time / 86400000;
  const years = time / 31536000000;

  if (options.rawout) {
    return { seconds, minutes, hours, days, years };
  }

  let number = 0;
  let label = '';

  if (years > 1) {
    number = numeral(years).format(',0.00');
    if (options.shorthand) {
      label = 'y';
    } else {
      label = years === 1 ? 'year' : 'years';
    }
  } else if (days > 1) {
    number = numeral(days).format(options.round ? '0' : '0.00');
    if (options.shorthand) {
      label = 'd';
    } else {
      label = days === 1 ? 'day' : 'days';
    }
  } else if (hours > 1) {
    number = numeral(hours).format(options.round ? '0' : '0.00');
    if (options.shorthand) {
      label = 'h';
    } else {
      label = hours === 1 ? 'hour' : 'hours';
    }
  } else if (minutes > 1) {
    number = numeral(minutes).format(options.round ? '0' : '0.00');
    if (options.shorthand) {
      label = 'm';
    } else {
      label = minutes === 1 ? 'minute' : 'minutes';
    }
  } else if (seconds > 1) {
    number = numeral(seconds).format(options.round ? '0' : '0.00');
    if (options.shorthand) {
      label = 's';
    } else {
      label = seconds === 1 ? 'second' : 'seconds';
    }
  }

  return { number, label };
};
