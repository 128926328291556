// Action Templates
import get from './templates/get';
// Action Calls
export const types = {
  array: {
    heatmap: {
      GET: 'aggregate/array/HEATMAP:GET',
      RESET: 'aggregate/array/HEATMAP:RESET',
      CANCEL: 'aggregate/array/HEATMAP:CANCEL',
      FAILED: 'aggregate/array/HEATMAP:FAILED',
      SUCCESS: 'aggregate/array/HEATMAP:SUCCESS'
    }
  },
  deployment: {
    occupancy: {
      GET: 'aggregate/deployment/OCCUPANCY:GET',
      RESET: 'aggregate/deployment/OCCUPANCY:RESET',
      CANCEL: 'aggregate/deployment/OCCUPANCY:CANCEL',
      FAILED: 'aggregate/deployment/OCCUPANCY:FAILED',
      SUCCESS: 'aggregate/deployment/OCCUPANCY:SUCCESS'
    }
  },
  zone: {
    visits: {
      GET: 'aggregate/zone/VISITS:GET',
      RESET: 'aggregate/zone/VISITS:RESET',
      CANCEL: 'aggregate/zone/VISITS:CANCEL',
      FAILED: 'aggregate/zone/VISITS:FAILED',
      SUCCESS: 'aggregate/zone/VISITS:SUCCESS'
    },
    entrances: {
      GET: 'aggregate/zone/ENTRANCES:GET',
      RESET: 'aggregate/zone/ENTRANCES:RESET',
      CANCEL: 'aggregate/zone/ENTRANCES:CANCEL',
      FAILED: 'aggregate/zone/ENTRANCES:FAILED',
      SUCCESS: 'aggregate/zone/ENTRANCES:SUCCESS'
    }
  }
};
// Deployment Functions
export default {
  types,
  array: {
    heatmap: {
      get:      payload => ({ type: `${types.array.heatmap.GET}[${payload.array_key}]`,     payload                   }),
      reset:    payload => ({ type: `${types.array.heatmap.RESET}[${payload.array_key}]`                              }),
      cancel:   payload => ({ type: `${types.array.heatmap.CANCEL}[${payload.array_key}]`                             }),
      failed:   payload => ({ type: `${types.array.heatmap.FAILED}[${payload.array_key}]`,  payload: payload.error    }),
      success:  payload => ({ type: `${types.array.heatmap.SUCCESS}[${payload.array_key}]`, payload: payload.response })
    }
  },
  deployment: {
    occupancy: {
      get:      payload => ({ type: `${types.deployment.occupancy.GET}[${payload.occupancy_tracker_uuid}]`,     payload                   }),
      reset:    payload => ({ type: `${types.deployment.occupancy.RESET}[${payload.occupancy_tracker_uuid}]`                              }),
      cancel:   payload => ({ type: `${types.deployment.occupancy.CANCEL}[${payload.occupancy_tracker_uuid}]`                             }),
      failed:   payload => ({ type: `${types.deployment.occupancy.FAILED}[${payload.occupancy_tracker_uuid}]`,  payload: payload.error    }),
      success:  payload => ({ type: `${types.deployment.occupancy.SUCCESS}[${payload.occupancy_tracker_uuid}]`, payload: payload.response })
    }
  },
  zone: {
    visits: {
      get:      payload => ({ type: `${types.zone.visits.GET}[${payload.zone_key}]`,        payload                   }),
      reset:    payload => ({ type: `${types.zone.visits.RESET}[${payload.zone_key}]`                                 }),
      cancel:   payload => ({ type: `${types.zone.visits.CANCEL}[${payload.zone_key}]`                                }),
      failed:   payload => ({ type: `${types.zone.visits.FAILED}[${payload.zone_key}]`,     payload: payload.error    }),
      success:  payload => ({ type: `${types.zone.visits.SUCCESS}[${payload.zone_key}]`,    payload: payload.response })
    },
    entrances: {
      get:      payload => ({ type: `${types.zone.entrances.GET}[${payload.zone_key}]`,     payload                   }),
      reset:    payload => ({ type: `${types.zone.entrances.RESET}[${payload.zone_key}]`                              }),
      cancel:   payload => ({ type: `${types.zone.entrances.CANCEL}[${payload.zone_key}]`                             }),
      failed:   payload => ({ type: `${types.zone.entrances.FAILED}[${payload.zone_key}]`,  payload: payload.error    }),
      success:  payload => ({ type: `${types.zone.entrances.SUCCESS}[${payload.zone_key}]`, payload: payload.response })
    }
  }
};
