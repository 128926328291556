// Action Templates
import get from './templates/get';
import post from './templates/post';
// Action Calls
export const types = {
  login: {
    POST: 'user/LOGIN:POST',
    RESET: 'user/LOGIN:RESET',
    FAILED: 'user/LOGIN:FAILED',
    SUCCESS: 'user/LOGIN:SUCCESS'
  },
  loginSocial: {
    POST: 'user/LOGIN_SOCIAL:POST',
    FAILED: 'user/LOGIN_SOCIAL:FAILED',
  },
  logout: {
    POST: 'user/LOGOUT:POST',
    RESET: 'user/LOGOUT:RESET',
    FAILED: 'user/LOGOUT:FAILED',
    SUCCESS: 'user/LOGOUT:SUCCESS'
  },
  superuser: {
    POST: 'user/SUPERUSER:POST',
    RESET: 'user/SUPERUSER:RESET',
    FAILED: 'user/SUPERUSER:FAILED',
    SUCCESS: 'user/SUPERUSER:SUCCESS'
  },
  edit: {
    POST: 'user/EDIT:POST',
    RESET: 'user/EDIT:RESET',
    CANCEL: 'user/EDIT:CANCEL',
    FAILED: 'user/EDIT:FAILED',
    SUCCESS: 'user/EDIT:SUCCESS'
  },
  changePassword: {
    POST: 'user/PASSWORD_CHANGE:POST',
    RESET: 'user/PASSWORD_CHANGE:RESET',
    CANCEL: 'user/PASSWORD_CHANGE:CANCEL',
    FAILED: 'user/PASSWORD_CHANGE:FAILED',
    SUCCESS: 'user/PASSWORD_CHANGE:SUCCESS'
  },
  role: {
    list: {
      GET: 'user/role/LIST:GET',
      RESET: 'user/role/LIST:RESET',
      FAILED: 'user/role/LIST:FAILED',
      SUCCESS: 'user/role/LIST:SUCCESS'
    },
    create: {
      POST: 'user/role/CREATE:POST',
      RESET: 'user/role/CREATE:RESET',
      FAILED: 'user/role/CREATE:FAILED',
      SUCCESS: 'user/role/CREATE:SUCCESS'
    },
    remove: {
      POST: 'user/role/REMOVE:POST',
      RESET: 'user/role/REMOVE:RESET',
      FAILED: 'user/role/REMOVE:FAILED',
      SUCCESS: 'user/role/REMOVE:SUCCESS'
    }
  }
};

// User Actions
export default {
  // Action Calls
  types,
  // Action Functions
  edit: post(types.edit),
  changePassword: post(types.changePassword),
  role: {
    list:    get(types.role.list),
    create: post(types.role.create),
    remove: post(types.role.remove)
  },
  login: {
    post: (username, password) => ({ type: types.login.POST, payload: { username, password } }),
    failed: payload => ({ type: types.login.FAILED, payload }),
    success: payload => ({ type: types.login.SUCCESS, payload: payload.response })
  },
  loginSocial: {
    post: (access_token) => ({ type: types.loginSocial.POST, payload: { access_token } }),
    failed: payload => ({ type: types.loginSocial.FAILED, payload }),
  },
  logout: {
    post: () => ({ type: types.logout.POST }),
    failed: payload => ({ type: types.logout.FAILED, payload }),
    success: payload => ({ type: types.logout.SUCCESS, payload: payload.response })
  },
  superuser: {
    post: state => ({
      type: types.superuser.POST,
      payload: { state }
    }),
    failed: payload => ({ type: types.superuser.FAILED, payload }),
    success: payload => ({ type: types.superuser.SUCCESS, payload: payload.response })
  }
};
