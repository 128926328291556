// NPM Modules
import qs from 'qs';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { map, mergeMap, catchError, takeUntil } from 'rxjs/operators';

// Metadat Action Handler
import metadata from '../../actions/metadata';

function urlParser(url, payload) {
  if (payload) {
    const parts = url.split('/');
    return parts
      .map(part => {
        if (part.charAt(0) === ':') {
          return `${payload[part.substring(1)]}`;
        }
        if (part.includes('?')) {
          let query = qs.parse(part.substring(part.indexOf('?')), {
            ignoreQueryPrefix: true
          });
          for (let key in query) {
            if (payload[key]) {
              query[key] = payload[key];
            } else {
              delete query[key];
            }
          }
          return part.substring(0, part.indexOf('?') + 1) + qs.stringify(query);
        }
        return `${part}`;
      })
      .join('/');
  } else {
    return url;
  }
}

export default (action$, actionHanlder, actionType, url) =>
  action$.pipe(
    ofType(actionType.GET),
    mergeMap(({ payload = {} }) =>
      ajax({ method: 'GET', url: urlParser(url, payload) }).pipe(
        map(({ response }) => {
          // If the payload has a metadata prop and that prop is set to true
          // Send the data into the metadata reducers
          if (payload.hasOwnProperty('metadata') && payload.metadata) {
            if (response.hasOwnProperty('list')) {
              return metadata.addList(response);
            }
            return metadata.add(response);
          }
          // All others go through here
          return actionHanlder.success(response);
        }),
        catchError(error => of(actionHanlder.failed(error))),
        takeUntil(action$.ofType(actionType.CANCEL))
      )
    ),
    catchError(error => of(actionHanlder.failed(error)))
  );
