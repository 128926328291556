// NPM Modules
import styled from 'styled-components';
import ButtonGroup from '@material-ui/core/ButtonGroup';
// Styled Components
export const Container = styled.div`
  flex-grow: 1;
  height: 90vh;
  position: relative;
  overflow: hidden;
`;
export const ToolGroup = styled(ButtonGroup)`
  height: 49px;
  ${({ theme }) => `border-bottom: 1px solid ${theme.palette.divider};`}
`;
