// Modules
import styled from 'styled-components';

// Styled Components
export const Body = styled.div``;
export const Content = styled.div`
  padding-bottom: 56px;
`;
export const LoggedOutConent = styled.div`
  margin-top: ${({ theme }) => theme.navigation.topBar.barHeight}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-left: ${({ open }) => (open ? 255 : 0)}px;
  }

  transition: ${({ theme, open }) =>
    theme.transitions.create('margin', {
      easing: open
        ? theme.transitions.easing.sharp
        : theme.transitions.easing.easeOut,
      duration: open
        ? theme.transitions.duration.leavingScreen
        : theme.transitions.duration.enteringScreen
    })};
`;
