// NPM Modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// Actions
import user from '../../../redux/actions/user';
import organization from '../../../redux/actions/organization';
import theme from '../../../redux/actions/organization/theme';
// Styled Components
import { Header } from './style';
// Material-UI
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import GroupWorkRoundedIcon from '@material-ui/icons/GroupWorkRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
/**
 * Organization List Component
 */
class OrganizationList extends Component {
  /**
   * state
   */
  state = {
    redirect: false,
    settings: false
  };
  /**
   * handleOrgSelect
   * Sets user organization or redirect user if that org is the same
   */
  handleOrgSelect = (org_key, settings) => {
    if (settings) this.setState({ settings: true });
    if (
      this.props.organization &&
      this.props.organization.org_key === org_key
    ) {
      this.setState({ redirect: true });
      this.props.getOrganizationTheme({ org_key });
    } else {
      this.props.getOrganization({ org_key });
      this.props.getOrganizationTheme({ org_key });
    }
  };
  /**
   * componentDidUpdate
   */
  componentDidUpdate(prevProps) {
    if (prevProps.infoStatus === 'get' && this.props.infoStatus === 'success') {
      this.setState({ redirect: true });
    }
    if (
      this.props.organizations.length === 1 &&
      this.props.infoStatus !== 'get' &&
      this.props.infoStatus !== 'success'
    ) {
      this.props.getOrganization({
        org_key: this.props.organizations[0].org_key
      });
    }
  }
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getOrganizationList();
    this.props.getUserRoleList({
      permission: 'org_write'
    });
  }
  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    if (this.props.listStatus === 'get') {
      this.props.cancelOrganizationList();
    }
    if (this.props.infoStatus === 'get') {
      this.props.cancelOrganization();
    }
  }
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    if (this.state.redirect) {
      const location = this.state.settings ? '/settings' : '/deployment/list';
      return (
        <Redirect
          push={this.props.organizations.length === 1 ? false : true}
          to={`/organization/${this.props.organization.org_key}${location}`}
        />
      );
    }
    const { organizations, userRoleList, userInfo } = this.props;
    const orgsWithRoles = userRoleList.map(role => role.org_key);
    return (
      <Fragment>
        <Header>
          <Typography variant="h6">Organizations</Typography>
        </Header>
        <List>
          {organizations.map(organization => {
            const canEdit = userInfo.toggle_super_user
              ? true
              : orgsWithRoles.includes(organization.org_key);
            return (
              <ListItem
                key={organization.org_key}
                button
                onClick={() => this.handleOrgSelect(organization.org_key)}
              >
                <ListItemIcon>
                  <GroupWorkRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={organization.org_name}
                  secondary={`${organization.org_key} - ${organization.description}`}
                />
                {canEdit ? (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        this.handleOrgSelect(organization.org_key, true)
                      }
                    >
                      <SettingsRoundedIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            );
          })}
        </List>
      </Fragment>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      userInfo: state.user.info.info,
      userRoleList: state.user.role.list.list,
      infoStatus: state.organization.info.status,
      listStatus: state.organization.list.request.status,
      organization: state.organization.info.info,
      organizations: state.organization.list.list
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getUserRoleList: user.role.list.get,
        getOrganizationTheme: theme.info.get,
        getOrganization: organization.info.get,
        cancelOrganization: organization.info.cancel,
        getOrganizationList: organization.list.get,
        cancelOrganizationList: organization.list.cancel
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  OrganizationList.mapStateToProps,
  OrganizationList.mapDispatchToProps
)(OrganizationList);
