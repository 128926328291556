// NPM Modules
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
// Components
import ZoneList from './ZoneList';
import ArrayInfo from './ArrayInfo';
import DataPointSettings from './DataPointSettings';
// DataPointType Enulm
import dataPointSystem from '../../../../redux/actions/dataPointSystem';
// Styled Components
import { Container, AppBar, Dialog, Toolbar, ExpandButton } from './style';
// Material-UI
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import DialogContent from '@material-ui/core/DialogContent';
// Material-UI Icons
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

function ScrollTrigger(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: props.target ? props.target.parentElement : undefined,
    threshold: 0
  });
  return React.cloneElement(props.children, {
    elevation: trigger ? 4 : 0
  });
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Heatmap Info Panel Component
 */
class HeatmapInfoPanel extends Component {
  dialogRef = React.createRef();
  state = {
    halfScreen: false
  };
  toggleHalfScreen = () => {
    this.setState({ halfScreen: !this.state.halfScreen });
  };
  /**
   * Component Render
   * @returns {Component}
   */
  render() {
    return this.props.isMobile ? (
      <Dialog
        keepMounted
        hideBackdrop={this.state.halfScreen}
        disableScrollLock
        open={!!this.props.dataPointId}
        fullScreen
        scroll="body"
        mode={this.state.halfScreen ? 'light' : 'dark'}
        style={{
          top: this.state.halfScreen ? '50%' : '0',
          height: this.state.halfScreen ? '50%' : '100%'
        }}
        onClose={this.props.onClearSelectedHeatmap}
        TransitionComponent={Transition}
      >
        <div ref={this.dialogRef}>
          {this.props.dataPointId ? (
            <React.Fragment>
              <ScrollTrigger target={this.dialogRef.current}>
                <AppBar
                  position="sticky"
                  mode={this.state.halfScreen ? 'light' : 'dark'}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      onClick={this.props.onClearSelectedHeatmap}
                      aria-label="close"
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                    <ExpandButton onClick={this.toggleHalfScreen} disableRipple>
                      {this.state.halfScreen ? (
                        <ExpandLessRoundedIcon />
                      ) : (
                        <ExpandMoreRoundedIcon />
                      )}
                    </ExpandButton>
                  </Toolbar>
                </AppBar>
              </ScrollTrigger>
              <DialogContent>
                <DataPointSettings
                  dataPoint={this.props.dataPoint}
                  onUpdateDataPoint={this.handleUpdateDataPoint}
                />
              </DialogContent>
              <Divider />
              <DialogContent>
                <ArrayInfo array={this.props.array} />
              </DialogContent>
              <Divider />
              <DialogContent>
                <ZoneList zones={this.props.zones} />
              </DialogContent>
            </React.Fragment>
          ) : null}
        </div>
      </Dialog>
    ) : (
      <Container>
        {this.props.dataPointId ? (
          <React.Fragment>
            <DataPointSettings
              dataPoint={this.props.dataPoint}
              onUpdateDataPoint={this.handleUpdateDataPoint}
            />
            <Divider variant="middle" />
            <ArrayInfo array={this.props.array} />
            <Divider variant="middle" />
            <ZoneList zones={this.props.zones} />
          </React.Fragment>
        ) : (
          <Typography>Select a heatmap to view information</Typography>
        )}
      </Container>
    );
  }
  /**
   * Calls DataPointSystem.update
   * @param {object} dataPoint
   */
  handleUpdateDataPoint = dataPoint => {
    this.props.dataPointUpdate({ ...dataPoint });
  };
  /**
   * Component PropTypes
   */
  static propTypes = {
    dataPointId: PropTypes.string
  };
  /**
   * Component Default PropTypes
   */
  static defaultProps = {
    dataPointId: null
  };
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state, props) {
    const both = {
      isMobile: state.device.isMobile
    };
    if (!props.dataPointId) return both;

    // Calculating this outside of the return so we can use it in the heatmap fetch
    const dataPoint = state.dataPointSystem[props.dataPointId];
    return {
      ...both,
      // Metadata => Array Info
      array: state.metadata.array[dataPoint.array_key],
      // Metadata => Zone List
      zones: Object.values(state.metadata.zone).filter(
        zone => zone.array_key === dataPoint.array_key
      ),
      // Data Point System => Data Point
      dataPoint: dataPoint,
      // Aggregate => Heatmap
      heatmap: state.aggregate.heatmaps[dataPoint.array_key],
      // Date Time Global System
      timezone: state.dateTime.timezone,
      endDateTime: state.dateTime.endDateTime,
      startDateTime: state.dateTime.startDateTime
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Data Point System => Data Points
        dataPointUpdate: dataPointSystem.update
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  HeatmapInfoPanel.mapStateToProps,
  HeatmapInfoPanel.mapDispatchToProps
)(HeatmapInfoPanel);
