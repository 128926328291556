// NPM Modules
import qs from 'qs';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { filter, map, mergeMap, catchError, takeUntil } from 'rxjs/operators';
// Actions
import aggregate, { types } from '../actions/aggregate';
// Base URL
const URL = '/api/aggregate';
// Epics
export default {
  //            action$          type(action$, actionHandler,            actionType,           URL)
  array: {
    heatmap:    action$ => getHeatmap(action$, aggregate.array.heatmap,  types.array.heatmap,  `${URL}/array/:array_key/heatmap`)
  },
  zone: {
    visits:     action$ =>        get(action$, aggregate.zone.visits,    types.zone.visits,    `${URL}/zone/:zone_key/visits`),
    entrances:  action$ =>        get(action$, aggregate.zone.entrances, types.zone.entrances, `${URL}/zone/:zone_key/entrances`),
  },
  deployment: {
    occupancy: action$ =>
      action$.pipe(
        filter(
          action =>
            action.type.substring(0, action.type.indexOf('[')) ===
            types.deployment.occupancy.GET
        ),
        mergeMap(action =>
          ajax({
            method: 'GET',
            url: `${URL}/deployment/${action.payload.deployment_key}/occupancy/${action.payload.occupancy_tracker_uuid}/${action.payload.interval}?start=${action.payload.start}&end=${action.payload.end}&timezone=${action.payload.timezone}`
          }).pipe(
            map(response =>
              aggregate.deployment.occupancy.success({
                response: response.response,
                occupancy_tracker_uuid: action.payload.occupancy_tracker_uuid
              })
            ),
            catchError(error =>
              of(
                aggregate.deployment.occupancy.failed({
                  occupancy_tracker_uuid: action.payload.occupancy_tracker_uuid,
                  error: error.response
                })
              )
            ),
            takeUntil(action$.ofType(types.deployment.occupancy.CANCEL))
          )
        ),
        catchError(error =>
          of(
            aggregate.deployment.occupancy.failed({
              occupancy_tracker_uuid: action.payload.occupancy_tracker_uuid,
              error: error.response
            })
          )
        )
      )
  }
};
// Epic Get Template
const get = (action$, actionHanlder, actionType, url) => action$.pipe(
  filter(action => action.type.substring(0, action.type.indexOf('[')) === actionType.GET),
  mergeMap(action => ajax({
    method: 'GET',
    url: `${url}/${action.payload.interval}?start=${action.payload.start}&end=${action.payload.end}&timezone=${action.payload.timezone}${action.payload.hasOwnProperty('threshold')? `&threshold=${action.payload.threshold}` : ''}`.replace(':zone_key', action.payload.zone_key)
  }).pipe(
    map(response => actionHanlder.success({ zone_key: action.payload.zone_key, response: response.response })),
    catchError(error => of(actionHanlder.failed({ zone_key: action.payload.zone_key, error: error.response }))),
    takeUntil(action$.ofType(actionType.CANCEL))
  )),
  catchError(error => of(actionHanlder.failed(error.response)))
);
const getHeatmap = (action$, actionHanlder, actionType, url) => action$.pipe(
  filter(action => action.type.substring(0, action.type.indexOf('[')) === actionType.GET),
  mergeMap(action => ajax({
    method: 'GET',
    url: `${url}?start=${action.payload.start}&end=${action.payload.end}&timezone=${action.payload.timezone}`.replace(':array_key', action.payload.array_key)
  }).pipe(
    map(response => actionHanlder.success({ array_key: action.payload.array_key, response: response.response })),
    catchError(error => of(actionHanlder.failed({ array_key: action.payload.array_key, error: error.response }))),
    takeUntil(action$.ofType(actionType.CANCEL))
  )),
  catchError(error => of(actionHanlder.failed(error.response)))
);