// Actions
import get from './templates/get';
import post from './templates/post';
import org, { types } from '../actions/organization';
// Sub Epics
import theme from './organization/theme';
// API URLS
const API = {
  DASH: '/api/dashboard/organization',
  QUERY: '/api/organization'
};
// Epics
export default {
  //        action$    type(action$, actionHandler,     actionType,          URL)
  info:     action$ =>  get(action$, org.info,          types.info,          `${API.QUERY}/:org_key`),
  list:     action$ =>  get(action$, org.list,          types.list,          `${API.QUERY}/list?search`),
  create:   action$ => post(action$, org.create,        types.create,        `${API.QUERY}/create`),
  edit:     action$ => post(action$, org.edit,          types.edit,          `${API.QUERY}/edit`),
  overview: action$ =>  get(action$, org.overview,      types.overview,      `${API.QUERY}/:org_key/overview?search&zone_type`),
  theme,
  member: {
    info:   action$ =>  get(action$, org.member.info,   types.member.info,   `${API.QUERY}/:org_key/member?member`),
    list:   action$ =>  get(action$, org.member.list,   types.member.list,   `${API.QUERY}/:org_key/member/list?page&page_size&search`),
    add:    action$ => post(action$, org.member.add,    types.member.add,    `${API.QUERY}/member/add`),
    edit:   action$ => post(action$, org.member.edit,   types.member.edit,   `${API.QUERY}/member/edit`),
    remove: action$ => post(action$, org.member.remove, types.member.remove, `${API.QUERY}/member/remove`),
  }
};
