// NPM Modules
import React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
// Snackbar extension
import { useSnackbar } from 'notistack';
// Redux
import userAction from '../../../../redux/actions/user';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
/**
 * Form for updating User Info
 * @returns {React.Component}
 */
function UserInfoForm() {
  // List of timezones provided by moment
  const timezoneList = moment.tz.names();

  // Setup notistack
  const { enqueueSnackbar } = useSnackbar();

  // Redux State and Action Setup
  const dispatch = useDispatch();
  const user = {
    info: useSelector(state => state.user.info.info),
    edit: useSelector(state => state.user.edit)
  };

  // Component State
  const [state, setState] = React.useState(user.info);
  const [timezoneInput, setTimezoneInput] = React.useState(user.info.timezone);

  // Update Component State
  const updateState = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value
    });
  };

  // Dispatch User Info Edit
  const dispatchUserEdit = event => {
    event.preventDefault();
    dispatch(userAction.edit.post(state));
  };

  // Inform user on post request changes
  React.useEffect(() => {
    switch (user.edit.status) {
      case 'success':
        enqueueSnackbar('Info successfully update.', { variant: 'success' });
        break;
      case 'failed':
        enqueueSnackbar('Something went wrong. Check inputs.', {
          variant: 'error'
        });
        break;
      default:
        break;
    }
  }, [user.edit.status]);

  return (
    <form onSubmit={dispatchUserEdit}>
      <Typography variant="h5">User Info</Typography>
      <FormControl fullWidth margin="dense">
        <TextField
          margin="dense"
          disabled
          onChange={updateState}
          name="email"
          label="Email"
          value={state.email}
        />
        <TextField
          margin="dense"
          onChange={updateState}
          name="first_name"
          label="First Name"
          value={state.first_name}
        />
        <TextField
          margin="dense"
          onChange={updateState}
          name="last_name"
          label="Last Name"
          value={state.last_name}
        />
        <Autocomplete
          value={state.timezone}
          inputValue={timezoneInput}
          options={timezoneList}
          getOptionLabel={option => option}
          onChange={(_, value) => {
            updateState({ target: { name: 'timezone', value } });
          }}
          onInputChange={(_, value) => {
            setTimezoneInput(value);
          }}
          renderInput={params => (
            <TextField
              margin="dense"
              {...params}
              label="Timezone"
              name="timezone"
            />
          )}
          groupBy={option => option.split('/')[0]}
        />
        <input type="submit" style={{ display: 'none' }} />
        <Box mt={2} mb={1}>
          <Button onClick={dispatchUserEdit}>Update Info</Button>
        </Box>
      </FormControl>
    </form>
  );
}

export default UserInfoForm;
