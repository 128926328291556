// Action Calls
export const types = {
  SET_SCHEME: 'ui/scheme/SET'
};

// User Actions
export default {
  // Action Calls
  types,
  // Action Functions
  setScheme: payload => ({ type: types.SET_SCHEME, payload })
};
