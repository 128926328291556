import * as Tools from '../Tools';
import keyboardShortcuts from './keyboardShortcuts';

/**
 * @typedef {Object} Cake~Options
 * @property {number} [width=500] - canvas width
 * @property {number} [height=500] - canvas height
 * @property {boolean} [responsive=true] - if true canvas will resize to parents size
 */

/**
 * Cakes have layers too.
 */
class Cake {
  /**
   * @param {HTMLCanvasElement} canvas
   * @param {Cake~Options} [options] - optional settings
   */
  constructor(canvas, options = {}) {
    // Holds all layers that apart of a cake
    this.layers = new Set();
    // The "real" canvas to which all layers will be rendered too
    this.canvas = canvas;
    // Canvas width aka viewport size (not resolution)
    this.width = options.hasOwnProperty('width') ? options.width : 500;
    // Canvas height aka viewport size (not resolution)
    this.height = options.hasOwnProperty('height') ? options.height : 500;
    // If true canvas will resize to parent size
    this.responsive = options.hasOwnProperty('responsive')
      ? options.responsive
      : true;
    // Keeps track of mouse settings
    this._mouse = { down: false, x: 0, y: 0 };
    // set up viewport with the default canvas transform
    const ctx = this.canvas.getContext('2d');
    this._viewport = ctx.getTransform();
    // Padding to screen
    this._viewport.e += options.hasOwnProperty('viewportXOffset')
      ? options.viewportXOffset
      : 24;
    this._viewport.f += options.hasOwnProperty('viewportYOffset')
      ? options.viewportYOffset
      : 24;
    // Set the canvas to be the set width and height
    this._setupCanvas();
    // Setup all the event listeners
    this._setupEventListeners(options.events);
    // Tool System
    this.tools = {
      pan: new Tools.Pan(this, false),
      zoom: new Tools.Zoom(this, false, {
        eventListeners: {
          ...(options.hasOwnProperty('height')
            ? { onZoom: options.onZoom }
            : {})
        }
      }),
      mouse: new Tools.Mouse(this, true)
    };
    this.toggleTool = false; // true if the current tool is only active while a condition is true (Example: keydown tool toggles)
    this.previousTool = 'mouse';
  }
  /**
   * Sets the canvas size to the cake's size.
   * @private
   */
  _setupCanvas() {
    this.canvas.width = this.width;
    this.canvas.height = this.height;
  }
  /**
   * Sets up all the cake's event listeners
   * @private
   */
  _setupEventListeners(eventBinders = {}) {
    // Listeners are bound to either canvas if rest of screen does not matter.
    window.addEventListener('keyup', this.handleKeyUp.bind(this));
    window.addEventListener('keydown', this.handleKeyDown.bind(this));

    if (this.responsive) {
      this.resizeObserver = new ResizeObserver(this.handleResize.bind(this));
      this.resizeObserver.observe(this.canvas.parentElement);
    }

    // Custom event listerns that users can bind in the event option
    Object.entries(eventBinders).forEach(([eventName, binder]) => {
      // if mouse event include transform
      if (eventName.includes('mouse')) {
        this.canvas.addEventListener(
          eventName,
          (event => {
            return binder(event, {
              position: {
                x: this._viewport.e,
                y: this._viewport.f
              },
              scale: {
                x: this._viewport.a,
                y: this._viewport.d
              },
              tool: this.getActiveTool()
            });
          }).bind(this)
        );
      } else {
        this.canvas.addEventListener(
          eventName,
          (event => binder(event)).bind(this)
        );
      }
    });
  }
  keyEventFired = false;
  handleKeyUp() {
    this.keyEventFired = false;
    if (this.toggleTool) {
      this.toggleTool = false;
      this.setTool(this.previousTool);
    }
  }
  handleKeyDown(event) {
    if (event.target instanceof HTMLBodyElement) {
      // Single Fire
      if (!this.keyEventFired) {
        this.keyEventFired = true;
        switch (event.keyCode) {
          case keyboardShortcuts.toggle.panTool:
            this.toggleTool = true;
            this.setTool('pan');
            break;
          case keyboardShortcuts.toggle.zoomTool:
            this.toggleTool = true;
            this.setTool('zoom');
            break;
          default:
            break;
        }
      }

      // Prevent default keydown events on specified keys
      switch (event.keyCode) {
        case keyboardShortcuts.toggle.panTool:
        case keyboardShortcuts.toggle.zoomTool:
          event.preventDefault();
          break;
        default:
          break;
      }
    }
  }
  handleResize([entry]) {
    this.width = entry.contentRect.width;
    this.height = entry.contentRect.height;
    this._setupCanvas();
    this.update();
  }
  /**
   * Sets the canvas cursor based off the tool type
   * @private
   */
  _setCursor() {
    switch (this.getActiveTool()) {
      case 'mouse':
        this.canvas.style.cursor = 'default';
        break;
      case 'pan':
        this.canvas.style.cursor = 'grab';
        break;
      case 'zoom':
        this.canvas.style.cursor = 'zoom-in';
        break;
      default:
        break;
    }
  }
  getActiveTool() {
    for (const [key, value] of Object.entries(this.tools)) {
      if (value.isActive()) {
        return key;
      }
    }
    return null;
  }
  /**
   * Sets cake's current tool.
   * If provided tool does not exits tool does not change.
   * @param {('mouse'|'pan'|'zoom')} tool
   */
  setTool(tool) {
    if (this.tools.hasOwnProperty(tool)) {
      // Set previous tool to current
      this.previousTool = this.getActiveTool();
      this.tools[this.previousTool].setActive(false);
      // Set new tool as current
      this.tools[tool].setActive(true);
      // Update mouse cursor on canvas
      this._setCursor();
    }
  }
  /**
   * Renders all the cake's layers to the "real" canvas
   */
  update() {
    const ctx = this.canvas.getContext('2d');
    ctx.save();
    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.setTransform(this._viewport);
    this.layers.forEach(layer => {
      if (layer.isVisible()) {
        ctx.drawImage(layer.getCanvas(), layer.xOffset, layer.yOffset);
      }
    });
    ctx.restore();
  }
  /**
   * Add a layer to the cake
   * @param {Layer} layer
   */
  addLayer(layer) {
    this.layers.add(layer);
    this.update();
  }
  /**
   * Returns all the layers in the cake
   * @returns {Layers[]}
   */
  getLayers() {
    return this.layers;
  }
  /**
   * Get's the first layer by given name.
   * If two layers have the same name it returns the lower one in the layers.
   * @param {string} name - name of layer
   * @returns {Layer}
   */
  getLayer(name) {
    const set = this.layers.values();
    let next = set.next().value;

    while (next !== null && next !== undefined) {
      if (next.name === name) {
        return next;
      }
      next = set.next().value;
    }

    return null;
  }
}

export default Cake;
