// NPM Modules
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI
import TextField from '@material-ui/core/TextField';
// Material-UI List Components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
/**
 * List Text Field used for ZoneItem
 * @param {*} props
 * @returns
 */
const ZoneField = props => (
  <ListItem dense>
    <ListItemText
      inset
      primary={
        <TextField {...props} size="small" fullWidth variant="outlined" />
      }
    />
  </ListItem>
);
ZoneField.defaultProps = {
  name: '',
  label: '',
  value: '',
  disabled: true,
  onChange: () => {}
};
ZoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default ZoneField;
