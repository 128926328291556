// NPM Modules
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Styled Components
import { Padding } from './style';
/**
 * Page Component
 */
const Page = props => {
  if (props.padding) {
    return <Padding {...props}>{props.children}</Padding>;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};
/**
 * Page Prop Types
 */
Page.propTypes = {
  padding: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  spacing: PropTypes.number,
  leftSpacing: PropTypes.number,
  rightSpacing: PropTypes.number,
  topSpacing: PropTypes.number,
  bottomSpacing: PropTypes.number
};
/**
 * Page Default Prop Types
 */
Page.defaultProps = {
  padding: true,
  left: true,
  right: true,
  top: true,
  bottom: true,
  spacing: 6,
  leftSpacing: null,
  rightSpacing: null,
  topSpacing: null,
  bottomSpacing: null
};
/**
 * Map Redux State to component.props
 * @param {Object} state
 * @returns
 */
Page.mapStateToProps = (state, ownProps) => {
  // Adjust spacing for mobile/desktop
  return {
    spacing: ownProps.spacing ? ownProps.spacing : state.device.isMobile ? 4 : 6
  };
};
/**
 * Export Component
 */
export default connect(Page.mapStateToProps)(Page);
