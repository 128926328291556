// NPM Modules
import qs from 'qs';
import equal from 'deep-equal';
import lzutf8 from 'lzutf8';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
// Actions
import dataPointSystem, {
  DataPointType,
  DataPointStructs
} from '../../../../redux/actions/dataPointSystem';
import metadata from '../../../../redux/actions/metadata';
// Components
import Arrays from './Arrays';
import Zones from './Zones';
// Styled Components
import { CustomChip, AddDataPointChip, Section } from './style';
// Materail-UI
import Divider from '@material-ui/core/Divider';
// Material-UI Icons
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

/**
 * DataPicker Component
 */
class DataPicker extends Component {
  /**
   * Component State
   */
  state = {
    dialogOpen: false
  };
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.updateURL();
  }
  /**
   * componentDidUpdate
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.dataPoints.length !== this.props.dataPoints.length ||
      !equal(this.props.dataPoints, prevProps.dataPoints)
    ) {
      this.updateURL();
    }
  }
  /**
   * Set state.dialogOpen to true
   */
  toggleDialog = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };
  /**
   *
   */
  updateURL() {
    const { dataPoints, history } = this.props;
    const search = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    });

    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify(
        {
          ...search,
          datapoints: lzutf8.compress(JSON.stringify(dataPoints), {
            outputEncoding: 'Base64'
          })
        },
        { encodeValuesOnly: true }
      )
    });
  }
  /**
   * Removes dataPoint from dataPoint list and it's category id list
   * @param {string} id
   */
  handleDataPointRemove = id => {
    this.props.dataPointsRemove({
      id: id,
      category: this.props.category
    });
  };
  /**
   * Create a new data point and add it to the props category
   * @param {*} dataPoint
   */
  handleDataPointAdd = ({ metadata, dataPoint }) => {
    this.props.metadataAdd({
      ...metadata
    });
    this.props.dataPointsAdd({
      ...DataPointStructs[this.props.DataPointType],
      type: this.props.DataPointType,
      parent: this.props.category,
      ...dataPoint
    });
  };
  isDataPointTypeArray() {
    switch (this.props.DataPointType) {
      case DataPointType.ArrayEntrances:
      case DataPointType.ArrayHeatmap:
      case DataPointType.ArrayVisits:
        return true;
      default:
        return false;
    }
  }
  /**
   * render
   */
  render() {
    const { dataPoints, isMobile, DataPointType } = this.props;
    const ArraysOrZones = this.isDataPointTypeArray() ? Arrays : Zones;
    return (
      <React.Fragment>
        <ArraysOrZones
          open={this.state.dialogOpen}
          onAdd={this.handleDataPointAdd}
          onRemove={this.handleDataPointRemove}
          onClose={this.toggleDialog}
          dataPoints={dataPoints}
          DataPointType={DataPointType}
        />
        <Divider />
        <Section isMobile={isMobile}>
          {dataPoints.map(dataPoint => (
            <CustomChip
              key={dataPoint.id}
              chipcolor={`rgb(${dataPoint.color})`}
              label={dataPoint.label}
              size="small"
              onDelete={() => this.handleDataPointRemove(dataPoint.id)}
            />
          ))}
          <AddDataPointChip
            label={dataPoints.length > 0 ? '' : 'Add Data Point'}
            size="small"
            color="primary"
            onClick={this.toggleDialog}
            onDelete={this.toggleDialog}
            deleteIcon={<AddCircleRoundedIcon />}
          />
        </Section>
        <Divider />
      </React.Fragment>
    );
  }
  /**
   * propTypes
   */
  static propTypes = {
    category: PropTypes.string.isRequired,
    DataPointType: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
  };
  /**
   * propTypes.defaultProps
   */
  static defaultProps = {
    category: 'uncategorized'
  };
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state, props) {
    return {
      isMobile: state.device.isMobile,
      // Data Points
      dataPoints: Object.values(state.dataPointSystem).filter(
        dataPoint => dataPoint.parent === props.category
      )
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Data Points
        dataPointsAdd: dataPointSystem.add,
        dataPointsRemove: dataPointSystem.remove,
        dataPointsUpdate: dataPointSystem.update,
        // Metadata
        metadataAdd: metadata.add
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  DataPicker.mapStateToProps,
  DataPicker.mapDispatchToProps
)(DataPicker);
