// NPM Modules
import { connect } from 'react-redux';
import React, { Component } from 'react';
// Components
import Heatmap from './Heatmap';
import DataPicker from '../components/DataPicker';
import DateRangePicker from '../../../components/DateRangePicker';
import Page from '../../../components/Page';
// DataPointType Enulm
import { DataPointType } from '../../../redux/actions/dataPointSystem';
// Components
import HeatmapInfoPanel from './HeatmapInfoPanel';
// Styled Components
import { Container, HeatmapsContainer, Left, Right } from './style';
/**
 * Data Exploration Heatmaps Page
 */
class Heatmaps extends Component {
  /**
   * Component State
   */
  state = {
    selectedHeatmap: null
  };
  /**
   * Handle Heatmap Select
   * @param {string} id - dataPoint Id
   */
  handleHeatMapSelect = id => {
    if (this.state.selectedHeatmap !== id) {
      this.setState({ selectedHeatmap: id });
    }
  };
  /**
   * Renders the list of datapoint heatmaps
   * @returns {Component}
   */
  renderHeatmaps() {
    if (this.props.ids === undefined) {
      return <h3>No heatmaps.</h3>;
    }

    return this.props.ids.map(id => (
      <Heatmap
        key={id}
        dataPointId={id}
        onSelect={this.handleHeatMapSelect}
        selected={id === this.state.selectedHeatmap}
      />
    ));
  }
  /**
   * Component Render
   * @returns {Component}
   */
  render() {
    return (
      <Page padding={false}>
        <Container isMobile={this.props.isMobile}>
          <Left>
            <DateRangePicker hasInterval={false} />
            <DataPicker
              history={this.props.history}
              DataPointType={DataPointType.ArrayHeatmap}
              category="heatmaps"
            />
            <Page>
              <HeatmapsContainer>{this.renderHeatmaps()}</HeatmapsContainer>
            </Page>
          </Left>
          <Right>
            <HeatmapInfoPanel
              dataPointId={this.state.selectedHeatmap}
              onClearSelectedHeatmap={() => this.handleHeatMapSelect(null)}
            />
          </Right>
        </Container>
      </Page>
    );
  }
  /**
   * NOTE: This should probably not be used however, can't think of another
   * way right now soooo... If someone wants to figure out another way to deal
   * with this.... Go for it!
   *
   * Forcing the state to be selectedHeatmap to be set to null when the
   * ids change. Can't do this ComponentDidUpdate because the render will
   * break the HeatmapInfoPanel component
   *
   * Could do a check in the render but that would cause a double render
   * @param {object} props
   * @param {object} state
   * @returns
   */
  static getDerivedStateFromProps(props, state) {
    if (!props.ids.find(id => id === state.selectedHeatmap)) {
      return { selectedHeatmap: null };
    }

    return null;
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      isMobile: state.device.isMobile,
      ids: Object.values(state.dataPointSystem)
        .filter(dataPoint => dataPoint.parent === 'heatmaps')
        .map(dataPoint => dataPoint.id)
    };
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(Heatmaps.mapStateToProps)(Heatmaps);
