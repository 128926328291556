// NPM Modules
import qs from 'qs';
import lzutf8 from 'lzutf8';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import aggregate from '../../../redux/actions/aggregate';
import deployment from '../../../redux/actions/deployment';
// Components
import Page from '../../../components/Page';
import LineBarChart from '../../../components/visualizers/charts/LineBarChart';
import DataPointPicker from './components/DataPointPicker';
import DateRangePicker from '../../../components/DateRangePicker';
// Material-UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// Material-UI Table
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
// Helper Functions
import intervalSplit from '../../../helpers/intervalSplit';
// Visualizers
import BigData from '../../../components/visualizers/BigData';
/**
 * Data Exploration Occupancy Page
 */
class Occupancy extends Component {
  state = {
    dataPoints: {}
  };
  updateURL() {
    const search = qs.parse(this.props.history.location.search, {
      ignoreQueryPrefix: true
    });
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: qs.stringify({
        ...search,
        datapoints: encodeURIComponent(
          lzutf8.compress(JSON.stringify(this.state.dataPoints), {
            outputEncoding: 'Base64'
          })
        )
      })
    });
  }
  componentDidMount() {
    this.props.getDeploymentTrackerList({ page: 1 });
    const search = qs.parse(this.props.history.location.search, {
      ignoreQueryPrefix: true
    });
    if (search.datapoints) {
      try {
        const decompressed = lzutf8.decompress(search.datapoints, {
          inputEncoding: 'Base64',
          outputEncoding: 'String'
        });
        const dataPoints = JSON.parse(decompressed);
        this.setState({ dataPoints });
      } catch (error) {}
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.timezone !== this.props.timezone ||
      prevProps.interval !== this.props.interval ||
      prevProps.startDateTime !== this.props.startDateTime ||
      prevProps.endDateTime !== this.props.endDateTime
    ) {
      // If date time changed update data.
      Object.values(this.state.dataPoints).forEach(point => {
        this.getDeploymentOccupancy(point);
      });
    }

    if (
      Object.keys(prevState.dataPoints).length !==
      Object.keys(this.state.dataPoints).length
    ) {
      this.updateURL();
      Object.values(this.state.dataPoints).forEach(point => {
        if (!this.props.occupancy[point.uuid]) {
          this.getDeploymentOccupancy(point);
        }
      });
    }

    Object.values(this.state.dataPoints).forEach(point => {
      const aggregate = this.props.occupancy[point.uuid];
      if (
        aggregate &&
        aggregate.error &&
        aggregate.error.data.error === 'Over 1000 time bins' &&
        prevProps.occupancy[point.uuid].fetching === true &&
        aggregate.fetching === false
      ) {
        this.props.enqueueSnackbar(
          `${aggregate.error.data.error}. Please reduce time window or interval.`,
          {
            variant: 'error',
            preventDuplicate: true
          }
        );
      }
    });
  }
  getDeploymentOccupancy(point) {
    this.props.getDeploymentOccupancy({
      ...point,
      occupancy_tracker_uuid: point.uuid,
      start: this.props.startDateTime,
      end: this.props.endDateTime,
      interval: this.props.interval,
      timezone: this.props.timezone
    });
  }
  handledSearch = search => {
    this.props.getDeploymentTrackerList({ page: 1, search });
  };
  handlePointSelected = point => {
    let dataPoints = { ...this.state.dataPoints };
    dataPoints[point.uuid] = { ...point, checked: true };
    this.setState({ dataPoints });
  };
  handlePointRemoved = point => {
    let dataPoints = { ...this.state.dataPoints };
    delete dataPoints[point.uuid];
    this.setState({ dataPoints });
  };
  render() {
    const isLoading = this.props.listStatus === 'get';

    const [number, type] = intervalSplit(this.props.interval);
    const unitLabel = ` per ${number === 1 ? '' : number} ${type}`;

    const datasets = Object.values(this.state.dataPoints).map(dataPoint => {
      const data = this.props.occupancy[dataPoint.uuid] || { results: [] };
      return {
        id: dataPoint.uuid,
        label: dataPoint.occupancy_tracker_name,
        backgroundColor: `rgba(${dataPoint.color.join(',')}, 0.5)`,
        borderColor: `rgba(${dataPoint.color.join(',')}, 1)`,
        radius: 0,
        borderWidth: 2,
        unit: 'day',
        data: data.results.map(node => ({
          x: moment.tz(node.date, this.props.timezone),
          y: node.occupancy
        }))
      };
    });

    return (
      <Page padding={false}>
        <DateRangePicker history={this.props.history} url={false} />
        <DataPointPicker
          points={this.props.list}
          selectedPoints={Object.values(this.state.dataPoints)}
          onPointSelected={this.handlePointSelected}
          onPointRemoved={this.handlePointRemoved}
          onSearch={this.handledSearch}
          isLoading={isLoading}
        />
        <Page>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} lg={4}>
              <BigData
                datasets={datasets}
                aggregation="max"
                title="Highest Occupancy Reached"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <BigData
                datasets={datasets}
                aggregation="min"
                title="Lowest Occupancy Reached"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BigData
                datasets={datasets}
                aggregation="average"
                title="Average Occupancy"
                unitLabel={unitLabel}
                numberFormat="0,0.[00]"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5">Occupancy Breakdown</Typography>
                  <LineBarChart chartType="line" datasets={datasets} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5">
                    Occupancy Dataset Breakdown
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name (Id)</TableCell>
                          <TableCell>Highest</TableCell>
                          <TableCell>Lowest</TableCell>
                          <TableCell>Average</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {datasets.map(set => {
                          const data = set.data.map(d => d.y);
                          const max = Math.max(...data);
                          const min = Math.min(...data);
                          const average =
                            data.length > 1
                              ? data.reduce((a, b) => a + b) / data.length
                              : 0;
                          return (
                            <TableRow key={set.id}>
                              <TableCell>{set.label || set.id}</TableCell>
                              <TableCell>{max}</TableCell>
                              <TableCell>{min}</TableCell>
                              <TableCell>{average}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Page>
      </Page>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      occupancy: state.aggregate.occupancy,
      list: state.deployment.occupancy.list.list,
      listStatus: state.deployment.occupancy.list.status,
      timezone: state.dateTime.timezone,
      interval: state.dateTime.interval,
      endDateTime: state.dateTime.endDateTime,
      startDateTime: state.dateTime.startDateTime
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getDeploymentOccupancy: aggregate.deployment.occupancy.get,
        getDeploymentTrackerList: deployment.occupancy.list.get
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  Occupancy.mapStateToProps,
  Occupancy.mapDispatchToProps
)(withSnackbar(Occupancy));
