// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../actions/roles';
// Reducer Template
import list from './templates/list';
// Reducer
export default combineReducers({
  available:    (s, a) => list(s, a, types.available),
  permissions: combineReducers({
    available:  (s, a) => list(s, a, types.permissions.available)
  })
});
