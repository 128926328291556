// NPM Modules
import React, { useState } from 'react';
// Components
import UserMenu from '../../../components/UserMenu';
// Styled Components
import { UserIconButton, UserIconAvatar } from './style';
// Material-UI
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
/**
 * getShortHandName
 * @param {*} user
 */
function getShortHandName(user) {
  if (user.first_name && user.last_name) {
    return user.first_name.charAt(0) + user.last_name.charAt(0);
  } else if (user.first_name) {
    return user.first_name.charAt(0);
  } else {
    return user.email.charAt(0);
  }
}
/**
 * UserPopMenu
 * @param {*} param0
 */
function UserPopMenu({ user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'menu-popper' : undefined;
  const hanldeAnchorSet = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <UserIconButton aria-describedby={id} onClick={hanldeAnchorSet}>
          <UserIconAvatar>{getShortHandName(user)}</UserIconAvatar>
        </UserIconButton>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          style={{ zIndex: 1200 }}
        >
          <Paper elevation={5} style={{ width: '320px' }}>
            <UserMenu onItemSelected={handleClickAway} />
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
export default UserPopMenu;
