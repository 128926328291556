// Actions
import aggregate from '../actions/aggregate';
// Organization Actions
import organization from '../actions/organization';
// User Actions
import user from '../actions/user';
// Default State of Reducer
const defaultState = {
  visits: {},
  heatmaps: {},
  entrances: {},
  occupancy: {}
};

const defaultResults = {
  startUTC: null,
  endUTC: null,
  results: [],
  requestedTimezone: null,
  interval: null,
  fetched: null,
  fetching: false,
  error: null
};

/**
 * Aggregate Reducer
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case user.types.LOGOUT_SUCCESS:
    case organization.types.info.GET: {
      return { ...defaultState };
    }
    default: {
      break;
    }
  }

  if (!action.type.includes('aggregate')) return state;

  const actionType = action.type.substring(0, action.type.indexOf('['));
  const key = action.type.substring(
    actionType.length + 1,
    action.type.length - 1
  );

  switch (actionType) {
    case aggregate.types.zone.entrances.GET: {
      let entrances = { ...state.entrances };
      let entrance = { ...entrances[key], ...defaultResults, fetching: true };
      entrances[key] = entrance;
      return { ...state, entrances };
    }
    case aggregate.types.zone.entrances.SUCCESS: {
      let entrances = { ...state.entrances };
      entrances[key] = { ...action.payload, fetching: false };
      return { ...state, entrances };
    }
    case aggregate.types.zone.entrances.FAILED: {
      let entrances = { ...state.entrances };
      entrances[key] = {
        ...defaultResults,
        fetching: false,
        error: action.payload
      };
      return { ...state, entrances };
    }
    case aggregate.types.zone.visits.GET: {
      let visits = { ...state.visits };
      let visit = { ...visits[key], ...defaultResults, fetching: true };
      visits[key] = visit;
      return { ...state, visits };
    }
    case aggregate.types.zone.visits.SUCCESS: {
      let visits = { ...state.visits };
      visits[key] = { ...action.payload, fetching: false };
      return { ...state, visits };
    }
    case aggregate.types.zone.visits.FAILED: {
      let visits = { ...state.visits };
      visits[key] = {
        ...defaultResults,
        fetching: false,
        error: action.payload
      };
      return { ...state, visits };
    }
    case aggregate.types.array.heatmap.GET: {
      let heatmaps = { ...state.heatmaps };
      let heatmap = { ...heatmaps[key], ...defaultResults, fetching: true };
      heatmaps[key] = heatmap;
      return { ...state, heatmaps };
    }
    case aggregate.types.array.heatmap.SUCCESS: {
      let heatmaps = { ...state.heatmaps };
      heatmaps[key] = { ...action.payload, fetching: false };
      return { ...state, heatmaps };
    }
    case aggregate.types.array.heatmap.FAILED: {
      let heatmaps = { ...state.heatmaps };
      heatmaps[key] = {
        ...defaultResults,
        fetching: false,
        error: action.payload
      };
      return { ...state, heatmaps };
    }
    case aggregate.types.deployment.occupancy.GET: {
      let occupancy = { ...state.occupancy };
      occupancy[key] = { ...occupancy[key], ...defaultResults, fetching: true };
      return { ...state, occupancy };
    }
    case aggregate.types.deployment.occupancy.SUCCESS: {
      let occupancy = { ...state.occupancy };
      occupancy[key] = { ...action.payload, fetching: false };
      return { ...state, occupancy };
    }
    case aggregate.types.deployment.occupancy.FAILED: {
      let occupancy = { ...state.occupancy };
      occupancy[key] = {
        ...defaultResults,
        fetching: false,
        error: action.payload
      };
      return { ...state, occupancy };
    }
    default: {
      return state;
    }
  }
};
