// Action Call Types
export const types = {
  ADD: 'METADATA:ADD',
  ADD_LIST: 'METADATA:ADD_LIST'
};

// Callable Action Functions
export default {
  types,
  add: payload => ({ type: types.ADD, payload: { ...payload } }),
  addList: payload => ({ type: types.ADD_LIST, payload: { ...payload } })
};
