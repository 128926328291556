// NPM Modules
import 'chartjs-adapter-moment';
import Chart from 'chart.js/auto';
import equal from 'deep-equal';
import merge from 'deepmerge';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import React, { Component, createRef } from 'react';

class LineBarChart extends Component {
  /**
   * Component PropTypes
   */
  static propTypes = {
    theme: PropTypes.object,
    chartType: PropTypes.oneOf(['line', 'bar']),
    datasets: PropTypes.array,
    options: PropTypes.object
  };
  /**
   * Component Default PropTypes
   */
  static defaultProps = {
    theme: {},
    chartType: 'line',
    datasets: [],
    options: {}
  };
  // Ref to canvas element
  canvasElement = createRef();
  chart = null;
  /**
   * Function to setup base chart options
   * @returns {object}
   */
  chartOptions() {
    const { theme, chartType, options } = this.props;

    Chart.defaults.color = theme.palette.text.primary;
    Chart.defaults.font.family = theme.typography.fontFamily.toString();
    Chart.defaults.scale.grid.color = theme.palette.divider;
    Chart.defaults.scale.grid.borderColor = theme.palette.divider;

    return {
      type: chartType,
      data: {
        datasets: []
      },
      options: merge(
        {
          onResize: chart => {
            const ratio =
              Math.round((window.outerWidth / window.outerHeight) * 4) / 4 +
              0.25;

            if (chart.aspectRatio !== ratio) {
              chart.options.aspectRatio = ratio;
              chart.resize();
            }
          },
          aspectRatio: 1.5,
          responsive: true,
          spanGaps: true,
          // parsing: false,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          scales: {
            x: {
              type: 'time',
              display: true,
              ticks: {
                source: 'data',
                major: {
                  enabled: true
                },
                color: function (context) {
                  return context.tick && context.tick.major
                    ? theme.palette.text.primary
                    : '#B4BAC4';
                }
              }
            },
            y: {
              type: 'linear',
              display: true
            }
          }
        },
        options
      )
    };
  }
  /**
   *
   * @param {*} nextProps
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps) {
    if (!equal(this.props.chartType, nextProps.chartType)) {
      return true;
    }
    if (!equal(this.props.datasets, nextProps.datasets)) {
      return true;
    }
    if (this.props.theme.palette.divider !== nextProps.theme.palette.divider) {
      return true;
    }

    return false;
  }
  /**
   * Setup Chart.js
   */
  componentDidMount() {
    this.chart = new Chart(this.canvasElement.current, this.chartOptions());

    if (process.env.NODE_ENV !== 'production' && window)
      window.chart = this.chart;
  }
  /**
   * Update chart datasets and options
   */
  componentDidUpdate() {
    // Remove any datasets that are not in the props.dataset list
    let datasets = this.chart.data.datasets.filter(chartSet =>
      this.props.datasets.find(propSet => propSet.id === chartSet.id)
    );

    // Update or add datasets to the chart datasets
    for (const set of this.props.datasets) {
      let index = datasets.findIndex(s => s.id === set.id);
      if (index !== -1) {
        // Only update if they are not equal
        if (!equal(datasets[index].data, set.data)) {
          datasets[index].data = set.data;
        }
      } else {
        datasets.push(set);
      }
    }

    this.chart.data.datasets = datasets;

    // TODO: Update chart styling when user changes theme
    // NOTE: This component only updates on specific things. Look at shouldComponentUpdate above
    // const { theme } = this.props;
    // this.chart._options.color = theme.palette.text.primary;
    // this.chart._options.font.family = theme.typography.fontFamily.toString();
    // this.chart._options.scale.grid.color = theme.palette.divider;
    // this.chart._options.scale.grid.borderColor = theme.palette.divider;

    this.chart.update();
  }
  /**
   * Render chart view
   */
  render() {
    return <canvas ref={this.canvasElement} />;
  }
}

export default withTheme(LineBarChart);
