// NPM Modules
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
// Material-UI
import Tooltip from '@material-ui/core/Tooltip';
// Styled Components
export const HeaderTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.background.paper
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.caption.fontSize
  }
}))(Tooltip);
export const Container = styled.div`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  width: ${({ theme, width }) => width + theme.spacing(2)}px;
`;
export const Header = styled.div`
  border: ${({ theme }) => theme.spacing(1)}px solid
    ${({ color, selected }) => (selected ? color : 'rgba(0,0,0,.25)')};
  border-bottom: 0;
  box-sizing: content-box;

  cursor: pointer;
`;
export const HeaderContent = styled.div`
  background: ${({ color }) => color};
  color: ${({ theme, color }) => theme.palette.getContrastText(color)};
  padding: ${({ theme }) => theme.spacing(2)}px;

  display: flex;
  align-items: center;

  overflow: hidden;

  > span {
    flex: 1 1 0;
  }

  > button {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    flex: 0 0 0;
  }
`;
export const Body = styled.div`
  border: ${({ theme }) => theme.spacing(1)}px solid
    ${({ color, selected }) => (selected ? color : 'rgba(0,0,0,.25)')};
  border-top: 0;
  box-sizing: content-box;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Canvas = styled.canvas``;
