// Modules
import styled from 'styled-components';
// Material-UI
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
// Styled Components
export const Body = styled.div`
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.navigation.topBar.barHeight}px)`};
`;
export const Content = styled.div`
  margin-top: ${({ theme }) => theme.navigation.topBar.barHeight}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-left: ${({ open }) => (open ? 255 : 0)}px;
  }

  transition: ${({ theme, open }) =>
    theme.transitions.create('margin', {
      easing: open
        ? theme.transitions.easing.sharp
        : theme.transitions.easing.easeOut,
      duration: open
        ? theme.transitions.duration.leavingScreen
        : theme.transitions.duration.enteringScreen
    })};
`;
export const UserIconButton = styled(IconButton)`
  && {
    padding: 4px;
    margin: 0 10px;
  }
`;
export const UserIconAvatar = styled(Avatar)`
  && {
    width: 32px;
    height: 32px;
  }
`;
export const MenuButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.navigation.topBar.text};
    padding: 8px;
    margin: 0 ${({ theme }) => theme.spacing(4)}px 0 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      margin: 0 ${({ theme }) => theme.spacing(1)}px 0 0;
    }
  }
`;
export const TopBar = styled.div`
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  width: 100%;

  z-index: ${({ theme }) => theme.zIndex.appBar};
  color: ${({ theme }) => theme.navigation.topBar.text};
  background: ${({ theme }) => theme.navigation.topBar.background};
  height: ${({ theme }) => theme.navigation.topBar.barHeight}px;
  padding: 0 ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0 ${({ theme }) => theme.spacing(2)}px;
  }
`;
export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Right = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Logo = styled.div`
  background: rgba(0, 0, 0, 0) url(${({ theme }) => theme.logourl});
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 160px;
`;
