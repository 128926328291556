// NPM Modules
import qs from 'qs';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useState, useEffect } from 'react';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Redux Actions
import authentication from '../../../redux/actions/authentication';
// Material-UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// Styled Components
import { Container } from '../style';

function PasswordReset(props) {
  const [fields, setFields] = useState({ password: '', confirmPassword: '' });
  const handleChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
  };
  return (
    <Container>
      <Card elevation={0}>
        <CardHeader title="Reset Password" />
        <CardContent>
          <form onSubmit={event => props.onSubmit(event, fields)}>
            <TextField
              onChange={handleChange}
              value={fields['password']}
              name="password"
              fullWidth
              type="password"
              label="New Password"
            />
            <TextField
              onChange={handleChange}
              value={fields['confirmPassword']}
              name="confirmPassword"
              fullWidth
              type="password"
              label="Confirm Password"
            />
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </CardContent>
        <CardActions>
          <Button
            onClick={event => props.onSubmit(event, fields)}
            fullWidth
            color="primary"
            disableElevation
            variant="contained"
          >
            Reset Password
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

function RequestPasswordReset(props) {
  const [fields, setFields] = useState({ email: '' });
  const handleChange = ({ target }) => {
    setFields({ ...fields, [target.name]: target.value });
  };
  return (
    <Container>
      <Card elevation={0}>
        <CardHeader
          title="Forgot your password?"
          action={
            <IconButton onClick={props.onRedirect}>
              <CloseRoundedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <form onSubmit={event => props.onSubmit(event, fields)}>
            <TextField
              onChange={handleChange}
              value={fields['email']}
              name="email"
              fullWidth
              type="email"
              label="Email"
            />
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </CardContent>
        <CardActions>
          <Button
            onClick={event => props.onSubmit(event, fields)}
            fullWidth
            color="primary"
            disableElevation
            variant="contained"
          >
            Send
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

function RequestPasswordResetSuccess(props) {
  return (
    <Container>
      <Card elevation={0}>
        <CardHeader
          title="Password reset sent"
          action={
            <IconButton onClick={props.onRedirect}>
              <CloseRoundedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Typography>
            Please check your email and follow the link to reset the password
            for your account.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={props.onRedirect}
            fullWidth
            color="primary"
            disableElevation
            variant="contained"
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

function PasswordResetPage(props) {
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const [redirect, setRedirect] = useState(props.resetStatus === 'success');

  const handleRedirect = () => {
    setRedirect(true);
  };

  const handleRequestForReset = (event, fields) => {
    event.preventDefault();
    props.passwordResetRequest(fields);
  };

  const handlePasswordReset = (event, { password }) => {
    event.preventDefault();
    props.passwordReset({ password, token: params.token });
  };

  useEffect(() => {
    if (props.requestStatus === 'success') {
      return () => {
        props.resetPasswordResetRequest();
      };
    }
  }, [props.requestStatus]);

  useEffect(() => {
    if (props.resetStatus === 'success') {
      setRedirect(true);
      return () => {
        props.enqueueSnackbar('Password successfully updated.', {
          variant: 'success'
        });
        props.resetPasswordReset();
      };
    }
  }, [props.resetStatus]);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  if (props.requestStatus === 'success') {
    return <RequestPasswordResetSuccess onRedirect={handleRedirect} />;
  }

  if (params.hasOwnProperty('token')) {
    return (
      <PasswordReset
        onSubmit={handlePasswordReset}
        onRedirect={handleRedirect}
      />
    );
  }

  return (
    <RequestPasswordReset
      onSubmit={handleRequestForReset}
      onRedirect={handleRedirect}
    />
  );
}

/**
 * static mapStateToProps
 * Maps the redux state to the component state.
 * @param {object} state redux state
 * @return {object} object of redux states
 */
function mapStateToProps(state) {
  return {
    resetStatus: state.authentication.passwordReset.status,
    requestStatus: state.authentication.passwordResetRequest.status
  };
}
/**
 * static mapDispatchToProps
 * Binds all the dispatch actions to one object.
 * @param {object} dispatch dispatch callback
 * @return {object} collectiong of dispatch actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      passwordReset: authentication.passwordReset.post,
      resetPasswordReset: authentication.passwordReset.reset,
      passwordResetRequest: authentication.passwordResetRequest.post,
      resetPasswordResetRequest: authentication.passwordResetRequest.reset
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(PasswordResetPage));
