// NPM Modules
import equal from 'deep-equal';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';
// Styled-Components
import { LineIcon } from './style';
// Material-UI
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

function TempDoor(props) {
  const [fields, setFields] = React.useState({
    name: '',
    linestring: 'LINESTRING (x1 y1, x2 y2)'
  });

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  return (
    <List dense>
      <ListItem>
        <ListItemIcon>
          <LineIcon iconcolor={'#ff0000'} />
        </ListItemIcon>
        <ListItemText
          primary={'New Door'}
          secondary={
            'Doors can be set with 1 passed the bounding box size. Example: LINE (( -1 -1, 10 10))'
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          inset
          primary={
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Door Name"
              value={fields.name}
              onChange={({ target }) => changeField(`name`, target.value)}
            />
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          inset
          primary={
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Door Line"
              value={fields.linestring}
              onChange={({ target }) => changeField(`linestring`, target.value)}
            />
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          inset
          primary={
            <div style={{ display: 'flex' }}>
              <Button
                onClick={props.onCancel}
                variant="outlined"
                fullWidth
                style={{ marginRight: '8px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => props.onSave(null, fields)}
                variant="outlined"
                fullWidth
                style={{ marginLeft: '8px' }}
              >
                Save
              </Button>
            </div>
          }
        />
      </ListItem>
    </List>
  );
}

/**
 * DoorList Component
 * Renders a list of door and allows for door creation/modification
 */
class DoorList extends Component {
  /**
   * Component state
   */
  state = {
    creatingNewDoor: false,
    editing: null
  };
  /**
   * Component PropTypes
   */
  static propTypes = {
    array: PropTypes.object
  };
  /**
   * Toggles state.creatingNewDoor
   */
  toggleCreateNewDoor = () => {
    this.setState({
      creatingNewDoor: !this.state.creatingNewDoor
    });
  };
  /**
   * Toggles ZoneSystem.state.layerVisibilities.doorLayer
   */
  toggleLayerVisibility = () => {
    this.props.onToggleLayerVisibilty('doorLayer');
  };
  /**
   *
   */
  updateDoors = (oldDoor, newDoor) => {
    const { entrance_doors = [] } = this.props.array.context;
    if (oldDoor) {
      const index = entrance_doors.findIndex(door => equal(oldDoor, door));
      if (newDoor === null) {
        entrance_doors.splice(index, 1);
      } else {
        entrance_doors[index] = newDoor;
      }
    } else {
      entrance_doors.push(newDoor);
    }
    this.props.onUpdateDoors(entrance_doors);
  };
  /**
   * Override of Component.render
   * Renders list of doors
   */
  render() {
    if (this.props.array === null) return this.renderSkelton();
    const { isLayerVisible, canCreateDelete } = this.props;
    const { creatingNewDoor } = this.state;
    return (
      <React.Fragment>
        <List>
          <ListItem>
            <ListItemText primary="Doors" />
            <ListItemSecondaryAction>
              <Tooltip
                title={isLayerVisible ? 'Hide door layer' : 'Show door layer'}
              >
                <IconButton
                  onClick={this.toggleLayerVisibility}
                  edge={!canCreateDelete && 'end'}
                >
                  {isLayerVisible ? (
                    <VisibilityRoundedIcon />
                  ) : (
                    <VisibilityOffRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
              {canCreateDelete ? (
                <Tooltip
                  title={
                    creatingNewDoor
                      ? 'Cancel creating a new door'
                      : 'Create a new door'
                  }
                >
                  <IconButton edge="end" onClick={this.toggleCreateNewDoor}>
                    {creatingNewDoor ? (
                      <CancelRoundedIcon />
                    ) : (
                      <AddCircleRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {creatingNewDoor ? (
          <TempDoor
            onSave={this.updateDoors}
            onCancel={this.toggleCreateNewDoor}
          />
        ) : null}
        {this.renderDoorList()}
      </React.Fragment>
    );
  }
  /**
   *
   * @param {*} item
   */
  handleItemEdit = item => {
    const { entrance_doors = [] } = this.props.array.context;
    const index = entrance_doors.findIndex(door => equal(item, door));
    this.setState({ editing: index });
  };
  /**
   * List of doors
   */
  renderDoorList() {
    const { entrance_doors = [] } = this.props.array.context;

    return (
      <List dense>
        {entrance_doors.map((door, i) => (
          <Item
            item={door}
            editing={this.state.editing === i}
            onSave={this.updateDoors}
            onEdit={this.handleItemEdit}
            canCreateDelete={this.props.canCreateDelete}
            key={`doorItem[${i}]`}
          />
        ))}
      </List>
    );
  }
}

function Item(props) {
  const [fields, setFields] = React.useState(props.item);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const toggleEdit = () => {
    props.onEdit(props.editing ? null : props.item);
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemIcon>
          <LineIcon iconcolor={randomColor({ seed: props.item.name })} />
        </ListItemIcon>
        <ListItemText
          primary={props.item.name}
          secondary={props.item.linestring}
        />
        {props.canCreateDelete ? (
          <ListItemSecondaryAction>
            <Tooltip title="Edit item">
              <IconButton onClick={toggleEdit}>
                {props.editing ? <CancelRoundedIcon /> : <EditRoundedIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete item">
              <IconButton
                edge="end"
                onClick={() => props.onSave(props.item, null)}
              >
                <DeleteForeverRoundedIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
      <Collapse in={props.editing} timeout="auto" unmountOnExit>
        <List dense>
          <ListItem>
            <ListItemText
              inset
              primary={
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Door Name"
                  value={fields.name}
                  onChange={({ target }) => changeField(`name`, target.value)}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              inset
              primary={
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Door Line"
                  value={fields.linestring}
                  onChange={({ target }) =>
                    changeField(`linestring`, target.value)
                  }
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              inset
              primary={
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={toggleEdit}
                    variant="outlined"
                    fullWidth
                    style={{ marginRight: '8px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => props.onSave(props.item, fields)}
                    variant="outlined"
                    fullWidth
                    style={{ marginLeft: '8px' }}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  );
}

export default DoorList;
