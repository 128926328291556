// NPM Modules
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Material-UI
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Dialog Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// Material-UI Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
/**
 * Array Info Text Component
 * Helper component to clean up the below function text
 * @param {*} props
 */
function ArrayInfoItem(props) {
  return (
    <ListItem dense>
      <ListItemText
        inset
        primary={
          <React.Fragment>
            <Typography variant="body2">{props.label}</Typography>
            <Typography variant="body2">{props.value}</Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}
/**
 * Array Item Component
 * Has state for toggling array info
 * @param {*} props
 */
function ArrayItem({ array, onDelete, canCreateDelete }) {
  const [open, setOpen] = React.useState(false);
  const [openConfigmDialog, setConfirmDialogOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem dense>
        <ListItemIcon>
          <ExtensionRoundedIcon
            style={{ color: `rgb(${array.color.toString()})` }}
          />
        </ListItemIcon>
        <ListItemText
          primary={array.array_name}
          secondary={
            <Link component={RouterLink} to={`../array/${array.array_key}`}>
              {array.array_key}
            </Link>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="toggle"
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <ArrayInfoItem label={`Description:`} value={array.description} />
          <ArrayInfoItem
            label={`Bounding Box Size X:`}
            value={JSON.stringify(array.bounding_box_size_x)}
          />
          <ArrayInfoItem
            label={`Bounding Box Size Y:`}
            value={array.bounding_box_size_y}
          />
          <ArrayInfoItem label={`Region:`} value={array.region} />
          {canCreateDelete ? (
            <ListItem dense>
              <ListItemText
                inset
                primary={
                  <Button
                    onClick={() => setConfirmDialogOpen(true)}
                    variant="outlined"
                  >
                    Delete forever
                  </Button>
                }
              />
            </ListItem>
          ) : null}
        </List>
      </Collapse>
      <Dialog
        open={openConfigmDialog}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Delete array forever?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once a array is deleted it can never be undeleted. This is a
            permanent action. Are you sure you want to delete this array?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={onDelete}>Delete forever</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
/**
 * Array List Component
 */
export default ({ list, isLoading, onDeleteArray, canCreateDelete }) => {
  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <List>
        {list.map(array => (
          <ArrayItem
            array={array}
            key={array.array_key}
            onDelete={() => onDeleteArray(array)}
            canCreateDelete={canCreateDelete}
          />
        ))}
      </List>
    );
  }
};
