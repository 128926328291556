// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import deployment from '../../../redux/actions/deployment';
// Components
import Page from '../../../components/Page';
import PageList from '../../../components/PageList';
import DeploymentCreateDialog from '../../../components/Dialogs/DeploymentCreateDialog';
/**
 * Deployment List Component
 */
class List extends Component {
  /**
   * state
   */
  state = {
    status: 'active',
    searchTerm: '',
    page_size: this.props.page_size,
    deploymentCreateDialogOpen: false
  };
  /**
   * getDeploymentList
   */
  getDeploymentList(options = {}) {
    this.props.get({
      org_key: this.props.organization.org_key,
      page: options.page || 1,
      search: options.searchTerm || this.state.searchTerm,
      page_size: options.pageSize || this.props.page_size,
      status: options.status || this.state.status
    });
  }
  /**
   * componentDidMount
   */
  componentDidMount() {
    // Handle data fetching
    if (this.props.status === 'server') {
      if (
        new Date().getTime() - new Date(this.props.fetched).getTime() >
        5000
      ) {
        this.getDeploymentList();
      }
    } else {
      this.getDeploymentList();
    }
  }
  /**
   * handleOnSearch
   */
  handleOnSearch = searchTerm => {
    this.setState({ searchTerm });
    this.getDeploymentList({ searchTerm, page: 1 });
  };
  /**
   * handlePageChange
   */
  handlePageChange = page => {
    this.getDeploymentList({ page: page + 1 });
  };
  /**
   * handlePageSizeChange
   */
  handlePageSizeChange = pageSize => {
    this.getDeploymentList({ pageSize });
  };
  /**
   * handleStatusChange
   */
  handleStatusChange = event => {
    const status = event.target.value;
    this.setState({ status });
    this.getDeploymentList({ status });
  };
  /**
   * When deployment is created. Get deployment list and set deployment create dialog open to false
   */
  handleDeploymentCreated = () => {
    this.setState({ deploymentCreateDialogOpen: false });
    this.getDeploymentList();
  };
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    const { deployments, status, page, page_size, count, history } = this.props;
    const isLoading = status !== 'server' && status !== 'success';
    return (
      <Page>
        <PageList
          actions={
            this.props.organization.permissions.find(
              p => p === 'deployment_create_delete'
            )
              ? [
                  {
                    text: 'Create Deployment',
                    size: 'large',
                    variant: 'outlined',
                    marginLeft: 16,
                    onClick: () =>
                      this.setState({ deploymentCreateDialogOpen: true })
                  }
                ]
              : []
          }
          options={{
            search: true,
            searchText: 'Search deployments',
            searchVariant: 'outlined',
            searchDebounceTime: 500,
            onSearch: this.handleOnSearch,
            isLoading: isLoading,
            count: count,
            pageSize: page_size,
            currentPage: page - 1,
            onPageChange: this.handlePageChange,
            onPageSizeChange: this.handlePageSizeChange,
            status: this.state.status,
            statusList: ['active', 'inactive'],
            onStatusChange: this.handleStatusChange
          }}
          list={deployments.map(deployment => ({
            title: deployment.deployment_name,
            subTitle: deployment.deployment_type,
            link: deployment.deployment_key,
            actions: [
              {
                text: 'View Deployment',
                size: 'small',
                marginLeft: 0
              }
            ]
          }))}
          history={history}
        />
        <DeploymentCreateDialog
          open={this.state.deploymentCreateDialogOpen}
          onDeploymentCreated={this.handleDeploymentCreated}
          onClose={() => this.setState({ deploymentCreateDialogOpen: false })}
        />
      </Page>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      page: state.deployment.list.page.current,
      count: state.deployment.list.count,
      status: state.deployment.list.request.status,
      fetched: state.deployment.list.request.fetched,
      page_size: state.deployment.list.page.size,
      deployments: state.deployment.list.list,
      organization: state.organization.info.info
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        get: deployment.list.get,
        cancelGet: deployment.list.cancel
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(List.mapStateToProps, List.mapDispatchToProps)(List);
