// Actions
import { types } from '../actions/location';

// Default State of Reducer
const defaultState = {
  title: ''
};

/**
 * Location Title
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case types.title.SET:
      return { ...state, title: action.payload };
    default:
      return state;
  }
};
