// NPM Modules
import React, { Component } from 'react';
// Styled Components
import { CustomChip, Section, AddDataPointChip } from './style';
// Material-UI
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// Material-UI Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
/**
 * Data Point Picker
 * Helps choose data points from a list
 */
class DataPointPicker extends Component {
  state = {
    open: false,
    search: ''
  };
  debounce = (fn, time) => {
    let timeout;
    return function () {
      const functionCall = () => fn.apply(this, arguments);
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  };
  updateSearch = this.debounce(search => {
    this.setState({ search });
    this.props.onSearch(search);
  }, 500);
  handleSearch = ({ target }) => {
    this.updateSearch(target.value);
  };
  handlePointSelect(point) {
    const index = this.props.selectedPoints.findIndex(
      p => p.uuid === point.uuid
    );
    if (index === -1) {
      this.props.onPointSelected(point);
    } else {
      this.props.onPointRemoved(point);
    }
  }
  renderListItem(point) {
    return (
      <ListItem
        key={point.uuid}
        button
        dense
        onClick={() => this.handlePointSelect(point)}
        disableRipple
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={point.checked}
            tabIndex={-1}
            disableRipple
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          primary={point.occupancy_tracker_name}
          secondary={point.uuid}
        />
      </ListItem>
    );
  }
  renderPoints(points) {
    return points.length > 0 ? (
      <List>{points.map(point => this.renderListItem(point))}</List>
    ) : (
      <Typography variant="subtitle1">No points selected.</Typography>
    );
  }
  renderPointSystem() {
    const unselectedPoints = this.props.points
      .filter(
        point => !this.props.selectedPoints.find(p => p.uuid === point.uuid)
      )
      .map(point => ({ ...point, checked: false }));
    return (
      <React.Fragment>
        {this.renderPoints(this.props.selectedPoints)}
        <Divider />
        {this.props.isLoading
          ? this.renderLoading()
          : unselectedPoints.length > 0
          ? this.renderPoints(unselectedPoints)
          : this.renderNoPoints()}
      </React.Fragment>
    );
  }
  renderLoading() {
    return <Typography variant="subtitle1">Loading...</Typography>;
  }
  renderNoPoints() {
    if (this.props.points.length > 0) {
      return (
        <Typography variant="subtitle1">
          No more unselected datapoints.
        </Typography>
      );
    } else {
      return <Typography variant="subtitle1">No data points found.</Typography>;
    }
  }
  renderDataPoints() {
    if (this.props.selectedPoints.length > 0) {
      let response = [];
      this.props.selectedPoints.forEach(point =>
        response.push(
          <CustomChip
            key={point.uuid}
            label={point.occupancy_tracker_name || point.uuid}
            chipcolor={`rgba(${point.color.join(',')}, 1)`}
            size="small"
            onDelete={() => this.handlePointSelect(point)}
          />
        )
      );
      response.push(
        <AddDataPointChip
          key="AddDataPointChip"
          label={''}
          size="small"
          color="primary"
          onClick={() => this.setState({ open: true })}
          onDelete={() => this.setState({ open: true })}
          deleteIcon={<AddCircleIcon />}
        />
      );
      return response;
    }
    return (
      <AddDataPointChip
        label={'Add Data Point'}
        size="small"
        color="primary"
        onClick={() => this.setState({ open: true })}
        onDelete={() => this.setState({ open: true })}
        deleteIcon={<AddCircleIcon />}
      />
    );
  }
  render() {
    return (
      <React.Fragment>
        <Divider />
        <Section>{this.renderDataPoints()}</Section>
        <Divider />
        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          scroll="paper"
        >
          <DialogTitle>
            Select data points to display on the graph
            <TextField
              type="text"
              label="Search"
              margin="none"
              onChange={this.handleSearch}
              style={{ width: '100%' }}
              autoFocus
            />
          </DialogTitle>
          <DialogContent>{this.renderPointSystem()}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ open: false })}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
/**
 * Export Module (React Component)
 */
export default DataPointPicker;
