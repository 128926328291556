import React, { Component } from 'react';
import { pointOnLine } from 'geometric';
import { withTheme } from '@material-ui/core/styles';
import randomColor from 'randomcolor';
// Cake Stuff
import Cake, { Items, Layer } from '../Cake';
import ZonePolygon from './ZonePolygon';
import MatItem from './MatItem';
import LineTextItem from './LineTextItem';
// Util
import { convertRegionToArray, convertLineStringToLine } from './util';
// Styled-Components
import { Container, ToolGroup } from './style';
// Material-UI
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// Material-UI Icons
import MouseRoundedIcon from '@material-ui/icons/MouseRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
/**
 * Configuration Tool for setting up zones, doors, and walls on an array.
 */
class ArrayConfigurationTool extends Component {
  canvasEl = React.createRef();
  board;

  state = {
    menuOpen: false,
    menuAnchorEl: null,
    activeTool: 'mouse',
    zoomLevel: '100%',
    zoneHeatmapStyle: false,
    matLayerShowing: false
  };

  grid = {
    gap: 20,
    style: {
      stroke: '#cfcfcf',
      strokeWidth: 1,
      selectable: false
    }
  };

  size = {
    width: this.props.array.bounding_box_size_x * this.grid.gap,
    height: this.props.array.bounding_box_size_y * this.grid.gap
  };

  tempZone = {
    selectedIndex: null,
    points: []
  };

  componentDidMount() {
    this.board = new Cake(this.canvasEl.current, {
      width: this.size.width,
      height: 500,
      responsive: true,
      panning: true,
      events: {
        mouseup: this.handleMouseUp,
        mousemove: this.handleMouseMove,
        mousedown: this.handleMouseDown,
        contextmenu: this.handleContextMenu
      },
      onZoom: this.handleZoom
    });
    window.board = this.board;
    this.createGrid();

    if (this.props.matList.length !== 0) {
      this.renderMats();
    }
    if (this.props.zones.length !== 0) {
      this.renderZones();
    }
    const { entrance_doors = [], walls = [] } = this.props.array.context;
    if (entrance_doors.length !== 0) {
      this.renderDoors();
    }
    if (walls.length !== 0) {
      this.renderWalls();
    }

    this.tempZoneLayer = new Layer(this.size.width, this.size.height, {
      name: 'tempZoneLayer'
    });
    this.tempZoneLayer.addItem(
      new ZonePolygon([], {
        renderStyle: 'creation',
        // strokeWidth: this.grid.gap,
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: '#ff0000',
        fillOpacity: 0.1
      })
    );
    this.board.addLayer(this.tempZoneLayer);
  }

  componentDidUpdate(prevProps) {
    if (this.props.zones.length !== prevProps.zones.length) {
      this.renderZones();
    }
    if (this.props.matList.length !== prevProps.matList.length) {
      this.renderMats();
    }
    if (prevProps.creatingNewZone && !this.props.creatingNewZone) {
      this.tempZone.selectedIndex = null;
      this.tempZone.points = [];
      this.updateTempZoneItem();
    }
    if (this.props.editZone !== null) {
      if (prevProps.editZone !== null) {
        if (this.props.editZone.zone_key !== prevProps.editZone.zone_key) {
          this.tempZone.selectedIndex = null;
          this.tempZone.points = this.props.region;
          this.updateTempZoneItem();
          this.renderZones();
        } else {
          // If region was updated. Update tempzone points
          if (this.props.region.toString() !== prevProps.region.toString()) {
            this.tempZone.selectedIndex = null;
            this.tempZone.points = this.props.region;
            this.updateTempZoneItem();
            this.renderZones();
          }
        }
      } else {
        this.tempZone.selectedIndex = null;
        this.tempZone.points = this.props.region;
        this.updateTempZoneItem();
        this.renderZones();
      }
    } else if (prevProps.editZone !== null) {
      this.tempZone.selectedIndex = null;
      this.tempZone.points = [];
      this.updateTempZoneItem();
      this.renderZones();
    }

    // TODO: Add in array.context update checking

    this.updateLayerVisibilities(prevProps);
  }

  /**
   * Compares props.layerVisibilities prev vs now states and updates the layers to
   * the visibility settings
   * @param {object} prevProps
   */
  updateLayerVisibilities(prevProps) {
    Object.entries(this.props.layerVisibilities).forEach(([name, state]) => {
      if (prevProps.layerVisibilities[name] !== state) {
        const layer = this.board.getLayer(name);
        if (layer !== null) {
          layer.setVisible(state);
          this.board.update();
        }
      }
    });
  }

  handleZoom = level => {
    this.setState({ zoomLevel: `${Math.floor(level * 100)}%` });
  };

  handleContextMenu = event => {
    if (event.button === 2) {
      event.preventDefault();
    }
  };

  handleMouseUp = (event, cake) => {
    if (this.tempZone.selectedIndex !== null) {
      this.tempZone.selectedIndex = null;
    }
    if (
      cake.tool === 'mouse' &&
      (this.props.creatingNewZone || this.props.editZone !== null)
    ) {
      this.props.onRegionChanged(this.tempZone.points);
    }
  };

  handleMouseMove = (event, cake) => {
    if (this.tempZone.selectedIndex !== null) {
      const point = [
        Math.floor(
          (event.offsetX - cake.position.x) / cake.scale.x / this.grid.gap
        ),
        this.props.array.bounding_box_size_y -
          1 -
          Math.floor(
            (event.offsetY - cake.position.y) / cake.scale.y / this.grid.gap
          )
      ];
      // Check if point selected is within array boundry
      if (
        point[0] >= 0 &&
        point[0] < this.props.array.bounding_box_size_x &&
        point[1] >= 0 &&
        point[1] < this.props.array.bounding_box_size_y
      ) {
        this.tempZone.points[this.tempZone.selectedIndex] = point;
        this.updateTempZoneItem();
      }
    }
  };

  handleMouseDown = (event, cake) => {
    if (
      cake.tool === 'mouse' &&
      (this.props.creatingNewZone || this.props.editZone !== null)
    ) {
      const point = [
        Math.floor(
          (event.offsetX - cake.position.x) / cake.scale.x / this.grid.gap
        ),
        this.props.array.bounding_box_size_y -
          1 -
          Math.floor(
            (event.offsetY - cake.position.y) / cake.scale.y / this.grid.gap
          )
      ];
      // Check if point selected is within array boundry
      if (
        point[0] >= 0 &&
        point[0] < this.props.array.bounding_box_size_x &&
        point[1] >= 0 &&
        point[1] < this.props.array.bounding_box_size_y
      ) {
        const exists = this.tempZone.points.findIndex(
          p => p[0] === point[0] && p[1] === point[1]
        );
        if (exists === -1) {
          if (event.button === 0) {
            // check if new point is on the between two points and insert it between them in the array.
            let pointInsertIndex = -1;
            this.tempZone.points.forEach((p, i, points) => {
              let line;
              if (i === points.length - 1) {
                line = [p, points[0]];
              } else {
                line = [p, points[i + 1]];
              }
              if (pointOnLine(point, line)) {
                pointInsertIndex = i + 1;
              }
            });

            if (pointInsertIndex !== -1) {
              this.tempZone.points.splice(pointInsertIndex, 0, point);
            } else {
              // Default insert point at end of points list
              this.tempZone.points.push(point);
            }
            this.updateTempZoneItem();
          }
        } else {
          if (event.button === 2) {
            this.tempZone.points.splice(exists, 1);
            this.updateTempZoneItem();
          } else if (event.button === 0) {
            this.tempZone.selectedIndex = exists;
          }
        }
      }
    }
  };

  updateTempZoneItem() {
    this.tempZoneLayer.getItems().forEach(item => {
      item.points = this.tempZone.points.map(p => [
        (p[0] + 0.5) * this.grid.gap,
        this.size.height - (p[1] + 0.5) * this.grid.gap
      ]);
    });
    this.tempZoneLayer.update();
    this.board.update();
  }

  createGrid() {
    const gridLayer = new Layer(this.size.width, this.size.height, {
      name: 'grid'
    });
    const grid = [];
    for (var i = 0; i <= this.size.width; i++) {
      const distance = i * this.grid.gap;
      const strokeColor =
        i % 2 === 0
          ? this.props.theme.palette.divider.replace('0.12', '0.4')
          : this.props.theme.palette.divider;
      grid.push(
        new Items.Line(0, 0, [0, distance], [this.size.width, distance], {
          stroke: strokeColor,
          strokeWidth: 1
        })
      );
    }
    for (var i = 0; i <= this.size.height; i++) {
      const distance = i * this.grid.gap;
      const strokeColor =
        i % 2 === 0
          ? this.props.theme.palette.divider.replace('0.12', '0.4')
          : this.props.theme.palette.divider;
      grid.push(
        new Items.Line(0, 0, [distance, 0], [distance, this.size.height], {
          stroke: strokeColor,
          strokeWidth: 1
        })
      );
    }
    gridLayer.addItems([...grid]);
    this.board.addLayer(gridLayer);
  }

  renderZones() {
    let zoneLayer = this.board.getLayer('zoneLayer');
    if (zoneLayer === null) {
      zoneLayer = new Layer(this.size.width, this.size.height, {
        name: 'zoneLayer'
      });
    }
    zoneLayer.removeAllItems(); // Clear all items

    const filteredZones = this.props.zones.filter(zone => {
      if (this.props.editZone === null) {
        return zone;
      } else if (this.props.editZone.zone_key !== zone.zone_key) {
        return zone;
      }
    });

    const zoneItems = filteredZones.map(zone => {
      return new ZonePolygon(
        convertRegionToArray(zone.region).map(p => [
          (p[0] + 0.5) * this.grid.gap,
          this.size.height - (p[1] + 0.5) * this.grid.gap // flip y axis
        ]),
        {
          // renderStyle: 'heatmap',
          fill: `rgb(${zone.color.toString()})`,
          fillOpacity: 0.1,
          stroke: `rgb(${zone.color.toString()})`,
          // strokeWidth: this.grid.gap, // use grid gap for heatmap style rendering
          strokeWidth: 2,
          closePath: true
        }
      );
    });
    zoneLayer.addItems([...zoneItems]);
    this.board.addLayer(zoneLayer);
  }

  /**
   *
   */
  renderMats() {
    // ⚠️⚠️⚠️ MatItem RENDERS ORIGIN 0,0 BOTTOM LEFT ⚠️⚠️⚠️
    let matLayer = this.board.getLayer('matLayer');
    if (matLayer === null) {
      matLayer = new Layer(this.size.width, this.size.height, {
        name: 'matLayer',
        visible: false
      });
    }
    matLayer.removeAllItems(); // Clear all items

    const matItems = this.props.matList.map(mat => {
      return new MatItem(
        mat.offset_x * this.grid.gap,
        mat.offset_y * this.grid.gap,
        mat.size_x * this.grid.gap,
        mat.size_y * this.grid.gap,
        {
          rotation: mat.rotate_deg,
          flip_x: mat.flip_x,
          flip_y: mat.flip_y,
          // Style
          fill: `rgb(${mat.color.toString()})`,
          fillOpacity: 0.1,
          stroke: `rgb(${mat.color.toString()})`,
          strokeWidth: 2
        }
      );
    });
    matLayer.addItems([...matItems]);
    this.board.addLayer(matLayer);
  }

  /**
   * renders the door layer from the array.context settings
   */
  renderDoors() {
    const WIDTH = this.size.width + 2 * this.grid.gap;
    const HEIGHT = this.size.height + 2 * this.grid.gap;
    let doorLayer = this.board.getLayer('doorLayer');
    if (doorLayer === null) {
      doorLayer = new Layer(WIDTH, HEIGHT, {
        name: 'doorLayer',
        visible: false,
        xOffset: -this.grid.gap,
        yOffset: -this.grid.gap
      });
    }
    doorLayer.removeAllItems(); // Clear all items
    const { entrance_doors = [] } = this.props.array.context;
    const doorItems = entrance_doors.map(door => {
      const line = convertLineStringToLine(door.linestring);
      return new LineTextItem(
        [
          (line[0][0] + 0.5) * this.grid.gap + this.grid.gap,
          HEIGHT - (line[0][1] + 0.5) * this.grid.gap - this.grid.gap
        ],
        [
          (line[1][0] + 0.5) * this.grid.gap + this.grid.gap,
          HEIGHT - (line[1][1] + 0.5) * this.grid.gap - this.grid.gap
        ],
        door.name,
        {
          // stroke: randomColor({ seed: door.name }),
          stroke: '#82bd28',
          strokeOpacity: 0.75,
          strokeWidth: this.grid.gap
        }
      );
    });
    doorLayer.addItems([...doorItems]);
    this.board.addLayer(doorLayer);
  }
  /**
   * renders the wall layer from the array.context settings
   */
  renderWalls() {
    const WIDTH = this.size.width + 2 * this.grid.gap;
    const HEIGHT = this.size.height + 2 * this.grid.gap;
    let wallLayer = this.board.getLayer('wallLayer');
    if (wallLayer === null) {
      wallLayer = new Layer(WIDTH, HEIGHT, {
        name: 'wallLayer',
        visible: false,
        xOffset: -this.grid.gap,
        yOffset: -this.grid.gap
      });
    }
    wallLayer.removeAllItems(); // Clear all items
    const { walls = [] } = this.props.array.context;
    const wallItems = walls.map(wall => {
      const line = convertLineStringToLine(wall.linestring);
      return new LineTextItem(
        [
          (line[0][0] + 0.5) * this.grid.gap + this.grid.gap,
          HEIGHT - (line[0][1] + 0.5) * this.grid.gap - this.grid.gap
        ],
        [
          (line[1][0] + 0.5) * this.grid.gap + this.grid.gap,
          HEIGHT - (line[1][1] + 0.5) * this.grid.gap - this.grid.gap
        ],
        wall.name,
        {
          // stroke: randomColor({ seed: wall.name }),
          stroke: '#F5F5DC',
          strokeOpacity: 0.75,
          strokeWidth: this.grid.gap
        }
      );
    });
    wallLayer.addItems([...wallItems]);
    this.board.addLayer(wallLayer);
  }

  toggleZoneRenderingStyle = () => {
    const zoneHeatmapStyle = !this.state.zoneHeatmapStyle;

    // Update zoneLayer items
    const zoneLayer = this.board.getLayer('zoneLayer');
    if (zoneLayer !== null) {
      const items = zoneLayer.getItems();
      items.forEach(item => {
        item.renderStyle = zoneHeatmapStyle ? 'heatmap' : 'default';
        item.style.strokeWidth = zoneHeatmapStyle ? this.grid.gap : 2;
      });
      zoneLayer.update();
      this.board.update();
    }

    this.setState({ zoneHeatmapStyle });
  };
  toggleMats = () => {
    const matLayer = this.board.getLayer('matLayer');
    const matLayerShowing = !matLayer.isVisible();
    if (matLayer !== null) {
      matLayer.setVisible(matLayerShowing);
      this.board.update();
    }
    this.setState({ matLayerShowing });
  };
  toggleZones = () => {
    const zoneLayer = this.board.getLayer('zoneLayer');
    if (zoneLayer !== null) {
      zoneLayer.setVisible(!zoneLayer.isVisible());
      this.board.update();
    }
  };

  openMenu = event => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget
    });
  };
  closeMenu = () => {
    this.setState({
      menuOpen: false,
      menuAnchorEl: null
    });
  };

  toggleTool = tool => {
    this.board.setTool(tool);
    this.setState({ activeTool: tool });
  };

  _setActiveButton(type) {
    return type === this.state.activeTool ? 'primary' : 'default';
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <div>
            <ToolGroup fullWidth variant="text" style={{ borderRadius: 0 }}>
              <Button
                size="large"
                onClick={() => this.toggleTool('mouse')}
                color={this._setActiveButton('mouse')}
                style={{ borderRadius: 0 }}
              >
                <MouseRoundedIcon />
              </Button>
              <Button
                size="large"
                onClick={() => this.toggleTool('pan')}
                color={this._setActiveButton('pan')}
                style={{ borderRadius: 0 }}
              >
                <PanToolRoundedIcon />
              </Button>
              <Button
                size="large"
                onClick={() => this.toggleTool('zoom')}
                color={this._setActiveButton('zoom')}
                startIcon={<SearchRoundedIcon />}
                style={{ borderRadius: 0 }}
              >
                {this.state.zoomLevel}
              </Button>
              <Button
                size="large"
                onClick={this.openMenu}
                style={{ borderRadius: 0 }}
              >
                <MoreHorizRoundedIcon />
              </Button>
            </ToolGroup>
            <Menu
              open={this.state.menuOpen}
              anchorEl={this.state.menuAnchorEl}
              onClose={this.closeMenu}
            >
              <MenuItem onClick={this.toggleZoneRenderingStyle}>
                <ListItemIcon>
                  {this.state.zoneHeatmapStyle ? (
                    <CheckBoxRoundedIcon />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon />
                  )}
                </ListItemIcon>
                Heatmap Zone Style
              </MenuItem>
              {this.props.matList.length > 0 ? (
                <MenuItem onClick={this.toggleMats}>
                  <ListItemIcon>
                    {this.state.matLayerShowing ? (
                      <CheckBoxRoundedIcon />
                    ) : (
                      <CheckBoxOutlineBlankRoundedIcon />
                    )}
                  </ListItemIcon>
                  Mats
                </MenuItem>
              ) : null}
            </Menu>
          </div>
          <canvas ref={this.canvasEl} />
        </Container>
      </React.Fragment>
    );
  }
}

export default withTheme(ArrayConfigurationTool);
