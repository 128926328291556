// Default State of Reducer
const defaultState = {
  email: '',
  confirm_code: ''
};
/**
 * Confirm Account Reducer
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
