// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Components
import List from './components/List';
import Actions from './components/Actions';
import SearchBar from './components/SearchBar';
// Material-UI
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
// Styled Components
import { Header, Body, SubHeader, Loading } from './style';
/**
 * Page List Component
 * This component is a generic list page.
 */
class PageList extends Component {
  /**
   * render
   */
  render() {
    const { options, actions, list, history } = this.props;
    return (
      <div>
        <Header>
          {options.search ? (
            <SearchBar
              text={options.searchText}
              history={history}
              variant={options.searchVariant}
              onSearch={options.onSearch}
              debounceTime={options.searchDebounceTime}
            />
          ) : null}
          <Actions actions={actions} />
        </Header>
        <SubHeader>
          {options.statusList && options.statusList.length > 0 ? (
            <FormControl style={{ justifyContent: 'center' }}>
              <Select
                labelId="select-label-status"
                id="select-status"
                value={options.status}
                onChange={options.onStatusChange}
                input={<InputBase />}
              >
                {options.statusList.map(status => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          <TablePagination
            component="div"
            count={options.count}
            page={options.currentPage}
            onChangePage={(event, value) => options.onPageChange(value)}
            rowsPerPage={options.pageSize}
            onChangeRowsPerPage={event =>
              options.onPageSizeChange(event.target.value)
            }
            labelRowsPerPage="Count:"
          />
        </SubHeader>
        <Body>
          {options.isLoading ? (
            <Loading>
              <CircularProgress />
            </Loading>
          ) : list.length === 0 ? (
            <h3>No items found</h3>
          ) : (
            <List list={list} />
          )}
        </Body>
      </div>
    );
  }
  /**
   * Component Prop Types
   */
  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string,
        link: PropTypes.string,
        actions: PropTypes.arrayOf(
          PropTypes.shape({
            icon: PropTypes.string,
            iconPosition: PropTypes.oneOf(['start', 'end']),
            text: PropTypes.string,
            onClick: PropTypes.func,
            disabled: PropTypes.bool,
            size: PropTypes.oneOf(['small', 'medium', 'large'])
          })
        )
      })
    ),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        iconPosition: PropTypes.oneOf(['start', 'end']),
        text: PropTypes.string,
        onClick: PropTypes.func,
        link: PropTypes.string,
        disabled: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large'])
      })
    ),
    options: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired,
      // Search Props
      search: PropTypes.bool,
      searchText: PropTypes.string,
      onSearch: PropTypes.func,
      searchVariant: PropTypes.oneOf(['text', 'outlined', 'contained']),
      searchDebounceTime: PropTypes.number,
      // Page Props
      count: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      currentPage: PropTypes.number.isRequired,
      onPageChange: PropTypes.func.isRequired,
      onPageSizeChange: PropTypes.func.isRequired,
      // Status List Props
      statusList: PropTypes.array,
      onStatusChange: PropTypes.func
    })
  };
}
/**
 * Export React Component
 */
export default PageList;
