// NPM Modules
import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
// Materail-UI
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Styled Components
import { LinearFormControl } from './style';
/**
 * Simple Date Picker
 */
class SimpleDatePicker extends Component {
  state = {
    startDateTime: moment.tz(this.props.startDateTime, this.props.timezone),
    endDateTime: moment.tz(this.props.endDateTime, this.props.timezone),
    intervalType: this.props.interval.split(/(d|h|m|M)/g, 2)[1],
    intervalNumber: parseInt(this.props.interval.split(/(d|h|m|M)/g, 2)[0], 10),
    timezone: this.props.timezone,
    timezoneInput: this.props.timezone,
    timezoneList: moment.tz.names(),
    threshold: this.props.threshold / 1000
  };

  handleStartChange = date => {
    this.setState({ startDateTime: moment.tz(date, this.props.timezone) });
  };

  handleEndChange = date => {
    this.setState({ endDateTime: moment.tz(date, this.props.timezone) });
  };

  handleChange = event => {
    let value = event.target.value;
    if (event.target.name === 'intervalNumber') {
      value = value < 1 ? 1 : value;
    }
    this.setState({ [event.target.name]: value });
  };

  handleUpdate = () => {
    this.props.onUpdate(
      this.state.startDateTime.format('Y-MM-DDTHH:mm:ss'),
      this.state.endDateTime.format('Y-MM-DDTHH:mm:ss'),
      `${this.state.intervalNumber}${this.state.intervalType}`,
      this.state.timezone,
      this.state.threshold * 1000
    );
    this.props.onClose();
  };

  componentDidUpdate(prevProps) {
    if (
      !moment(prevProps.startDateTime).isSame(this.props.startDateTime) ||
      !moment(prevProps.endDateTime).isSame(this.props.endDateTime) ||
      prevProps.interval !== this.props.interval ||
      prevProps.timezone !== this.props.timezone
    ) {
      this.setState({
        startDateTime: moment.tz(this.props.startDateTime, this.props.timezone),
        endDateTime: moment.tz(this.props.endDateTime, this.props.timezone),
        intervalType: this.props.interval.split(/(d|h|m|M)/g, 2)[1],
        intervalNumber: parseInt(
          this.props.interval.split(/(d|h|m|M)/g, 2)[0],
          10
        ),
        timezone: this.props.timezone
      });
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={() => this.props.onClose()}>
        <DialogTitle>Select Date Time Window</DialogTitle>
        <DialogContent>
          <FormControl margin="normal" fullWidth>
            <DateTimePicker
              label="Start Date Time"
              onChange={this.handleStartChange}
              value={this.state.startDateTime}
              disableFuture
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <DateTimePicker
              label="End Date Time"
              onChange={this.handleEndChange}
              value={this.state.endDateTime}
              disableFuture
            />
          </FormControl>
          {this.props.hasInterval && (
            <LinearFormControl>
              <FormControl margin="normal">
                <TextField
                  label="Interval Number"
                  name="intervalNumber"
                  type="number"
                  value={this.state.intervalNumber}
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <InputLabel>Interval Type</InputLabel>
                <Select
                  name="intervalType"
                  value={this.state.intervalType}
                  onChange={this.handleChange}
                >
                  <MenuItem value="M">Months</MenuItem>
                  <MenuItem value="d">Days</MenuItem>
                  <MenuItem value="h">Hours</MenuItem>
                  <MenuItem value="m">Minutes</MenuItem>
                </Select>
              </FormControl>
            </LinearFormControl>
          )}
          {this.props.hasThreshold && (
            <LinearFormControl>
              <FormControl margin="normal">
                <TextField
                  label="Threshold (Seconds)"
                  name="threshold"
                  type="number"
                  value={this.state.threshold}
                  onChange={this.handleChange}
                />
              </FormControl>
            </LinearFormControl>
          )}
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              fullWidth
              margin="normal"
              value={this.state.timezone}
              inputValue={this.state.timezoneInput}
              options={this.state.timezoneList}
              getOptionLabel={option => option}
              onChange={(event, newInputValue) => {
                this.setState({ timezone: newInputValue });
              }}
              onInputChange={(event, newInputValue) => {
                this.setState({ timezoneInput: newInputValue });
              }}
              renderInput={params => (
                <TextField {...params} label="Timezone" name="timezone" />
              )}
              groupBy={option => option.split('/')[0]}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleUpdate}>Update</Button>
          <Button onClick={() => this.props.onClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
  /**
   * Component PropTypes
   */
  static propTypes = {
    hasInterval: PropTypes.bool,
    hasThreshold: PropTypes.bool
  };
  /**
   * Component Default PropTypes
   */
  static defaultProps = {
    hasInterval: true,
    hasThreshold: true
  };
}

export default SimpleDatePicker;
