// NPM Modules
import React from 'react';
// Styled Components
import { Logo } from './style';
/**
 * Logo Component
 */
export default () => (
  <Logo>
    <svg x="0px" y="0px" viewBox="0 0 1694 214">
      <g>
        <polygon
          className="logo-hexagon-color"
          points="72.02,87.74 24.02,87.74 0.02,129.31 24.02,170.88 72.02,170.88 96.02,129.31"
        />
        <polygon
          className="logo-hexagon-color"
          points="147.02,44.44 99.02,44.44 75.02,86.01 99.02,127.58 147.02,127.58 171.02,86.01"
        />
        <polygon
          className="logo-hexagon-color"
          points="222.02,1.14 174.02,1.14 150.02,42.71 174.02,84.28 222.02,84.28 246.02,42.71"
        />
        <polygon
          className="logo-hexagon-color"
          points="297.02,44.44 249.02,44.44 225.02,86.01 249.02,127.58 297.02,127.58 321.02,86.01"
        />
        <polygon
          className="logo-hexagon-color"
          points="372.02,1.14 324.02,1.14 300.02,42.71 324.02,84.28 372.02,84.28 396.02,42.71"
        />
        <polygon
          className="logo-hexagon-color"
          points="297.02,131.04 249.02,131.04 225.02,172.61 249.02,214.18 297.02,214.18 321.02,172.61"
        />
        <path
          className="logo-hexagon-color"
          d="M222.02,87.74h-48l-24,41.57l24,41.57h48l24-41.57L222.02,87.74z M221.02,150.09h-12l-6-10.39l6-10.39h12l6,10.39L221.02,150.09z"
        />
      </g>
      <g>
        <path
          className="logo-text-color"
          d="M501.69,48.78c-0.6,1.13-1.46,1.69-2.59,1.69c-0.86,0-1.98-0.6-3.34-1.83c-1.36-1.23-3.19-2.59-5.53-4.09c-2.32-1.49-5.22-2.87-8.71-4.13c-3.49-1.26-7.78-1.9-12.9-1.9c-5.11,0-9.61,0.73-13.49,2.19c-3.89,1.46-7.15,3.45-9.76,5.98c-2.63,2.53-4.62,5.45-5.98,8.77c-1.36,3.32-2.04,6.81-2.04,10.46c0,4.78,1.01,8.72,3.03,11.85c2.03,3.11,4.7,5.77,8.02,7.96c3.32,2.2,7.08,4.04,11.3,5.53c4.22,1.51,8.56,2.98,13,4.44c4.44,1.46,8.79,3.06,13,4.83c4.22,1.75,7.98,3.97,11.3,6.61c3.32,2.66,6,5.93,8.02,9.82c2.03,3.87,3.03,8.71,3.03,14.49c0,5.9-1.01,11.46-3.03,16.68c-2.03,5.2-4.96,9.74-8.82,13.59c-3.84,3.86-8.56,6.89-14.14,9.11c-5.58,2.24-11.96,3.34-19.13,3.34c-9.29,0-17.3-1.64-24.01-4.93c-6.7-3.29-12.58-7.78-17.62-13.49l2.79-4.39c0.79-0.99,1.72-1.49,2.79-1.49c0.6,0,1.36,0.41,2.29,1.2c0.94,0.79,2.06,1.78,3.39,2.93c1.33,1.17,2.92,2.43,4.78,3.79c1.86,1.36,4.02,2.61,6.47,3.78c2.46,1.17,5.29,2.14,8.48,2.93c3.18,0.79,6.79,1.2,10.85,1.2c5.58,0,10.57-0.84,14.95-2.55c4.38-1.69,8.09-3.99,11.11-6.92c3.02-2.92,5.33-6.39,6.92-10.41c1.59-4,2.38-8.32,2.38-12.89c0-4.99-1.01-9.08-3.03-12.3c-2.03-3.23-4.7-5.92-8.02-8.07c-3.31-2.16-7.08-3.96-11.3-5.43c-4.22-1.46-8.54-2.89-13-4.28c-4.44-1.39-8.77-2.95-12.99-4.69c-4.23-1.72-7.99-3.92-11.32-6.61s-6-6.05-8.01-10.07c-2.03-4-3.05-9.05-3.05-15.09c0-4.7,0.89-9.26,2.69-13.63c1.8-4.39,4.41-8.25,7.86-11.61s7.73-6.05,12.81-8.07c5.07-2.03,10.88-3.03,17.38-3.03c7.31,0,13.86,1.17,19.68,3.49c5.8,2.32,11.11,5.87,15.87,10.65L501.69,48.78z"
        />
        <path
          className="logo-text-color"
          d="M601,86.74c-0.34,0.32-0.65,0.62-0.94,0.84c-0.31,0.23-0.71,0.36-1.25,0.36c-0.66,0-1.57-0.45-2.69-1.35c-1.13-0.91-2.66-1.88-4.59-2.95c-1.93-1.05-4.33-2.04-7.21-2.93c-2.89-0.89-6.39-1.35-10.51-1.35c-5.72,0-10.78,1.02-15.19,3.05c-4.43,2.03-8.15,4.93-11.22,8.71c-3.05,3.79-5.35,8.37-6.92,13.75c-1.56,5.38-2.33,11.43-2.33,18.12c0,6.97,0.81,13.15,2.45,18.53c1.62,5.38,3.92,9.91,6.92,13.6c2.98,3.68,6.6,6.48,10.85,8.41s8.97,2.89,14.15,2.89c4.78,0,8.77-0.57,12-1.69c3.23-1.13,5.88-2.37,7.98-3.7c2.09-1.33,3.73-2.55,4.93-3.68c1.18-1.13,2.19-1.69,2.98-1.69s1.46,0.32,1.99,0.99l2.5,3.19c-1.54,1.98-3.45,3.84-5.79,5.58c-2.32,1.72-4.94,3.21-7.86,4.47c-2.92,1.26-6.1,2.24-9.52,2.93c-3.42,0.7-6.99,1.05-10.7,1.05c-6.32,0-12.08-1.15-17.28-3.44c-5.22-2.29-9.69-5.61-13.46-9.95c-3.74-4.36-6.66-9.68-8.75-16c-2.11-6.31-3.15-13.47-3.15-21.51c0-7.57,0.99-14.48,2.98-20.72c1.99-6.24,4.91-11.61,8.77-16.13c3.84-4.51,8.54-8.02,14.09-10.51c5.54-2.5,11.92-3.74,19.08-3.74c6.44,0,12.14,1.04,17.14,3.1c4.98,2.06,9.32,4.85,13.05,8.37L601,86.74z"
        />
        <path
          className="logo-text-color"
          d="M625.5,87.53c5.17-5.17,10.62-9.09,16.33-11.75c5.71-2.66,12.19-3.99,19.42-3.99c5.32,0,9.97,0.84,13.94,2.55c3.99,1.69,7.3,4.12,9.92,7.26c2.63,3.16,4.59,6.99,5.92,11.46c1.33,4.47,1.99,9.48,1.99,15v64.54h-3.78c-1.99,0-3.23-0.92-3.7-2.79l-1.39-12.74c-2.72,2.64-5.41,5.04-8.11,7.17s-5.5,3.92-8.43,5.38c-2.92,1.46-6.06,2.58-9.45,3.39c-3.39,0.79-7.12,1.18-11.15,1.18c-3.39,0-6.68-0.49-9.87-1.49c-3.18-0.99-6.01-2.51-8.51-4.57c-2.48-2.06-4.47-4.7-5.98-7.93c-1.49-3.23-2.24-7.08-2.24-11.61c0-4.18,1.2-8.06,3.58-11.66c2.4-3.58,6.14-6.7,11.27-9.35c5.11-2.66,11.67-4.78,19.66-6.37c8.01-1.6,17.62-2.53,28.84-2.79v-10.36c0-9.18-1.98-16.23-5.93-21.17c-3.94-4.94-9.81-7.43-17.57-7.43c-4.78,0-8.85,0.66-12.21,1.99c-3.36,1.33-6.19,2.79-8.51,4.39c-2.33,1.59-4.22,3.05-5.67,4.38c-1.46,1.33-2.69,1.99-3.7,1.99c-0.73,0-1.33-0.16-1.78-0.5c-0.47-0.32-0.88-0.76-1.2-1.3L625.5,87.53z M683.76,124.69c-9.44,0.32-17.56,1.07-24.35,2.24c-6.81,1.17-12.42,2.74-16.84,4.73c-4.41,1.99-7.68,4.36-9.81,7.12c-2.12,2.76-3.19,5.9-3.19,9.42c0,3.32,0.55,6.19,1.65,8.61c1.09,2.43,2.55,4.43,4.38,5.98c1.83,1.56,3.94,2.71,6.32,3.44c2.4,0.73,4.88,1.09,7.47,1.09c3.86,0,7.39-0.41,10.6-1.23c3.23-0.84,6.21-1.99,8.97-3.49c2.76-1.51,5.33-3.27,7.73-5.33c2.38-2.06,4.73-4.28,7.07-6.68V124.69z"
        />
        <path
          className="logo-text-color"
          d="M733.78,90.82c4.57-5.71,9.87-10.29,15.89-13.75c6-3.45,12.69-5.19,20.07-5.19c5.45,0,10.23,0.88,14.4,2.59c4.13,1.73,7.57,4.22,10.29,7.47c2.72,3.26,4.78,7.17,6.18,11.75s2.09,9.76,2.09,15.53v63.36h-9.55v-63.36c0-9.29-2.14-16.58-6.39-21.85c-4.25-5.29-10.72-7.93-19.42-7.93c-6.44,0-12.47,1.65-18.08,4.93c-5.61,3.29-10.6,7.86-15,13.7v74.51h-9.55v-99.1h5.27c1.67,0,2.63,0.83,2.89,2.48L733.78,90.82z"
        />
        <path
          className="logo-text-color"
          d="M831.48,87.53c5.17-5.17,10.62-9.09,16.33-11.75c5.71-2.66,12.19-3.99,19.42-3.99c5.32,0,9.97,0.84,13.94,2.55c3.99,1.69,7.3,4.12,9.92,7.26c2.63,3.16,4.59,6.99,5.92,11.46c1.33,4.47,1.99,9.48,1.99,15v64.54h-3.78c-1.99,0-3.23-0.92-3.7-2.79l-1.39-12.74c-2.72,2.64-5.41,5.04-8.11,7.17s-5.5,3.92-8.43,5.38c-2.92,1.46-6.06,2.58-9.45,3.39c-3.39,0.79-7.12,1.18-11.15,1.18c-3.39,0-6.68-0.49-9.87-1.49c-3.18-0.99-6.01-2.51-8.51-4.57c-2.48-2.06-4.47-4.7-5.98-7.93c-1.49-3.23-2.24-7.08-2.24-11.61c0-4.18,1.2-8.06,3.58-11.66c2.4-3.58,6.14-6.7,11.27-9.35c5.11-2.66,11.67-4.78,19.66-6.37c8.01-1.6,17.62-2.53,28.84-2.79v-10.36c0-9.18-1.98-16.23-5.93-21.17c-3.94-4.94-9.81-7.43-17.57-7.43c-4.78,0-8.85,0.66-12.21,1.99c-3.36,1.33-6.19,2.79-8.51,4.39c-2.33,1.59-4.22,3.05-5.67,4.38c-1.46,1.33-2.69,1.99-3.7,1.99c-0.73,0-1.33-0.16-1.78-0.5c-0.47-0.32-0.88-0.76-1.2-1.3L831.48,87.53z M889.75,124.69c-9.44,0.32-17.56,1.07-24.35,2.24c-6.81,1.17-12.42,2.74-16.84,4.73c-4.41,1.99-7.68,4.36-9.81,7.12c-2.12,2.76-3.19,5.9-3.19,9.42c0,3.32,0.55,6.19,1.65,8.61c1.09,2.43,2.55,4.43,4.38,5.98c1.83,1.56,3.94,2.71,6.32,3.44c2.4,0.73,4.88,1.09,7.47,1.09c3.86,0,7.39-0.41,10.6-1.23c3.23-0.84,6.21-1.99,8.97-3.49c2.76-1.51,5.33-3.27,7.73-5.33c2.38-2.06,4.73-4.28,7.07-6.68V124.69z"
        />
        <path
          className="logo-text-color"
          d="M941.14,27.66v144.93h-9.57V27.66H941.14z"
        />
        <path
          className="logo-text-color"
          d="M1054.79,73.49l-56.97,130.98c-0.41,0.92-0.91,1.7-1.54,2.29c-0.63,0.6-1.54,0.91-2.74,0.91h-6.78l17.43-38.75l-41.92-95.42h7.86c0.99,0,1.8,0.24,2.38,0.75c0.6,0.5,1.01,1.04,1.2,1.64l33.36,77.1c0.41,0.99,0.75,2.01,1.05,3.03c0.29,1.04,0.58,2.08,0.84,3.15c0.34-1.07,0.68-2.11,1.05-3.15c0.36-1.02,0.75-2.04,1.13-3.03l32.97-77.1c0.32-0.73,0.79-1.31,1.39-1.73c0.6-0.44,1.26-0.65,1.99-0.65H1054.79z"
        />
        <path
          className="logo-text-color"
          d="M1156.89,172.59h-9.56V83.64h-57.37v67.25c0,2.72,0.34,5.06,1.04,7.02c0.7,1.96,1.67,3.57,2.89,4.83c1.23,1.26,2.68,2.19,4.34,2.79c1.65,0.6,3.45,0.89,5.38,0.89c2.38,0,4.44-0.34,6.16-1.04c1.73-0.7,3.23-1.46,4.49-2.3c1.26-0.83,2.29-1.59,3.08-2.29c0.81-0.7,1.43-1.05,1.9-1.05c0.54,0,1.07,0.34,1.59,1.01l2.59,4.18c-2.53,2.79-5.72,5.03-9.61,6.73c-3.87,1.69-7.91,2.53-12.09,2.53c-6.71,0-11.95-1.85-15.69-5.58c-3.74-3.71-5.63-9.45-5.63-17.23V83.64h-14.25c-0.73,0-1.33-0.19-1.78-0.58c-0.47-0.41-0.7-0.97-0.7-1.7v-3.68l16.93-1.2l2.4-35.46c0.13-0.6,0.39-1.13,0.79-1.6c0.39-0.45,0.96-0.7,1.69-0.7h4.49v37.85h66.94V172.59z M1161.27,38.13c0,1.18-0.24,2.3-0.75,3.32c-0.5,1.04-1.17,1.95-1.99,2.74c-0.83,0.81-1.78,1.44-2.84,1.9c-1.07,0.47-2.19,0.7-3.39,0.7s-2.32-0.23-3.39-0.7c-1.07-0.45-1.99-1.09-2.79-1.9c-0.79-0.79-1.43-1.7-1.9-2.74c-0.45-1.02-0.7-2.14-0.7-3.32c0-1.2,0.24-2.35,0.7-3.44c0.47-1.1,1.1-2.04,1.9-2.84c0.79-0.81,1.72-1.44,2.79-1.9c1.07-0.47,2.19-0.7,3.39-0.7s2.32,0.23,3.39,0.7c1.05,0.45,2.01,1.09,2.84,1.9c0.83,0.79,1.49,1.73,1.99,2.84C1161.02,35.78,1161.27,36.93,1161.27,38.13z"
        />
        <path
          className="logo-text-color"
          d="M1257.29,86.74c-0.34,0.32-0.65,0.62-0.94,0.84c-0.31,0.23-0.71,0.36-1.25,0.36c-0.66,0-1.57-0.45-2.69-1.35c-1.13-0.91-2.66-1.88-4.59-2.95c-1.93-1.05-4.33-2.04-7.21-2.93c-2.89-0.89-6.39-1.35-10.51-1.35c-5.72,0-10.78,1.02-15.19,3.05c-4.43,2.03-8.15,4.93-11.22,8.71c-3.05,3.79-5.35,8.37-6.92,13.75c-1.56,5.38-2.33,11.43-2.33,18.12c0,6.97,0.81,13.15,2.45,18.53c1.62,5.38,3.92,9.91,6.92,13.6c2.98,3.68,6.6,6.48,10.85,8.41c4.25,1.93,8.97,2.89,14.15,2.89c4.78,0,8.77-0.57,12-1.69c3.23-1.13,5.88-2.37,7.98-3.7c2.09-1.33,3.73-2.55,4.93-3.68c1.18-1.13,2.19-1.69,2.98-1.69c0.79,0,1.46,0.32,1.99,0.99l2.5,3.19c-1.54,1.98-3.45,3.84-5.79,5.58c-2.32,1.72-4.94,3.21-7.86,4.47c-2.92,1.26-6.1,2.24-9.52,2.93c-3.42,0.7-6.99,1.05-10.7,1.05c-6.32,0-12.08-1.15-17.28-3.44c-5.22-2.29-9.69-5.61-13.46-9.95c-3.74-4.36-6.66-9.68-8.75-16c-2.11-6.31-3.15-13.47-3.15-21.51c0-7.57,0.99-14.48,2.98-20.72c1.99-6.24,4.91-11.61,8.77-16.13c3.84-4.51,8.54-8.02,14.09-10.51c5.54-2.5,11.92-3.74,19.08-3.74c6.44,0,12.14,1.04,17.14,3.1c4.98,2.06,9.32,4.85,13.05,8.37L1257.29,86.74z"
        />
        <path
          className="logo-text-color"
          d="M1339.06,85.73c-0.52,0.94-1.28,1.39-2.29,1.39c-0.73,0-1.65-0.41-2.79-1.23c-1.13-0.84-2.64-1.77-4.54-2.79c-1.88-1.04-4.23-1.96-7.02-2.79c-2.79-0.84-6.18-1.25-10.15-1.25c-3.6,0-6.86,0.52-9.82,1.54c-2.95,1.04-5.46,2.42-7.55,4.13c-2.11,1.73-3.71,3.73-4.83,6.03c-1.13,2.29-1.7,4.7-1.7,7.21c0,3.13,0.79,5.71,2.4,7.77c1.59,2.06,3.66,3.83,6.23,5.29c2.55,1.46,5.48,2.72,8.8,3.78c3.32,1.07,6.7,2.14,10.12,3.19c3.42,1.07,6.79,2.24,10.12,3.53c3.31,1.3,6.26,2.9,8.8,4.83c2.56,1.93,4.64,4.28,6.23,7.07c1.6,2.79,2.4,6.18,2.4,10.16c0,4.31-0.76,8.33-2.3,12.06c-1.52,3.71-3.76,6.94-6.71,9.65c-2.97,2.72-6.6,4.9-10.91,6.48s-9.26,2.38-14.85,2.38c-6.97,0-12.97-1.1-18.03-3.34c-5.04-2.22-9.55-5.12-13.54-8.71l2.19-3.39c0.32-0.53,0.71-0.92,1.15-1.2c0.42-0.26,1.01-0.39,1.73-0.39c0.86,0,1.91,0.53,3.15,1.59c1.22,1.07,2.87,2.2,4.93,3.44c2.06,1.23,4.59,2.37,7.62,3.44c3.02,1.05,6.76,1.59,11.2,1.59c4.18,0,7.86-0.58,11.06-1.73c3.19-1.17,5.84-2.74,7.98-4.73c2.12-1.99,3.73-4.33,4.83-7.02c1.09-2.69,1.64-5.53,1.64-8.53c0-3.31-0.79-6.06-2.4-8.27c-1.59-2.19-3.68-4.04-6.27-5.58c-2.58-1.52-5.53-2.82-8.8-3.87c-3.29-1.07-6.66-2.12-10.12-3.19c-3.45-1.05-6.83-2.22-10.12-3.49c-3.27-1.26-6.23-2.85-8.8-4.78c-2.59-1.93-4.69-4.26-6.27-7.02c-1.6-2.76-2.4-6.19-2.4-10.31c0-3.52,0.76-6.91,2.29-10.16c1.54-3.24,3.71-6.1,6.53-8.56c2.82-2.45,6.26-4.41,10.31-5.87c4.05-1.46,8.59-2.2,13.65-2.2c6.03,0,11.4,0.88,16.08,2.59c4.69,1.73,8.95,4.38,12.79,7.98L1339.06,85.73z"
        />
        <path
          className="logo-text-color"
          d="M1438.07,172.59h-10.26V31.65h10.26V172.59z"
        />
        <path
          className="logo-text-color"
          d="M1482.7,90.82c4.57-5.71,9.87-10.29,15.89-13.75c6-3.45,12.69-5.19,20.07-5.19c5.45,0,10.23,0.88,14.4,2.59c4.13,1.73,7.57,4.22,10.29,7.47c2.72,3.26,4.78,7.17,6.18,11.75c1.39,4.59,2.09,9.76,2.09,15.53v63.36h-9.55v-63.36c0-9.29-2.14-16.58-6.39-21.85c-4.25-5.29-10.72-7.93-19.42-7.93c-6.44,0-12.47,1.65-18.08,4.93c-5.61,3.29-10.6,7.86-15,13.7v74.51h-9.55v-99.1h5.27c1.67,0,2.63,0.83,2.89,2.48L1482.7,90.82z"
        />
        <path
          className="logo-text-color"
          d="M1649.83,86.74c-0.34,0.32-0.65,0.62-0.94,0.84c-0.31,0.23-0.71,0.36-1.25,0.36c-0.66,0-1.57-0.45-2.69-1.35c-1.13-0.91-2.66-1.88-4.59-2.95c-1.93-1.05-4.33-2.04-7.21-2.93c-2.89-0.89-6.39-1.35-10.51-1.35c-5.72,0-10.78,1.02-15.19,3.05c-4.43,2.03-8.15,4.93-11.22,8.71c-3.05,3.79-5.35,8.37-6.92,13.75c-1.56,5.38-2.33,11.43-2.33,18.12c0,6.97,0.81,13.15,2.45,18.53c1.62,5.38,3.92,9.91,6.92,13.6c2.98,3.68,6.6,6.48,10.85,8.41c4.25,1.93,8.97,2.89,14.15,2.89c4.78,0,8.77-0.57,12-1.69c3.23-1.13,5.88-2.37,7.98-3.7c2.09-1.33,3.73-2.55,4.93-3.68c1.18-1.13,2.19-1.69,2.98-1.69c0.79,0,1.46,0.32,1.99,0.99l2.5,3.19c-1.54,1.98-3.45,3.84-5.79,5.58c-2.32,1.72-4.94,3.21-7.86,4.47c-2.92,1.26-6.1,2.24-9.52,2.93c-3.42,0.7-6.99,1.05-10.7,1.05c-6.32,0-12.08-1.15-17.28-3.44c-5.22-2.29-9.69-5.61-13.46-9.95c-3.74-4.36-6.66-9.68-8.75-16c-2.11-6.31-3.15-13.47-3.15-21.51c0-7.57,0.99-14.48,2.98-20.72c1.99-6.24,4.91-11.61,8.77-16.13c3.84-4.51,8.54-8.02,14.09-10.51c5.54-2.5,11.92-3.74,19.08-3.74c6.44,0,12.14,1.04,17.14,3.1c4.98,2.06,9.32,4.85,13.05,8.37L1649.83,86.74z"
        />
        <path
          className="logo-text-color"
          d="M1674.82,164.93c0-1.26,0.24-2.46,0.7-3.58c0.47-1.13,1.12-2.11,1.95-2.95c0.83-0.83,1.8-1.49,2.89-1.98c1.1-0.5,2.32-0.75,3.65-0.75c1.25,0,2.45,0.24,3.58,0.75c1.12,0.49,2.11,1.15,2.93,1.98c0.83,0.84,1.49,1.82,1.99,2.95c0.5,1.12,0.75,2.32,0.75,3.58c0,1.33-0.24,2.55-0.75,3.63c-0.5,1.1-1.17,2.06-1.99,2.89c-0.83,0.84-1.82,1.48-2.93,1.95c-1.13,0.47-2.33,0.7-3.58,0.7c-2.59,0-4.77-0.88-6.53-2.64C1675.72,169.69,1674.82,167.52,1674.82,164.93z"
        />
      </g>
    </svg>
  </Logo>
);
