// Action Templates
import get from './templates/get';
// Action Calls
export const types = {
  available: {
    GET: 'roles/AVAILABLE:GET',
    RESET: 'roles/AVAILABLE:RESET',
    CANCEL: 'roles/AVAILABLE:CANCEL',
    FAILED: 'roles/AVAILABLE:FAILED',
    SUCCESS: 'roles/AVAILABLE:SUCCESS'
  },
  permissions: {
    available: {
      GET: 'roles/permissions/AVAILABLE:GET',
      RESET: 'roles/permissions/AVAILABLE:RESET',
      CANCEL: 'roles/permissions/AVAILABLE:CANCEL',
      FAILED: 'roles/permissions/AVAILABLE:FAILED',
      SUCCESS: 'roles/permissions/AVAILABLE:SUCCESS'
    }
  }
};
// Deployment Functions
export default {
  types,
  available:   get(types.available),
  permissions: {
    available: get(types.permissions.available)
  }
};
