// NPM Modules
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Styled Components
import { ArrayIcon } from './style';
// Material-UI
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
/**
 * HeatmapInfoPanel => ArrayInfo Component
 */
class ArrayInfo extends Component {
  /**
   * Component PropTypes
   */
  static propTypes = {
    array: PropTypes.object
  };
  /**
   * Component Default PropTypes
   */
  static defaultProps = {
    array: {}
  };
  /**
   * Component State
   */
  state = {
    collapse: true
  };
  /**
   * Component Render
   * @returns {Component}
   */
  render() {
    const { array } = this.props;
    return (
      <List subheader={<ListSubheader>Array Info</ListSubheader>}>
        <ListItem>
          <ListItemIcon>
            <ArrayIcon iconcolor={array.color} />
          </ListItemIcon>
          <ListItemText
            primary={array.array_name}
            secondary={
              <Link component={RouterLink} to={`../array/${array.array_key}`}>
                {array.array_key}
              </Link>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() =>
                this.setState({
                  collapse: !this.state.collapse
                })
              }
            >
              {this.state.collapse ? (
                <ExpandMoreRoundedIcon />
              ) : (
                <ExpandLessRoundedIcon />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={!this.state.collapse} timeout="auto" unmountOnExit>
          <ListItem dense>
            <ListItemText
              inset
              primary="Deployment"
              secondary={
                <Link
                  component={RouterLink}
                  to={`../deployment/${array.deployment_key}`}
                >
                  {array.deployment_name}
                </Link>
              }
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              inset
              primary="Description"
              secondary={array.description}
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              inset
              primary="Bounding Box Size X"
              secondary={array.bounding_box_size_x}
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              inset
              primary="Bounding Box Size Y"
              secondary={array.bounding_box_size_y}
            />
          </ListItem>
          <ListItem dense>
            <ListItemText inset primary="Region" secondary={array.region} />
          </ListItem>
        </Collapse>
      </List>
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default ArrayInfo;
