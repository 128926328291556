// Actions
import get from './templates/get';
import post from './templates/post';
import dep, { types } from '../actions/deployment';

const URL = '/api/deployment';

export default {
  //         action$    type(action$, actionHandler, actionType,   URL)
  info:      action$ =>  get(action$, dep.info,              types.info,              `${URL}/:deployment_key`),
  list:      action$ =>  get(action$, dep.list,              types.list,              `${URL}/list?page&page_size&search&status`),
  create:    action$ => post(action$, dep.create,            types.create,            `${URL}/create`),
  edit:      action$ => post(action$, dep.edit,              types.edit,              `${URL}/edit`),
  remove:    action$ => post(action$, dep.remove,            types.remove,            `${URL}/delete`),
  occupancy: {
    list:    action$ =>  get(action$, dep.occupancy.list,    types.occupancy.list,    `${URL}/occupancy-tracker/list?page&page_size&search`),
    tracker: action$ =>  get(action$, dep.occupancy.tracker, types.occupancy.tracker, `${URL}/:deployment_key/occupancy-tracker/:occupancy_tracker_uuid`)
  },
  basestation: {
    list:    action$ =>  get(action$, dep.basestation.list,  types.basestation.list,  `${URL}/:deployment_key/basestation/list?page&page_size&array_key`),
  }
};
