// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../actions/organization';
// Reducer Template
import get from './templates/get';
import post from './templates/post';
import list from './templates/list';
// sub reducers
import theme from './organization/theme';
// Reducer
export default combineReducers({
  // GET REQUESTS
  info:     (s, a) =>  get(s, a, types.info),
  list:     (s, a) => list(s, a, types.list),
  overview: (s, a) => list(s, a, types.overview),
  theme,
  // POST REQUESTS
  edit:     (s, a) => post(s, a, types.edit),
  create:   (s, a) => post(s, a, types.create),
  delete:   (s, a) => post(s, a, types.delete),
  member: combineReducers({
    // GET REQUESTS
    info:   (s, a) =>  get(s, a, types.member.info),
    list:   (s, a) => list(s, a, types.member.list),
    // POST REQUESTS
    add:    (s, a) => post(s, a, types.member.add),
    edit:   (s, a) => post(s, a, types.member.edit),
    remove: (s, a) => post(s, a, types.member.remove)
  })
});
