// NPM Modules
import styled from 'styled-components';
// Styled Components
export const HeatmapsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* Subtract the header height */
  min-height: calc(100vh - 56px);
  ${({ isMobile }) => (isMobile ? `padding-bottom: 50vh;` : null)}
`;
export const Left = styled.div`
  flex-grow: 1;
  overflow-x: auto;
`;
export const Right = styled.div`
  flex: 0 0 0;
  display: flex;
  height: calc(100vh - 56px);
  width: 400px;
  position: sticky;
  top: 56px;
`;
