/**
 * Action Call Types
 * @readonly
 * @enum {string}
 */
export const types = {
  ADD: 'dataPointSystem/dataPoint:ADD',
  UPDATE: 'dataPointSystem/dataPoint:UPDATE',
  REMOVE: 'dataPointSystem/dataPoint:REMVOE'
};
/**
 * Enum for Data Point System => Data Point Types
 * Use this enum to know which setting struct to follow
 * @readonly
 * @enum {string}
 */
export const DataPointType = {
  ArrayHeatmap: 'ArrayHeatmap',
  ArrayVisits: 'ArrayVisits',
  ArrayEntrances: 'ArrayEntrances',
  DeploymentOccupancy: 'DeploymentOccupancy',
  ZoneVisits: 'ZoneVisits',
  ZoneEntrances: 'ZoneEntrances'
};
/**
 * Structures for dataPoints
 * @readonly
 */
export const DataPointStructs = {
  ArrayEntrances: {
    color: [0, 0, 0],
    parent: null,
    label: '',
    array_key: null,
    interval: null,
    start: null,
    end: null,
    timezone: null
  },
  ArrayHeatmap: {
    color: [0, 0, 0],
    parent: null,
    label: '',
    array_key: null,
    start: null,
    end: null,
    timezone: null,
    toggleDuration: false,
    toggleLogScale: false,
    toggleZones: false,
    toggleGlobalSync: true
  },
  ArrayVisits: {
    color: [0, 0, 0],
    parent: null,
    label: '',
    array_key: null,
    interval: null,
    start: null,
    end: null,
    timezone: null,
    threshold: null
  },
  ZoneEntrances: {
    color: [0, 0, 0],
    parent: null,
    label: '',
    zone_key: null,
    interval: null,
    start: null,
    end: null,
    timezone: null
  },
  ZoneVisits: {
    color: [0, 0, 0],
    parent: null,
    label: '',
    zone_key: null,
    interval: null,
    start: null,
    end: null,
    timezone: null,
    threshold: null
  }
};
// prettier-ignore
export default {
  types,
  DataPointType,
  DataPointStructs,
  // Callable Action Functions
  add:    payload => ({ type: types.ADD,    payload: { ...payload } }),
  update: payload => ({ type: types.UPDATE, payload: { ...payload } }),
  remove: payload => ({ type: types.REMOVE, payload: { ...payload } }),
};
