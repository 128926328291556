// NPM Modules
import ExcelJS from 'exceljs';

// NOTE: mimetypes: https://www.iana.org/assignments/media-types/media-types.xhtml#text
const FILE_TYPES = {
  xlsx: {
    extention: 'xlsx',
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  csv: {
    extention: 'csv',
    mimetype: 'text/csv'
  }
};

/**
 *
 * @param {array} results
 * @param {string} zone_key
 * @param {{extention:string, mimetype:string}} fileType
 * @returns
 */
function generateRowsAndColumns(results, fileType, zone_key) {
  let rows = [];
  let columns = [];

  //Add Columns and format
  Object.entries(results[0]).forEach(([key]) => {
    switch (key.toLowerCase()) {
      case 'date':
        columns.splice(0, 0, { name: 'Date Time', key: key });
        break;
      case 'c':
        columns.push({ name: 'Count', key: key });
        break;
      case 'durms':
        columns.push({ name: 'Duration (MS)', key: key });
        break;
      default:
        columns.push({
          name: key.charAt(0).toUpperCase() + key.slice(1),
          key: key
        });
    }
  });
  //Add Zone Key Column for csv files
  if (fileType.extention == 'csv') {
    columns.splice(0, 0, { name: 'Zone Key', key: 'zone_key' });
  }

  //Add Rows and Format
  results.forEach(result => {
    let row = [];
    Object.entries(result).forEach(([key, value]) => {
      if (key == 'date') {
        row.splice(
          0,
          0,
          Intl.DateTimeFormat([], {
            dateStyle: 'short',
            timeStyle: 'medium'
          }).format(new Date(value))
        );
      } else {
        row.push(value);
      }
    });
    //Add zonekey in rows for csv files
    if (fileType.extention == 'csv') {
      row.splice(0, 0, zone_key);
    }
    rows.push(row);
  });
  const data = { rows, columns };
  return data;
}

/**
 *
 * @param {object} data
 * @param {array} dataPoints
 * @param {string} fileType
 * @param {string} fileName
 */
export default function fileDataExporter(data, dataPoints, fileType, fileName) {
  const ft = FILE_TYPES[fileType];
  const workbook = new ExcelJS.Workbook();

  switch (ft.extention) {
    case 'xlsx':
      //Loop through the different Entrances to create seperate pages of data
      dataPoints.forEach(datapoint => {
        const sheet = workbook.addWorksheet(
          `(${datapoint.zone_key}) ${datapoint.label}`
        );

        let rowColumnData = generateRowsAndColumns(
          data[datapoint.zone_key].results,
          ft
        );

        //Add rows and columns in a formatted table
        sheet.addTable({
          name: `data`,
          ref: 'A1',
          headerRow: true,
          columns: rowColumnData.columns,
          rows: rowColumnData.rows
        });
        //Width isn't changing when specified prior to addition to the table
        //We will change it after here
        sheet.columns.forEach(function (column, i) {
          var maxLength = 0;
          column['eachCell']({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });
          column.width = maxLength < 10 ? 10 : maxLength;
        });
      });
      break;
    default:
      // csv
      //CSV file preperation requires all data in one sheet, so we have one set of columns and rows
      // and loop through the different zones adding their data into it
      const sheet = workbook.addWorksheet('fileName');
      let rowColumnData = { columns: {}, rows: [] };

      dataPoints.forEach(datapoint => {
        let tempData = generateRowsAndColumns(
          data[datapoint.zone_key].results,
          ft,
          datapoint.zone_key
        );
        rowColumnData.columns = tempData.columns;
        rowColumnData.rows = rowColumnData.rows.concat(tempData.rows);
      });

      //Add rows and columns in a formatted table
      sheet.addTable({
        name: 'data',
        ref: 'A1',
        headerRow: true,
        columns: rowColumnData.columns,
        rows: rowColumnData.rows
      });
      break;
  }

  workbook[ft.extention]
    .writeBuffer()
    .then(ab => {
      const file = new File([ab], `${fileName}.${ft.extention}`, {
        type: ft.mimetype
      });

      const link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(file));
      link.setAttribute('download', file.name);
      link.click();
    })
    .catch(error => {
      console.log(error);
    });
}
