// NPM Modules
import merge from 'deepmerge';
import { createMuiTheme } from '@material-ui/core/styles';
// UI Theme Systems
import themes from './themes';

// Database Color Stuff
class Color {
  constructor(color) {
    this.color = color;
  }

  toRgba() {
    return `rgba(${this.color.toString()})`;
  }
}

function dbColorsToArrayOfColors(colors) {
  return colors
    .split(';')
    .map(c => new Color(c.split(',').map(n => parseFloat(n, 10))));
}

function convertColorsToObject(theme) {
  let colors = theme.tempColors ? theme.tempColors : theme.colors;
  const [primary, secondary] = dbColorsToArrayOfColors(colors);

  return {
    primary: primary.toRgba(),
    secondary: secondary.toRgba()
  };
}

/**
 * @param {Object} theme Organization Theme
 * @param {Object} ui Redux UI state
 */
export default (theme, ui) => {
  const prefersColorScheme =
    typeof window === 'undefined'
      ? 'nordLight'
      : window.matchMedia('(prefers-color-scheme: light)').matches
      ? 'nordLight'
      : 'nord';
  const type = ui.scheme === 'auto' ? prefersColorScheme : ui.scheme;
  const colors = convertColorsToObject(theme);

  return createMuiTheme(
    merge(
      {
        logourl: theme.logourl,
        spacing: 4,
        shape: {
          borderRadius: 8
        },
        typography: {
          useNextVariants: true,
          fontFamily: ['Nunito', 'sans-serif'],
          body1: {
            lineHeight: 'normal'
          },
          body2: {
            lineHeight: 'normal'
          }
        },
        // Defaults - the ui themes all overide everything but barHeight
        navigation: {
          sideMenu: {},
          topBar: {
            barHeight: 56,
            text: '#FFF',
            background: '#333'
          },
          mobile: {}
        },
        palette: {
          primary: {
            main: colors.primary
          },
          secondary: {
            main: colors.secondary
          }
        },
        overrides: {
          MuiAvatar: {
            colorDefault: {
              backgroundColor: colors.primary
            }
          },
          MuiInputLabel: {
            filled: {
              color: '#4C566A',
              fontSize: '1.25rem'
            }
          },
          MuiFilledInput: {
            root: {
              color: '#2E3440',
              borderRadius: '8px',
              backgroundColor: '#ECEFF4',
              '&:hover': {
                backgroundColor: '#D8DEE9'
              },
              '&$focused': {
                backgroundColor: '#ECEFF4'
              }
            },
            input: {
              '&:-webkit-autofill': {
                'caret-color': '#2E3440',
                '-webkit-text-fill-color': '#2E3440',
                '-webkit-box-shadow': '0 0 0 50px #ECEFF4 inset',
                borderRadius: '8px'
              }
            }
          },
          MuiButton: {
            contained: {
              color: '#2E3440',
              backgroundColor: '#ECEFF4',
              '&:hover': {
                backgroundColor: '#D8DEE9'
              },
              '&:disabled': {
                color: '#4C566A',
                backgroundColor: '#3B4252'
              }
            }
          },
          MuiTouchRipple: {
            child: {
              backgroundColor: '#4C566A'
            }
          }
        }
      },
      themes[type]
    )
  );
};
