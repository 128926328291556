// Module
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
// Styled Components
export const Markdown = styled(ReactMarkdown)`
  h1,
  h2,
  p {
    padding: 8px 0;
  }

  p {
    text-align: justify;
  }
`;
