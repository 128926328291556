// NPM Modules
import { combineEpics } from 'redux-observable';

// Epics
import user from './user';
import zone from './zone';
import array from './array';
import roles from './roles';
import users from './users';
import aggregate from './aggregate';
import deployment from './deployment';
import organization from './organization';
import authentication from './authentication';

// Combine all the object epics into just function values for combineEpic to read.
const combine = (...epics) =>
  epics
    .map(epic =>
      typeof epic === 'object' ? combine(...Object.values(epic)) : epic
    )
    .flat();

export default combineEpics(
  ...combine(
    user,
    zone,
    array,
    roles,
    users,
    aggregate,
    deployment,
    organization,
    authentication
  )
);
