// NPM Modules
import React, { Component } from 'react';
import * as msal from '@azure/msal-browser';
import LogRocket from 'logrocket';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Link as RouterLink } from 'react-router-dom';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Components
import Logo from './Logo';
import Hexagons from './Hexagons';
// Styled Components
import { Container, LoginColumn, Link } from './style';
// Material-UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// Actions
import user from '../../redux/actions/user';
// Social Icons
import GoogleIcon from './GoogleIcon';
import MicrosoftIcon from './MicrosoftIcon';
// Social Client Ids
const SOCIAL_CLIENT_IDS = {
  MICROSOFT: '96b47667-63a7-4044-9458-4cb6b0d51ddd',
  GOOGLE:
    '796482957055-c76pkrnkle7kocsm8l7r2pdt3haff5s8.apps.googleusercontent.com'
};
/**
 * Login Component
 */
class Login extends Component {
  /**
   * MSAL instance for Microsoft SSO
   */
  msalInstance = new msal.PublicClientApplication({
    auth: { clientId: SOCIAL_CLIENT_IDS.MICROSOFT }
  });
  /**
   * Dom Ref for Google SSO button
   */
  googleSignIn = React.createRef();
  /**
   * state
   * Component state.
   */
  state = {
    username: '',
    password: '',
    disabledGoogleSSO: true
  };
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.handleGoogleSSO();
  }
  /**
   * componentDidUpdate
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      if (this.props.status === 'failed') {
        this.props.enqueueSnackbar(
          `Failed to login, invalid email or password.`,
          { variant: 'error' }
        );
      }
    }
  }
  /**
   * Handle Microsoft SSO popup
   */
  handleMicrosoftSSO = () => {
    this.msalInstance
      .loginPopup({
        scopes: ['User.Read'],
        redirectUri:
          'https://query.scanalyticsinc.com/auth/complete/microsoft-graph/'
      })
      .then(response => {
        console.log(response);
      })
      .catch(response => {
        console.error(response);
      });
  };
  /**
   * Helper func to wait for
   * @param {string} property window property to wait for
   * @param {number} timeout number of miliseconds till error is thrown
   * @returns {Promise}
   */
  waitForWindowProperty = (property, timeout) =>
    new Promise((resolve, reject) => {
      const STEP = 50;
      let acc = 0;
      const internval = setInterval(() => {
        if (window.hasOwnProperty(property)) {
          clearInterval(internval);
          resolve(window[property]);
        }
        if (acc >= timeout) {
          clearInterval(internval);
          reject('Unable to find window');
        }
        acc += STEP;
      }, STEP);
    });
  /**
   * Sets up Google SSO
   */
  handleGoogleSSO() {
    this.waitForWindowProperty('gapi', 1000)
      .then(gapi => {
        this.setState({ disabledGoogleSSO: false });

        gapi.load('auth2', () => {
          const GoogleAuth = gapi.auth2.init({
            client_id: SOCIAL_CLIENT_IDS.GOOGLE,
            scope: 'profile email'
          });

          GoogleAuth.then(auth2 => {
            auth2.attachClickHandler(
              this.googleSignIn.current,
              {},
              googleUser => {
                const { access_token } = googleUser.getAuthResponse();
                this.props.loginSocial(access_token);
              },
              error => {
                throw new Error(error);
              }
            );
          }).catch(error => {
            throw new Error(error);
          });
        });
      })
      .catch(error => {
        console.log('Issue connecting to Google API. Disabled Google SSO.');
      });
  }
  /**
   * handleChange
   * Updates the component state values with the input values from the form.
   * @param {object} event onchange event
   */
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * handleSubmit
   * Dispatches a login post action.
   * @param {object} event onsubmit event
   */
  handleSubmit = event => {
    event.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    if (
      (this.props.status === 'server' || this.props.status === 'success') &&
      this.props.info &&
      !this.props.error
    ) {
      const search = this.props.history.location.search;
      const location = search.includes('?redirect_to=')
        ? search.substring(13)
        : '/organization/list';

      // LogRocket Intergration
      // if (process.env.NODE_ENV === 'production') {
      //   LogRocket.identify(this.props.info.email, {
      //     name: `${this.props.info.first_name} ${this.props.info.last_name}`,
      //     email: this.props.info.email
      //   });
      // }

      return <Redirect to={location} />;
    }

    return (
      <Container>
        <LoginColumn fontSize="1.25rem">
          <div
            style={{
              zIndex: 2,
              width: '100%',
              height: '100%',
              position: 'relative'
            }}
          >
            <Logo />
            <form onSubmit={this.handleSubmit}>
              <Box fontSize="1.25rem" marginBottom="24px">
                {props => (
                  <React.Fragment>
                    {/* TODO: Figure out Microsoft SSO */}
                    {/* <Button
                      {...props}
                      disableElevation
                      startIcon={<MicrosoftIcon />}
                      variant="contained"
                      fullWidth
                      color="default"
                      onClick={this.handleMicrosoftSSO}
                    >
                      Sign in with Microsoft
                    </Button> */}
                    <Button
                      {...props}
                      disableElevation
                      startIcon={<GoogleIcon />}
                      variant="contained"
                      fullWidth
                      color="default"
                      ref={this.googleSignIn}
                      id="google-sign-btn"
                      disabled={this.state.disabledGoogleSSO}
                    >
                      Sign in with Google
                    </Button>
                    <Box {...props} textAlign="center">
                      LOGIN WITH EMAIL
                    </Box>
                    <TextField
                      {...props}
                      label="Email"
                      type="email"
                      name="username"
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      onChange={this.handleChange}
                    />
                    <TextField
                      {...props}
                      label="Password"
                      type="password"
                      name="password"
                      size="small"
                      fullWidth
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      onChange={this.handleChange}
                    />
                  </React.Fragment>
                )}
              </Box>
              <Box fontSize="1rem" textAlign="right" margin="-12px 0">
                <Link component={RouterLink} to="/accounts/password-reset">
                  Forgot password?
                </Link>
              </Box>
              <input type="submit" style={{ display: 'none' }} />
              <Box fontSize="1.25rem" marginTop="24px">
                {props => (
                  <Button
                    {...props}
                    disableElevation
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Login
                  </Button>
                )}
              </Box>
            </form>
            <Box fontSize="1rem" textAlign="center" marginTop="12px">
              <Link component={RouterLink} to="/terms-of-service">
                Terms Of Service
              </Link>
              {' | '}
              <Link component={RouterLink} to="/privacy-policy">
                Privacy Policy
              </Link>
            </Box>
          </div>
          <Hexagons />
        </LoginColumn>
      </Container>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      info: state.user.info.info,
      error: state.user.info.error,
      status: state.user.info.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        login: user.login.post,
        loginSocial: user.loginSocial.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  Login.mapStateToProps,
  Login.mapDispatchToProps
)(withSnackbar(Login));
