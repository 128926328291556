import { Items } from '../Cake';
import { util } from '../Cake';

function lineIntersectsLine(lineA, lineB) {
  let a = lineA[0][0],
    b = lineA[0][1],
    c = lineA[1][0],
    d = lineA[1][1],
    p = lineB[0][0],
    q = lineB[0][1],
    r = lineB[1][0],
    s = lineB[1][1],
    det,
    gamma,
    lambda;

  det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
}

class ZonePolygon extends Items.Polygon {
  constructor(points = [], options = {}) {
    super(0, 0, points, options);
    this.outline = options.hasOwnProperty('outline') ? options.outline : false;
    this.closePath = options.hasOwnProperty('closePath')
      ? options.closePath
      : true;
    this.renderStyle = options.hasOwnProperty('renderStyle')
      ? options.renderStyle
      : 'default';
  }

  heatmapStyle(context) {
    const canvas = util.offscreenCanvas(
      context.canvas.width,
      context.canvas.height
    );
    const ctx = canvas.getContext('2d');
    // Inital Settings
    ctx.globalCompositeOperation = 'source-over';
    ctx.strokeStyle = this.style.stroke;
    ctx.fillStyle = this.style.fill;
    ctx.lineWidth = this.style.strokeWidth;
    ctx.lineJoin = 'round';
    ctx.globalAlpha = 1;
    ctx.translate(this.x, this.y);

    // Render Zones
    ctx.beginPath();
    this.points.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point[0], point[1]);
      } else {
        if (this.closePath && index === this.points.length - 1) {
          ctx.closePath();
        } else {
          ctx.lineTo(point[0], point[1]);
        }
      }
    });

    // Stroke Outside
    if (this.outline) {
      ctx.strokeStyle = '#000';
      ctx.stroke();
      ctx.strokeStyle = this.style.stroke;
      ctx.lineWidth = this.style.strokeWidth - 0.05 * this.style.strokeWidth;
    }
    ctx.stroke();

    // Remove part of the stroke
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fill();
    ctx.lineWidth = this.style.strokeWidth - 0.25 * this.style.strokeWidth; // Outline size 1/4 of the strokeWidth
    ctx.stroke();
    // Fill Inside Stroke (Part of the main fill)
    // ctx.globalAlpha = this.style.fillOpacity;
    ctx.globalAlpha = 0.1;
    ctx.globalCompositeOperation = 'source-over';
    ctx.lineWidth = this.style.strokeWidth;
    ctx.stroke();
    // Fill Outside Stroke (This is the stroke);
    ctx.globalCompositeOperation = 'destination-out';
    // ctx.globalAlpha = this.style.strokeOpacity;
    ctx.globalAlpha = 1;
    ctx.fill();
    // Fill Center
    ctx.globalCompositeOperation = 'source-over';
    // ctx.globalAlpha = this.style.fillOpacity;
    ctx.globalAlpha = 0.1;
    ctx.fill();

    // Point Line
    // ctx.globalAlpha = 1;
    // ctx.strokeStyle = `rgba(0, 0, 0, 0.5)`;
    // ctx.lineWidth = 2;
    // ctx.stroke();
    // Render back to main context
    context.save();
    context.globalAlpha = 1;
    context.drawImage(canvas, 0, 0);
    context.restore();
  }

  defaultStyle(ctx) {
    ctx.save();
    ctx.fillStyle = this.style.fill;
    ctx.strokeStyle = this.style.stroke;
    ctx.lineWidth = this.style.strokeWidth;
    ctx.setLineDash([5, 5]);
    ctx.translate(this.x, this.y);
    ctx.beginPath();
    this.points.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point[0], point[1]);
      } else {
        ctx.lineTo(point[0], point[1]);
        if (this.closePath && index === this.points.length - 1) {
          ctx.closePath();
        }
      }
    });
    ctx.globalAlpha = this.style.fillOpacity;
    ctx.fill();
    ctx.globalAlpha = this.style.strokeOpacity;
    ctx.stroke();
    ctx.restore();
  }

  creationStyle(ctx) {
    ctx.save();
    // e23a3a
    ctx.fillStyle = '#46C965';
    ctx.strokeStyle = '#46C965';
    ctx.lineWidth = this.style.strokeWidth;
    ctx.translate(this.x, this.y);

    if (this.points.length > 2) {
      this.points.forEach((point, index, poly) => {
        let line;
        if (index === poly.length - 1) {
          line = [point, poly[0]];
        } else {
          line = [point, poly[index + 1]];
        }
        for (let i = index + 1; i < poly.length; i++) {
          let checkingLine;
          if (i === poly.length - 1) {
            checkingLine = [poly[i], poly[0]];
          } else {
            checkingLine = [poly[i], poly[i + 1]];
          }
          if (lineIntersectsLine(line, checkingLine)) {
            ctx.fillStyle = '#ff0000';
            ctx.strokeStyle = '#ff0000';
            break;
          }
        }
      });
    }

    // Line
    ctx.beginPath();
    this.points.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point[0], point[1]);
      } else {
        ctx.lineTo(point[0], point[1]);
        if (index === this.points.length - 1) {
          ctx.closePath();
        }
      }
    });
    ctx.globalAlpha = 0.2;
    ctx.fill();
    ctx.globalAlpha = 0.8;
    ctx.stroke();

    // Cirlces
    this.points.forEach((point, index) => {
      ctx.beginPath();
      ctx.arc(point[0], point[1], this.style.strokeWidth * 2, 0, Math.PI * 2);
      ctx.globalAlpha = 0.2;
      ctx.fill();
      ctx.globalAlpha = 1;
      ctx.stroke();
    });

    ctx.restore();
  }

  draw(ctx) {
    this[`${this.renderStyle}Style`](ctx);
  }
}

export default ZonePolygon;
