import Item from './Item';
/**
 * Line Item
 * @class
 * @memberof Items
 * @extends Item
 */
class Line extends Item {
  /**
   * @param {number} x - x coordinate
   * @param {number} y - y coordinate
   * @param {number[]} p1 - [x,y]
   * @param {number[]} p2 - [x,y]
   * @param {Item~Options} [options]
   * @example
   * import { Items } from 'cake';
   * let line = new Items.Line(0, 0, [0, 0], [5, 5]); // line with no options
   * @example
   * import { Items } from 'cake';
   * let line = new Items.Line(0, 0, [0, 0], [5, 5], { stroke: 'red' }); // line that is red
   */
  constructor(x = 0, y = 0, p1, p2, options) {
    super(x, y, options);
    this.p1 = p1;
    this.p2 = p2;
  }

  draw(ctx) {
    ctx.save();
    ctx.fillStyle = this.style.fill;
    ctx.strokeStyle = this.style.stroke;
    ctx.lineWidth = this.style.strokeWidth;
    ctx.translate(this.x, this.y);
    ctx.beginPath();
    ctx.moveTo(this.p1[0], this.p1[1]);
    ctx.lineTo(this.p2[0], this.p2[1]);
    ctx.stroke();
    ctx.restore();
  }
}

export default Line;
