// NPM Modules
import styled from 'styled-components';
// Styled Componets
export const LinearFormControl = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 1 0;
  }
`;
