// NPM Modules
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { map, mergeMap, catchError, takeUntil } from 'rxjs/operators';

function urlParser(url, payload) {
  if (payload) {
    const parts = url.split('/');
    return parts
      .map(part => {
        if (part.charAt(0) === ':') {
          return `${payload[part.substring(1)]}`;
        }
        if (part.includes('?')) {
          let query = qs.parse(part.substring(part.indexOf('?')), {
            ignoreQueryPrefix: true
          });
          for (let key in query) {
            if (payload[key]) {
              query[key] = payload[key];
            } else {
              delete query[key];
            }
          }
          return part.substring(0, part.indexOf('?') + 1) + qs.stringify(query);
        }
        return `${part}`;
      })
      .join('/');
  } else {
    return url;
  }
}

export default (action$, actionHanlder, actionType, url) =>
  action$.pipe(
    ofType(actionType.POST),
    mergeMap(action =>
      ajax({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        url,
        body: { ...action.payload }
      }).pipe(
        map(actionHanlder.success),
        catchError(error => of(actionHanlder.failed(error))),
        takeUntil(action$.ofType(actionType.CANCEL))
      )
    ),
    catchError(error => of(actionHanlder.failed(error)))
  );
