// NPM Modules
import qs from 'qs';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
// Actions
import get from './templates/get';
import post from './templates/post';
import user, { types } from '../actions/user';
import organization from '../actions/organization';

const URL = '/api/user';

export default {
  //         action$    type(action$, actionHandler,    actionType,        URL?params)
  logout:         action$ => post(action$, user.logout,         types.logout,         `${URL}/logout`),
  superuser:      action$ => post(action$, user.superuser,      types.superuser,      `${URL}/superuser`),
  edit:           action$ => post(action$, user.edit,           types.edit,           `${URL}/edit`),
  changePassword: action$ => post(action$, user.changePassword, types.changePassword, `${URL}/change-password`),
  role: {
    list:         action$ =>  get(action$, user.role.list,   types.role.list,   `${URL}/role/list?page&page_size&array_key&deployment_key&org_key&permission`),
    create:       action$ => post(action$, user.role.create, types.role.create, `${URL}/role/create`),
    remove:       action$ => post(action$, user.role.remove, types.role.remove, `${URL}/role/remove`),
  },
  // I special :D
  login: action$ =>
    action$.pipe(
      ofType(types.login.POST, types.loginSocial.POST),
      mergeMap(action =>
        ajax({
          method: 'POST',
          url:
            action.type === types.login.POST
              ? `${URL}/login`
              : `${URL}/login-social`,
          body: qs.stringify({ ...action.payload })
        }).pipe(
          mergeMap(({ response }) =>
            of(
              // We want to update user info and organization list
              user.login.success({ response: response.user.info }),
              organization.info.success({ ...response.organization.info }),
              organization.list.success({ ...response.organization.list })
            )
          ),
          catchError(error => of(user.login.failed(error)))
        )
      ),
      catchError(error => of(user.login.failed(error)))
    )
};