import Tool from './Tool';
/**
 * Zoom Tool
 */
class Zoom extends Tool {
  /**
   * TODO: Fill in comments
   * @param {*} cake
   * @param {*} active
   */
  constructor(cake, active = false, options = {}) {
    super(cake, active, options);
    // Zoom Properties
    this.zoomLevel = options.hasOwnProperty('level') ? options.level : 7;
    this.zoomLevels = options.hasOwnProperty('levels')
      ? options.levels
      : [
          0.25,
          0.33,
          0.5,
          0.66,
          0.75,
          0.8,
          0.9,
          1,
          1.1,
          1.25,
          1.5,
          1.75,
          2,
          2.5,
          3,
          4,
          5
        ];
    // Register Events
    this.on('mousedown', this.handleMouseDown, true);
    this.on('mousewheel', this.handleMouseWheel, true);
  }
  /**
   * TODO: Fill in comments
   * @param {*} direction
   * @param {*} event
   */
  _handleZoom(direction, event) {
    let zoomLevel = this.zoomLevel + direction;
    zoomLevel = zoomLevel < 0 ? 0 : zoomLevel;
    zoomLevel =
      zoomLevel >= this.zoomLevels.length
        ? this.zoomLevels.length - 1
        : zoomLevel;
    this.zoomLevel = zoomLevel;

    if (this.zoomLevels[zoomLevel] !== this._cake._viewport.a) {
      // I understand the idea of how this works but don't actually at the same time. MATRIX MATH SHIT...
      let point = new DOMPoint(event.offsetX, event.offsetY, 1);
      // before
      const before = point;
      point = point.matrixTransform(this._cake._viewport.inverse());
      // ZOOM
      this._cake._viewport.a = this.zoomLevels[zoomLevel];
      this._cake._viewport.d = this.zoomLevels[zoomLevel];
      // after
      const after = point.matrixTransform(this._cake._viewport);
      // TRANSLATE
      this._cake._viewport.e += before.x - after.x;
      this._cake._viewport.f += before.y - after.y;

      this._cake.update();
      this.triggerEvent('onZoom', this.zoomLevels[zoomLevel]);
    }
  }
  /**
   * TODO: Fill in comments
   * @param {*} event
   */
  handleMouseDown = event => {
    if (this.isActive()) {
      switch (event.button) {
        case 0: // Left click
          this._handleZoom(1, event);
          break;
        case 2: // Right click
          this._handleZoom(-1, event);
          break;
        default:
          break;
      }
    }
  };
  /**
   * TODO: Fill in comments
   * @param {*} event
   */
  handleMouseWheel = event => {
    if (this.isActive()) {
      this._handleZoom(event.deltaY > 0 ? -1 : 1, event);
      event.preventDefault();
    }
  };
}

export default Zoom;
