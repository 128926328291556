// Action Templates
import post from './templates/post';

// Action Call Types
export const types = {
  passwordReset: {
    POST: 'authentication/PASSWORD_RESET:POST',
    RESET: 'authentication/PASSWORD_RESET:RESET',
    CANCEL: 'authentication/PASSWORD_RESET:CANCEL',
    FAILED: 'authentication/PASSWORD_RESET:FAILED',
    SUCCESS: 'authentication/PASSWORD_RESET:SUCCESS'
  },
  passwordResetRequest: {
    POST: 'authentication/PASSWORD_RESET_REQUEST:POST',
    RESET: 'authentication/PASSWORD_RESET_REQUEST:RESET',
    CANCEL: 'authentication/PASSWORD_RESET_REQUEST:CANCEL',
    FAILED: 'authentication/PASSWORD_RESET_REQUEST:FAILED',
    SUCCESS: 'authentication/PASSWORD_RESET_REQUEST:SUCCESS'
  }
};

// Actions
export const actions = {
  passwordReset: post(types.passwordReset),
  passwordResetRequest: post(types.passwordResetRequest)
};

// Export
export default {
  types,
  ...actions
};
