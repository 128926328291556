// NPM Modules
import React from 'react';
import { Switch,  Redirect } from 'react-router-dom';
// Location Tracker Route
import Route from './routes/Route';
// Theme Provider
import ThemeProvider from './ui/ThemeProvider';
//----------------------------------------------------------------------------\\
//                                    Pages                                   \\
// Data Exploration Pages
import DataExploration from './pages/data-exploration';
import DataExplorationVisits from './pages/data-exploration/visits';
import DataExplorationHeatmaps from './pages/data-exploration/heatmaps';
import DataExplorationEntrances from './pages/data-exploration/entrances';
import DataExplorationOccupancy from './pages/data-exploration/occupancy';
// Authentication Routes
import PasswordReset from './pages/authentication/password-reset';
import CompleteAccount from './pages/authentication/complete-account';
// User Pages
import Login from './pages/login';
// Deployment Pages
import Deployment from './pages/deployment';
import DeploymentList from './pages/deployment/list';
// Array Pages
import Array from './pages/array';
import ArrayList from './pages/array/list';
// Organization Pages
import OrganizationList from './pages/organization/list';
import OrganizationSettings from './pages/organization';
// More Page (Mobile only)
import More from './pages/more';
// Documents
import PrivacyPolicy from './pages/documents/PrivacyPolicy';
import TermsOfService from './pages/documents/TermsOfService';
// User
import UserSettings from './pages/user/settings';
//----------------------------------------------------------------------------//
/**
 * RoutingSystem
 * Routes url locations to components
 */
class RoutingSystem extends React.Component {
  render() {
    return (
      <ThemeProvider {...this.props}>
        <Switch>
          <Route exact notheme        component={OrganizationList}         routeTitle=""                      path="/organization/list" />
          <Route exact                component={OrganizationSettings}     routeTitle="Organization Settings" path="/organization/:org_key/settings" />
          <Route exact                component={More}                     routeTitle="More"                  path="/organization/:org_key/more" />
          <Route exact                component={DataExploration}          routeTitle="Data Exploration"      path="/organization/:org_key/data-exploration" />
          <Route exact                component={DataExplorationVisits}    routeTitle="Visits"                path="/organization/:org_key/data-exploration/visits" />
          <Route exact                component={DataExplorationHeatmaps}  routeTitle="Heatmaps"              path="/organization/:org_key/data-exploration/heatmaps" />
          <Route exact                component={DataExplorationEntrances} routeTitle="Entrances"             path="/organization/:org_key/data-exploration/entrances" />
          <Route exact                component={DataExplorationOccupancy} routeTitle="Occupancy"             path="/organization/:org_key/data-exploration/occupancy" />
          <Route exact                component={DeploymentList}           routeTitle="Deployments"           path="/organization/:org_key/deployment/list" />
          <Route exact                component={Deployment}               routeTitle="Deployments"           path="/organization/:org_key/deployment/:deployment_key" />
          <Route exact                component={ArrayList}                routeTitle="Arrays"                path="/organization/:org_key/array/list" />
          <Route exact                component={Array}                    routeTitle="Arrays"                path="/organization/:org_key/array/:array_key" />
          <Route exact         public component={PrivacyPolicy}            routeTitle="Privacy Policy"        path="/privacy-policy" />
          <Route exact         public component={TermsOfService}           routeTitle="Terms Of Service"      path="/terms-of-service" />
          <Route exact                component={UserSettings}             routeTitle="User Settings"         path="/user-settings" />
          <Route exact notheme public component={PasswordReset}            routeTitle="Password Reset"        path="/accounts/password-reset" />
          <Route exact notheme public component={CompleteAccount}          routeTitle="Complete Account"      path="/accounts/complete-account" />
          <Route exact notheme public component={Login}                    routeTitle=""                      path="/login" />
          <Route notheme component={() => <Redirect to="/organization/list" />} />
        </Switch>
      </ThemeProvider>
    );
  }
}

export default RoutingSystem;
