// NPM Modules
import styled from 'styled-components';
// Styled Components
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  & > div {
    width: 100%;
    max-width: 500px;
  }
`;
