// NPM Modules
import React from 'react';
// Styled Components
import { SchemeGroup, SchemeButton } from './style';
// Material-UI
import Button from '@material-ui/core/Button';
// Material-UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// Material-UI Icons
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
// Display Schemes
const SCHEMES = {
  auto: 'System Default',
  scan: 'Classic',
  nord: 'Nord Dark',
  nordLight: 'Nord Light',
  light: 'Light',
  dark: 'Dark'
};
/**
 * Appearance Component
 * @param {*} props
 * @returns
 */
export default function (props) {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>Appearance</DialogTitle>
      <DialogContent>
        <SchemeGroup>
          {Object.entries(SCHEMES).map(([key, label]) => (
            <SchemeButton
              color="primary"
              key={key}
              scheme={key}
              selected={props.scheme === key}
              onClick={() => props.onSetScheme(key)}
              startIcon={
                props.scheme === key ? (
                  <RadioButtonCheckedRoundedIcon color="primary" />
                ) : (
                  <RadioButtonUncheckedRoundedIcon />
                )
              }
            >
              <span>{label}</span>
            </SchemeButton>
          ))}
        </SchemeGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
