// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Components
import ZoneItem from './ZoneItem';
import ZoneField from './ZoneField';
// Material-UI
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Material-UI Icons
import BlurOnRoundedIcon from '@material-ui/icons/BlurOnRounded';
/**
 * Helper func to add the first point in the poly at the end for API formatting.
 * @param {string} region - POLYGON Region format
 * @returns {string}
 */
function convertRegionToValidPolygon(region) {
  const firstPoint = region.substring(
    region.indexOf('((') + 2,
    region.indexOf(',')
  );
  return `${region.slice(0, region.indexOf('))'))}, ${firstPoint}))`;
}
/**
 *
 * @param {*} props
 */
function TempZone({ onCreate, onCancelCreate, array, region }) {
  const [fields, setFields] = React.useState({
    zone_name: '',
    zone_type: '',
    zone_type_input: '',
    algorithm_name: '',
    algorithm_name_input: '',
    algorithm_settings: '',
    region: `POLYGON ((${region.map(p => p.join(' ')).toString()}))`
  });

  React.useEffect(() => {
    setFields({
      ...fields,
      region: `POLYGON ((${region.map(p => p.join(' ')).toString()}))`
    });
  }, [JSON.stringify(region)]); // dump work around | Can't use length cuz points can change

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const handleCreate = () => {
    onCreate({
      array_key: array.array_key,
      zone_name: fields.zone_name,
      zone_type: fields.zone_type,
      algorithm_name: fields.algorithm_name,
      algorithm_settings: JSON.parse(fields.algorithm_settings),
      region: convertRegionToValidPolygon(fields.region)
    });
  };

  const algorithm_name_list = ['kalman_entrance', 'kalman_visit'];
  const zone_type_list = ['entrance', 'visit'];
  const algorithm_settings_examples = {
    kalman_entrance: `{"angle_deg":90,"direction":"VECTOR (1, 0)"}`,
    kalman_visit: `{"threshold_ms":4000}`
  };

  return (
    <React.Fragment>
      <ListItem dense>
        <ListItemIcon>
          <BlurOnRoundedIcon style={{ color: `#ff0000` }} />
        </ListItemIcon>
        <ListItemText
          primary="New Zone"
          secondary="Click on the canvas to create points for the region."
        />
      </ListItem>
      <List>
        <ZoneField
          label={`Zone Name`}
          value={fields.zone_name}
          disabled={false}
          onChange={({ target }) => changeField(`zone_name`, target.value)}
        />
        <Autocomplete
          value={fields.zone_type}
          inputValue={fields.zone_type_input}
          options={zone_type_list}
          autoHighlight
          freeSolo
          onChange={(_, value) => changeField(`zone_type`, value)}
          onInputChange={(_, value) => changeField(`zone_type_input`, value)}
          renderInput={params => (
            <ZoneField {...params} label={`Zone Type`} disabled={false} />
          )}
        />
        <Autocomplete
          value={fields.algorithm_name}
          inputValue={fields.algorithm_name_input}
          options={algorithm_name_list}
          autoHighlight
          freeSolo
          onChange={(_, value) => {
            setFields({
              ...fields,
              algorithm_name: value,
              algorithm_settings:
                algorithm_settings_examples[value] || fields.algorithm_settings
            });
          }}
          onInputChange={(_, value) => {
            setFields({
              ...fields,
              algorithm_name_input: value,
              algorithm_settings:
                algorithm_settings_examples[value] || fields.algorithm_settings
            });
          }}
          renderInput={params => (
            <ZoneField {...params} label={`Algorithm Name`} disabled={false} />
          )}
        />
        <ZoneField
          label={`Algorithm Settings`}
          value={fields.algorithm_settings}
          disabled={false}
          onChange={({ target }) =>
            changeField(`algorithm_settings`, target.value)
          }
        />
        <ZoneField
          label={`Region`}
          value={fields.region}
          disabled={false}
          onChange={({ target }) => changeField(`region`, target.value)}
        />
        <ListItem dense>
          <ListItemText
            inset
            primary={
              <div style={{ display: 'flex' }}>
                <Button
                  onClick={onCancelCreate}
                  variant="outlined"
                  fullWidth
                  style={{ marginRight: '8px' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCreate}
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: '8px' }}
                >
                  Save
                </Button>
              </div>
            }
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
/**
 * Zone List Component
 */
class ZoneListComponent extends Component {
  renderTempZone() {
    if (!this.props.creatingNewZone) return null;

    return (
      <TempZone
        onCreate={this.props.onCreate}
        onCancelCreate={this.props.onCancelCreate}
        array={this.props.array}
        region={this.props.region}
      />
    );
  }

  renderZoneItems() {
    return this.props.list.map(zone => (
      <ZoneItem
        zone={zone}
        editing={
          this.props.editZone !== null
            ? zone.zone_key === this.props.editZone.zone_key
            : false
        }
        region={this.props.region}
        key={zone.zone_key}
        onRegionChanged={this.props.onRegionChanged}
        onEditZoneSave={this.props.onEditZoneSave}
        onSetEditZone={this.props.onSetEditZone}
        onDelete={() => this.props.onDeleteZone(zone)}
        canCreateDelete={this.props.canCreateDelete}
      />
    ));
  }

  render() {
    // If props.isLoading is true we need to show loading template
    // TODO: add in material-ui skeleton
    if (this.props.isLoading) return <CircularProgress />;

    return (
      <List>
        {this.renderTempZone()}
        {this.renderZoneItems()}
      </List>
    );
  }
}

export default ZoneListComponent;
