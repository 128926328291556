// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Material-UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// Actions
import user from '../../redux/actions/user';
/**
 * Invite Modal Component
 * @extends Component
 */
class UserInviteDialog extends Component {
  /**
   * Component State
   * @property {string}   role_name - Name of role to be given to the specified user.
   * @property {string}   user      - Email needed to create role.
   * @property {boolean}  open      - Modal open/close state.
   */
  state = {
    role_name: this.props.roles[0][0],
    user: ''
  };
  /**
   * componentDidUpdate
   * Send UI feedback to user if a user was successfully invited or not.
   * @param {object} prevProps previous properties of the component
   */
  componentDidUpdate(prevProps) {
    if (this.props.roleCreateStatus !== prevProps.roleCreateStatus) {
      if (this.props.roleCreateStatus === 'success') {
        this.setState({ user: '' });
        this.props.onClose();
        this.props.enqueueSnackbar('Invite successfully sent.', {
          variant: 'success'
        });
      } else if (this.props.roleCreateStatus === 'failed') {
        this.props.enqueueSnackbar('Invite failed.', { variant: 'error' });
      }
    }
  }
  /**
   * handleRoleSelect
   * Set's state role_name to event.target.value
   */
  handleRoleSelect = event => {
    this.setState({ role_name: event.target.value });
  };
  /**
   * handleUserUpdate
   * Set's state user to event.target.value
   */
  handleUserUpdate = event => {
    this.setState({ user: event.target.value });
  };
  /**
   * handleCreateRole
   * Fires action user/roles/CREATE:POST
   */
  handleCreateRole = () => {
    let optional = {};
    if (this.props.array_key) optional.array_key = this.props.array_key;
    if (this.props.deployment_key)
      optional.deployment_key = this.props.deployment_key;
    this.props.createRole({
      ...optional,
      org_key: this.props.org_key,
      role_name: this.state.role_name,
      user: this.state.user
    });
  };
  /**
   * renderRoles
   * Renders roles for the select box.
   */
  renderRoles() {
    return this.props.roles.map(([key, value]) => (
      <MenuItem value={key} key={key}>
        {value}
      </MenuItem>
    ));
  }
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullScreen={this.props.isMobile}
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If the user you are adding does not have an account with us they
            will be sent an email invite to create an account.
          </DialogContentText>
          <TextField
            onChange={this.handleUserUpdate}
            label="Email"
            value={this.state.user}
            fullWidth
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              onChange={this.handleRoleSelect}
              value={this.state.role_name}
              labelId="role-select-label"
            >
              {this.renderRoles()}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleCreateRole}>Invite</Button>
        </DialogActions>
      </Dialog>
    );
  }
  /**
   * Prop Type Checking
   * @property {string} array_key       - Optional array key for the new role.
   * @property {string} deployment_key  - Optional deployment key for the new role.
   * @property {string} org_key         - Organization key for the new role.
   * @property {Array.<[string,string]>} roles - [key, value] roles list
   */
  static propTypes = {
    array_key: PropTypes.string,
    deployment_key: PropTypes.string,
    org_key: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired
  };

  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      // Device Info
      isMobile: state.device.isMobile,
      deployment: state.deployment.info.info,
      organization: state.organization.info.info,
      roleCreateStatus: state.user.role.create.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        createRole: user.role.create.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  UserInviteDialog.mapStateToProps,
  UserInviteDialog.mapDispatchToProps
)(withSnackbar(UserInviteDialog));
