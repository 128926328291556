// NPM Modules
import styled from 'styled-components';
// Material-UI
import Paper from '@material-ui/core/Paper';
import PhotoSizeSelectSmallRoundedIcon from '@material-ui/icons/PhotoSizeSelectSmallRounded';
// Styled Components
const WIDTH = 300;
export const Container = styled(Paper)`
  z-index: 9999;
  position: absolute;
  padding: 0px;
  width: ${WIDTH}px;
  left: ${({ left }) => -left}px;
  ${({ top, scale }) =>
    top === 'true' ? `top: ${scale + 16}px` : `bottom: calc(100% + 16px)`};
  transition: 0.1s;
`;
export const Triangle = styled.div`
  position: absolute;
  border-radius: 4px;
  top: ${({ top }) => (top === 'true' ? '-8px' : 'calc(100% - 8px)')};
  clip-path: polygon(
    ${({ top }) =>
      top === 'true'
        ? '100% 0%, 0% 100%, 0% 100%, 0% 0%'
        : '100% 0%, 0% 100%, 0% 100%, 100% 100%'}
  );
  left: ${({ left, scale }) => left + scale / 2 - 8}px;
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.palette.background.paper};
  transition: 0.1s;
`;
export const ZoneIcon = styled(PhotoSizeSelectSmallRoundedIcon)`
  color: rgb(${({ iconcolor }) => iconcolor.toString()});
`;
