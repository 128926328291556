// NPM Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Material-UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
/**
 * Action
 */
class Action extends Component {
  /**
   * render
   */
  render() {
    const action = this.props.action;
    const icon = action.icon ? <Icon>{action.icon}</Icon> : null;
    const link = action.link;
    const handleOnClick = event => {
      if (action.onClick) {
        action.onClick(event);
        event.stopPropagation();
      }
    };
    const size = action.size ? action.size : 'medium';
    const variant = action.variant ? action.variant : 'text';

    if (action.text) {
      if (link) {
        return (
          <Button
            component={Link}
            to={link}
            disabled={action.disabled}
            onClick={event => handleOnClick(event)}
            startIcon={action.iconPosition === 'start' ? icon : null}
            endIcon={action.iconPosition === 'end' ? icon : null}
            size={size}
            variant={variant}
            style={{ marginLeft: action.marginLeft }}
          >
            {action.text}
          </Button>
        );
      }
      return (
        <Button
          disabled={action.disabled}
          onClick={event => handleOnClick(event)}
          startIcon={action.iconPosition === 'start' ? icon : null}
          endIcon={action.iconPosition === 'end' ? icon : null}
          size={size}
          variant={variant}
          style={{ marginLeft: action.marginLeft }}
        >
          {action.text}
        </Button>
      );
    } else {
      if (link) {
        rn(
          <IconButton
            component={Link}
            to={link}
            disabled={action.disabled}
            onClick={event => handleOnClick(event)}
            size={size}
            style={{ marginLeft: action.marginLeft }}
          >
            {icon}
          </IconButton>
        );
      }
      return (
        <IconButton
          disabled={action.disabled}
          onClick={event => handleOnClick(event)}
          size={size}
          style={{ marginLeft: action.marginLeft }}
        >
          {icon}
        </IconButton>
      );
    }
  }
  /**
   * Component Default Prop State
   */
  static defaultProps = {
    action: {}
  };
  /**
   * Component Prop Types
   */
  static propTypes = {
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
  };
}
/**
 * Export React Component
 */
export default Action;
