// Organization Types
import * as organization from '../../actions/organization';
// User Actions
import user from '../../actions/user';

const RequestSatuses = {
  get: 'get',
  failed: 'failed',
  success: 'success',
  cancel: 'cancel'
};

export const defaultState = {
  request: {
    status: null,
    fetched: null,
    error: null
  },
  count: 0,
  page: {
    size: 100,
    lastPage: null,
    current: 1,
    next: null,
    previous: null
  },
  hasPages: true,
  list: []
};

/**
 * Template List Reducer
 */
export default (
  state = { ...defaultState },
  { payload = {}, type = '' },
  types
) => {
  switch (type) {
    // Handle Clear Out States
    case user.types.LOGOUT_SUCCESS:
    case types.RESET:
    case organization.types.info.GET: {
      // NOTE: Change this later but quick hack to get around templating...
      // Return state if nothing happens, otherwise shit breaks.
      if (type === organization.types.info.GET) {
        if (types.GET !== 'organization/LIST:GET') {
          return { ...defaultState };
        }
        return state;
      }
      return { ...defaultState };
    }

    // List Template Reducer
    case types.GET: {
      return {
        ...state,
        request: {
          ...state.request,
          status: RequestSatuses.get,
          error: null
        }
      };
    }
    case types.CANCEL: {
      return {
        ...state,
        request: {
          ...state.request,
          status: RequestSatuses.cancel
        }
      };
    }
    case types.FAILED: {
      return {
        ...state,
        request: {
          ...state.request,
          status: RequestSatuses.failed,
          error: payload
        }
      };
    }
    case types.SUCCESS: {
      const hasPages = payload.hasOwnProperty('page_size');
      let page = { ...state.page };
      if (hasPages) {
        page.size = payload.page_size;
        page.lastPage = Math.ceil(payload.count / page.size);
        page.current = payload.page;
        page.next = page.current + 1 <= page.lastPage ? page.current + 1 : null;
        page.previous = page.current - 1 > 0 ? page.current - 1 : null;
      }
      return {
        ...state,
        request: {
          ...state.request,
          status: RequestSatuses.success
        },
        count: payload.count,
        page,
        list: payload.list,
        hasPages
      };
    }
    default: {
      return state;
    }
  }
};
