// NPM Modules
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
// Actions
import ui from '../../redux/actions/ui';
import user from '../../redux/actions/user';
// Helpers
import permissionCheck from '../../helpers/permissionCheck';
// Components
import Appearance from './components/Appearance';
import ReleaseNotesDialog from '../Dialogs/ReleaseNotesDialog';
// Material-UI
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import BrushRoundedIcon from '@material-ui/icons/BrushRounded';
import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import GroupWorkRoundedIcon from '@material-ui/icons/GroupWorkRounded';
import ChildCareRoundedIcon from '@material-ui/icons/ChildCareRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import RoomServiceRoundedIcon from '@material-ui/icons/RoomServiceRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
/**
 * User list component
 * @param {Object} props
 * @param {Object} props.user
 * @param {Function} props.onToggleSuperUser
 * @return {JSX} react component
 */
function UserList(props) {
  const { user, onToggleSuperUser } = props;
  const [themeMenuAnchor, setThemeMenuAnchor] = React.useState(null);
  const openThemeMenu = event => {
    setThemeMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setThemeMenuAnchor(null);
  };
  const handleSetScheme = scheme => {
    props.setScheme({ scheme });
    handleClose();
  };
  /**
   * getShortHandName
   * Converts user's info into a short hand name
   */
  const getShortHandName = () => {
    if (user.first_name && user.last_name) {
      return user.first_name.charAt(0) + user.last_name.charAt(0);
    } else if (user.first_name) {
      return user.first_name.charAt(0);
    } else {
      return user.email.charAt(0);
    }
  };
  return (
    <>
      <Appearance
        open={!!themeMenuAnchor}
        onClose={handleClose}
        anchorEl={themeMenuAnchor}
        onSetScheme={handleSetScheme}
        scheme={props.ui.scheme}
      />
      <List>
        <ListItem
          button
          component={Link}
          onClick={props.onItemSelected}
          to="/user-settings"
        >
          <ListItemAvatar>
            <Avatar>{getShortHandName()}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${user.first_name} ${user.last_name}`}
            secondary={user.email}
          />
        </ListItem>
        <ListItem button onClick={openThemeMenu}>
          <ListItemAvatar>
            <Avatar>
              <BrushRoundedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Change Appearance" />
        </ListItem>
        {user.super_user ? (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ChildCareRoundedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Toggle Super User" />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                color="primary"
                tabIndex={-1}
                onChange={onToggleSuperUser}
                checked={user.toggle_super_user}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ) : null}
      </List>
    </>
  );
}
/**
 * Organization list component
 * @param {Object} props
 * @param {Object} props.organization
 * @param {Object} props.user
 * @return {JSX} react component
 */
function OrganizationList({ organization, user, onItemSelected }) {
  const hasOrgAdminAccess = user.toggle_super_user
    ? true
    : permissionCheck('org_write', organization.permissions);
  return (
    <List
      subheader={
        <ListSubheader disableSticky>{organization.org_name}</ListSubheader>
      }
    >
      <ListItem button component={Link} to="/organization/list">
        <ListItemAvatar>
          <Avatar>
            <GroupWorkRoundedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Switch Organization" />
      </ListItem>
      {hasOrgAdminAccess ? (
        <ListItem
          button
          component={Link}
          onClick={onItemSelected}
          to={`/organization/${organization.org_key}/settings`}
        >
          <ListItemAvatar>
            <Avatar>
              <SettingsRoundedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Organization Settings" />
        </ListItem>
      ) : null}
    </List>
  );
}
function MobileInfo({ isMobile }) {
  if (!isMobile) return null;
  const [open, setOpen] = useState(false);
  const toggleReleaseNotes = () => {
    setOpen(!open);
  };

  return (
    <>
      <ReleaseNotesDialog open={open} onClose={toggleReleaseNotes} />
      <List>
        <ListItem button component={Link} to="/terms-of-service">
          <ListItemAvatar>
            <Avatar>
              <RoomServiceRoundedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Terms of Service" />
        </ListItem>
        <ListItem button component={Link} to="/privacy-policy">
          <ListItemAvatar>
            <Avatar>
              <PolicyRoundedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Privacy Policy" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={toggleReleaseNotes}>
          <ListItemAvatar>
            <Avatar>
              <DescriptionRoundedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Release Notes" />
        </ListItem>
      </List>
      <Divider />
    </>
  );
}
/**
 * User Menu Component
 */
class UserMenu extends Component {
  /**
   * Fires every time the component updates.
   * Enqueue snackbar for super user toggle.
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.user.toggle_super_user !== this.props.user.toggle_super_user
    ) {
      this.props.enqueueSnackbar(
        `Super user state: ${this.props.user.toggle_super_user ? 'On' : 'Off'}`,
        {
          action: key => (
            <Button
              onClick={() => window.location.reload()}
              size="small"
              color="primary"
            >
              Reload Page
            </Button>
          )
        }
      );
    }
  }
  /**
   * handleLogout
   * Sends logout action request
   */
  handleLogout = () => {
    this.props.logout();
  };
  /**
   * handleToggleSuperUser
   * Sends super user toggle action request
   */
  handleToggleSuperUser = () => {
    this.props.toggleSuperUser(!this.props.user.toggle_super_user);
  };
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    const { user, organization, ui, setScheme, isMobile } = this.props;
    if (!user && !organization) return null;
    return (
      <div>
        <UserList
          user={user}
          onToggleSuperUser={this.handleToggleSuperUser}
          ui={ui}
          setScheme={setScheme}
          isMobile={isMobile}
          onItemSelected={
            this.props.onItemSelected ? this.props.onItemSelected : () => {}
          }
        />
        <Divider />
        <OrganizationList
          organization={organization}
          user={user}
          onItemSelected={
            this.props.onItemSelected ? this.props.onItemSelected : () => {}
          }
        />
        <Divider />
        <MobileInfo isMobile={isMobile} />
        <List>
          <ListItem button onClick={this.handleLogout}>
            <ListItemAvatar>
              <Avatar>
                <PowerSettingsNewRoundedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      ui: state.ui,
      user: state.user.info.info,
      isMobile: state.device.isMobile,
      organization: state.organization.info.info
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        setScheme: ui.setScheme,
        logout: user.logout.post,
        toggleSuperUser: user.superuser.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  UserMenu.mapStateToProps,
  UserMenu.mapDispatchToProps
)(withSnackbar(UserMenu));
