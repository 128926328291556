// Action Templates
import get from './templates/get';
import post from './templates/post';
// Action Calls
export const types = {
  info: {
    GET: 'zone/INFO:GET',
    RESET: 'zone/INFO:RESET',
    CANCEL: 'zone/INFO:CANCEL',
    FAILED: 'zone/INFO:FAILED',
    SUCCESS: 'zone/INFO:SUCCESS'
  },
  list: {
    GET: 'zone/LIST:GET',
    RESET: 'zone/LIST:RESET',
    CANCEL: 'zone/LIST:CANCEL',
    FAILED: 'zone/LIST:FAILED',
    SUCCESS: 'zone/LIST:SUCCESS'
  },
  create: {
    POST: 'zone/CREATE:POST',
    RESET: 'zone/CREATE:RESET',
    CANCEL: 'zone/CREATE:CANCEL',
    FAILED: 'zone/CREATE:FAILED',
    SUCCESS: 'zone/CREATE:SUCCESS'
  },
  edit: {
    POST: 'zone/EDIT:POST',
    RESET: 'zone/EDIT:RESET',
    CANCEL: 'zone/EDIT:CANCEL',
    FAILED: 'zone/EDIT:FAILED',
    SUCCESS: 'zone/EDIT:SUCCESS'
  },
  remove: {
    POST: 'zone/DELETE:POST',
    RESET: 'zone/DELETE:RESET',
    CANCEL: 'zone/DELETE:CANCEL',
    FAILED: 'zone/DELETE:FAILED',
    SUCCESS: 'zone/DELETE:SUCCESS'
  }
};
// Deployment Functions
export default {
  types,
  info: get(types.info),
  list: get(types.list),
  create: post(types.create),
  edit: post(types.edit),
  remove: post(types.remove)
};
