// NPM Modules
import equal from 'deep-equal';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import aggregate from '../../../redux/actions/aggregate';
import dataPointSystem from '../../../redux/actions/dataPointSystem';
// App Components
import Page from '../../../components/Page';
import BigData from '../../../components/visualizers/BigData';
import LineBarChart from '../../../components/visualizers/charts/LineBarChart';
import DateRangePicker from '../../../components/DateRangePicker';
// Data Exporation Components
import Exporter from '../components/Exporter';
import DataPicker from '../components/DataPicker';
// Helper Functions
import intervalSplit from '../../../helpers/intervalSplit';
import formatMilliseconds from '../../../helpers/formatMilliseconds';
// Material-UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
/**
 * Data Exploration Visits Page
 */
class Visits extends Component {
  /**
   * Fetch data on mount
   * @param {*} prevProps
   */
  componentDidMount() {
    this.props.dataPoints.forEach(dataPoints => {
      this.getAggregateData(dataPoints);
    });
  }
  /**
   * Fetch data and inform client on anything going wrong
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    // Inform client if any errors occure
    this.props.dataPoints.forEach(dataPoint => {
      const aggregate = this.props.visits[dataPoint.zone_key];
      if (
        aggregate &&
        aggregate.error &&
        aggregate.error.data.error === 'Over 1000 time bins' &&
        prevProps.visits[dataPoint.zone_key].fetching === true &&
        aggregate.fetching === false
      ) {
        this.props.enqueueSnackbar(
          `${aggregate.error.data.error}. Please reduce time window or interval.`,
          {
            variant: 'error',
            preventDuplicate: true
          }
        );
      }
    });
    // If date time system updates fetch fresh data
    if (
      prevProps.timezone !== this.props.timezone ||
      prevProps.interval !== this.props.interval ||
      prevProps.startDateTime !== this.props.startDateTime ||
      prevProps.endDateTime !== this.props.endDateTime ||
      prevProps.threshold !== this.props.threshold
    ) {
      this.props.dataPoints.forEach(dataPoint => {
        // this.getAggregateData(dataPoint);
        this.props.dataPointsUpdate({
          ...dataPoint,
          interval: this.props.interval,
          timezone: this.props.timezone,
          start: this.props.startDateTime,
          end: this.props.endDateTime,
          threshold: this.props.threshold
        });
      });
    }
    // If dp has changed or just been added fetch data
    this.props.dataPoints.forEach(dataPoint => {
      const prev = prevProps.dataPoints.find(dp => dp.id === dataPoint.id);
      if (prev === undefined || !equal(prev, dataPoint)) {
        this.getAggregateData(dataPoint);
      }
    });
  }
  /**
   * Fetch aggregate data for this component
   * @param {*} dataPoint
   */
  getAggregateData(dataPoint) {
    this.props.getZoneVisits({
      zone_key: dataPoint.zone_key,
      start: moment(dataPoint.start).toISOString(true).slice(0, -6),
      end: moment(dataPoint.end).toISOString(true).slice(0, -6),
      interval: dataPoint.interval,
      timezone: dataPoint.timezone,
      threshold: dataPoint.threshold
    });
  }
  /**
   * Render component
   * @returns
   */
  render() {
    const { dataPoints, visits, interval, timezone, history } = this.props;

    const [number, type] = intervalSplit(interval);
    const unitLabel = ` per ${number === 1 ? '' : number} ${type}`;

    let datasetsCount = [];
    let datasetsDuration = [];

    dataPoints.forEach(dataPoint => {
      const data = visits[dataPoint.zone_key] || { results: [] };
      datasetsCount.push({
        id: dataPoint.id,
        label: dataPoint.label,
        backgroundColor: `rgba(${dataPoint.color.join(',')}, 0.5)`,
        borderColor: `rgba(${dataPoint.color.join(',')}, 1)`,
        radius: 0,
        borderWidth: 2,
        unit: 'day',
        data: data.results.map(node => ({
          x: moment.tz(node.date, timezone).format('Y-MM-DDTHH:mm:ss'),
          y: node.c
        }))
      });
      datasetsDuration.push({
        id: dataPoint.id,
        label: dataPoint.label,
        backgroundColor: `rgba(${dataPoint.color.join(',')}, 0.5)`,
        borderColor: `rgba(${dataPoint.color.join(',')}, 1)`,
        radius: 0,
        borderWidth: 2,
        data: data.results.map(node => ({
          x: moment.tz(node.date, timezone).format('Y-MM-DDTHH:mm:ss'),
          y: node.durMs
        }))
      });
    });

    let averageVistorDuration = 0;
    if (dataPoints.length > 0) {
      let countSum = datasetsCount.map(set => set.data).flat();
      let durSum = datasetsDuration.map(set => set.data).flat();

      if (countSum.length > 0 && durSum.length > 0) {
        countSum = countSum.reduce((a, b) => a + b.y, 0);
        durSum = durSum.reduce((a, b) => a + b.y, 0);
        averageVistorDuration = durSum / countSum;
      }
    }

    return (
      <Page padding={false}>
        <DateRangePicker
          hasThreshold
          exporter={
            <Exporter
              isMobile={this.props.isMobile}
              data={visits}
              dataPoints={dataPoints}
            />
          }
        />
        <DataPicker
          history={history}
          DataPointType={dataPointSystem.DataPointType.ZoneVisits}
          category="visits"
        />
        <Page>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} lg={4}>
              <BigData
                datasets={datasetsCount}
                aggregation="sum"
                title="Total Visits"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <BigData
                datasets={datasetsCount}
                aggregation="average"
                title="Average Visits"
                unitLabel={unitLabel}
                numberFormat="0,0.[00]"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BigData
                value={averageVistorDuration}
                title="Average Visitor Duration"
                type="time"
                numberFormat="0,0.[00]"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Visit Count
                  </Typography>
                  <LineBarChart chartType="line" datasets={datasetsCount} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Duration of Visits
                  </Typography>
                  <LineBarChart
                    chartType="bar"
                    datasets={datasetsDuration}
                    options={{
                      scales: {
                        y: {
                          type: 'linear',
                          display: true,
                          text: 'Total Duration (minutes)',
                          ticks: {
                            callback: value => {
                              const { number, label } = formatMilliseconds(
                                value,
                                { round: true, shorthand: true }
                              );
                              return number + label;
                            }
                          }
                        }
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: context => {
                              var label = context.dataset.label || '';

                              if (label) {
                                label += ': ';
                              }
                              if (context.parsed.y !== null) {
                                const format = formatMilliseconds(
                                  context.parsed.y
                                );
                                const value = numeral(format.number).format(
                                  '0,0.[00]'
                                );
                                label += ` ${value} ${format.label}`;
                              }
                              return label;
                            }
                          }
                        }
                      }
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Page>
      </Page>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      dataPoints: Object.values(state.dataPointSystem).filter(
        dataPoint => dataPoint.parent === 'visits'
      ),
      visits: state.aggregate.visits,
      timezone: state.dateTime.timezone,
      threshold: state.dateTime.threshold,
      interval: state.dateTime.interval,
      endDateTime: state.dateTime.endDateTime,
      startDateTime: state.dateTime.startDateTime,
      // Mobile state
      isMobile: state.device.isMobile
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Aggregate => Zones
        getZoneVisits: aggregate.zone.visits.get,
        // Data Point System => Data Points
        dataPointsUpdate: dataPointSystem.update
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  Visits.mapStateToProps,
  Visits.mapDispatchToProps
)(withSnackbar(Visits));
