// NPM Modules
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import user from '../../../redux/actions/user';
import users from '../../../redux/actions/users';
// Material-UI
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
// Material-UI Card
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// Styled Components
import { Container } from '../style';
/**
 * Complete Account Page
 */
class CompleteAccount extends Component {
  state = {
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    showPassword: false,
    timezone: '',
    timezoneInput: '',
    timezoneList: moment.tz.names()
  };
  handleCreateAccount = () => {
    if (this.areInputsValid()) {
      this.props.acceptInvitation({
        email: this.props.email,
        confirm_code: this.props.confirm_code,
        password: this.state.password,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        timezone: this.state.timezone
      });
    }
  };
  areInputsValid() {
    if (
      this.state.password !== this.state.confirm_password ||
      this.state.password.length < 8 ||
      this.state.first_name === '' ||
      this.state.last_name === '' ||
      this.state.timezone === ''
    ) {
      return false;
    }
    return true;
  }
  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };
  handleToggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      if (this.props.status === 'success') {
        this.props.enqueueSnackbar('Account created! Logging in user.', {
          variant: 'success'
        });
        this.props.login(this.props.email, this.state.password);
      }
      if (this.props.status === 'failed') {
        this.props.enqueueSnackbar(
          `Failed to confirm account. Check inputs are valid.`,
          { variant: 'error' }
        );
      }
    }
  }
  /**
   * render
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    if (
      this.props.user_status === 'success' &&
      this.props.user_info &&
      !this.props.user_error
    ) {
      return <Redirect to="/organization/list" />;
    }
    const isPasssword8Char =
      this.state.password === '' || this.state.password.length > 7;
    const doPasswordsMatch =
      this.state.password === this.state.confirm_password;
    return (
      <Container>
        <Card elevation={0}>
          <CardHeader title="Complete Account Creation" />
          <CardContent>
            <form onSubmit={this.handleCreateAccount}>
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  name="email"
                  type="text"
                  value={this.props.email}
                  disabled
                />

                <FormControl>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    error={!isPasssword8Char}
                    id="standard-adornment-password"
                    name="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleToggleShowPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!isPasssword8Char ? (
                    <FormHelperText id="standard-adornment-confirm_password_help">
                      Password must contain 8 characters.
                    </FormHelperText>
                  ) : null}
                </FormControl>

                <FormControl>
                  <InputLabel htmlFor="standard-adornment-confirm_password">
                    Confirm Password
                  </InputLabel>
                  <Input
                    error={!doPasswordsMatch}
                    id="standard-adornment-confirm_password"
                    name="confirm_password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.confirm_password}
                    onChange={this.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleToggleShowPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!doPasswordsMatch ? (
                    <FormHelperText id="standard-adornment-confirm_password_help">
                      Password must match confirm password.
                    </FormHelperText>
                  ) : null}
                </FormControl>

                <TextField
                  label="First Name"
                  name="first_name"
                  type="text"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
                <TextField
                  label="Last Name"
                  name="last_name"
                  type="text"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                />
                <Autocomplete
                  value={this.state.timezone}
                  inputValue={this.state.timezoneInput}
                  options={this.state.timezoneList}
                  getOptionLabel={option => option}
                  onChange={(event, newInputValue) => {
                    this.setState({ timezone: newInputValue });
                  }}
                  onInputChange={(event, newInputValue) => {
                    this.setState({ timezoneInput: newInputValue });
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Timezone" name="timezone" />
                  )}
                  groupBy={option => option.split('/')[0]}
                />
                <input type="submit" style={{ display: 'none' }} />
              </FormControl>
            </form>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              color="primary"
              disableElevation
              variant="contained"
              onClick={this.handleCreateAccount}
              disabled={!this.areInputsValid()}
            >
              Create Account
            </Button>
          </CardActions>
        </Card>
      </Container>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      email: state.confirmAccount.email,
      confirm_code: state.confirmAccount.confirm_code,
      info: state.users.acceptInvitation.info,
      status: state.users.acceptInvitation.status,
      user_info: state.user.info.info,
      user_error: state.user.info.error,
      user_status: state.user.info.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        login: user.login.post,
        acceptInvitation: users.acceptInvitation.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  CompleteAccount.mapStateToProps,
  CompleteAccount.mapDispatchToProps
)(withSnackbar(CompleteAccount));
