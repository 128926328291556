import { Items } from '../Cake';
/**
 * Mat Item
 * @class
 * @extends Items.Rectangle
 */
class MatItem extends Items.Rectangle {
  /**
   *
   * @param {number} x - x coordinate
   * @param {number} y - y coordinate
   * @param {*} width
   * @param {*} height
   * @param {Item~Options} [options]
   */
  constructor(x = 0, y = 0, width = 0, height = 0, options = {}) {
    super(x, y, width, height, options);
    this.rotation = options.hasOwnProperty('rotation') ? options.rotation : 0;
    this.flip_x = options.hasOwnProperty('flip_x') ? options.flip_x : false;
    this.flip_y = options.hasOwnProperty('flip_y') ? options.flip_y : false;
  }

  draw(ctx) {
    // Save current canvas setting
    ctx.save();
    // Setup Base
    ctx.fillStyle = this.style.fill;
    ctx.strokeStyle = this.style.stroke;
    ctx.lineWidth = this.style.strokeWidth;
    // Set origin bottom left
    ctx.translate(0, ctx.canvas.height);
    ctx.scale(1, -1);
    // Translate, Rotate, Flip
    ctx.translate(this.x, this.y);
    ctx.rotate((this.rotation * Math.PI) / 180);
    ctx.scale(this.flip_x ? -1 : 1, this.flip_y ? -1 : 1);
    // Draw Shape
    ctx.beginPath();
    ctx.rect(0, 0, this.width, this.height);
    // Fill/Stroke Shape
    ctx.globalAlpha = this.style.fillOpacity;
    ctx.fill();
    ctx.globalAlpha = this.style.strokeOpacity;
    ctx.stroke();
    // Restore canvas settings
    ctx.restore();
  }
}

export default MatItem;
