// NPM Modules
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link as RouterLink } from 'react-router-dom';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import deployment from '../../redux/actions/deployment';
// Material-UI
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Default State
const DEFAULT_STATE = {
  deployment_name: '',
  deployment_type: '',
  timezone: moment.tz.guess(),
  timezoneInput: moment.tz.guess(),
  timezoneList: moment.tz.names(),
  start_date: moment.tz(moment(), moment.tz.guess())
};
/**
 * Deployment Create Dialog
 */
class DeploymentCreateDilaog extends Component {
  state = { ...DEFAULT_STATE };
  /**
   * fires when a component has updated
   * send message to use if deployment was created successfully otherwise send error message
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const {
      deploymentCreateInfo,
      deploymentCreateError,
      deploymentCreateStatus
    } = this.props;
    if (
      deploymentCreateStatus === 'success' &&
      prevProps.deploymentCreateStatus !== 'success'
    ) {
      this.props.onDeploymentCreated();
      this.props.enqueueSnackbar(
        <span>
          Deployment successfully created:{' '}
          <Link
            component={RouterLink}
            to={`./${deploymentCreateInfo.deployment_key}`}
          >
            {deploymentCreateInfo.message.slice(0, -19)}
          </Link>
        </span>
      );
      this.setState({ ...DEFAULT_STATE });
    }
    if (
      deploymentCreateStatus === 'failed' &&
      prevProps.deploymentCreateStatus !== 'failed' &&
      deploymentCreateError
    ) {
      this.props.enqueueSnackbar(deploymentCreateError.data.message, {
        variant: 'error'
      });
    }
  }
  /**
   * Fires deployment create action
   */
  handleDeploymentCreate = () => {
    this.props.createDeployment({
      deployment_name: this.state.deployment_name,
      deployment_type: this.state.deployment_type,
      start_date: this.state.start_date.format('Y-MM-DDTHH:mm:ss'),
      timezone: this.state.timezone,
      org_key: this.props.organization.org_key
    });
  };
  /**
   * Handles field input changes and updates component state to reflect
   * @param {Object} event
   */
  handleFieldChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  /**
   * Renders component visual.
   * @return {JSX} react component
   */
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullScreen={this.props.isMobile}
      >
        <DialogTitle>Create Deployment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deployments are the top parent of all bits of information gathering.
            Users can be invited to a deployment once created.
          </DialogContentText>
          <TextField
            name="deployment_name"
            label="Name"
            type="text"
            fullWidth
            value={this.state.deployment_name}
            onChange={this.handleFieldChange}
          />
          <TextField
            name="deployment_type"
            label="Type"
            type="text"
            fullWidth
            value={this.state.deployment_type}
            onChange={this.handleFieldChange}
          />
          <Autocomplete
            value={this.state.timezone}
            inputValue={this.state.timezoneInput}
            options={this.state.timezoneList}
            getOptionLabel={option => option}
            onChange={(_, timezone) => this.setState({ timezone })}
            onInputChange={(_, timezoneInput) =>
              this.setState({ timezoneInput })
            }
            renderInput={params => (
              <TextField {...params} label="Timezone" name="timezone" />
            )}
            groupBy={option => option.split('/')[0]}
          />
          <KeyboardDatePicker
            fullWidth
            label="Start Date"
            variant="standard"
            onChange={date => {
              this.setState({
                start_date: moment.tz(date, this.state.timezone)
              });
            }}
            value={this.state.start_date}
            format="MM/DD/yyyy"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleDeploymentCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      // Device Info
      isMobile: state.device.isMobile,
      // Organization Info
      organization: state.organization.info.info,
      // Deployment Create Info
      deploymentCreateInfo: state.deployment.create.info,
      deploymentCreateError: state.deployment.create.error,
      deploymentCreateStatus: state.deployment.create.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        createDeployment: deployment.create.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  DeploymentCreateDilaog.mapStateToProps,
  DeploymentCreateDilaog.mapDispatchToProps
)(withSnackbar(DeploymentCreateDilaog));
