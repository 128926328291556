// Epic Templates
import get from './templates/get';
// Redux Actions and Types
import roles, { types } from '../actions/roles';
// URL Entry Point
const URL = '/api/roles';
// Epics
export default {
  //            action$ => type(action$, actionHandler,               actionType,                  URL)
  available:    action$ =>  get(action$, roles.available,             types.available,             `${URL}/available`),
  permissions: {
    available:  action$ =>  get(action$, roles.permissions.available, types.permissions.available, `${URL}/permissions/available`),
  }
};
