// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import deployment from '../../../../redux/actions/deployment';
// Components
import BasestationList from './components/BasestationList';
// Material-UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TablePagination from '@material-ui/core/TablePagination';
/**
 * Basestation System
 */
class BasestationSystem extends Component {
  componentDidMount() {
    this.props.getBasestationList({
      deployment_key: this.props.deploymentInfo.deployment_key,
      page_size: this.props.page.size
    });
  }

  handlePageChange = (_, page) => {
    this.props.getBasestationList({
      deployment_key: this.props.deploymentInfo.deployment_key,
      page_size: this.props.page.size,
      page: page + 1
    });
  };

  handlePageSizeChange = ({ target }) => {
    this.props.getBasestationList({
      deployment_key: this.props.deploymentInfo.deployment_key,
      page_size: target.value
    });
  };

  render() {
    return (
      <React.Fragment>
        <List>
          <ListItem>
            <ListItemText primary="Basestations" />
            <TablePagination
              component="div"
              count={this.props.count}
              page={this.props.page.current - 1}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handlePageSizeChange}
              rowsPerPage={this.props.page.size}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Count:"
            />
          </ListItem>
        </List>
        <BasestationList
          list={this.props.list}
          isLoading={this.props.status === 'get'}
        />
      </React.Fragment>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      list: state.deployment.basestation.list.list,
      page: state.deployment.basestation.list.page,
      count: state.deployment.basestation.list.count,
      status: state.deployment.basestation.list.request.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getBasestationList: deployment.basestation.list.get
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  BasestationSystem.mapStateToProps,
  BasestationSystem.mapDispatchToProps
)(withSnackbar(BasestationSystem));
