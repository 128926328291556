// NPM Modules
import React from 'react';
import LogRocket from 'logrocket';
import MomentUtils from '@date-io/moment';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider
} from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// React App
import App from '../app';

// material-ui theme
import theme from '../theme';

// Browser History
const history = createBrowserHistory();

// Redux
import configureStore from '../app/redux/configureStore';
const store = configureStore(window.__PRELOADED_STATE__);

// LogRocket Intergration
// if (process.env.NODE_ENV === 'production') {
//   // Setup dashboard
//   LogRocket.init('zjc4zu/dashboard', {
//     release: 'v1.7.1-staging',
//     network: {
//       requestSanitizer: request => {
//         if (request.url.toLowerCase().indexOf('/api/user/login') !== -1) {
//           request.body = null;
//         }
//         return request;
//       }
//     }
//   });
//   // Get user state from redux and identify the user
//   const state = store.getState();
//   if (state.user.info && state.user.info.status === 'server') {
//     LogRocket.identify(state.user.info.info.email, {
//       name: `${state.user.info.info.first_name} ${state.user.info.info.last_name}`,
//       email: state.user.info.info.email
//     });
//   }
// }

// Wipe state from globals
window.__PRELOADED_STATE__ = {};

hydrate(
  <StylesProvider injectFirst>
    <Provider store={store}>
      <Router history={history}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <App history={history} />
        </MuiPickersUtilsProvider>
      </Router>
    </Provider>
  </StylesProvider>,
  document.getElementById('scan-app'),
  // Remove Sytle sheet after app has loaded
  () => {
    // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }
);

if (module.hot) {
  module.hot.accept();
}
