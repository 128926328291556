import Item from './Item';
/**
 * Rectangle Item
 * @class
 * @memberof Items
 * @extends Item
 */
class Rectangle extends Item {
  /**
   *
   * @param {number} x - x coordinate
   * @param {number} y - y coordinate
   * @param {*} width
   * @param {*} height
   * @param {Item~Options} [options]
   */
  constructor(x = 0, y = 0, width = 0, height = 0, options = {}) {
    super(x, y, options);
    this.width = width;
    this.height = height;
  }

  draw(ctx) {
    // Hold global settings
    ctx.save();
    // Setup Style
    ctx.fillStyle = this.style.fill;
    ctx.strokeStyle = this.style.stroke;
    ctx.lineWidth = this.style.strokeWidth;
    // Translate Canvas
    ctx.translate(this.x, this.y);
    // Draw Shape
    ctx.beginPath();
    ctx.rect(0, 0, this.width, this.height);
    // Fill and Stroke Shape
    ctx.globalAlpha = this.style.fillOpacity;
    ctx.fill();
    ctx.globalAlpha = this.style.strokeOpacity;
    ctx.stroke();
    // Restore global settings
    ctx.restore();
  }
}

export default Rectangle;
