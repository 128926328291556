// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import zone from '../../../../redux/actions/zone';
import array from '../../../../redux/actions/array';
// Components
import ZoneList from './components/ZoneList';
import DoorList from './components/DoorList';
import WallList from './components/WallList';
import ArrayConfigurationTool from '../../../../components/ArrayConfigurationTool';
import { convertRegionToArray } from './util';
// Styled Components
import {
  LayoutManger,
  ZoneListContainer,
  BorderContainer,
  ACTContainer
} from './style';
// Material-UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
// Material-UI Tab Components
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
/**
 * Zone System
 */
class ZoneSystem extends Component {
  state = {
    region: [],
    fetchingZones: true,
    creatingNewZone: false,
    editZone: null,
    zoneToDelete: null,
    selectedList: 0,
    // State of each layer's visibility
    layerVisibilities: {
      tempZoneLayer: true,
      grid: true,
      zoneLayer: true,
      matLayer: false,
      doorLayer: false,
      wallLayer: false
    }
  };

  componentDidMount() {
    this.props.getZoneList({
      array_key: this.props.array.array_key,
      page_size: this.props.zoneListPageSize
    });
    this.props.getMatList({ array_key: this.props.array.array_key });
  }

  handleStatusError(currentStatus, oldStatus, message) {
    if (currentStatus === 'failed' && oldStatus !== 'failed') {
      this.props.enqueueSnackbar(message, { variant: 'error' });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.zoneListStatus === 'success' &&
      this.props.zoneListStatus !== prevProps.zoneListStatus
    ) {
      this.setState({ fetchingZones: false });
    }
    if (
      (this.props.zoneDeleteStatus === 'success' &&
        prevProps.zoneDeleteStatus !== 'success') ||
      (this.props.zoneCreateStatus === 'success' &&
        prevProps.zoneCreateStatus !== 'success')
    ) {
      if (this.state.zoneToDelete !== null) {
        this.props.deleteZone({ zone_key: this.state.zoneToDelete }); // Do this after new zone is confirmed to be created
      }
      this.setState({
        region: [],
        fetchingZones: true,
        creatingNewZone: false,
        editZone: null,
        zoneToDelete: null
      });
      this.props.getZoneList({
        array_key: this.props.array.array_key,
        page_size: this.props.zoneListPageSize
      });
    }
    this.handleStatusError(
      this.props.zoneCreateStatus,
      prevProps.zoneCreateStatus,
      'Failed to create zone. Check that inputs are valid.'
    );
    this.handleStatusError(
      this.props.zoneDeleteStatus,
      prevProps.zoneDeleteStatus,
      'Failed to delete zone.'
    );
    this.handleStatusError(
      this.props.zoneListStatus,
      prevProps.zoneListStatus,
      'Failed to load zone list.'
    );
  }

  handleZoneListPageChange = (_, page) => {
    this.props.getZoneList({
      array_key: this.props.array.array_key,
      page_size: this.props.zoneListPageSize,
      page: page + 1
    });
  };
  handleZoneListPageSizeChange = ({ target }) => {
    this.props.getZoneList({
      array_key: this.props.array.array_key,
      page_size: target.value
    });
  };
  handleRegionChange = region => {
    this.setState({ region: [...region] });
  };
  handleCreateNewZone = () => {
    this.setState({ creatingNewZone: true, editZone: null, region: [] });
  };
  handleCancelNewZone = () => {
    this.setState({ creatingNewZone: false, editZone: null, region: [] });
  };
  handleSetEditZone = zone => {
    // if zone is null then we cancel zone edit
    if (zone !== null) {
      this.setState({
        editZone: zone,
        creatingNewZone: false,
        region: convertRegionToArray(zone.region, true)
      });
    } else {
      this.setState({
        editZone: null,
        creatingNewZone: false,
        region: []
      });
    }
  };
  handleEditZoneSave = (oldZone, newZone) => {
    this.setState({ zoneToDelete: oldZone.zone_key }); // hold old zone_key in state till new zone is created successfully
    this.props.createZone(newZone);
  };
  handleListChange = (_, newValue) => {
    this.setState({ selectedList: newValue });
  };

  toggleLayerVisibility = layer => {
    const layerVisibilities = {
      ...this.state.layerVisibilities,
      [layer]: !this.state.layerVisibilities[layer]
    };
    this.setState({ layerVisibilities });
  };

  renderZoneList() {
    return (
      <React.Fragment>
        <List>
          <ListItem>
            <ListItemText primary="Zones" />
            {/** TODO: Come back in here and clean this up */}
            <ListItemSecondaryAction style={{ display: 'flex' }}>
              {this.props.zoneListCount / this.props.zoneListPageSize > 1 ? (
                <div style={{ flex: '0 0 none' }}>
                  <TablePagination
                    component="div"
                    count={this.props.zoneListCount}
                    page={this.props.zoneListPage - 1}
                    onChangePage={this.handleZoneListPageChange}
                    onChangeRowsPerPage={this.handleZoneListPageSizeChange}
                    rowsPerPage={this.props.zoneListPageSize}
                    labelRowsPerPage="Count:"
                  />
                </div>
              ) : null}
              <div style={{ flex: '0 0 none' }}>
                <Tooltip
                  title={
                    this.state.layerVisibilities.zoneLayer
                      ? 'Hide zone layer'
                      : 'Show zone layer'
                  }
                >
                  <IconButton
                    onClick={() => this.toggleLayerVisibility('zoneLayer')}
                    edge={!this.props.canCreateDelete && 'end'}
                  >
                    {this.state.layerVisibilities.zoneLayer ? (
                      <VisibilityRoundedIcon />
                    ) : (
                      <VisibilityOffRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
                {this.props.canCreateDelete ? (
                  <Tooltip title="Create a zone">
                    <IconButton
                      edge="end"
                      aria-label="create-new-zone"
                      onClick={this.handleCreateNewZone}
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <ZoneList
          list={this.props.zoneList}
          array={this.props.array}
          region={[...this.state.region]}
          onCreate={this.props.createZone}
          onSetEditZone={this.handleSetEditZone}
          onEditZoneSave={this.handleEditZoneSave}
          onCancelCreate={this.handleCancelNewZone}
          onRegionChanged={this.handleRegionChange}
          editZone={this.state.editZone}
          creatingNewZone={this.state.creatingNewZone}
          onDeleteZone={this.props.deleteZone}
          isLoading={this.props.zoneListStatus === 'get'}
          canCreateDelete={this.props.canCreateDelete}
        />
      </React.Fragment>
    );
  }
  /**
   * Update array with new doors
   * @param {array} doors
   */
  handleUpdateDoors = doors => {
    const { array, editArray } = this.props;
    editArray({
      array_key: array.array_key,
      context: { ...array.context, entrance_doors: doors }
    });
  };
  /**
   * Update array with new wall
   * @param {array} walls
   */
  handleUpdateWalls = walls => {
    const { array, editArray } = this.props;
    editArray({
      array_key: array.array_key,
      context: { ...array.context, walls }
    });
  };

  renderLists() {
    switch (this.state.selectedList) {
      case 1:
        return (
          <DoorList
            array={this.props.array}
            isLayerVisible={this.state.layerVisibilities.doorLayer}
            onToggleLayerVisibilty={this.toggleLayerVisibility}
            onUpdateDoors={this.handleUpdateDoors}
            canCreateDelete={this.props.canCreateDelete}
          />
        );
      case 2:
        return (
          <WallList
            array={this.props.array}
            isLayerVisible={this.state.layerVisibilities.wallLayer}
            onToggleLayerVisibilty={this.toggleLayerVisibility}
            onUpdateWalls={this.handleUpdateWalls}
            canCreateDelete={this.props.canCreateDelete}
          />
        );
      default:
        return this.renderZoneList();
    }
  }

  render() {
    return (
      <LayoutManger>
        <ACTContainer top right>
          <ArrayConfigurationTool
            array={this.props.array}
            zones={this.props.zoneList}
            matList={this.props.matList}
            region={[...this.state.region]}
            editZone={this.state.editZone}
            creatingNewZone={this.state.creatingNewZone}
            onRegionChanged={this.handleRegionChange}
            layerVisibilities={this.state.layerVisibilities}
          />
        </ACTContainer>
        <ZoneListContainer>
          <BorderContainer top bottom>
            <Tabs
              value={this.state.selectedList}
              onChange={this.handleListChange}
              variant="fullWidth"
              indicatorColor="primary"
            >
              <Tab label="Zones" />
              <Tab label="Doors" />
              <Tab label="Walls" />
            </Tabs>
          </BorderContainer>
          {this.renderLists()}
        </ZoneListContainer>
      </LayoutManger>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      // Zone List Info
      zoneList: state.zone.list.list,
      zoneListPage: state.zone.list.page,
      zoneListCount: state.zone.list.count,
      zoneListError: state.zone.list.error,
      zoneListStatus: state.zone.list.status,
      zoneListPageSize: state.zone.list.page_size,
      // Zone Create Info
      zoneCreateStatus: state.zone.create.status,
      // Zone Delete Info
      zoneDeleteStatus: state.zone.remove.status,
      // Mat List
      matList: state.array.matList.list,
      matListPage: state.array.matList.page,
      matListCount: state.array.matList.count,
      matListError: state.array.matList.error,
      matListStatus: state.array.matList.status,
      matListPageSize: state.array.matList.page_size
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        // Array
        editArray: array.edit.post,
        getMatList: array.matList.get,
        // Zone
        getZoneList: zone.list.get,
        createZone: zone.create.post,
        deleteZone: zone.remove.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  ZoneSystem.mapStateToProps,
  ZoneSystem.mapDispatchToProps
)(withSnackbar(ZoneSystem));
