const FiveColor = (count, min, max, logScale) => {
  count = count > max ? max : count;

  if (count <= 0) {
    return 'hsla(0,0%,0%,0.1)';
  }
  let value = 0;

  if (logScale && count !== min) {
    // Log Scale
    value = Math.log10(count - min) / Math.log10(max - min);
  } else {
    // Linear Scale
    value = (count - min) / (max - min);
    value = value <= 0 ? 0 : value;
  }

  let h = (1 - value) * 240;
  return `hsla(${h},100%,50%,1)`;
};

const MonoChrome = (count, min, max, logScale) => {
  count = count > max ? max : count;
  count = count < min ? min : count;
  let v = (count - min) / (max - min);
  // School Color: hsla(148°, 100%, 24%, 1)
  let l = v * 50;
  return `hsla(0, 100%, ${l}%, 1)`;
};

export { FiveColor, MonoChrome };