/**
 * intervalSplit
 * @param {string} interval - #d, #h, #m
 * @return {{number, string}} - number string
 */
export default interval => {
  const split = interval.split(/(d|h|m|M)/g, 2);

  const number = parseInt(split[0], 10);
  let type = '';

  switch (split[1]) {
    case 'm':
      type = 'minute';
      break;
    case 'h':
      type = 'hour';
      break;
    case 'd':
      type = 'day';
      break;
    case 'M':
      type = 'month';
      break;
    default:
      break;
  }

  if (number > 1) type += 's';

  return [number, type];
};
