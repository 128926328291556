// NPM Modules
import React from 'react';
import numeral from 'numeral';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
// Herlp Functions
import formatMilliseconds from '../../../../../helpers/formatMilliseconds';
// Styled Components
import { Container, Triangle, ZoneIcon } from './style';
// Material-UI
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
/**
 * HoverInfoPanel Component
 */
export default props => {
  const durMs = formatMilliseconds(props.node.durMs);
  return (
    <MuiThemeProvider theme={props.theme}>
      <ThemeProvider theme={props.theme}>
        <Container
          left={props.left}
          top={props.top.toString()}
          scale={props.scale}
        >
          <Triangle
            left={props.left}
            top={props.top.toString()}
            scale={props.scale}
          />
          <List
            subheader={
              <ListSubheader disableSticky>Pixel Info:</ListSubheader>
            }
          >
            <ListItem dense>
              <ListItemText
                primary={`Pixel:`}
                secondary={`[${props.node.x}, ${props.node.y}]`}
              />
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary={`Count:`}
                secondary={`${numeral(props.node.c).format()}`}
              />
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary={`Duration:`}
                secondary={`${durMs.number} ${durMs.label}`}
              />
            </ListItem>
          </List>
          <Divider />
          <List
            subheader={
              <ListSubheader disableSticky>Zones:</ListSubheader>
            }
          >
            {props.node.zones.map(zone => (
              <ListItem dense key={zone.zone_key}>
                <ListItemIcon>
                  <ZoneIcon iconcolor={zone.color} />
                </ListItemIcon>
                <ListItemText
                  primary={zone.zone_name}
                  secondary={zone.zone_key}
                />
              </ListItem>
            ))}
          </List>
        </Container>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
