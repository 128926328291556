// NPM Modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link as RouterLink } from 'react-router-dom';
// Snackbar extension
import { withSnackbar } from 'notistack';
// Actions
import array from '../../../../redux/actions/array';
// Components
import ArrayList from './components/ArrayList';
import SimpleForm from './components/SimpleForm';
import ArrayCreateDialog from '../../../../components/Dialogs/ArrayCreateDialog';
// Material-UI
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
// Material-UI List Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// Material-UI Icons
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
/**
 * Array System
 */
class ArraySystem extends Component {
  state = {
    arrayCreateDialogOpen: false
  };

  componentDidMount() {
    this.props.getArrayList({
      deployment_key: this.props.deployment.deployment_key,
      page_size: this.props.arrayListPageSize,
      status: 'active'
    });
  }

  handleSnackbarMessage(currentStatus, oldStatus, status, variant, message) {
    if (currentStatus === status && oldStatus !== status) {
      this.props.enqueueSnackbar(message, { variant });
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (
      props.arrayDeleteStatus === 'success' &&
      prevProps.arrayDeleteStatus !== 'success'
    ) {
      this.setState({ formOpen: false });
      props.getArrayList({
        deployment_key: props.deployment.deployment_key,
        page_size: this.props.arrayListPageSize,
        status: 'active'
      });
    }
    if (props.arrayDeleteInfo) {
      this.handleSnackbarMessage(
        props.arrayDeleteStatus,
        prevProps.arrayDeleteStatus,
        'success',
        'default',
        `${props.arrayDeleteInfo.message}.`
      );
    }
    this.handleSnackbarMessage(
      props.arrayDeleteStatus,
      prevProps.arrayDeleteStatus,
      'failed',
      'error',
      'Failed to delete array.'
    );
    this.handleSnackbarMessage(
      props.arrayListStatus,
      prevProps.arrayListStatus,
      'failed',
      'error',
      'Failed to load array list.'
    );
  }

  handleDeleteArray = array => {
    this.props.deleteArray(array);
  };

  handleArrayListPageChange = (_, page) => {
    this.props.getArrayList({
      deployment_key: this.props.deployment.deployment_key,
      page_size: this.props.arrayListPageSize,
      page: page + 1,
      status: 'active'
    });
  };

  handleArrayListPageSizeChange = ({ target }) => {
    this.props.getArrayList({
      deployment_key: this.props.deployment.deployment_key,
      page_size: target.value,
      status: 'active'
    });
  };

  /**
   * When array is created. Get array list and set array create dialog open to false
   */
  handleArrayCreated = () => {
    this.setState({ arrayCreateDialogOpen: false });
    this.props.getArrayList({
      deployment_key: this.props.deployment.deployment_key,
      page_size: this.props.arrayListPageSize,
      status: 'active'
    });
  };

  render() {
    const {
      deployment,
      canCreateDelete,
      arrayCreateStatus,
      arrayList,
      arrayListPage,
      arrayListCount,
      arrayListStatus,
      arrayListPageSize
    } = this.props;
    return (
      <div>
        <ArrayCreateDialog
          deployment_key={this.props.deployment.deployment_key}
          open={this.state.arrayCreateDialogOpen}
          onArrayCreated={this.handleArrayCreated}
          onClose={() => this.setState({ arrayCreateDialogOpen: false })}
        />
        <List>
          <ListItem>
            <ListItemText primary="Arrays" />
            {canCreateDelete ? (
              <ListItemSecondaryAction>
                <div style={{ display: 'flex' }}>
                  <TablePagination
                    component="div"
                    count={arrayListCount}
                    page={arrayListPage.current - 1}
                    onChangePage={this.handleArrayListPageChange}
                    onChangeRowsPerPage={this.handleArrayListPageSizeChange}
                    rowsPerPage={arrayListPageSize}
                    labelRowsPerPage="Count:"
                  />

                  <Tooltip title="Create an array">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        this.setState({ arrayCreateDialogOpen: true });
                      }}
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        </List>
        <ArrayList
          list={arrayList}
          onDeleteArray={this.handleDeleteArray}
          isLoading={arrayListStatus === 'get'}
          canCreateDelete={canCreateDelete}
        />
      </div>
    );
  }
  /**
   * static mapStateToProps
   * Maps the redux state to the component state.
   * @param {object} state redux state
   * @return {object} object of redux states
   */
  static mapStateToProps(state) {
    return {
      // Array List Info
      arrayList: state.array.list.list,
      arrayListPage: state.array.list.page,
      arrayListCount: state.array.list.count,
      arrayListError: state.array.list.request.error,
      arrayListStatus: state.array.list.request.status,
      arrayListPageSize: state.array.list.page.size,
      // Array Delete Info
      arrayDeleteInfo: state.array.remove.info,
      arrayDeleteStatus: state.array.remove.status
    };
  }
  /**
   * static mapDispatchToProps
   * Binds all the dispatch actions to one object.
   * @param {object} dispatch dispatch callback
   * @return {object} collectiong of dispatch actions
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getArrayList: array.list.get,
        deleteArray: array.remove.post
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  ArraySystem.mapStateToProps,
  ArraySystem.mapDispatchToProps
)(withSnackbar(ArraySystem));
