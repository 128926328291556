// Modules
import styled from 'styled-components';
// Material-UI
import MaterialDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MaterialListItem from '@material-ui/core/ListItem';
import MaterialListItemIcon from '@material-ui/core/ListItemIcon';
import MaterialListSubheader from '@material-ui/core/ListSubheader';
// Styled Components
export const Drawer = styled(MaterialDrawer)`
  /* width: 255px; */
  flex-shrink: 0;

  .MuiDrawer-paper {
    background: ${({ theme }) => theme.navigation.sideMenu.background};
    @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      width: 255px;
      top: ${({ theme }) => theme.navigation.topBar.barHeight}px;
      height: calc(
        100% - ${({ theme }) => theme.navigation.topBar.barHeight}px
      );
    }
  }
`;
export const MenuLogo = styled(MaterialListItem)`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  background: ${({ theme }) => theme.navigation.topBar.background};
`;
export const ListItem = styled(MaterialListItem)`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(6)}px`};
  ${({ active, theme }) => {
    if (active === 'true') {
    }
  }}

  &&:hover {
  }
`;
export const ListItemIcon = styled(MaterialListItemIcon)`
  min-width: 48px;
`;
export const ListSubheader = styled(MaterialListSubheader)`
  padding: ${({ theme }) =>
    `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
      2
    )}px ${theme.spacing(6)}px`};
  line-height: 20px;
`;
export const Footer = styled.div`
  margin-top: auto;
  padding: 16px;
  font-size: 14px;
  text-align: center;
`;
export const Logo = styled.div`
  background: rgba(0, 0, 0, 0) url(${({ theme }) => theme.logourl});
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 160px;
`;
export const MenuButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.navigation.topBar.text};
    padding: 8px;
    margin: 0 ${({ theme }) => theme.spacing(4)}px 0 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      margin: 0 ${({ theme }) => theme.spacing(1)}px 0 0;
    }
  }
`;
