// Components
import Page from '../../../components/Page';
import UserInfoForm from './components/UserInfoForm';
import UserPasswordForm from './components/UserPasswordForm';
import { useSelector } from 'react-redux';
// Material-UI
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

function UserSettings() {
  const isMobile = useSelector(state => state.device.isMobile);
  const width = isMobile ? '100%' : 400;
  return (
    <Page>
      <Box maxWidth={width}>
        <UserInfoForm />
      </Box>
      <Box my={4}>
        <Divider />
      </Box>{' '}
      <Box maxWidth={width}>
        <UserPasswordForm />{' '}
      </Box>
    </Page>
  );
}

export default UserSettings;
