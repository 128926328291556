// NPM Modules
import React, { Component } from 'react';
// Material-UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
/**
 * Simple Dialog Form
 */
class SimpleForm extends Component {
  state = {
    array_name: this.props.array.array_name,
    bounding_box_size_x: this.props.array.bounding_box_size_x,
    bounding_box_size_y: this.props.array.bounding_box_size_y,
    description: this.props.array.description,
    region: this.props.array.region
  };

  handleArrayEdit = () => {
    this.props.onEdit({
      array_key: this.props.array.array_key,
      array_name: this.state.array_name,
      bounding_box_size_x: this.state.bounding_box_size_x,
      bounding_box_size_y: this.state.bounding_box_size_y,
      description: this.state.description,
      region: this.state.region
    });
  };

  handleTextFieldChange = event => {
    let region = this.state.region;
    if (event.target.id === 'bounding_box_size_x') {
      region = `POLYGON ((0 0, ${event.target.value - 1} 0, ${
        event.target.value - 1
      } ${this.state.bounding_box_size_y - 1}, 0 ${
        this.state.bounding_box_size_y - 1
      }, 0 0))`;
    }
    if (event.target.id === 'bounding_box_size_y') {
      region = `POLYGON ((0 0, ${this.state.bounding_box_size_x - 1} 0, ${
        event.target.value - 1
      } ${this.state.bounding_box_size_x - 1}, 0 ${
        event.target.value - 1
      }, 0 0))`;
    }
    this.setState({ [event.target.id]: event.target.value, region });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullScreen={this.props.isMobile}
      >
        <DialogTitle>Edit Array</DialogTitle>
        <DialogContent>
          <TextField
            id="array_name"
            label="Name"
            type="text"
            fullWidth
            value={this.state.array_name}
            onChange={this.handleTextFieldChange}
          />
          <TextField
            id="bounding_box_size_x"
            label="Bounding Box Size X"
            type="number"
            min={0}
            fullWidth
            value={this.state.bounding_box_size_x}
            onChange={this.handleTextFieldChange}
          />
          <TextField
            id="bounding_box_size_y"
            label="Bounding Box Size Y"
            type="number"
            min={0}
            fullWidth
            value={this.state.bounding_box_size_y}
            onChange={this.handleTextFieldChange}
          />
          <TextField
            id="description"
            label="Description"
            type="text"
            fullWidth
            value={this.state.description}
            onChange={this.handleTextFieldChange}
          />
          <TextField
            id="region"
            label="Region"
            type="text"
            fullWidth
            disabled
            value={this.state.region}
            onChange={this.handleTextFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleArrayEdit}>Update</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SimpleForm;
