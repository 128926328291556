import Item from './Item';
/**
 * Polygon Item
 * @class
 * @memberof Items
 * @extends Item
 */
class Polygon extends Item {
  /**
   *
   * @param {number} x - x coordinate
   * @param {number} y - y coordinate
   * @param {Array.<Array.<number>>} points
   * @param {Item~Options} [options]
   */
  constructor(x = 0, y = 0, points = [], options = {}) {
    super(x, y, options);
    this.points = points;
    this.closePath = true;
  }

  draw(ctx) {
    ctx.save();
    ctx.fillStyle = this.style.fill;
    ctx.strokeStyle = this.style.stroke;
    ctx.lineWidth = this.style.strokeWidth;
    ctx.translate(this.x, this.y);
    ctx.beginPath();
    this.points.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point[0], point[1]);
      } else {
        ctx.lineTo(point[0], point[1]);
        if (this.closePath && index === this.points.length - 1) {
          ctx.closePath();
        }
      }
    });
    ctx.globalAlpha = this.style.fillOpacity;
    ctx.fill();
    ctx.globalAlpha = this.style.strokeOpacity;
    ctx.stroke();
    ctx.restore();
  }
}

export default Polygon;
