// NPM Modules
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Snackbar extension
import { useSnackbar } from 'notistack';
// Redux
import userAction from '../../../../redux/actions/user';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
/**
 * Form for updating User Info
 * @returns {React.Component}
 */
function UserPasswordForm() {
  // Setup notistack
  const { enqueueSnackbar } = useSnackbar();

  // Redux State and Action Setup
  const dispatch = useDispatch();
  const status = useSelector(state => state.user.changePassword.status);

  // Component State
  const [state, setState] = React.useState({
    current_password: '',
    new_password: '',
    repeat_password: '',
    valid: false
  });

  // Update Component State
  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value
    });
  };

  // Dispatch User Info Edit
  const dispatchUserPasswordChange = event => {
    event.preventDefault();
    dispatch(
      userAction.changePassword.post({
        new_password: state.new_password,
        current_password: state.current_password
      })
    );
  };

  React.useEffect(() => {
    let valid =
      state.new_password === state.repeat_password &&
      state.new_password !== state.current_password &&
      state.new_password.length > 7;
    if (valid !== state.valid) {
      setState({ ...state, valid });
    }
  }, [state]);

  // Inform user on post request changes
  React.useEffect(() => {
    switch (status) {
      case 'success':
        enqueueSnackbar('Password successfully update.', {
          variant: 'success'
        });
        setState({
          current_password: '',
          new_password: '',
          repeat_password: ''
        });
        break;
      case 'failed':
        enqueueSnackbar('Something went wrong. Check inputs.', {
          variant: 'error'
        });
        break;
      default:
        break;
    }
  }, [status]);

  return (
    <form onSubmit={dispatchUserPasswordChange}>
      <Typography variant="h5">Update Password</Typography>
      <FormControl fullWidth margin="dense">
        <TextField
          margin="dense"
          onChange={handleChange}
          name="current_password"
          type="password"
          label="Current Password"
          value={state.current_password}
        />
        <TextField
          margin="dense"
          error={!state.valid && state.new_password !== ''}
          onChange={handleChange}
          name="new_password"
          type="password"
          label="New Password"
          value={state.new_password}
        />
        <TextField
          margin="dense"
          error={!state.valid && state.new_password !== ''}
          helperText={
            !state.valid && state.new_password !== ''
              ? 'Password do not match or password is not at least 8 characters long.'
              : ''
          }
          onChange={handleChange}
          name="repeat_password"
          type="password"
          label="Repeat New Password"
          value={state.repeat_password}
        />
        <input type="submit" style={{ display: 'none' }} />{' '}
        <Box mt={2} mb={1}>
          <Button disabled={!state.valid} onClick={dispatchUserPasswordChange}>
            Change Password
          </Button>{' '}
        </Box>
      </FormControl>
    </form>
  );
}

export default UserPasswordForm;
