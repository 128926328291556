import { Items } from '../Cake';
/**
 * Mat Item
 * @class
 * @extends Items.Rectangle
 */
class LineTextItem extends Items.Line {
  /**
   * @param {*} width
   * @param {*} height
   * @param {Item~Options} [options]
   */
  constructor(p1, p2, text = '', options = {}) {
    super(0, 0, p1, p2, options);
    this.text = text;
  }

  draw(ctx) {
    // Save current canvas setting
    ctx.save();
    // Setup Base
    ctx.fillStyle = this.style.stroke;
    ctx.strokeStyle = this.style.stroke;
    ctx.lineWidth = this.style.strokeWidth;
    ctx.globalAlpha = this.style.strokeOpacity;
    // Translate
    ctx.translate(this.x, this.y);

    // Circle Ends (cuz easy life)
    ctx.beginPath();
    ctx.arc(this.p1[0], this.p1[1], this.style.strokeWidth / 2, 0, Math.PI * 2);
    ctx.fill();

    ctx.beginPath();
    ctx.arc(this.p2[0], this.p2[1], this.style.strokeWidth / 2, 0, Math.PI * 2);
    ctx.fill();

    // Cut Cirlce Parts
    ctx.globalCompositeOperation = 'destination-out';
    ctx.globalAlpha = 1;
    ctx.beginPath();
    ctx.moveTo(this.p1[0], this.p1[1]);
    ctx.lineTo(this.p2[0], this.p2[1]);
    ctx.stroke();

    // Draw Line
    ctx.globalCompositeOperation = 'source-over';
    ctx.globalAlpha = this.style.strokeOpacity;
    ctx.beginPath();
    ctx.moveTo(this.p1[0], this.p1[1]);
    ctx.lineTo(this.p2[0], this.p2[1]);
    ctx.stroke();

    // Text Part
    ctx.globalCompositeOperation = 'destination-out';
    ctx.font = 'bold 18px arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.translate((this.p1[0] + this.p2[0]) / 2, (this.p1[1] + this.p2[1]) / 2);
    const dx = this.p2[0] - this.p1[0];
    const dy = this.p2[1] - this.p1[1];
    ctx.rotate(Math.atan2(dy, dx)); // Why dafuq is this (Y, X)???
    ctx.fillText(this.text, 0, 0);

    // Restore canvas settings
    ctx.restore();
  }
}

export default LineTextItem;
