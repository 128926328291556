export default function offscreenCanvas(width, height) {
  if (window.hasOwnProperty('OffscreenCanvas')) {
    return new OffscreenCanvas(width, height);
  } else {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
}
