// Actions
import post from './templates/post';
import users, { types } from '../actions/users';
// API URL Shortener
const URL = '/api/users';
// Epics
// prettier-ignore
export default {
  //                  action$    type(action$,  actionHandler,            actionType,               URL?params)
  acceptInvitation:   action$ => post(action$,  users.acceptInvitation,   types.acceptInvitation,   `${URL}/accept-invitation`)
};
