// NPM Modules
import { combineReducers } from 'redux';
// Actions
import { types } from '../actions/user';
// Reducer Template
import get from './templates/get';
import list from './templates/list';
import post from './templates/post';
/**
 * Info Reducer
 */
const defaultState = {
  status: null,
  error: null,
  info: null
};
const infoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.logout.SUCCESS:
      return { ...defaultState };
    case types.login.POST:
      return { status: 'post', error: null, info: null };
    case types.login.SUCCESS:
      return { status: 'success', error: null, info: action.payload };
    case types.login.FAILED:
      return { status: 'failed', error: action.payload, info: null };
    case types.superuser.SUCCESS:
      if (state.info !== null) {
        return {
          ...state,
          info: {
            ...state.info,
            toggle_super_user: action.payload.state
          }
        };
      }
      return state;
    default:
      return state;
  }
};
// Reducers
export default combineReducers({
  info:           (s, a) => infoReducer(s, a),
  edit:           (s, a) => post(s, a, types.edit),
  changePassword: (s, a) => post(s, a, types.changePassword),
  role: combineReducers({
    list:         (s, a) => list(s, a, types.role.list),
    create:       (s, a) => post(s, a, types.role.create),
    remove:       (s, a) => post(s, a, types.role.remove)
  })
});
