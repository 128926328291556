// NPM
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
// Components
import Page from '../../components/Page';
// Material-UI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// Material-UI Card
import MuiCard from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MuiCardActionArea from '@material-ui/core/CardActionArea';
// Styled Components
const GridItem = styled(Grid)`
  display: flex;
`;
const Card = styled(MuiCard)`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;
const CardActionArea = styled(MuiCardActionArea)`
  flex-grow: 1;
`;
/**
 * Helper Component
 * @param {object} props
 * @returns {React.Component}
 */
const Item = props => (
  <GridItem item xs={12} sm={6}>
    <Card>
      <CardActionArea
        component={RouterLink}
        to={`./data-exploration/${props.path}`}
      >
        {props.image ? (
          <CardMedia src={props.image} title={props.label} />
        ) : null}
        <CardContent>
          <Typography gutterBottom variant="h6">
            {props.label}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component={RouterLink}
          to={`./data-exploration/${props.path}`}
        >
          Explore {props.label}
        </Button>
      </CardActions>
    </Card>
  </GridItem>
);
Item.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
};
Item.defaultProps = {
  path: '',
  label: '',
  image: null,
  description: ''
};
/**
 * Data Exploration Page
 */
export default () => (
  <Page>
    <Grid spacing={3} container>
      <Item
        label="Heatmaps"
        path="heatmaps"
        description="View detailed pixel heatmaps of your arrays. Compare different arrays accross different time spans."
      />
      <Item
        label="Entrances"
        path="entrances"
        description="Compare zone entry exit data."
      />
      <Item
        label="Visits"
        path="visits"
        description="Compare zone visit data."
      />
      <Item
        label="Occupancy"
        path="occupancy"
        description="View historical occupancy data."
      />
    </Grid>
  </Page>
);
