// NPM Modules
import styled from 'styled-components';
// Material-UI
import MuiToolbar from '@material-ui/core/Toolbar';
// Styled Components
export const Section = styled.div`
  padding: ${({ theme, isMobile }) =>
    `${theme.spacing(3)}px ${theme.spacing(isMobile ? 4 : 6)}px`};
  ${({ seperate }) =>
    !!seperate &&
    `display: flex;
    justify-content: space-between;`}
`;
export const Toolbar = styled(MuiToolbar)`
  padding: 0px ${({ isMobile, theme }) => theme.spacing(isMobile ? 4 : 6)}px;
`;
