// Action Calls
export const types = {
  startDateTime: {
    SET: 'dateTime/STARTDATETIME:SET'
  },
  endDateTime: {
    SET: 'dateTime/ENDDATETIME:SET'
  },
  interval: {
    SET: 'dateTime/INTERVAL:SET'
  },
  all: {
    SET: 'dateTime/ALL:SET'
  }
};
// DataPoints Functions
export default {
  types,
  startDateTime: {
    set: datetime => ({ type: types.startDateTime.SET, payload: datetime })
  },
  endDateTime: {
    set: datetime => ({ type: types.endDateTime.SET, payload: datetime })
  },
  interval: {
    set: interval => ({ type: types.startDateTime.SET, payload: interval })
  },
  all: {
    set: payload => ({ type: types.all.SET, payload })
  },
};
